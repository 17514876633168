/* ------------------------------------------------------------------------------------
// STYLES HEADER
/ ------------------------------------------------------------------------------------*/


body {
  #dx-page-body {
    header {
      //background-color: yellow;
      //Donner une hauteur au header si on utilise le sticky (si le contenu est position fixed ou absolute, il n'a pas de hauteur automatiquement). Ça tient le contenu en-dessous et le JS en a besoin.
      //height:$header-height;
      .container-nav {
        .sticky-nav {
          top: 0;
          transition: top 0.6s ease-out,padding-top 0.6s;
        }
        nav.navbar {
          padding-top: 32px;
          //transition:padding-top 0.6s;
          padding-right: 0;
          padding-left: 0;
          background-color: $secondary;
          @include media-breakpoint-down(lg) {
            padding-top: 15px;
            padding-bottom: 0;
          }
          .navbar-toggler {
            border: none;
            @include media-breakpoint-down(lg) {
              padding-top: 0;
              margin-top: -3px;
            }
            .navbar-toggler-icon {
              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
            }
          }
          .container-fluid {
            @include media-breakpoint-down(lg) {
              padding-left: 7px;
              padding-right: 7px;
              align-items: flex-start;
              flex-direction: column;
            }
          }
          .nav-box{
            @include media-breakpoint-down(lg) {
              width: 100%;
              display: flex;
              flex-direction: column;
            }
          }
          .nav-top {
            width:100%;
            transition:width 0.5s;
            display: flex;
            @include media-breakpoint-down(lg) {
              flex-direction: row;
              justify-content: space-between;
              width: 100%;
            }
          }
          .navbar-brand {
            z-index: 2000;
            @include media-breakpoint-down(5 xl) {

            }
            @include media-breakpoint-down(4 xl) {
            }
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xxl) {
              max-width: 200px;
            }
            @include media-breakpoint-down(xl) {
              display: none;
            }
          }
          .navbar-brand-mobile {
            display: none;
            @include media-breakpoint-down(xl) {
              display: flex;
              z-index: 2000;
            }
            @include media-breakpoint-down(lg) {
              padding-top: 10px;
            }
          }
          .camion-box-mobile {
            display: none;
            @include media-breakpoint-down(lg) {
              display: flex;
              padding-right: 13px;
              padding-top: 7px;
            }
          }
          .nav-mobile-search {
            display: none;
            @include media-breakpoint-down(lg) {
              display: flex;
              width: 100%;
              justify-content: center;
            }
            .recherche-mobile-box {
              padding: 10px 15px 10px 15px;
              width: 100%;
              form {
                width: 100%;
                .input-group {
                  min-width: 280px;
                  width: auto;
                  @include media-breakpoint-down(md) {
                    width: 100%;
                  }
                }
                #basic-addon1 {
                  border-bottom-left-radius: 20px;
                  border-top-left-radius: 20px;
                  background-color: white;
                  border-color: white;
                }
                input {
                  border-top-right-radius: 20px;
                  border-bottom-right-radius: 20px;
                  @include font-light-1;
                  font-size: 13px;
                  letter-spacing: 1.02px;
                  border-color: white;
                  background-color: white;
                  margin-bottom: 0;
                  padding: 0;
                }
              }
            }
          }
          .offcanvas {
            top: $header-height-mobile;
            .offcanvas-header {

            }
          }
          .mobile-onglets {
            display: none;
            @include media-breakpoint-down(lg) {
              display: flex;
              width: 100%;
              justify-content: flex-start;
              padding-left: 9px;
              padding-top: 13px;
              padding-bottom: 30px;
            }
            .nav-link {
              font-size: 14px;
              line-height: 17px;
              text-transform: uppercase;
              padding: 0 15px;
              @include font-reg-1;
              &.active {
                @include font-bold-1;
                text-decoration: underline;
              }
            }
          }
          .navbar-nav {
            padding-left: 24px;
            padding-top: 5px;
            flex-wrap: wrap;
            @include media-breakpoint-down(lg) {
              padding: 0 !important;
            }
            .nav-item {
              .nav-link {
                @include font-black-1;
                text-transform: uppercase;
                font-size: 16px;
                color: $color-text-alt;
                z-index: 2000;
                position: relative;
                padding-left: 0;
                padding-right: 0;
                margin-left: 20px;
                margin-right: 20px;
                @include media-breakpoint-down(5 xl) {
                  font-size: 15px;
                }
                @include media-breakpoint-down(4 xl) {
                  font-size: 14px;
                }
                @include media-breakpoint-down(xxxl) {
                  font-size: 13px;
                }
                @include media-breakpoint-down(xxl) {
                  font-size: 12px;
                }
                @include media-breakpoint-down(lg) {
                  color: $color-text-main;
                  font-size: 14px;
                  @include font-bold-1;
                }
                &:before {
                  background-color: transparent;
                  width: 100%;
                  height: 4px;
                  display: block;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  content: "";
                  transition: background-color 0.2s linear;
                }
                &:after {
                  @include media-breakpoint-down(lg) {
                    display: none;
                  }
                }
                &:hover {
                  &:before {
                    background-color: $color-inverse;
                  }
                }
              }
              &.nav-mobile-item {
                .nav-link {
                  text-transform: none;
                  padding-left: 40px;
                  @include font-reg-1;
                  font-size: 14px;
                  &:before {
                    width: 20px;
                    height: 20px;
                    bottom: auto;
                    top: 11px;
                    left: 3px;
                    background-repeat: no-repeat;
                  }
                }
                #link-propos {
                  &:before {
                    background-image: url("/assets/img/ico-mobile-propos.svg");
                  }
                }
                #link-fun {
                  &:before {
                    background-image: url("/assets/img/ico-mobile-fun.svg");
                  }
                }
                #link-contact {
                  &:before {
                    background-image: url("/assets/img/ico-mobile-contact.svg");
                  }
                }
              }
              &.dropdown {
                position: inherit;
                a.dropdown-toggle {
                  @include media-breakpoint-down(lg) {
                    display: none;
                  }
                }
                &:hover {
                  > ul {
                    @include media-breakpoint-up($tablet-size) {
                      display: flex;
                    }
                  }
                }

                .dropdown-menu:hover {
                  @include media-breakpoint-up($tablet-size) {
                    display: flex;
                  }
                }
                .dropdown-menu {
                  width: 100%;
                  background-color: $color-secondary;
                  padding-top: 54px;
                  border: none;
                  padding-left: 135px;
                  padding-bottom: 50px;
                  left: 0;
                  flex-wrap: wrap;
                  @include media-breakpoint-down(lg) {
                    display: none;
                    background-color: transparent;
                    padding-top: 10px;
                    padding-left: 0;
                    padding-bottom: 10px;
                    flex-direction: column;
                  }
                  &.onglet-active{
                    @include media-breakpoint-down(lg) {
                      display: flex;
                    }
                  }
                  li {
                    @include media-breakpoint-down(lg) {
                      border-bottom: 1px solid $color-border-1;
                    }
                    .dropdown-item {
                      color: $color-inverse;
                      text-transform: uppercase;
                      transition: background-color 0.2s linear;
                      @include media-breakpoint-down(xxl) {
                        font-size: 14px;
                      }
                      @include media-breakpoint-down(xl) {
                        font-size: 13px;
                      }
                      @include media-breakpoint-down(lg) {
                        color: $color-text-main;
                        padding: 10px 25px;
                        text-transform: none;
                        font-size: 14px;
                      }
                      &:hover, &:focus {
                        background-color: transparent;
                        @include media-breakpoint-down(lg) {
                          background-color: $color-secondary;
                          color: $color-inverse;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .nav-right-box {
          display: flex;
          align-items: center;
          margin-top: -10px;
          padding-left: 10px;
          //padding-right: 38px;
          @include media-breakpoint-down(lg) {
            display: none;
          }
          a {
            img {
              transition: opacity 0.2s linear;
              z-index: 2000;
              position: relative;
            }
            &:hover {
              img {
                opacity: 0.8;
              }
            }
          }
          .zero-fail-box {
            img{
                 max-width:100%;
               // transition: all 0.5s;
            }
          }
          .search-box {
            padding-left: 33px;

          }
          .camion-box {
            padding-right: 15px;
            padding-left: 23px;

          }
        }
      }
      #offcanvasSearchBar {
        top: calc($header-height - 30px);
        border-radius: 38px;
        width: 88%;
        margin: 0 auto;
        border: 4px solid $color-border-2;
        height: 585px;
        max-height: 75vh;
        @include media-breakpoint-down(5 xl) {

        }
        @include media-breakpoint-down(4 xl) {
        }
        @include media-breakpoint-down(xxxl) {
        }
        @include media-breakpoint-down(xxl) {
        }
        @include media-breakpoint-down(lg) {
          top: $header-height-mobile;
          right: auto;
          margin: 0;
          border: none;
          border-radius: 0;
          height: 100%;
          max-height: none;
          width: 85%;
          //transform: translateX(-100%);
        }
        .offcanvas-header {
        }
        .offcanvas-body {
          padding: 0;
          margin-right: 20px; // scroll
          @include media-breakpoint-down(lg) {
            margin-right: 0;
          }


        }
        .recherche-box {
          //@extend .container-fluid;
          .recherche-input-box {
            padding: 10px 15px 30px 15px;
            width: 100%;
            @include media-breakpoint-down(5 xl) {
            }
            @include media-breakpoint-down(4 xl) {
              padding: 10px 15px 20px 15px;
            }
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xxl) {
              padding: 10px 15px 10px 15px;
            }
            @include media-breakpoint-down(lg) {
              display: none;
            }
            form {
              width: 100%;
              .input-group {
                min-width: 280px;
                width: auto;
                @include media-breakpoint-down(md) {
                  width: 100%;
                }
              }
              #basic-addon1 {
                border-bottom-left-radius: 20px;
                border-top-left-radius: 20px;
                background-color: white;
                border-color: white;
              }
              input {
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                @include font-light-1;
                font-size: 16px;
                letter-spacing: 0.8px;
                border-color: white;
                background-color: white;
                margin-bottom: 0;
              }
            }
          }
          .result-box {
            display: flex;
            justify-content: space-between;
            padding: 0 35px;
            @include media-breakpoint-down(lg) {
              flex-direction: column;
              padding: 25px 35px;
            }
            .section-un {
              width: 15%;
              @include media-breakpoint-down(lg) {
                width: 100%;
              }
            }
            .section-deux {
              width: 52%;
              @include media-breakpoint-down(lg) {
                width: 100%;
              }
            }
            .section-trois {
              width: 27%;
              @include media-breakpoint-down(lg) {
                width: 100%;
              }
            }
            .result-col-header {
              border-bottom: 2px solid $color-border-2;
              h4 {
                text-transform: uppercase;
                @include font-bold-1;
                font-size: 18px;
                letter-spacing: 0.95px;
                line-height: 28px;
                padding-bottom: 10px;
                padding-left: 2px;
                @include media-breakpoint-down(5 xl) {
                }
                @include media-breakpoint-down(4 xl) {
                  font-size: 17px;
                  letter-spacing: 0.9px;
                  line-height: 27px;
                  padding-bottom: 9px;
                }
                @include media-breakpoint-down(xxxl) {
                  font-size: 16px;
                  letter-spacing: 0.8px;
                  line-height: 26px;
                  padding-bottom: 8px;
                }
                @include media-breakpoint-down(xxl) {
                  font-size: 14px;
                  letter-spacing: 0.6px;
                  line-height: 24px;
                  padding-bottom: 2px;
                }
                @include media-breakpoint-down(lg) {
                  font-size: 13px;
                  letter-spacing: 0.72px;
                  line-height: 21px;
                  padding-bottom: 2px;
                }
              }
            }
            .result-content-box {

            }
            .result-populaires-box {
              .result-content-box {
                ul {
                  margin: 0;
                  padding: 12px 0;
                  list-style-type: none;
                  @include media-breakpoint-down(lg) {
                    padding: 8px 0 44px 0;
                  }
                  li {
                    a {
                      padding: 9px 0;
                      display: block;
                      color: $color-text-main;
                      @include font-light-1;
                      font-size: 18px;
                      line-height: 24px;
                      letter-spacing: 1.35px;
                      @include media-breakpoint-down(5 xl) {
                      }
                      @include media-breakpoint-down(4 xl) {
                        font-size: 17px;
                        line-height: 23px;
                        letter-spacing: 1.3px;
                      }
                      @include media-breakpoint-down(xxxl) {
                        font-size: 16px;
                        line-height: 22px;
                        letter-spacing: 1.2px;
                      }
                      @include media-breakpoint-down(xxl) {
                        font-size: 15px;
                        line-height: 21px;
                        letter-spacing: 1.15px;
                      }
                      @include media-breakpoint-down(lg) {
                        font-size: 13px;
                        line-height: 19px;
                        letter-spacing: 1.02px;
                        padding: 6px 0;
                      }
                    }
                  }
                }
              }
            }
            .result-tendances-box {
              .result-content-box {
                ul {
                  margin: 0;
                  padding: 18px 10px 12px 0;
                  list-style-type: none;
                  display: flex;
                  flex-wrap: wrap;
                  width: 100%;
                  justify-content: space-between;
                  @include media-breakpoint-down(lg) {
                    padding-bottom: 0;
                  }
                  li {
                    width: calc(50% - 8px);
                    margin-bottom: 16px;
                    @include media-breakpoint-down(lg) {
                      width: 100%;
                    }
                    a {
                      display: flex;
                      align-items: stretch;
                      height: 100%;
                      width: 100%;
                      background-color: rgba(72, 68, 66, 0.051);
                      border-radius: 20px;
                      //margin: 5px;
                      padding: 10px;
                      color: $color-text-main;
                      transition: background-color 0.2s linear;
                      @include media-breakpoint-down(xxl) {
                        padding: 5px 10px;
                      }
                      &:hover, &:focus {
                        background-color: rgba(72, 68, 66, 0.2);
                      }
                      .product-thumb-box {
                        display: flex;
                        align-items: center;
                        .img-box {
                          max-width: 67px;
                          margin-right: 15px;
                          @include media-breakpoint-down(xxl) {
                            max-width: 50px;
                          }
                        }
                        .desc-box {
                          padding: 11px 0 3px 0;
                          h5 {
                            @include font-bold-1;
                            font-size: 13px;
                            line-height: 19px;
                            letter-spacing: 0.65px;
                            margin-bottom: 0;
                            @include media-breakpoint-down(5 xl) {
                            }
                            @include media-breakpoint-down(4 xl) {
                            }
                            @include media-breakpoint-down(xxxl) {
                              font-size: 12px;
                              line-height: 18px;
                              letter-spacing: 0.6px;
                            }
                            @include media-breakpoint-down(xxl) {
                              font-size: 11px;
                              line-height: 17px;
                              letter-spacing: 0.5px;
                            }
                            @include media-breakpoint-down(lg) {
                              font-size: 10px;
                              line-height: 14px;
                              letter-spacing: 0.49px;
                            }
                          }
                          .prix-txt {
                            @include font-reg-1;
                            font-size: 13px;
                            line-height: 19px;
                            letter-spacing: 0.65px;
                            @include media-breakpoint-down(5 xl) {
                            }
                            @include media-breakpoint-down(4 xl) {
                            }
                            @include media-breakpoint-down(xxxl) {
                              font-size: 12px;
                              line-height: 18px;
                              letter-spacing: 0.6px;
                            }
                            @include media-breakpoint-down(xxl) {
                              font-size: 11px;
                              line-height: 17px;
                              letter-spacing: 0.5px;
                            }
                            @include media-breakpoint-down(lg) {
                              font-size: 10px;
                              line-height: 14px;
                              letter-spacing: 0.49px;
                            }
                          }
                          .prix-strike {
                            @include font-reg-1;
                            font-size: 10px;
                            line-height: 16px;
                            letter-spacing: 0.5px;
                            text-decoration: line-through;
                            display: inline-block;
                            margin-left: 10px;
                            vertical-align: text-top;
                            @include media-breakpoint-down(lg) {
                              font-size: 8px;
                              line-height: 12px;
                              letter-spacing: 0.38px;
                              vertical-align: middle;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .result-realisations-box {
              @include media-breakpoint-down(lg) {
                margin-top: 40px;
                margin-bottom: 140px;
              }
              .result-content-box {
                padding: 12px 0;
                .image-gallery {
                  display: flex;
                  flex-direction: row;
                  gap: 10px;
                  margin-bottom: 20px;
                  @include media-breakpoint-down(lg) {
                    //flex-direction: column;
                  }
                }

                .image-gallery .column {
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                }

                .image-item img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
                /* overlay styles */

                .image-item {
                  position: relative;
                  cursor: pointer;
                }

                .overlay {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  background: rgba(57, 57, 57, 0.502);
                  top: 0;
                  left: 0;
                  opacity: 0;
                  transition: all 0.2s linear;
                  color: #fff;
                  /* center overlay content */
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                  font-size: 12px;
                  line-height: 14px;
                  padding: 4px;
                  @include media-breakpoint-down(xxl) {
                    font-size: 11px;
                    line-height: 13px;
                  }
                }

                /* hover */
                .image-item:hover .overlay {
                  opacity: 0.8;
                }

              }
            }
          }
        }
      }
    }
    /** ------------------------------------------------------------------------------------
    // STICKY NAV
    ------------------------------------------------------------------------------------ */
    &.nav-scrolled {
      //Classe ajoutée par JS quand on est scrollé au haut de la page ou qu'on scroll up.
      // Sert à changer le look de la nav.
      header {
        .navbar {
          background-color: $secondary !important;
          padding-top: 0!important;
          padding-bottom: 0!important;
          .dropdown-menu {
            background-color: $color-secondary !important;
          }
          .nav-box{
            flex-direction: row!important;
            .navbar-toggler{
              margin-top: 10px!important;
            }
            .navbar-brand{
                margin-bottom: -10px;
            }
            .navbar-brand-mobile{
                margin-bottom: -10px;
              @include media-breakpoint-down(lg) {
                margin-bottom: -10px;
                margin-left: 3px!important;
              }
            }
          }
          .navbar-nav{
            align-items: center;
            padding-top: 0!important;
          }
          .nav-top{
            width: 60% !important;
            @include media-breakpoint-down(xl) {
              width: 80% !important;
            }
            @include media-breakpoint-down(lg) {
              width: 40% !important;
              justify-content: flex-start!important;
            }
            .camion-box-mobile{
              @include media-breakpoint-down(lg) {
                display: none!important;
              }
            }
          }
          .nav-mobile-search{
            width:60% !important;
            margin-bottom: -5px!important;
            .recherche-mobile-box{
              padding:15px 13px 0 0!important;
              form{
                .input-group{
                  min-width: auto!important;
                }
              }
            }
          }
        }
        .nav-right-box{
          .zero-fail-box{
            img{
              max-width: 53px;
              padding-top: 10px;
              @include media-breakpoint-down(xl) {
                max-width: 43px;
                padding-top: 10px;
              }
            }
          }
          .search-box{
            padding-top: 10px;
          }
          .camion-box{
            padding-top: 10px;

          }
        }
        .container-nav {
          .offcanvas{
            @include media-breakpoint-down(lg) {
              top: 64px!important;
            }
            @include media-breakpoint-down(md) {
              top: 59px!important;
            }
          }
          .offcanvas-body {
            @include media-breakpoint-down(lg) {
              //display: none;
            }
            .navbar-nav{
              @include media-breakpoint-down(lg) {
                align-items: stretch!important;
              }
            }
          }
          .sticky-nav {
            //Quand on est scrollé, on masque la nav.
            //Caché vers le haut avec animation
            //top: -1px - $header-nav-height + 180px !important;

            @include media-breakpoint-down($navbar-collapsed-size) {
              top: -1px - $header-nav-height-mobile + 141px !important;
            }
          }
        }
        #offcanvasSearchBar{
          top: 71px!important;
        }
      }
    }

    /**
    Nav cachée. Quand la nav se masque en scroll down (et réapparait en scoll up).
    Classe .nav-scrolled-hide ajoutée par JS
    */
    &.nav-scrolled-hide {
      header {
        .container-nav {
          .sticky-nav {
            //Quand on est scrollé, on masque la nav.
            //Caché vers le haut avec animation
            top: -1px - $header-nav-height !important;

            @include media-breakpoint-down($navbar-collapsed-size) {
              top: -1px - $header-nav-height-mobile !important;
            }
          }
        }
      }
    }
  }
  //#henri-project-body

  &.opened-nav {
    //Nav mobile ouverte (classe ajouté par JS au body)

    //IMPORTANT: Ne PAS styler le menu mobile ici,
    // cette classe s'applique juste une fois le menu 100% fini d'ouvrir et pas durant son ouverture!
    // Ça sert juste à cibler le body et gérer l'overflow.

    @include media-breakpoint-down($navbar-collapsed-size) {
      //Petit truc sur le body, pour que la nav scroll si elle est plus grande que l'écran.
      overflow: hidden;
      height: 100%;
      position: relative;
      z-index: 1;

      header {
        .container-nav {
          @include media-breakpoint-down($navbar-collapsed-size) {
            overflow-y: auto;
            overflow-x: hidden;
            bottom: 0;
          }
        }
      }
    }
  }
  &.page-home{
    .dropdown-menu {
      background-color: transparent !important;
    }
  }
}

//body
