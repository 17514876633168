@charset "utf-8";

/* ------------------------------------------------------------------------------------
// Type
/ ------------------------------------------------------------------------------------*/


/* ------------------------------------------------------------------------------------
// IMPORT
/ ------------------------------------------------------------------------------------*/

//Import TYPEKIT
//@import url("https://use.typekit.net/imj0xlw.css");

//Import GOOGLE
//@import url(http://fonts.googleapis.com/css?family=Lato:300,300italic&subset=latin,latin-ext);

//Import web font
//@font-face {
//  font-family: 'alright_sans_regularregular';
//  src: url('../../fonts/alrightsans-regular-webfont.woff2') format('woff2'),
//  url('../../fonts/alrightsans-regular-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}

@font-face {
  font-family: 'Arial-black';
  src: url('/assets/fonts/arial-black/Arial-Black.eot');
  src: url('/assets/fonts/arial-black/Arial-Black.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/arial-black/Arial-Black.woff2') format('woff2'),
  url('/assets/fonts/arial-black/Arial-Black.woff') format('woff'),
  url('/assets/fonts/arial-black/Arial-Black.svg#Arial-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Arial-light';
  src: url('/assets/fonts/arial-light/Arial-Lgt.eot');
  src: url('/assets/fonts/arial-light/Arial-Lgt.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/arial-light/Arial-Lgt.woff2') format('woff2'),
  url('/assets/fonts/arial-light/Arial-Lgt.woff') format('woff'),
  url('/assets/fonts/arial-light/Arial-Lgt.svg#Arial-Lgt') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sidecar 3';
  src: url('/assets/fonts/sidecar-regular/SidecarRegular.eot');
  src: url('/assets/fonts/sidecar-regular/SidecarRegular.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/sidecar-regular/SidecarRegular.woff2') format('woff2'),
  url('/assets/fonts/sidecar-regular/SidecarRegular.woff') format('woff'),
  url('/assets/fonts/sidecar-regular/SidecarRegular.svg#SidecarRegular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@import url("https://use.typekit.net/kop8qkf.css");



/* ------------------------------------------------------------------------------------
// MIXIN
/ ------------------------------------------------------------------------------------*/
//Fonte writing
@mixin font-cursive-reg {
  font-family: 'Sidecar 3', cursive;
  font-weight: 400;
  font-weight: normal;
  font-style: normal;
}


//Fonte titre
@mixin font-title-reg {
  //font-family: minion-3, serif;
  font-family: minion-3-display, serif;
  font-weight: 400;
  font-style: normal;
  strong{
    font-weight: 700;
  }
  em{
    font-style: italic;
  }
}

//Fonte titre
@mixin font-title-med {
  //font-family: minion-3, serif;
  font-family: minion-3-display, serif;
  font-weight: 500;
  font-style: normal;
  strong{
    font-weight: 700;
  }
  em{
    font-style: italic;
  }
}
//Fonte titre
@mixin font-title-bold {
  //font-family: minion-3, serif;
  font-family: minion-3-display, serif;
  font-weight: 700;
  font-style: normal;
  em{
    font-style: italic;
  }
}


//Font régulière,
@mixin font-light-1 {
  font-family: 'Arial-light', Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 300;
  em{
    font-style: italic;
  }
  strong{
    font-weight: 700;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  }
}


//Font régulière,
@mixin font-reg-1 {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  em{
    font-style: italic;
  }
  strong{
    font-weight: 700;
  }
}

//Font régulière,
@mixin font-bold-1 {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  em{
    font-style: italic;
  }
  strong{
    font-weight: 900;
    font-family: 'Arial-black', Arial, "Helvetica Neue", Helvetica, sans-serif;
  }
}

//Font régulière,
@mixin font-black-1 {
  font-family: 'Arial-black', Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 900;
  em{
    font-style: italic;
  }
}
