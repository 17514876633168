#dx-page-body {
  &.page-a-propos {
    background-color: white;
    header {
      .bg-secondary {
        //background-color: transparent!important;
      }
    }
    .site-main { //main
      .main-content-wrapper {
        .content-area {
          //padding-top: 0; // hauteur de la nav - nav transparente pour carousel
          .section-main {
            > h1 {
              display: none;
            }
          }
        }
      }
    }
    .intro-content-box {
      padding-top: 25px;
      @include media-breakpoint-down(xxl) {
        padding-top: 0px;
      }
      h3 {
        @include font-reg-1;
        font-size: 28px;
        line-height: 36px;
        text-transform: uppercase;
        padding-bottom: 25px;
        @include media-breakpoint-down(5xl) {
          font-size: 26px;
          line-height: 34px;
          padding-bottom: 20px;
        }
        @include media-breakpoint-down(4xl) {
          font-size: 24px;
          line-height: 30px;
          padding-bottom: 15px;
        }
        @include media-breakpoint-down(xxxl) {
          font-size: 22px;
          line-height: 27px;
          padding-bottom: 10px;
        }
        @include media-breakpoint-down(xxl) {
          font-size: 20px;
          line-height: 25px;
          padding-bottom: 5px;
        }
        @include media-breakpoint-down(xl) {
          font-size: 18px;
          line-height: 21px;
          padding-bottom: 20px;
        }
        @include media-breakpoint-down(lg) {
        }
        @include media-breakpoint-down(md) {
        }
        @include media-breakpoint-down(sm) {
        }
        strong {
          display: block;
          @include font-bold-1;
        }
      }
      p.lead {
        font-size: 19px;
        line-height: 26px;
        max-width: 450px;
        @include font-light-1;
        @include media-breakpoint-down(5xl) {
          font-size: 18px;
          line-height: 25px;
        }
        @include media-breakpoint-down(4xl) {
          font-size: 16px;
          line-height: 24px;
        }
        @include media-breakpoint-down(xxxl) {
          font-size: 15px;
          line-height: 23px;
        }
        @include media-breakpoint-down(xxl) {
          font-size: 13px;
          line-height: 17px;
        }
        @include media-breakpoint-down(xl) {
        }
        @include media-breakpoint-down(lg) {
          font-size: 21px;
          line-height: 28px;
        }
        @include media-breakpoint-down(md) {
        }
        @include media-breakpoint-down(sm) {
        }
      }
      p {
        font-size: 19px;
        line-height: 26px;
        max-width: 450px;
        @include media-breakpoint-down(5xl) {
          font-size: 18px;
          line-height: 25px;
        }
        @include media-breakpoint-down(4xl) {
          font-size: 16px;
          line-height: 24px;
        }
        @include media-breakpoint-down(xxxl) {
          font-size: 15px;
          line-height: 23px;
        }
        @include media-breakpoint-down(xxl) {
          font-size: 13px;
          line-height: 17px;
        }
        @include media-breakpoint-down(xl) {
        }
        @include media-breakpoint-down(lg) {
          font-size: 14px;
          line-height: 20px;
        }
        @include media-breakpoint-down(md) {
        }
        @include media-breakpoint-down(sm) {
        }
      }
      .btn-link-box {
        padding-top: 15px;
      }
    }
    .section-intro {
      padding-top: 100px;
      padding-bottom: 100px;
      position: relative;
      @include media-breakpoint-down(5xl) {
        //background-color: red;
        padding-top: 90px;
        padding-bottom: 90px;
      }
      @include media-breakpoint-down(4xl) {
        //background-color: yellow;
        padding-top: 80px;
        padding-bottom: 80px;
      }
      @include media-breakpoint-down(xxxl) {
        //background-color: green;
        padding-top: 70px;
        padding-bottom: 70px;
      }
      @include media-breakpoint-down(xxl) {
        //background-color: pink;
        padding-top: 60px;
        padding-bottom: 30px;
      }
      @include media-breakpoint-down(xl) {
        //background-color: mediumseagreen;
        padding-top: 65px;
        padding-bottom: 20px;
      }
      @include media-breakpoint-down(lg) {
        //background-color: hotpink;
      }
      @include media-breakpoint-down(md) {
        //background-color: salmon;
      }
      @include media-breakpoint-down(sm) {
        //background-color: greenyellow;
      }
      .raison-intro-box {
        .dx-bg-box {
          width: 176px;
          height: 632px;
          top: 100px;
          left: 0;
          @include media-breakpoint-down(5xl) {
            width: 176px;
          }
          @include media-breakpoint-down(4xl) {
            width: 170px;
            height: 628px;
          }
          @include media-breakpoint-down(xxxl) {
            width: 155px;
            height: 613px;
          }
          @include media-breakpoint-down(xxl) {
            width: 98px;
            height: 424px;
            top: 80px;
          }
          @include media-breakpoint-down(xl) {
            width: 62px;
            height: 165px;
            top: 415px;
          }
          @include media-breakpoint-down(lg) {
            width: 38px;
            height: 157px;
            top: 415px;
          }
        }
        .img-box {
          padding-top: 160px;
          @include media-breakpoint-down(5xl) {
            padding-top: 140px;
          }
          @include media-breakpoint-down(4xl) {
            padding-top: 120px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-top: 100px;
          }
          @include media-breakpoint-down(xxl) {
            padding-top: 90px;
          }
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(lg) {
            padding-top: 0;
            margin-top: -60px;
            padding-bottom: 20px;
          }
          @include media-breakpoint-down(md) {
            margin-left: -36px;
          }
          @include media-breakpoint-down(sm) {
          }
        }
        .intro-content-box {
          @include media-breakpoint-down(xxxl) {
            padding-left: 42px;
          }
          @include media-breakpoint-down(xxl) {
            padding-left: 32px;
          }
          @include media-breakpoint-down(lg) {
            padding-top: 45px;
          }
        }

      }
      .raison-open-box {
        padding-top: 135px;
        position: relative;
        @include media-breakpoint-down(5xl) {
          padding-top: 125px;
        }
        @include media-breakpoint-down(4xl) {
          padding-top: 115px;
        }
        @include media-breakpoint-down(xxxl) {
          padding-top: 105px;
        }
        @include media-breakpoint-down(xxl) {
          padding-top: 95px;
        }
        @include media-breakpoint-down(lg) {
          padding-top: 0px;
        }
        .dx-bg-box {
          &.bg-1 {
            width: 577px;
            height: 300px;
            top: 900px;
            left: 0;
            @include media-breakpoint-down(5xl) {
              width: 424px;
              height: 246px;
              top: 810px;
            }
            @include media-breakpoint-down(4xl) {
              width: 378px;
              height: 220px;
              top: 740px;
            }
            @include media-breakpoint-down(xxxl) {
              width: 394px;
              height: 204px;
              top: 680px;
            }
            @include media-breakpoint-down(xxl) {
              width: 350px;
              height: 156px;
              top: 600px;
            }
            @include media-breakpoint-down(xl) {
              width: 100px;
              height: 118px;
              top: 984px;
            }
          }
          &.bg-2 {
            width: 497px;
            height: 301px;
            top: 525px;
            right: 0;
            @include media-breakpoint-down(5xl) {
              width: 230px;
              height: 228px;
              top: 450px;
            }
            @include media-breakpoint-down(4xl) {
              width: 220px;
              height: 198px;
              top: 410px;
            }
            @include media-breakpoint-down(xxxl) {
              width: 200px;
              height: 188px;
              top: 360px;
            }
            @include media-breakpoint-down(xxl) {
              width: 180px;
              height: 155px;
              top: 330px;
            }
            @include media-breakpoint-down(xl) {
              width: 80px;
              height: 110px;
              top: 376px;
            }
          }
        }
        .col-img-mobile {
          .img-box {
            margin-top: -20px;
            margin-bottom: 25px;
          }
        }
        .title-box {
          h4 {
            font-size: 35px;
            line-height: 42px;
            letter-spacing: 1.74px;
            @include font-reg-1;
            text-transform: uppercase;
            @include media-breakpoint-down(5xl) {
              font-size: 30px;
              line-height: 37px;
              letter-spacing: 1.6px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 25px;
              line-height: 32px;
              letter-spacing: 1.5px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 22px;
              line-height: 29px;
              letter-spacing: 1.4px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 20px;
              line-height: 27px;
              letter-spacing: 1.3px;
            }
            @include media-breakpoint-down(lg) {
              font-size: 13px;
              line-height: 16px;
            }
            strong {
              display: block;
              letter-spacing: 0;
            }
            &.part-2 {
              text-align: center;
              padding-top: 10px;
              font-size: 129px;
              line-height: 193px;
              letter-spacing: -5px;
              @include font-title-reg;
              font-style: italic;
              text-transform: none;
              @include media-breakpoint-down(5xl) {
                font-size: 119px;
                line-height: 149px;
                letter-spacing: -4.8px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 109px;
                line-height: 119px;
                letter-spacing: -4.5px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 99px;
                line-height: 99px;
                letter-spacing: -4px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 89px;
                line-height: 89px;
                letter-spacing: -3.5px;
              }
              @include media-breakpoint-down(lg) {
                font-size: 43px;
                line-height: 50px;
                letter-spacing: 0;
                padding-top: 0;
              }
              span {
                @include media-breakpoint-down(lg) {
                  display: block;
                }
              }
            }
          }
        }
        .row-content {
          padding-top: 50px;
          padding-bottom: 50px;
          @include media-breakpoint-down(5xl) {
            padding-top: 45px;
            padding-bottom: 45px;
          }
          @include media-breakpoint-down(4xl) {
            padding-top: 35px;
            padding-bottom: 35px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-top: 30px;
            padding-bottom: 30px;
          }
          @include media-breakpoint-down(xxl) {
            padding-top: 25px;
            padding-bottom: 25px;
          }
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(lg) {
            padding-top: 0;
            padding-bottom: 25px;
          }
          @include media-breakpoint-down(md) {
          }
          @include media-breakpoint-down(sm) {
          }
        }
        .content-txt-box {
          padding-top: 35px;
          @include media-breakpoint-down(lg) {
            padding-top: 27px;
          }
          p {
            font-size: 19px;
            line-height: 26px;
            @include media-breakpoint-down(5xl) {
              font-size: 18px;
              line-height: 25px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 16px;
              line-height: 24px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 15px;
              line-height: 23px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 13px;
              line-height: 17px;
            }
            @include media-breakpoint-down(xl) {
            }
            @include media-breakpoint-down(lg) {
            }
            @include media-breakpoint-down(md) {
            }
            @include media-breakpoint-down(sm) {
            }
          }
        }
      }
    }
    .section-bhag {
      padding-bottom: 105px;
      overflow-x: clip;
      @include media-breakpoint-down(5xl) {
        padding-bottom: 80px;
      }
      @include media-breakpoint-down(4xl) {
        padding-bottom: 50px;
      }
      @include media-breakpoint-down(xxxl) {
        padding-bottom: 30px;
      }
      @include media-breakpoint-down(xxl) {
        padding-bottom: 15px;
      }
      .bhag-box {
        position: relative;
        .container-fluid {
          //padding-left: 0;
          //padding-right: 0;
          //overflow: hidden;
          @include media-breakpoint-down(lg) {
            padding-right: 0;
            //padding-left: 30px;
          }
        }
        .intro-content-box {
          padding-top: 120px;
        }
        .bg-turquoise {
          position: absolute;
          height: 90%;
          background-color: $color-alt-3;
          width: 70%;
          top: 65px;
          left: 30%;
          z-index: 1;
          @include media-breakpoint-down(5xl) {
          }
          @include media-breakpoint-down(4xl) {
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xxl) {
            top: 65px;
            height: 82%;
          }
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(lg) {
            top: 0;
            width: 100%;
            left: 0;
            height: 100%;
          }
        }
        .bhag-content-bg-box {
          background-image: url("/assets/img/bhag-fond-rond.svg");
          background-size: contain;
          background-repeat: no-repeat;
          //background-color: red;
          background-position: left 100px center;
          padding-bottom: 55px;
          margin-bottom: 120px;
          padding-top: 55px;
          position: relative;
          z-index: 10;
          @include media-breakpoint-down(5xl) {
          }
          @include media-breakpoint-down(4xl) {
            background-position: left 0px center;
          }
          @include media-breakpoint-down(xxxl) {
            background-position: left -100px center;
          }
          @include media-breakpoint-down(xxl) {
            background-position: left -300px center;
          }
          @include media-breakpoint-down(xl) {
            background-size: cover;
          }
          @include media-breakpoint-down(lg) {
            background-position: right center;
            background-size: cover;
            margin-top: 150px;
            padding-top: 0;
          }
          &:before {
            @include media-breakpoint-down(lg) {
              content: "";
              background-color: white;
              width: 30px;
              height: 100%;
              display: block;
              position: absolute;
              top: 0;
              left: -30px;
            }
          }
          &.open-bg {
            @include media-breakpoint-down(5xl) {
              background-position: left 0 center;
            }
            @include media-breakpoint-down(4xl) {
              background-position: right center;
              background-size: cover;
            }
            @include media-breakpoint-down(xxxl) {
              //background-position: left 0 center;
            }
            @include media-breakpoint-down(xxl) {
              background-position: right center;
              background-size: cover;
            }
            @include media-breakpoint-down(xl) {
            }
            @include media-breakpoint-down(lg) {
              background-position: right -200px center;
              background-size: cover;
            }
          }
          .title-slash-v3 {
            margin-left: 145px;
            padding-left: 190px;
            @include media-breakpoint-down(5xl) {
            }
            @include media-breakpoint-down(4xl) {
            }
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xxl) {
              margin-left: 145px;
              padding-left: 125px;
            }
            @include media-breakpoint-down(xl) {
            }
            @include media-breakpoint-down(lg) {
              margin-left: 82px;
              padding-left: 90px;
            }
            .part-2 {
              h2 {
                margin-left: -155px;
                @include media-breakpoint-down(5xl) {
                }
                @include media-breakpoint-down(4xl) {
                }
                @include media-breakpoint-down(xxxl) {
                }
                @include media-breakpoint-down(xxl) {
                  margin-left: -115px;
                }
                @include media-breakpoint-down(xl) {
                }
                @include media-breakpoint-down(lg) {
                  margin-left: -80px;
                }
              }
            }
          }
          .intro-content-box {
            margin-left: 90px;
            padding-top: 75px;
            @include media-breakpoint-down(5xl) {
            }
            @include media-breakpoint-down(4xl) {
            }
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xxl) {
            }
            @include media-breakpoint-down(xl) {
            }
            @include media-breakpoint-down(lg) {
              margin-left: 0px;
              margin-right: 40px;
              padding-top: 60px;
            }
            h3{
              max-width: 700px;
              strong{
                display: inline;
              }
            }
          }
          .plus-content-box {
            margin-left: 90px;
            padding-top: 0;
            padding-bottom: 30px;
            @include media-breakpoint-down(lg) {
              margin-left: 0px;
              margin-right: 40px;
            }
            h4 {
              font-size: 25px;
              line-height: 36px;
              @include font-black-1;
              text-transform: uppercase;
              margin-bottom: 20px;
              max-width: 550px;
              @include media-breakpoint-down(5xl) {
                font-size: 23px;
                line-height: 34px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 21px;
                line-height: 32px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 19px;
                line-height: 30px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 17px;
                line-height: 28px;
              }
              @include media-breakpoint-down(xl) {
              }
              @include media-breakpoint-down(lg) {
                font-size: 14px;
                line-height: 20px;
              }
            }
            p {
              font-size: 19px;
              line-height: 26px;
              max-width: 550px;
              @include media-breakpoint-down(5xl) {
                font-size: 18px;
                line-height: 25px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 16px;
                line-height: 24px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 15px;
                line-height: 23px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 13px;
                line-height: 17px;
              }
              @include media-breakpoint-down(xl) {
              }
              @include media-breakpoint-down(lg) {
                font-size: 14px;
                line-height: 20px;
              }
              @include media-breakpoint-down(md) {
              }
              @include media-breakpoint-down(sm) {
              }
            }
          }
          .img-box {
            margin-top: -170px;
            @include media-breakpoint-down(5xl) {
            }
            @include media-breakpoint-down(4xl) {
            }
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xxl) {
              margin-top: -120px;
            }
            @include media-breakpoint-down(xl) {
            }
            @include media-breakpoint-down(lg) {
              margin-top: -200px;
            }
          }
        }
      }
    }
    .section-adn {
      .adn-intro-box {
        .img-box {
          padding-top: 125px;
          @include media-breakpoint-down(xl) {
            text-align: center;
            padding-top: 40px;
            padding-bottom: 10px;
          }
        }
        .adn-content-bg-box {
          padding-left: 80px;
          @include media-breakpoint-down(5xl) {
          }
          @include media-breakpoint-down(4xl) {
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xxl) {
            padding-left: 45px;
          }
          @include media-breakpoint-down(xl) {
            padding-left: 0px;
          }
          .title-slash-v3 {
            padding-left: 50px;
            @include media-breakpoint-down(5xl) {
            }
            @include media-breakpoint-down(4xl) {
            }
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xxl) {
              padding-left: 5px;
            }
            @include media-breakpoint-down(xl) {
              padding-left: 185px;
            }
            &:before {
              left: -100px;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
                top: 10px;
              }
              @include media-breakpoint-down(xl) {
                left: 110px;
              }
            }
            .part-2 {
              h2 {
                margin-left: 40px;
                @include media-breakpoint-down(5xl) {
                }
                @include media-breakpoint-down(4xl) {
                }
                @include media-breakpoint-down(xxxl) {
                }
                @include media-breakpoint-down(xxl) {
                  margin-left: 30px;
                }
              }
            }
          }
          .intro-content-box {
            padding-top: 63px;
            @include media-breakpoint-down(5xl) {
            }
            @include media-breakpoint-down(4xl) {
            }
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xxl) {
              padding-top: 43px;
            }
            @include media-breakpoint-down(xl) {
              max-width: 379px;
              margin: 0 auto;
            }
          }
        }
      }
      .adn-open-box {
        padding-top: 100px;
        @include media-breakpoint-down(5xl) {
          padding-top: 60px;
        }
        @include media-breakpoint-down(4xl) {
          padding-top: 40px;
        }
        @include media-breakpoint-down(xxxl) {
          padding-top: 20px;
        }
        @include media-breakpoint-down(xxl) {
          padding-top: 0px;
        }
        .adn-open-col-content {
          padding-top: 75px;
          padding-bottom: 135px;
          position: relative;
          @include media-breakpoint-down(5xl) {
          }
          @include media-breakpoint-down(4xl) {
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xxl) {
            padding-top: 25px;
            padding-bottom: 55px;
          }
          &.bg-color-turquoise {
            border-bottom-right-radius: 600px;
            border-top-right-radius: 600px;
            @include media-breakpoint-down(xxl) {
              margin-bottom: 30px;
              margin-top: 40px;
            }
            @include media-breakpoint-down(md) {
              padding-right: 80px;
            }
            &:before {
              content: "";
              background-color: $color-alt-3;
              height: 100%;
              width: 600px;
              display: block;
              position: absolute;
              top: 0;
              left: -48%;
              z-index: 0;
              @include media-breakpoint-down(xxxl) {
                width: 400px;
              }
              @include media-breakpoint-down(xxl) {
                width: 500px;
                left: -38%;
              }
              @include media-breakpoint-down(xl) {
                width: 100px;
                left: -10%;
              }
              @include media-breakpoint-down(md) {
                left: -30px;
                width: 30px;
              }
            }
          }
        }
        .adn-open-content-box {
          padding-top: 50px;
          @include media-breakpoint-down(xl) {
            max-width: 300px;
            margin: 0 auto;
          }
          h4 {
            font-size: 39px;
            line-height: 35px;
            @include font-title-reg;
            font-style: italic;
            padding-bottom: 20px;
            @include media-breakpoint-down(5xl) {
            }
            @include media-breakpoint-down(4xl) {
            }
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xxl) {
              font-size: 28px;
              line-height: 26px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 42px;
              line-height: 38px;
            }
            strong {
              @include font-black-1;
              font-size: 25px;
              line-height: 23px;
              font-style: normal;
              display: block;
              text-transform: uppercase;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
                font-size: 23px;
                line-height: 21px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 27px;
                line-height: 25px;
              }
            }
          }
        }
        .img-box {
          position: relative;
          z-index: 10;
          @include media-breakpoint-down(xl) {
            text-align: center;
          }
        }
      }
    }
    .section-equipe {
      position: relative;
      p {
        font-size: 19px;
        line-height: 29px;
        //letter-spacing: 1.08px;
        @include media-breakpoint-down(5xl) {
          font-size: 18px;
          line-height: 28px;
        }
        @include media-breakpoint-down(4xl) {
          font-size: 17px;
          line-height: 26px;
        }
        @include media-breakpoint-down(xxxl) {
          font-size: 15px;
          line-height: 21px;
        }
        @include media-breakpoint-down(xxl) {
          font-size: 13px;
          line-height: 19px;
        }
        @include media-breakpoint-down(xl) {
          font-size: 16px;
          line-height: 20px;
        }
      }
      .equipe-intro-box {
        margin-top: 135px;
        @include media-breakpoint-down(5xl) {
          margin-top: 115px;
        }
        @include media-breakpoint-down(4xl) {
          margin-top: 105px;
        }
        @include media-breakpoint-down(xxxl) {
          margin-top: 95px;
        }
        @include media-breakpoint-down(xxl) {
          margin-top: 85px;
        }
        .dx-bg-box {
          width: 1018px;
          height: 298px;
          top: 328px;
          left: 0;
          @include media-breakpoint-down(5xl) {
            width: 894px;
            height: 265px;
            top: 315px;
          }
          @include media-breakpoint-down(4xl) {
            width: 642px;
            height: 237px;
            top: 265px;
          }
          @include media-breakpoint-down(xxxl) {
            width: 594px;
            height: 190px;
            top: 241px;
          }
          @include media-breakpoint-down(xxl) {
            width: 503px;
            height: 184px;
            top: 228px;
          }
          @include media-breakpoint-down(xl) {
            width: 78px;
            height: 70px;
            top: 320px;
          }
        }
        .intro-content-box {
          position: relative;
          padding: 130px 0;
          @include media-breakpoint-down(5xl) {
            padding: 125px 0 115px 0;
          }
          @include media-breakpoint-down(4xl) {
            padding: 115px 0 85px 0;
          }
          @include media-breakpoint-down(xxxl) {
            padding: 105px 0 75px 0;
          }
          @include media-breakpoint-down(xxl) {
            padding: 95px 0 65px 0;
          }
          @include media-breakpoint-down(xl) {
            padding: 70px 0 45px 0;
          }
          &.bg-color-turquoise {
            &:before {
              @include media-breakpoint-down(xl) {
                background-color: $color-alt-3;
                content: "";
                display: block;
                width: 42px;
                height: 100%;
                position: absolute;
                top: 0;
                left: -42px;
                z-index: 0;
              }
              @include media-breakpoint-down(lg) {
                width: 30px;
                left: -30px;
              }
            }
            &:after {
              background-color: $color-alt-3;
              content: "";
              display: block;
              width: 100px;
              height: 100%;
              position: absolute;
              top: 0;
              right: -42px;
              z-index: 0;
              @include media-breakpoint-down(xxl) {
                width: 42px;
              }
              @include media-breakpoint-down(xl) {
                width: 42px;
                right: -42px;
              }
              @include media-breakpoint-down(lg) {
                width: 30px;
                right: -30px;
              }
            }
          }
          .intro-in-box {
            @include media-breakpoint-down(xl) {
              max-width: 320px;
              margin: 0 auto;
              text-align: right;
            }
          }
          .title-box {
            max-width: 58%;
            text-align: right;
            @include media-breakpoint-down(xxl) {
              max-width: 70%;
            }
            @include media-breakpoint-down(xl) {
              max-width: none;
            }
            h3 {
              //font-size: 60px;
              //line-height: 70px;
              //letter-spacing: 3.12px;
              font-size: 72px;
              line-height: 70px;
              margin-bottom: 0;
              margin-right: 45px;
              @include font-bold-1;
              @include media-breakpoint-down(5xl) {
                font-size: 60px;
                line-height: 62px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 52px;
                line-height: 52px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 48px;
                line-height: 53px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 45px;
                line-height: 50px;
                margin-right: -35px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 40px;
                line-height: 50px;
                margin-right: 0;
                padding-bottom: 0;
              }
            }
            h4 {
              margin-bottom: 0;
              font-size: 90px;
              line-height: 50px;
              letter-spacing: -3px;
              font-style: italic;
              @include media-breakpoint-down(5xl) {
                font-size: 80px;
                line-height: 45px;
                letter-spacing: -2.5px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 70px;
                line-height: 40px;
                letter-spacing: -2px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 60px;
                line-height: 45px;
                letter-spacing: -2px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 56px;
                line-height: 50px;
                letter-spacing: -2px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 50px;
                line-height: 40px;
                letter-spacing: 0;
              }
            }
          }
          .intro-txt-box {
            padding-top: 50px;
            @include media-breakpoint-down(5xl) {
              padding-top: 45px;
            }
            @include media-breakpoint-down(4xl) {
              padding-top: 35px;
            }
            @include media-breakpoint-down(xxxl) {
              padding-top: 25px;
            }
            @include media-breakpoint-down(xxl) {
              padding-top: 15px;
            }
            @include media-breakpoint-down(xl) {
              padding-top: 30px;
            }
          }
        }
        .grande-img-box {
          img {
            width: 100%;
          }
        }
      }
      .equipe-plus {
        position: relative;
        .dx-bg-box {
          width: 260px;
          height: 1266px;
          bottom: 600px;
          right: 0;
          @include media-breakpoint-down(5xl) {
            //width: 180px;
            width: 200px;
            height: 1058px;
            bottom: 546px;
          }
          @include media-breakpoint-down(4xl) {
            width: 160px;
            height: 864px;
            bottom: 546px;
          }
          @include media-breakpoint-down(xxxl) {
            display: none;
          }
          @include media-breakpoint-down(xxl) {

          }
          @include media-breakpoint-down(xl) {

          }
        }
        .bloc-bg-turquoise {
          background-color: $color-alt-3;
          width: 24%;
          height: 238px;
          position: absolute;
          bottom: 100px;
          left: 0;
          z-index: 1;
          @include media-breakpoint-down(5xl) {
            width: 20%;
            height: 228px;
            bottom: 90px;
          }
          @include media-breakpoint-down(4xl) {
            width: 16%;
            height: 208px;
            bottom: 50px;
          }
          @include media-breakpoint-down(xxxl) {
            width: 13%;
            height: 98px;
            bottom: 20px;
          }
          @include media-breakpoint-down(xxl) {
            height: 160px;
            width: 10%;
            bottom: 10px;
          }
          @include media-breakpoint-down(xl) {
            height: 107px;
            width: 10%;
            bottom: 0px;
          }
        }
        .title-slash-box { // pour tous dans cette section
          padding-left: 0;
          padding-bottom: 30px;
          @include media-breakpoint-down(xxl) {
            padding-bottom: 20px;
          }
          &.slash-left {
            &:before {
              left: -80px;
              @include media-breakpoint-down(xxl) {
                left: -45px;
              }
            }
            .part-1 {
              margin-left: 40px;
            }
          }
        }
        .terrain-box {
          .terrain-bg-box {
            z-index: 10;
            position: relative;
            padding: 180px 165px 75px 375px;
            @include media-breakpoint-down(5xl) {
              padding: 165px 125px 70px 160px;
            }
            @include media-breakpoint-down(4xl) {
              padding: 145px 105px 65px 140px;
            }
            @include media-breakpoint-down(xxxl) {
              padding: 125px 85px 60px 120px;
            }
            @include media-breakpoint-down(xxl) {
              padding: 105px 65px 40px 100px;
            }
            @include media-breakpoint-down(xl) {
              padding: 85px 55px 50px 50px;
            }
            @include media-breakpoint-down(lg) {
              padding: 50px 40px 40px 60px;
            }
            &.bg-color-turquoise {
              &:before {
                content: "";
                display: block;
                width: 42px;
                height: 100%;
                background-color: $color-alt-3;
                position: absolute;
                top: 0;
                left: -42px;
                z-index: 0;
                @include media-breakpoint-down(lg) {
                  left: -30px;
                  width: 30px;
                }
              }
            }
            .terrain-in-box {
              @include media-breakpoint-down(xl) {
                max-width: 200px;
                margin: 0 auto;
              }
            }
          }
          .col-img {
            margin-left: -8.3333333%;
            z-index: 1;
            @include media-breakpoint-down(lg) {
              margin-left: auto;
            }
          }
          .img-box {
            margin-top: 95px;
            @include media-breakpoint-down(5xl) {
              margin-top: 80px;
            }
            @include media-breakpoint-down(4xl) {
              margin-top: 115px;
            }
            @include media-breakpoint-down(xxxl) {
              margin-top: 45px;
            }
            @include media-breakpoint-down(xxl) {
              margin-top: 80px;
            }
            @include media-breakpoint-down(lg) {
              margin-top: 0px;
            }
          }
        }
        .entrepot-box {
          padding-top: 130px;
          @include media-breakpoint-down(5xl) {
            padding-top: 100px;
          }
          @include media-breakpoint-down(4xl) {
            padding-top: 80px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-top: 70px;
          }
          @include media-breakpoint-down(xxl) {
            padding-top: 60px;
          }
          .img-box {

          }
          .entrepot-content-box {
            padding-top: 40px;
            @include media-breakpoint-down(xxl) {
              padding-top: 90px;
            }
            @include media-breakpoint-down(xl) {
              padding-top: 0px;
              padding-bottom: 40px;
              max-width: 200px;
              margin: 0 auto;
            }

          }
        }
        .bureaux-box {
          padding-top: 120px;
          @include media-breakpoint-down(xxl) {
            padding-top: 75px;
          }
          .bureau-content-box {
            @include media-breakpoint-down(xxl) {
              padding-top: 55px;
            }
            @include media-breakpoint-down(xl) {
              padding-top: 0px;
              padding-bottom: 40px;
              max-width: 200px;
              margin: 0 auto;
            }
            p {
              font-size: 22px;
              line-height: 32px;
              letter-spacing: 1.08px;
              @include media-breakpoint-down(5xl) {
                font-size: 20px;
                line-height: 28px;
                letter-spacing: 0.103px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 18px;
                line-height: 25px;
                letter-spacing: 0.93px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 16px;
                line-height: 23px;
                letter-spacing: 0.83px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.73px;
              }
            }
          }
        }
        .img-box {
          padding-top: 70px;
          @include media-breakpoint-down(xxl) {
            padding-top: 0px;
          }
        }
      }
    }
    .section-historique {
      position: relative;
      //overflow: hidden;
      .histo-menu-box {
        position: sticky;
        margin-top: -800px;
        background-color: white;
        top: 15%;
        left: 20%;
        z-index: 100;
        width: 120px;
        @include media-breakpoint-down(5xl) {
          left: 15%;
          top: 11%;
        }
        @include media-breakpoint-down(4xl) {
          left: 10%;
          top: 10%;
        }
        @include media-breakpoint-down(xxxl) {
          left: 5%;
          top: 5%;
        }
        @include media-breakpoint-down(xl) {
          top: -1px;
        }
        @include media-breakpoint-down(md) {
          left: 0;
          width: 100%;
          margin-top: 0px;
        }
        .mobile-menu-accordeon {
          display: none;
          @include media-breakpoint-down(md) {
            display: block;
            .menu-active-txt {
              a {
                position: relative;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.45px;
                @include font-black-1;
                padding: 20px 40px 20px 33px;
                display: flex;
                align-items: center;
                color: $color-text-main;
                &:after {
                  background-image: url("/assets/img/histo-menu-mobile-fleche.svg");
                  width: 18px;
                  height: 10px;
                  content: "";
                  display: inline-flex;
                  background-repeat: no-repeat;
                  transition: opacity 0.3s linear;
                  margin-left: 10px;
                  transform: rotate(180deg);
                }
                &.open {
                  &:after {
                    transform: none;
                  }
                }
              }
            }
          }
        }
        .histo-menu-in {
          padding: 20px 40px 20px 20px;
          @include media-breakpoint-down(md) {
            padding: 0;
            visibility: hidden;
            height:0;
            opacity: 0;
            transition: visibility 0s, opacity 0.8s ease-out;
            float:left;
          }
          &.open{
            padding: 0px 40px 20px 20px;
            opacity: 1;
            height:auto;
            visibility: visible;
            background-color: white;
            width:100%;
          }
          ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            li {
              position: relative;
              .menu-link {
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.4px;
                @include font-reg-1;
                color: $color-text-main;
                padding: 12px 0 12px 50px;
                display: block;
                @include media-breakpoint-down(xxl) {
                  font-size: 11px;
                  line-height: 13px;
                  letter-spacing: 0.27px;
                  padding: 8px 0 8px 33px;
                }
                @include media-breakpoint-down(md) {
                  font-size: 18px;
                  line-height: 22px;
                  letter-spacing: 0.45px;
                  padding: 13px 0 13px 13px;
                }
                &:before {
                  width: 20px;
                  height: 16px;
                  content: "";
                  display: block;
                  position: absolute;
                  top: 11px;
                  left: 0;
                  opacity: 0;
                  background-repeat: no-repeat;
                  transition: opacity 0.3s linear;
                  @include media-breakpoint-down(xxl) {
                    width: 13px;
                    height: 9px;
                    background-size: 13px;
                    top: 8px;
                  }
                  @include media-breakpoint-down(xl) {
                  }
                }
                &:hover {
                  @include media-breakpoint-down(md) {
                    text-decoration: underline;
                  }
                  &:before {
                    opacity: 1;
                    background-image: url("/assets/img/histo-menu-dash.svg");
                    @include media-breakpoint-down(md) {
                      background-image: none;
                    }
                  }
                }
                &.active {
                  @include font-black-1;
                  @include media-breakpoint-down(md) {
                    text-decoration: underline;
                  }
                  &:before {
                    opacity: 1;
                    background-image: url("/assets/img/histo-menu-dash.svg");
                    @include media-breakpoint-down(md) {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .histo-annees-box {
        .histo-annee {
          position: relative;
          &.annee-1 {
            padding-top: 160px;
            @include media-breakpoint-down(5xl) {
              //width: 20%;
            }
            @include media-breakpoint-down(4xl) {
              //width: 16%;
            }
            @include media-breakpoint-down(xxxl) {
              //width: 13%;
            }
            @include media-breakpoint-down(xxl) {
              padding-top: 75px;
            }
            @include media-breakpoint-down(xl) {
              //width: 10%;
            }
            .dx-bg-box {
              width: 260px;
              height: 633px;
              top: 205px;
              right: 0;
              z-index: 0;
              @include media-breakpoint-down(5xl) {
                height: 633px;
                width: 180px;
              }
              @include media-breakpoint-down(4xl) {
                height: 628px;
                width: 180px;
              }
              @include media-breakpoint-down(xxxl) {
                width: 100px;
              }
              @include media-breakpoint-down(xxl) {
                display: none;
              }
              @include media-breakpoint-down(xl) {
              }
            }
            .pic-1-box {
              width: 25%;
              margin-left: 25%;
              @include media-breakpoint-down(5xl) {
                //width: 20%;
              }
              @include media-breakpoint-down(4xl) {
                width: 30%;
                margin-left: 21%;
              }
              @include media-breakpoint-down(xxxl) {
                //width: 13%;
              }
              @include media-breakpoint-down(xxl) {
                width: 33.33333333%;
                margin-left: 15.66666667%;
              }
              @include media-breakpoint-down(xl) {
                width: 50%;
                margin-left: -30px;
              }
              @include media-breakpoint-down(md) {
                width: 50%;
                margin-left: -30px;
              }
            }
            .pic-2-box {
              width: 16.66666667%;
              margin-left: 28.5%;
              margin-top: 125px;
              @include media-breakpoint-down(5xl) {
                //width: 20%;
              }
              @include media-breakpoint-down(4xl) {
                //width: 16%;
              }
              @include media-breakpoint-down(xxxl) {
                //width: 13%;
              }
              @include media-breakpoint-down(xxl) {
                width: 22%;
                margin-left: 19.66666667%;
                margin-top: 85px;
              }
              @include media-breakpoint-down(xl) {
                width: 40%;
                margin-top: -305px;
                margin-left: 50%;
              }
              @include media-breakpoint-down(md) {
                width: 40%;
                margin-top: -145px;
                margin-left: 50%;
              }
            }
            .pic-3-box {
              width: 50%;
              margin-left: 50%;
              margin-top: -340px;
              position: relative;
              z-index: 2;
              @include media-breakpoint-down(5xl) {
                margin-top: -200px;
              }
              @include media-breakpoint-down(4xl) {
                margin-top: -180px;
              }
              @include media-breakpoint-down(xxxl) {
                //width: 13%;
              }
              @include media-breakpoint-down(xxl) {
                width: 50%;
                margin-left: 50%;
                margin-top: -190px;
              }
              @include media-breakpoint-down(xl) {
                width: 70%;
                margin-left: calc(30% + 30px);
                margin-top: 165px;
              }
              @include media-breakpoint-down(md) {
                width: 70%;
                margin-left: calc(30% + 30px);
                margin-top: 165px;
              }
            }
            .annee-box {
              top: 32.4%;
              left: 40%;
              @include media-breakpoint-down(5xl) {
                top: 30.1%;
              }
              @include media-breakpoint-down(4xl) {
                //width: 16%;
              }
              @include media-breakpoint-down(xxxl) {
                top: 35.1%;
              }
              @include media-breakpoint-down(xxl) {
                top: 31.4%;
                left: 33.3333333%;
              }
              @include media-breakpoint-down(xl) {
                top: 40.4%;
                left: 20%;
              }
            }
          }
          &.annee-2 {
            padding-top: 160px;
            margin-top: 100px;
            @include media-breakpoint-down(5xl) {
              //width: 20%;
            }
            @include media-breakpoint-down(4xl) {
              //width: 16%;
            }
            @include media-breakpoint-down(xxxl) {
              //width: 13%;
            }
            @include media-breakpoint-down(xxl) {
              padding-top: 75px;
            }
            @include media-breakpoint-down(xl) {
              //width: 10%;
            }
            .dx-bg-box {
              width: 260px;
              height: 633px;
              top: 205px;
              right: 0;
              z-index: 0;
              @include media-breakpoint-down(5xl) {
                width: 180px;
                height: 632px;
              }
              @include media-breakpoint-down(4xl) {
                width: 180px;
                height: 628px;
              }
              @include media-breakpoint-down(xxxl) {
                width: 100px;
              }
              @include media-breakpoint-down(xxl) {
                display: none;
              }
              @include media-breakpoint-down(xl) {
              }
            }
            .pic-1-box {
              width: 16.66666667%;
              margin-left: 25%;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
                width: 20%;
                margin-left: 20%;
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
                width: 25%;
                margin-left: 16.6666%;
              }
              @include media-breakpoint-down(xl) {
                width: 50%;
                margin-left: -30px;
              }
              @include media-breakpoint-down(md) {
                width: 50%;
                margin-left: -30px;
              }
            }
            .pic-2-box {
              width: 22%;
              margin-left: 65%;
              margin-top: -225px;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
                margin-top: -140px;
                width: 25%;
                margin-left: 68%;
              }
              @include media-breakpoint-down(xl) {
                width: 35%;
              }
              @include media-breakpoint-down(md) {
                width: 40%;
                margin-top: -110px;
                margin-left: calc(60% + 30px);
              }
            }
            .pic-3-box {
              width: 25%;
              margin-left: 33%;
              margin-top: 240px;
              position: relative;
              z-index: 2;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
                margin-top: 100px;
                margin-left: 25%;
                width: 33.33333%;

              }
              @include media-breakpoint-down(xl) {
                width: 40%;
                margin-left: 12%;
              }
              @include media-breakpoint-down(md) {
                width: 70%;
                margin-top: 130px;
                margin-left: -30px;
              }
            }
            .pic-4-box {
              width: 18%;
              margin-left: 65%;
              margin-top: -178px;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
                margin-top: -138px;
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
              }
              @include media-breakpoint-down(xl) {
                margin-top: -178px;
              }
              @include media-breakpoint-down(md) {
                width: 30%;
                margin-left: 70%;
                margin-top: -108px;
              }
            }
            .annee-box {
              top: 38.4%;
              left: 40%;
              @include media-breakpoint-down(5xl) {
                top: 36.1%;
              }
              @include media-breakpoint-down(4xl) {
                top: 38%;
              }
              @include media-breakpoint-down(xxxl) {
                top: 43.1%;
              }
              @include media-breakpoint-down(xxl) {
                top: 37.4%;
                left: 33.3333333%;
              }
              @include media-breakpoint-down(xl) {
                top: 44.4%;
                left: 20%;
              }
            }
          }
          &.annee-3 {
            padding-top: 160px;
            margin-top: 200px;
            @include media-breakpoint-down(5xl) {
              //width: 20%;
            }
            @include media-breakpoint-down(4xl) {
              //width: 16%;
            }
            @include media-breakpoint-down(xxxl) {
              //width: 13%;
            }
            @include media-breakpoint-down(xxl) {
              padding-top: 75px;
            }
            @include media-breakpoint-down(xl) {
              //width: 10%;
            }
            .dx-bg-box {
              width: 260px;
              height: 633px;
              top: 205px;
              right: 0;
              z-index: 0;
              @include media-breakpoint-down(5xl) {
                width: 180px;
              }
              @include media-breakpoint-down(4xl) {
                width: 180px;
                height: 628px;
              }
              @include media-breakpoint-down(xxxl) {
                width: 100px;
              }
              @include media-breakpoint-down(xxl) {
                display: none;
              }
              @include media-breakpoint-down(xl) {
              }
            }
            .pic-1-box {
              width: 25%;
              margin-left: 28%;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
                margin-left: 25%;
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
                width: 25%;
                margin-left: 16.6666%;
              }
              @include media-breakpoint-down(xl) {
                width: 50%;
                margin-left: -30px;
              }
              @include media-breakpoint-down(md) {
                width: 50%;
                margin-left: 0;
              }
            }
            .pic-2-box {
              width: 33.33333333%;
              margin-left: 55%;
              margin-top: -105px;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
                margin-left: 65%;
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
                margin-top: -50px;
                width: 50%;
                margin-left: 50%;
              }
              @include media-breakpoint-down(xl) {
                width: 35%;
              }
              @include media-breakpoint-down(md) {
                width: 50%;
                margin-top: -50px;
                margin-left: calc(50% + 30px);
              }
            }
            .pic-3-box {
              width: 25%;
              margin-left: 30%;
              margin-top: 220px;
              position: relative;
              z-index: 2;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
                margin-top: 100px;
                margin-left: 20%;
                width: 33.33333%;

              }
              @include media-breakpoint-down(xl) {
                width: 40%;
                margin-left: 12%;
              }
              @include media-breakpoint-down(md) {
                width: 50%;
                margin-top: 150px;
                margin-left: 5%;
                //margin-left: -30px;
              }
            }
            .annee-box {
              top: 39.4%;
              left: 40%;
              @include media-breakpoint-down(5xl) {
                top: 36.1%;
              }
              @include media-breakpoint-down(4xl) {
                top: 38%;
              }
              @include media-breakpoint-down(xxxl) {
                top: 43.1%;
              }
              @include media-breakpoint-down(xxl) {
                top: 34.4%;
                left: 33.3333333%;
              }
              @include media-breakpoint-down(xl) {
                top: 38.4%;
                left: 20%;
              }
            }
          }
          &.annee-4 {
            padding-top: 160px;
            //margin-top: 300px;
            @include media-breakpoint-down(5xl) {
              //width: 20%;
            }
            @include media-breakpoint-down(4xl) {
              //width: 16%;
            }
            @include media-breakpoint-down(xxxl) {
              //width: 13%;
            }
            @include media-breakpoint-down(xxl) {
              padding-top: 75px;
            }
            @include media-breakpoint-down(xl) {
              //width: 10%;
            }
            .dx-bg-box {
              width: 260px;
              height: 633px;
              top: 205px;
              right: 0;
              z-index: 0;
              @include media-breakpoint-down(5xl) {
                width: 180px;
              }
              @include media-breakpoint-down(4xl) {
                width: 180px;
                height: 628px;
              }
              @include media-breakpoint-down(xxxl) {
                width: 100px;
              }
              @include media-breakpoint-down(xxl) {
                display: none;
              }
              @include media-breakpoint-down(xl) {
              }
            }
            .pic-1-box {
              width: 33.33333333%;
              margin-left: 61%;
              position: relative;
              z-index: 4;
              margin-top: -80px;
              @include media-breakpoint-down(5xl) {
                margin-left: 64%;
              }
              @include media-breakpoint-down(4xl) {
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
              }
              @include media-breakpoint-down(xl) {
              }
              @include media-breakpoint-down(md) {
                width: 70%;
                margin-left: calc(30% + 30px);
              }
            }
            .pic-2-box {
              width: 8.33333333%;
              margin-left: 46%;
              margin-top: 180px;
              @include media-breakpoint-down(5xl) {
                margin-top: 120px;
              }
              @include media-breakpoint-down(4xl) {
                margin-top: 170px;
              }
              @include media-breakpoint-down(xxxl) {
                margin-top: 150px;
              }
              @include media-breakpoint-down(xxl) {
                margin-top: 150px;
                margin-left: 41%;
              }
              @include media-breakpoint-down(xl) {
              }
              @include media-breakpoint-down(md) {
                width: 22%;
                margin-left: 16%;
                margin-top: 130px;
              }
            }
            .annee-box {
              top: 39.4%;
              left: 40%;
              @include media-breakpoint-down(5xl) {
                top: 36.1%;
              }
              @include media-breakpoint-down(4xl) {
                top: 38%;
              }
              @include media-breakpoint-down(xxxl) {
                top: 43.1%;
              }
              @include media-breakpoint-down(xxl) {
                top: 34.4%;
                left: 33.3333333%;
              }
              @include media-breakpoint-down(xl) {
                top: 44.4%;
                left: 20%;
              }
            }
          }
          &.annee-5 {
            margin-top: 200px;
            padding-top: 160px;
            @include media-breakpoint-down(5xl) {
              //width: 20%;
            }
            @include media-breakpoint-down(4xl) {
              //width: 16%;
            }
            @include media-breakpoint-down(xxxl) {
              //width: 13%;
            }
            @include media-breakpoint-down(xxl) {
              padding-top: 75px;
            }
            @include media-breakpoint-down(xl) {
              //width: 10%;
            }
            .dx-bg-box {
              width: 260px;
              height: 633px;
              top: 205px;
              right: 0;
              z-index: 0;
              @include media-breakpoint-down(5xl) {
                height: 633px;
                width: 180px;
              }
              @include media-breakpoint-down(4xl) {
                height: 628px;
                width: 180px;
              }
              @include media-breakpoint-down(xxxl) {
                width: 100px;
              }
              @include media-breakpoint-down(xxl) {
                display: none;
              }
              @include media-breakpoint-down(xl) {
              }
            }
            .pic-1-box {
              width: 25%;
              margin-left: 25%;
              @include media-breakpoint-down(5xl) {
                //width: 20%;
              }
              @include media-breakpoint-down(4xl) {
                width: 30%;
                margin-left: 21%;
              }
              @include media-breakpoint-down(xxxl) {
                //width: 13%;
              }
              @include media-breakpoint-down(xxl) {
                width: 33.33333333%;
                margin-left: 15.66666667%;
              }
              @include media-breakpoint-down(xl) {
                width: 50%;
                margin-left: -30px;
              }
              @include media-breakpoint-down(md) {
                width: 50%;
                margin-left: -30px;
              }
            }
            .pic-2-box {
              width: 16.66666667%;
              margin-left: 32.5%;
              margin-top: 315px;
              @include media-breakpoint-down(5xl) {
                //width: 20%;
              }
              @include media-breakpoint-down(4xl) {
                //width: 16%;
                margin-top: 305px;
              }
              @include media-breakpoint-down(xxxl) {
                //width: 13%;
                margin-top: 150px;
              }
              @include media-breakpoint-down(xxl) {
                width: 22%;
                margin-left: 25.66666667%;
                margin-top: 185px;
              }
              @include media-breakpoint-down(xl) {
                width: 40%;
                margin-top: -225px;
                margin-left: 50%;
              }
              @include media-breakpoint-down(md) {
                width: 40%;
                margin-top: -245px;
                margin-left: 50%;
              }
              @include media-breakpoint-down(sm) {
                margin-top: -145px;
              }
            }
            .pic-3-box {
              width: 50%;
              margin-left: 50%;
              margin-top: -180px;
              position: relative;
              z-index: 2;
              @include media-breakpoint-down(5xl) {
                //margin-top: -200px;
              }
              @include media-breakpoint-down(4xl) {
                margin-top: -200px;
                margin-left: 55%;
              }
              @include media-breakpoint-down(xxxl) {
                //width: 13%;
                margin-top: -200px;
                margin-left: 55%;
              }
              @include media-breakpoint-down(xxl) {
                width: 50%;
                margin-top: -180px;
              }
              @include media-breakpoint-down(xl) {
                width: 70%;
                margin-left: calc(30% + 30px);
                margin-top: 155px;
              }
              @include media-breakpoint-down(md) {
                width: 70%;
                margin-left: calc(30% + 30px);
                margin-top: 125px;
              }
              @include media-breakpoint-down(sm) {
                margin-top: 155px;
              }
            }
            .annee-box {
              top: 36.4%;
              left: 40%;
              @include media-breakpoint-down(5xl) {
                //top: 30.1%;
              }
              @include media-breakpoint-down(4xl) {
                top: 40.4%;
              }
              @include media-breakpoint-down(xxxl) {
                top: 39.1%;
              }
              @include media-breakpoint-down(xxl) {
                top: 36.4%;
                left: 33.3333333%;
              }
              @include media-breakpoint-down(xl) {
                top: 40.4%;
                left: 20%;
              }
            }
          }
          &.annee-6 {
            padding-top: 160px;
            margin-top: 250px;
            @include media-breakpoint-down(5xl) {
              //width: 20%;
            }
            @include media-breakpoint-down(4xl) {
              //width: 16%;
              margin-top: 200px;
            }
            @include media-breakpoint-down(xxxl) {
              //width: 13%;
              margin-top: 150px;
            }
            @include media-breakpoint-down(xxl) {
              padding-top: 75px;
            }
            @include media-breakpoint-down(xl) {
              //width: 10%;
              margin-top: 120px;
            }
            .dx-bg-box {
              width: 260px;
              height: 633px;
              top: 205px;
              right: 0;
              z-index: 0;
              @include media-breakpoint-down(5xl) {
                width: 180px;
              }
              @include media-breakpoint-down(4xl) {
                width: 180px;
                height: 628px;
              }
              @include media-breakpoint-down(xxxl) {
                width: 100px;
              }
              @include media-breakpoint-down(xxl) {
                display: none;
              }
              @include media-breakpoint-down(xl) {
              }
            }
            .pic-1-box {
              width: 25%;
              margin-left: 33%;
              margin-top: 10px;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
                margin-left: 30%;
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
                width: 25%;
                margin-left: 16.6666%;
              }
              @include media-breakpoint-down(xl) {
                width: 15%;
                margin-left: 10%;
              }
              @include media-breakpoint-down(md) {
                width: 15%;
                margin-left: 10%;
              }
            }
            .pic-2-box {
              width: 33.33333333%;
              margin-left: 50%;
              margin-top: -305px;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
                margin-left: 55%;
              }
              @include media-breakpoint-down(xxxl) {
                margin-left: 55%;
                margin-top: -205px;
              }
              @include media-breakpoint-down(xxl) {
                margin-top: -250px;
                width: 50%;
                margin-left: 40%;
              }
              @include media-breakpoint-down(xl) {
                width: 35%;
              }
              @include media-breakpoint-down(md) {
                width: 50%;
                margin-top: -150px;
                margin-left: calc(50% + 30px);
              }
              @include media-breakpoint-down(sm) {
                margin-top: -50px;
              }
            }
            .pic-3-box {
              width: 25%;
              margin-left: 30%;
              margin-top: 290px;
              position: relative;
              z-index: 2;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
                margin-left:20%;
                margin-top: 250px;
              }
              @include media-breakpoint-down(xxxl) {
                margin-top: 140px;
              }
              @include media-breakpoint-down(xxl) {
                margin-top: 0px;
                margin-left: 20%;
                width: 33.33333%;

              }
              @include media-breakpoint-down(xl) {
                margin-top: 150px;
                width: 40%;
                margin-left: 12%;
              }
              @include media-breakpoint-down(md) {
                width: 50%;
                margin-top: 20px;
                margin-left: 5%;
                //margin-left: -30px;
              }
              @include media-breakpoint-down(sm) {
                margin-top: 90px;
              }
            }
            .annee-box {
              top: 40.4%;
              left: 40%;
              @include media-breakpoint-down(5xl) {
                top: 40.1%;
              }
              @include media-breakpoint-down(4xl) {
                top: 45%;
              }
              @include media-breakpoint-down(xxxl) {
                top: 48.1%;
              }
              @include media-breakpoint-down(xxl) {
                top: 38.4%;
                left: 33.3333333%;
              }
              @include media-breakpoint-down(xl) {
                top: 38.4%;
                left: 20%;
              }
              @include media-breakpoint-down(md) {
                top: 42.4%;
                left: 20%;
              }
            }
          }
          &.annee-7 {
            padding-top: 160px;
            @include media-breakpoint-down(5xl) {
              //width: 20%;
            }
            @include media-breakpoint-down(4xl) {
              //width: 16%;
            }
            @include media-breakpoint-down(xxxl) {
              //width: 13%;
            }
            @include media-breakpoint-down(xxl) {
              padding-top: 75px;
            }
            @include media-breakpoint-down(xl) {
              //width: 10%;
            }
            .dx-bg-box {
              width: 260px;
              height: 633px;
              top: 205px;
              right: 0;
              z-index: 0;
              @include media-breakpoint-down(5xl) {
                height: 633px;
                width: 180px;
              }
              @include media-breakpoint-down(4xl) {
                height: 628px;
                width: 180px;
              }
              @include media-breakpoint-down(xxxl) {
                width: 100px;
              }
              @include media-breakpoint-down(xxl) {
                display: none;
              }
              @include media-breakpoint-down(xl) {
              }
            }
            .pic-1-box {
              width: 25%;
              margin-left: 22%;
              @include media-breakpoint-down(5xl) {
                //width: 20%;
              }
              @include media-breakpoint-down(4xl) {
                width: 30%;
                margin-left: 15%;
              }
              @include media-breakpoint-down(xxxl) {
                //width: 13%;
              }
              @include media-breakpoint-down(xxl) {
                width: 33.33333333%;
                margin-left: 5.66666667%;
              }
              @include media-breakpoint-down(xl) {
                width: 40%;
                margin-left: -30px;
              }
              @include media-breakpoint-down(md) {
                width: 50%;
                margin-left: -30px;
              }
            }
            .pic-2-box {
              width: 16.66666667%;
              margin-left: 32%;
              margin-top: 365px;
              @include media-breakpoint-down(5xl) {
                //width: 20%;
              }
              @include media-breakpoint-down(4xl) {
                //width: 16%;
              }
              @include media-breakpoint-down(xxxl) {
                //width: 13%;
              }
              @include media-breakpoint-down(xxl) {
                width: 22%;
                margin-left: 19.66666667%;
                margin-top: 255px;
              }
              @include media-breakpoint-down(xl) {
                width: 40%;
                margin-top: -135px;
                margin-left: 50%;
              }
              @include media-breakpoint-down(md) {
                width: 40%;
                margin-top: -45px;
                margin-left: 50%;
              }
            }
            .pic-3-box {
              width: 50%;
              margin-left: 59%;
              margin-top: -100px;
              position: relative;
              z-index: 2;
              @include media-breakpoint-down(5xl) {
                margin-left: 58%;
              }
              @include media-breakpoint-down(4xl) {
                margin-top: -180px;
              }
              @include media-breakpoint-down(xxxl) {
                //width: 13%;
              }
              @include media-breakpoint-down(xxl) {
                width: 50%;
                margin-left: 50%;
                margin-top: -140px;
              }
              @include media-breakpoint-down(xl) {
                width: 50%;
                margin-left: calc(50% + 30px);
                margin-top: 225px;
              }
              @include media-breakpoint-down(md) {
                width: 70%;
                margin-left: calc(30% + 30px);
                margin-top: 165px;
              }
            }
            .annee-box {
              top: 38.4%;
              left: 40%;
              @include media-breakpoint-down(5xl) {
                //top: 30.1%;
              }
              @include media-breakpoint-down(4xl) {
                //width: 16%;
              }
              @include media-breakpoint-down(xxxl) {
                top: 44.1%;
              }
              @include media-breakpoint-down(xxl) {
                top: 36.4%;
                left: 33.3333333%;
              }
              @include media-breakpoint-down(xl) {
                top: 44.4%;
                left: 20%;
              }
            }
          }
          &.annee-8 {
            padding-top: 160px;
            margin-top: 200px;
            @include media-breakpoint-down(5xl) {
              //width: 20%;
            }
            @include media-breakpoint-down(4xl) {
              //width: 16%;
              margin-top: 150px;
            }
            @include media-breakpoint-down(xxxl) {
              //width: 13%;
            }
            @include media-breakpoint-down(xxl) {
              padding-top: 75px;
            }
            @include media-breakpoint-down(xl) {
              //width: 10%;
            }
            .dx-bg-box {
              width: 260px;
              height: 633px;
              top: 205px;
              right: 0;
              z-index: 0;
              @include media-breakpoint-down(5xl) {
                width: 180px;
              }
              @include media-breakpoint-down(4xl) {
                width: 180px;
                height: 628px;
              }
              @include media-breakpoint-down(xxxl) {
                width: 100px;
              }
              @include media-breakpoint-down(xxl) {
                display: none;
              }
              @include media-breakpoint-down(xl) {
              }
            }
            .pic-1-box {
              width: 33.33333333%;
              margin-left: 61%;
              position: relative;
              z-index: 4;
              margin-top: -80px;
              @include media-breakpoint-down(5xl) {
                margin-left: 64%;
              }
              @include media-breakpoint-down(4xl) {
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
              }
              @include media-breakpoint-down(xl) {
                width: 40%;
                margin-left: calc(50% + 30px);
              }
              @include media-breakpoint-down(md) {
                width: 40%;
                margin-left: calc(50% + 30px);
              }
            }
            .pic-2-box {
              width: 20.33333333%;
              margin-left: 46%;
              margin-top: 350px;
              position: relative;
              z-index: 200;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
                //margin-top: 170px;
              }
              @include media-breakpoint-down(xxxl) {
                //margin-top: 150px;
              }
              @include media-breakpoint-down(xxl) {
                margin-left: 41%;
              }
              @include media-breakpoint-down(xl) {
                margin-top: 150px;
              }
              @include media-breakpoint-down(md) {
                width: 22%;
                margin-left: 16%;
                margin-top: 150px;
              }
            }
            .annee-box {
              top: 30.4%;
              left: 40%;
              @include media-breakpoint-down(5xl) {
                top: 36.1%;
              }
              @include media-breakpoint-down(4xl) {
                top: 38%;
              }
              @include media-breakpoint-down(xxxl) {
                top: 43.1%;
              }
              @include media-breakpoint-down(xxl) {
                top: 34.4%;
                left: 33.3333333%;
              }
              @include media-breakpoint-down(xl) {
                top: 34.4%;
                left: 20%;
              }
              @include media-breakpoint-down(md) {
                top: 24.4%;
              }
            }
          }
          .annee-box {
            position: absolute;
            z-index: 3;
            background-color: $color-alt-3;
            border-bottom-right-radius: 300px;
            border-top-right-radius: 300px;
            width: 28%;
            //width: 33.33333333%;
            padding: 42px 70px 30px 100px;
            min-height: 422px;
            display: flex;
            //align-items: center;
            flex-direction: column;
            justify-content: center;
            @include media-breakpoint-down(5xl) {
              width: 32%;
            }
            @include media-breakpoint-down(4xl) {
              width: 36%;
              padding: 38px 60px 25px 100px;
              min-height: 380px;
            }
            @include media-breakpoint-down(xxxl) {
              min-height: 300px;
              padding: 38px 60px 25px 60px;
            }
            @include media-breakpoint-down(xxl) {
              width: 40%;
              min-height: 286px;
              padding: 38px 60px 25px 50px;
            }
            @include media-breakpoint-down(xl) {
              width: 50%;
              min-height: 240px;
              padding: 32px 50px 30px 40px;
            }
            @include media-breakpoint-down(md) {
              width: 66%;
              min-height: 166px;
              padding: 22px 40px 20px 20px;
            }
            h3 {
              font-size: 152px;
              line-height: 140px;
              @include font-title-reg;
              font-style: italic;
              margin-bottom: 0;
              margin-right: 50px;
              @include media-breakpoint-down(5xl) {
                font-size: 142px;
                line-height: 130px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 132px;
                line-height: 130px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 120px;
                line-height: 118px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 102px;
                line-height: 100px;
              }
              @include media-breakpoint-down(md) {
                font-size: 59px;
                line-height: 50px;
                margin-bottom: 10px;
              }
            }
            .content-box {
              position: relative;
              padding-left: 40px;
              @include media-breakpoint-down(md) {
                padding-left: 20px;
              }
              &:before {
                content: "";
                width: 22px;
                height: 37px;
                background-image: url("/assets/img/histo-fleche.svg");
                display: block;
                position: absolute;
                top: 4px;
                left: 0;
                @include media-breakpoint-down(5xl) {
                  //width: 20%;
                }
                @include media-breakpoint-down(4xl) {
                  //width: 16%;
                }
                @include media-breakpoint-down(xxxl) {
                  //width: 13%;
                }
                @include media-breakpoint-down(xxl) {
                  width: 14px;
                  height: 24px;
                  background-size: 14px;
                }
                @include media-breakpoint-down(xl) {
                  //width: 10%;
                }
              }
              h4 {
                font-size: 16px;
                line-height: 23px;
                letter-spacing: 0.86px;
                @include font-bold-1;
                margin-bottom: 0;
                @include media-breakpoint-down(5xl) {
                  font-size: 15px;
                  line-height: 22px;
                  letter-spacing: 0.8px;
                }
                @include media-breakpoint-down(4xl) {
                  font-size: 14px;
                  line-height: 21px;
                  letter-spacing: 0.7px;
                }
                @include media-breakpoint-down(xxxl) {
                  font-size: 13px;
                  line-height: 20px;
                  letter-spacing: 0.65px;
                }
                @include media-breakpoint-down(xxl) {
                  font-size: 11px;
                  line-height: 17px;
                  letter-spacing: 0.58px;
                }
                @include media-breakpoint-down(md) {
                  font-size: 10px;
                  line-height: 14px;
                  letter-spacing: 0.34px;
                }
              }
              p {
                font-size: 16px;
                line-height: 25px;
                @include font-reg-1;
                margin-bottom: 0;
                @include media-breakpoint-down(5xl) {
                  font-size: 15px;
                  line-height: 24px;
                }
                @include media-breakpoint-down(4xl) {
                  font-size: 14px;
                  line-height: 24px;
                }
                @include media-breakpoint-down(xxxl) {
                  font-size: 13px;
                  line-height: 23px;
                }
                @include media-breakpoint-down(xxl) {
                  font-size: 11px;
                  line-height: 17px;
                }
                @include media-breakpoint-down(md) {
                  font-size: 10px;
                  line-height: 14px;
                }
              }
            }
          }
        }
      }
    }
    .section-logos {
      margin-top: 90px;
      .bg-logos-dx {
        background-size: 600px;
        background-image: url("/assets/img/bg-dx.svg");
        padding: 280px 0 100px 0;
        position: relative;
        @include media-breakpoint-down(5xl) {
          background-size: 560px;
        }
        @include media-breakpoint-down(4xl) {
          background-size: 560px;
        }
        @include media-breakpoint-down(xxxl) {
          background-size: 500px;
        }
        @include media-breakpoint-down(xxl) {
          padding: 260px 0 50px 0;
          background-size: 400px;
        }
        @include media-breakpoint-down(md) {
          padding: 80px 0 35px 0;
          background-size: 200px;
        }
        &:before {
          background-color: white;
          width: 80%;
          height: 135px;
          position: absolute;
          right: 0;
          top: 0;
          content: "";
          display: block;
          @include media-breakpoint-down(xxl) {
            height: 105px;
          }
          @include media-breakpoint-down(md) {
            height: 45px;
          }
        }
      }
      .commanditaires-box {
        background-color: white;
        padding: 35px 0;
        @include media-breakpoint-down(xxl) {
          padding: 10px 0;
        }
        @include media-breakpoint-down(md) {
          padding: 15px 0;
        }
      }
    }
  }
}

//#dx-page-body
