#dx-page-body {
  &.page-recrutement-postes-listing{
    background-color: white;
    overflow: hidden;
    header{
      .bg-secondary{
        //background-color: transparent!important;
      }
    }
    .site-main { //main
      .main-content-wrapper {
        .content-area {
          //padding-top: 0; // hauteur de la nav - nav transparente pour carousel
          .section-main{
            > h1{
              display: none;
            }
          }
        }
      }
    }
    //Pour tous
    .title-normal{
      h2,h3,h4{
        text-transform: lowercase;
        @include font-title-med;
        font-style: italic;
        margin-bottom: 30px;
        font-size: 56px;
        line-height: 40px;
        @include media-breakpoint-down(5xl) {
          font-size: 50px;
          line-height: 34px;
        }
        @include media-breakpoint-down(4xl) {
          font-size: 42px;
          line-height: 28px;
        }
        @include media-breakpoint-down(xxxl) {
          font-size: 40px;
          line-height: 26px;
        }
        @include media-breakpoint-down(xxl) {
          font-size: 37px;
          line-height: 23px;
        }
        @include media-breakpoint-down(xl) {
          font-size: 35px;
          line-height: 21px;
        }
        span, .span-2{
          //font-size: 130x;
          //line-height: 130px;
          display: block;
          text-transform: lowercase;
          @include font-title-med;
          font-style: italic;
          margin-left: -40px;
          font-size: 104px;
          line-height: 74px;
          @include media-breakpoint-down(5xl) {
            font-size: 98px;
            line-height: 68px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 92px;
            line-height: 62px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 82px;
            line-height: 52px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 72px;
            line-height: 42px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 65px;
            line-height: 36px;
          }
        }
      }
    }
    .content-box{
      &:before{
        background-image: url("/assets/img/slash.svg");
        background-repeat: no-repeat;
        background-size: 60px;
        width: 60px;
        height: 97px;
        content: "";
        display: block;
        position: absolute;
        //top: -65px;
        //left:-80px;
        @include media-breakpoint-down(5xl) {
          //left:-70px;
          //top: -50px;
        }
        @include media-breakpoint-down(4xl) {
          background-size: 60px;
          width: 60px;
          height: 97px;
          //left:-80px;
        }
        @include media-breakpoint-down(xxxl) {
          background-size: 71px;
          width: 71px;
          height: 87px;
        }
        @include media-breakpoint-down(xxl) {
          background-size: 63px;
          width: 63px;
          height: 77px;
        }
        @include media-breakpoint-down(xl) {
          background-size: 44px;
          width: 44px;
          height: 54px;
          //top: -30px;
          //left:-60px;
        }
        @include media-breakpoint-down(lg) {
          background-size: 31px;
          width: 31px;
          height: 38px;
          //bottom: 35px;
        }
        @include media-breakpoint-down(md) {
          //top: -20px;
          //left:-50px;
        }
      }
      p.p-title{
        text-transform: uppercase;
        @include font-light-1;
        font-size: 21px;
        line-height: 32px;
        letter-spacing: 1.2px;
        @include media-breakpoint-down(5xl) {
        }
        @include media-breakpoint-down(4xl) {
          font-size: 20px;
          line-height: 29px;
          letter-spacing: 0.99px;
        }
        @include media-breakpoint-down(xxxl) {
          font-size: 18px;
          line-height: 27px;
          letter-spacing: 0.89px;
        }
        @include media-breakpoint-down(xl) {
          font-size: 15px;
          line-height: 22px;
          letter-spacing: 0.75px;
        }
        @include media-breakpoint-down(lg) {
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0;
        }
        @include media-breakpoint-down(md) {
        }
        @include media-breakpoint-down(sm) {
        }
        strong{
          @include font-bold-1;
        }
      }
      &.title-mobile-only{
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }


    .section-offres{
      margin-top: 0;

      @include media-breakpoint-down(5xl) {
      }
      @include media-breakpoint-down(4xl) {
      }
      @include media-breakpoint-down(xxl) {
      }
      @include media-breakpoint-down(xl) {
      }
      @include media-breakpoint-down(md) {
      }
    }



    &.nav-scrolled {
      .section-menu-fun{
        top:$header-nav-height-sticky;
      }
    }
    &.nav-scrolled-hide {
      .section-menu-fun{
        top:-1px;
      }
    }
    &.nav-scrolled-second-sub-show {
      .section-menu-fun{
        opacity:1;
      }
    }
  }
}//#dx-page-body
