#dx-page-body {
  &.page-accueil-recrutement{
    background-color: white;
    overflow: hidden;
    header{
      .bg-secondary{
        //background-color: transparent!important;
      }
    }
    .site-main { //main
      .main-content-wrapper {
        .content-area {
          //padding-top: 0; // hauteur de la nav - nav transparente pour carousel
          .section-main{
            > h1{
              display: none;
            }
          }
        }
      }
    }
    //Pour tous
    .title-normal{
      h2,h3,h4{
        text-transform: lowercase;
        @include font-title-med;
        font-style: italic;
        margin-bottom: 30px;
        font-size: 56px;
        line-height: 40px;
        @include media-breakpoint-down(5xl) {
          font-size: 50px;
          line-height: 34px;
        }
        @include media-breakpoint-down(4xl) {
          font-size: 42px;
          line-height: 28px;
        }
        @include media-breakpoint-down(xxxl) {
          font-size: 40px;
          line-height: 26px;
        }
        @include media-breakpoint-down(xxl) {
          font-size: 37px;
          line-height: 23px;
        }
        @include media-breakpoint-down(xl) {
          font-size: 35px;
          line-height: 21px;
        }
        span, .span-2{
          //font-size: 130x;
          //line-height: 130px;
          display: block;
          text-transform: lowercase;
          @include font-title-med;
          font-style: italic;
          margin-left: -40px;
          font-size: 104px;
          line-height: 74px;
          @include media-breakpoint-down(5xl) {
            font-size: 98px;
            line-height: 68px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 92px;
            line-height: 62px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 82px;
            line-height: 52px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 72px;
            line-height: 42px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 65px;
            line-height: 36px;
          }
        }
      }
    }
    .content-box{
      &:before{
        background-image: url("/assets/img/slash.svg");
        background-repeat: no-repeat;
        background-size: 60px;
        width: 60px;
        height: 97px;
        content: "";
        display: block;
        position: absolute;
        //top: -65px;
        //left:-80px;
        @include media-breakpoint-down(5xl) {
          //left:-70px;
          //top: -50px;
        }
        @include media-breakpoint-down(4xl) {
          background-size: 60px;
          width: 60px;
          height: 97px;
          //left:-80px;
        }
        @include media-breakpoint-down(xxxl) {
          background-size: 71px;
          width: 71px;
          height: 87px;
        }
        @include media-breakpoint-down(xxl) {
          background-size: 63px;
          width: 63px;
          height: 77px;
        }
        @include media-breakpoint-down(xl) {
          background-size: 44px;
          width: 44px;
          height: 54px;
          //top: -30px;
          //left:-60px;
        }
        @include media-breakpoint-down(lg) {
          background-size: 31px;
          width: 31px;
          height: 38px;
          //bottom: 35px;
        }
        @include media-breakpoint-down(md) {
          //top: -20px;
          //left:-50px;
        }
      }
      p.p-title{
        text-transform: uppercase;
        @include font-light-1;
        font-size: 21px;
        line-height: 32px;
        letter-spacing: 1.2px;
        @include media-breakpoint-down(5xl) {
        }
        @include media-breakpoint-down(4xl) {
          font-size: 20px;
          line-height: 29px;
          letter-spacing: 0.99px;
        }
        @include media-breakpoint-down(xxxl) {
          font-size: 18px;
          line-height: 27px;
          letter-spacing: 0.89px;
        }
        @include media-breakpoint-down(xl) {
          font-size: 15px;
          line-height: 22px;
          letter-spacing: 0.75px;
        }
        @include media-breakpoint-down(lg) {
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0;
        }
        @include media-breakpoint-down(md) {
        }
        @include media-breakpoint-down(sm) {
        }
        strong{
          @include font-bold-1;
        }
      }
      &.title-mobile-only{
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }

    .section-fiable{
      .fiable-bg{
        position: relative;
      //  background-image: url("/assets/img/recrutement/accueil-hero-bg.jpg");
      //  background-size: cover;
      //  background-position: center;
        //overflow: hidden;
        min-height: 511px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 340px;
        @include media-breakpoint-down(5xl) {
          margin-bottom: 330px;
        }
        @include media-breakpoint-down(4xl) {
          margin-bottom:280px;
        }
        @include media-breakpoint-down(xxxl) {
          margin-bottom: 250px;
        }
        @include media-breakpoint-down(xxl) {
          margin-bottom: 200px;
        }
        @include media-breakpoint-down(xl) {
          min-height: 400px;
          margin-bottom: 150px;
        }
        @include media-breakpoint-down(lg) {
        }
        .open-more-content{
          margin-bottom: -40px;
        }
        .bg-img{
          position: absolute;
          z-index: 1;
          width:100%;
          height:100%;
          overflow: hidden;
          canvas{
            width:100%;
            height:100%;
          }
        }
      }
      
      .boite-d{
        max-width: 1393px;
        margin: 40px 0 -300px 150px;
        position: relative;
        z-index: 2;
        padding: 120px 280px 135px 255px;
        border-bottom-right-radius: 380px;
        border-top-right-radius: 380px;
        //background-color: lightcyan;
        background-color: white;
        @include media-breakpoint-down(5xl) {
         // background-color: lightblue;
          margin: 40px 0 -300px 200px;
          padding: 100px 250px 125px 225px;
        }
        @include media-breakpoint-down(4xl) {
         // background-color: lightcoral;
          margin: 40px 0 -300px 200px;
          padding: 80px 200px 105px 175px;
        }
        @include media-breakpoint-down(xxxl) {
          //background-color: lightgoldenrodyellow;
          margin: 0 0 -300px 200px;
          padding: 70px 180px 95px 165px;
        }
        @include media-breakpoint-down(xxl) {
          //background-color: lightgreen;
        }
        @include media-breakpoint-down(xl) {
          padding: 50px 60px 65px 90px;
          //background-color: lightgray;
        }
        @include media-breakpoint-down(lg) {
          //background-color: lightpink;
        }
        @include media-breakpoint-down(md) {
          //background-color: lightseagreen;
          margin: 0 0 -200px 100px;
          padding: 50px 120px 65px 50px;
        }
        @include media-breakpoint-down(sm) {
          //background-color: lightslategray;
          margin: 0 -50px -200px 20px;
          padding: 50px 50px 30px 20px;
        }
        .content-box{
          position: relative;
          z-index: 2;
          p.p-title{
            max-width: 401px;
            margin-left: 150px;
            @include media-breakpoint-down(5xl) {
              margin-left: 140px;
            }
            @include media-breakpoint-down(4xl) {
              margin-left: 120px;
            }
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xl) {
              margin-left: 110px;
            }
            @include media-breakpoint-down(lg) {
              margin-left: 90px;
              padding-right: 50px;
            }
            @include media-breakpoint-down(md) {
              margin-left: 90px;
              padding-right: 30px;
            }
            @include media-breakpoint-down(sm) {
              margin-left: 90px;
              padding-right: 50px;
            }
          }
          &:before{
            display: none;
          }
        }
        .title-principal-2-parts{
          display: flex;
          align-items: center;
          @include media-breakpoint-down(xxl) {
            margin-left: 20px;
          }
          .part-1{
            margin-right: 30px;
            h2,h3,h4{
              display: block;
              //font-size: 32px;
              //line-height: 35px;
              text-transform: uppercase;
              @include font-reg-1;
              font-weight: normal;
              letter-spacing: 2px;
              margin-bottom: 0;
              margin-left: -30px;
              font-size: 30px;
              line-height: 33px;
              text-align: right;
              @include media-breakpoint-down(5xl) {
                font-size: 28px;
                line-height: 31px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 24px;
                line-height: 26px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 24px;
                line-height: 26px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 20px;
                line-height: 24px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 18px;
                line-height: 22px;
                margin-left: -15px;
                letter-spacing: 0.65px;
              }
              strong{
                @include font-bold-1;
                display: block;
              }
            }
            &:after{
              background-image: url("/assets/img/slash.svg");
              background-repeat: no-repeat;
              background-size: 60px;
              width: 60px;
              height: 97px;
              content: "";
              display: block;
              position: absolute;
              bottom: 15px;
              left:80px;
              @include media-breakpoint-down(5xl) {
                left:70px;
              }
              @include media-breakpoint-down(4xl) {
                background-size: 60px;
                width: 60px;
                height: 97px;
                left:50px;
              }
              @include media-breakpoint-down(xxxl) {
                background-size: 71px;
                width: 71px;
                height: 87px;
              }
              @include media-breakpoint-down(xxl) {
                background-size: 63px;
                width: 63px;
                height: 77px;
              }
              @include media-breakpoint-down(xl) {
                background-size: 44px;
                width: 44px;
                height: 54px;
              }
              @include media-breakpoint-down(lg) {
                background-size: 31px;
                width: 31px;
                height: 38px;
                bottom: 35px;
              }
            }
          }
          .part-2{
            h2,h3,h4{
              //font-size: 168px;
              //line-height: 168px;
              text-transform: lowercase;
              @include font-title-med;
              font-style: italic;
              margin-bottom: 30px;
              font-size: 150px;
              line-height: 150px;
              @include media-breakpoint-down(5xl) {
                font-size: 135px;
                line-height: 135px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 124px;
                line-height: 124px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 110px;
                line-height: 110px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 95px;
                line-height: 95px;
                //margin-left: -255px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 66px;
                line-height: 66px;
                margin-bottom: 15px;
              }
            }
          }
        }
        p{

        }
        .btn-link-box{
          margin-top: 40px;
          .btn-link{
            &.opened{
              line-height: 0;
              font-size: 0;
            }
          }
        }
      }
      .dx-bg-box {
        width: 1018px;
        height: 265px;
        bottom: -266px;
        left: 0;
        @include media-breakpoint-down(5xl) {
          width: 894px;
          height: 265px;
          bottom: -266px;
        }
        @include media-breakpoint-down(4xl) {
          width: 642px;
          height: 237px;
          bottom: -240px;
        }
        @include media-breakpoint-down(xxxl) {
          width: 594px;
          height: 189px;
          bottom: -195px;
        }
        @include media-breakpoint-down(xxl) {
          width: 503px;
          height: 184px;
          bottom: -190px;
        }
        @include media-breakpoint-down(xl) {
          width: 398px;
          height: 134px;
          bottom: -136px;
        }
        @include media-breakpoint-down(md) {
          width: 305px;
          height: 134px;
          bottom: -136px;
        }
        @include media-breakpoint-down(sm) {
          width: 256px;
          height: 94px;
          bottom: -96px;
        }
      }
    }
    .section-avantages{
      margin-top: 15px;
      .title-normal{
        h3{
          @include media-breakpoint-down(xxxl) {
            margin-left: 30px;
          }
          @include media-breakpoint-down(xxl) {
            margin-left: 50px;
          }
        }
      }
      .icos-avantages-box{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        @include media-breakpoint-down(md) {
          margin-top: 30px;
        }
      }
      .avantage-box{
        text-align: center;
        //background-color: lightcyan;
        width: 33.33333333%;
        //min-width: 150px;
        //max-width: 200px;
        padding: 15px;
        margin-bottom: 20px;
        @include media-breakpoint-down(xxxl) {
        }
        @include media-breakpoint-down(sm) {
          width: 50%;
        }
        &:nth-child(2){
          //background-color: blue;
          margin-right: 200px;
          @include media-breakpoint-down(xxxl) {
            margin-right: 20px;
          }
          @include media-breakpoint-down(sm) {
            margin-right: 0px;
          }
        }
        &:last-child{
          //background-color: yellow;
          margin: 0 0 0 auto;
        }
        .ico-box{
          min-height: 74px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 30px;
          margin-left: auto;
          margin-right: auto;
          max-width: 73px;
          @include media-breakpoint-down(4xl) {
            max-width: 60px;
          }
          @include media-breakpoint-down(xxxl) {
            min-height: 60px;
            max-width: 50px;
          }
          @include media-breakpoint-down(xl) {
            margin-bottom: 5px;
          }
          img{
            @include media-breakpoint-down(xxxl) {
              max-height: 60px;
            }
            @include media-breakpoint-down(xl) {
              max-height: 47px;
            }
          }
        }
        .qualite-content-box{
          br{
            @include media-breakpoint-down(sm) {
              display: none;
            }
          }
          h5{
            font-style: italic!important;
            text-decoration: none;
            font-size: 34px;
            line-height: 40px;
            @include font-title-reg;
            margin-bottom: 20px;
            @include media-breakpoint-down(5xl) {
              font-size: 30px;
              line-height: 33px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 28px;
              line-height: 31px;
              margin-bottom: 15px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 26px;
              line-height: 29px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 24px;
              line-height: 27px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 22px;
              line-height: 25px;
              margin-bottom: 10px;
            }
            @include media-breakpoint-down(sm) {
            }
          }
          p{
            font-size: 16px;
            line-height: 23px;
            letter-spacing: 0.8px;
            max-width: 220px;
            margin: 0 auto;
            @include media-breakpoint-down(5xl) {
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.7px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 13px;
              line-height: 17px;
              letter-spacing: 0.65px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 0.6px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 11px;
              line-height: 15px;
              letter-spacing: 0.55px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 10px;
              line-height: 14px;
              letter-spacing: 0.51px;
            }
            @include media-breakpoint-down(sm) {
            }
          }
        }
      }
      .offset-manuel{
        margin-left: 50px;
        @include media-breakpoint-down(5xl) {
          margin-left: 70px;
        }
        @include media-breakpoint-down(4xl) {
          margin-left: 90px;
        }
        @include media-breakpoint-down(xl) {
          margin-left: 70px;
        }
        @include media-breakpoint-down(md) {
          margin-left: -30px;
        }
      }
      .content-box{
        position: relative;
        margin-top: 60px;
        @include media-breakpoint-down(xl) {
          margin-top: 30px;
        }
        @include media-breakpoint-down(md) {
          margin-left: 70px;
          margin-top: 50px;
        }
        &:before{
          top: -65px;
          left:-80px;
          @include media-breakpoint-down(5xl) {
            left:-70px;
            top: -50px;
          }
          @include media-breakpoint-down(4xl) {
            height: 97px;
            left:-80px;
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            top: -30px;
            left:-60px;
          }
          @include media-breakpoint-down(lg) {
            bottom: 35px;
          }
          @include media-breakpoint-down(md) {
            top: -20px;
            left:-50px;
          }
        }
        &.title-desktop-only{
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
        .p-title{
          max-width: 500px;
          @include media-breakpoint-down(sm) {
            max-width: 200px;
          }
        }
      }
      .image-DX{
        position: relative;
        margin-top: 290px;
        @include media-breakpoint-down(5xl) {
          margin-top: 260px;
        }
        @include media-breakpoint-down(4xl) {
          margin-top: 250px;
        }
        @include media-breakpoint-down(xxxl) {
          margin-top: 240px;
        }
        @include media-breakpoint-down(xxl) {
          margin-top: 230px;
        }
        @include media-breakpoint-down(md) {
          margin-top: 50px;
        }
        @include media-breakpoint-down(sm) {
          margin-top: 30px;
        }
        .img-box{
          position: relative;
          z-index: 2;
          overflow: hidden;
          width:100%;
          border-bottom-right-radius: 384px;
          border-top-right-radius: 384px;
          img{
            border-bottom-right-radius: 384px;
            border-top-right-radius: 384px;
          }
          canvas{
            width:100%;
            //border-bottom-right-radius: 384px;
            //border-top-right-radius: 384px;
          }
        }
        .dx-bg-box {
          width: 1018px;
          height: 388px;
          bottom: -120px;
          left: 100px;
          @include media-breakpoint-down(5xl) {
            width: 894px;
            height: 265px;
            bottom: -66px;
          }
          @include media-breakpoint-down(4xl) {
            width: 642px;
            height: 236px;
            bottom: -80px;
          }
          @include media-breakpoint-down(xxxl) {
            width: 594px;
            height: 189px;
            bottom: -95px;
          }
          @include media-breakpoint-down(xxl) {
            width: 503px;
            height: 184px;
            bottom: -87px;
          }
          @include media-breakpoint-down(xl) {
            width: 398px;
            height: 134px;
            bottom: -56px;
          }
          @include media-breakpoint-down(md) {
            width: 705px;
            height: 254px;
            bottom: -36px;
          }
          @include media-breakpoint-down(sm) {
            width: 456px;
            height: 194px;
            bottom: -36px;
          }
        }
      }
    }
// section OFFRES est dans recrutement_general
    .section-mode-de-vie{
      .bg-img-color-box{
        width: 100%;
        min-height: 900px;
        background-size: cover;
        @include media-breakpoint-down(xl) {
          background-position: left -50px center;
          min-height:auto;
        }
        @include media-breakpoint-down(md) {
          background-position: left -510px center;
        }
      }
      .title-normal{
        margin-top: 300px;
        @include media-breakpoint-down(xl) {
          margin-top:200px;
        }
        @include media-breakpoint-down(md) {
          margin-top: 70px;
        }
        h2,h3,h4{
          color: $color-inverse;
          span{
            margin-left: 0px;
            @include media-breakpoint-down(md) {
              margin-left: 20px;
            }
          }
        }
      }
      .content-box{
        position: relative;
        margin-top: 60px;
        margin-left: 125px;
        margin-bottom: 0;
        @include media-breakpoint-down(4xl) {
          margin-left: 105px;
        }
        @include media-breakpoint-down(xxl) {
          margin-top: 80px;
        }
        @include media-breakpoint-down(xl) {
          margin-top: 50px;
        }
        @include media-breakpoint-down(md) {
          margin-top: 40px;
          margin-left: 45px;
        }
        &:before{
          background-image: url("/assets/img/slash-white.svg");
          top: 5px;
          left:-80px;
          @include media-breakpoint-down(5xl) {
            left:-70px;
          }
          @include media-breakpoint-down(4xl) {
            left:-80px;
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            left:-60px;
          }
          @include media-breakpoint-down(lg) {
          }
          @include media-breakpoint-down(md) {
            left:-40px;
            top: -15px;
          }
        }
        .p-title{
          color: $color-inverse;
        }
      }
      .feed-instagram-box{
        max-width: 400px;
        margin:95px auto 0 auto;
        @include media-breakpoint-down(xl) {
          margin:35px auto 35px auto;
        }
        @include media-breakpoint-down(md) {
          margin:50px auto 200px auto;
        }
      }
    }
    .section-environnement{
      margin-top: -150px;
      @include media-breakpoint-down(4xl) {
        margin-top: -140px;
      }
      @include media-breakpoint-down(xxxl) {
        //margin-top: -130px;
      }
      @include media-breakpoint-down(xxl) {
        margin-top: -120px;
      }
      @include media-breakpoint-down(xl) {
        margin-top: -100px;
      }
      @include media-breakpoint-down(lg) {
        margin-top: -90px;
      }
      @include media-breakpoint-down(md) {
        margin-top: -80px;
      }
      .bg-blanc{
        border-top-right-radius: 500px;
        border-bottom-right-radius: 500px;
        background-color: white;
        width: 52%;
        height: 1000px;
        @include media-breakpoint-down(5xl) {
          width: 55%;
          height: 900px;
        }
        @include media-breakpoint-down(4xl) {
          width: 65%;
          height: 800px;
        }
        @include media-breakpoint-down(xxxl) {
          width: 70%;
          height: 700px;
        }
        @include media-breakpoint-down(xxl) {
          width: 72%;
          height: 600px;
        }
        @include media-breakpoint-down(xl) {
          width: 70%;
          height: 500px;
        }
        @include media-breakpoint-down(lg) {
        }
        @include media-breakpoint-down(md) {
          width: 95%;
          height: 330px;
        }
      }
      .container-1{
        margin-top: -700px;
        @include media-breakpoint-down(5xl) {
          margin-top: -650px;
        }
        @include media-breakpoint-down(4xl) {
          margin-top: -600px;
        }
        @include media-breakpoint-down(xxxl) {
          margin-top: -500px;
        }
        @include media-breakpoint-down(xxl) {
          //margin-top: -830px;
        }
        @include media-breakpoint-down(xl) {
          margin-top: -400px;
        }
        @include media-breakpoint-down(lg) {
        }
        @include media-breakpoint-down(md) {
          margin-top: -270px;
        }
      }
      .container-2{
        @include media-breakpoint-down(md) {
          padding: 0!important;
        }
      }
      .title-normal{
        h2,h3,h4{
          span{
            margin-left: 32px;
            @include media-breakpoint-down(md) {
              margin-left: 20px;
            }
          }
        }
      }
      .content-box{
        position: relative;
        margin-top: 45px;
        margin-left:0;
        margin-bottom:80px;
        @include media-breakpoint-down(4xl) {
          //margin-left: 105px;
        }
        @include media-breakpoint-down(xxl) {
          //margin-top: 80px;
        }
        @include media-breakpoint-down(xl) {
          //margin-top: 50px;
        }
        @include media-breakpoint-down(md) {
           margin-bottom:50px;
        }
        &:before{
          background-image: url("/assets/img/slash.svg");
          top: -64px;
          left:-45px;
          @include media-breakpoint-down(5xl) {
            //left:-70px;
          }
          @include media-breakpoint-down(4xl) {
            //left:-80px;
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            left:-60px;
          }
          @include media-breakpoint-down(lg) {
          }
          @include media-breakpoint-down(md) {
            left:-40px;
            top: -15px;
          }
        }
        .p-title{
          max-width: 500px;
        }
      }
      .title-desktop-only{
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
      .title-mobile-only{
        display: none;
        @include media-breakpoint-down(md) {
          display: block;
        }
      }
      .demo-slide-box{
        margin-left: 8.33333333%;
        margin-right: 8.33333333%;
        @include media-breakpoint-down(md) {
          margin-left: 0;
          margin-right: 0;
        }
        .bg-img-box{
          background-size: cover;
          width: 100%;
          min-height: 1050px;
          display: block;
          background-position: center;
          @include media-breakpoint-down(5xl) {
            min-height: 950px;
          }
          @include media-breakpoint-down(4xl) {
            min-height: 800px;
          }
          @include media-breakpoint-down(xxxl) {
            min-height: 600px;
          }
          @include media-breakpoint-down(xxl) {
            min-height: 550px;
          }
          @include media-breakpoint-down(xl) {
            min-height:500px;
          }
          @include media-breakpoint-down(lg) {
            min-height:400px;
          }
          @include media-breakpoint-down(md) {
            min-height:300px;
          }
          img{
            width: 100%;
            display: none;
          }

        }
      }
      .swiper-button{
        transition: opacity 0.2s linear;
        margin-top: -32.5px;
        width: 35px;
        height: 65px;
        top: 50%;
        bottom:0;
        background-size: 35px;
        background-repeat: no-repeat;
        background-image: url("/assets/img/carousel-fleche-d.svg");
        @include media-breakpoint-down(5xl) {
          margin-top: -30px;
          width: 30px;
          height: 60px;
          background-size: 30px;
        }
        @include media-breakpoint-down(4xl) {
          margin-top: -27.5px;
          width: 25px;
          height: 55px;
          background-size: 25px;
        }
        @include media-breakpoint-down(xxxl) {
          margin-top: -27.5px;
          width: 25px;
          height: 55px;
          background-size: 25px;
        }
        @include media-breakpoint-down(xl) {
          margin-top: -6px;
          width: 12px;
          height: 23px;
          background-size: 12px;
        }
        @include media-breakpoint-down(lg) {
          margin-top: -28px;
          width:20px;
          height:56px;
          background-size: 20px;
        }
        @include media-breakpoint-down(md) {
          background-image: url("/assets/img/carousel-fleche.svg");
          width: 40px;
          height:40px;
          background-size:40px;
          margin-top: -20px;
        }
        @include media-breakpoint-down(sm) {
        }
        &:hover{
          opacity: 0.85;
        }
        &:after{
          content: "";
        }
        &.swiper-button-prev{
          left: 40px;
          transform: rotate(180deg);
          @include media-breakpoint-down(xl) {
            left: 5px;
          }
          @include media-breakpoint-down(sm) {
          }
        }
        &.swiper-button-next{
          right: 45px;
          @include media-breakpoint-down(xl) {
            right: 5px;
          }
          @include media-breakpoint-down(sm) {
          }
        }
      }
    }
    .section-adn {
      margin-top: 100px;
      position: relative;
      .bg-gris{
        border-top-right-radius: 500px;
        border-bottom-right-radius: 500px;
        //background-color: red;
        background-color: #F5F5F5;
        width: 60%;
        height: 620px;
        @include media-breakpoint-down(5xl) {
          width: 68%;
          height: 600px;
        }
        @include media-breakpoint-down(4xl) {
          width: 76%;
          height: 580px;
        }
        @include media-breakpoint-down(xxxl) {
          width: 80%;
          height: 550px;
        }
        @include media-breakpoint-down(xxl) {
          width: 89%;
          height: 500px;
        }
        @include media-breakpoint-down(xl) {
          width: 95%;
          height: 330px;
        }
        @include media-breakpoint-down(lg) {
        }
        @include media-breakpoint-down(md) {
          width: 95%;
          height: 330px;
        }
      }

      .adn-intro-box {
        background-color: #F5F5F5;
        margin-top: -400px;
        margin-left: 16.666666%;
        @include media-breakpoint-down(5xl) {
          margin-top: -390px;
        }
        @include media-breakpoint-down(4xl) {
          margin-top: -380px;
        }
        @include media-breakpoint-down(xxxl) {
          margin-top: -350px;
        }
        @include media-breakpoint-down(xxl) {
          margin-top: -310px;
        }
        @include media-breakpoint-down(xl) {
          margin-top: -220px;
          margin-left: 0;
        }
        @include media-breakpoint-down(lg) {
        }
        @include media-breakpoint-down(md) {
          margin-top: -220px;
        }
        &:before{
          content: "";
          //background-color: green;
          background-color: #F5F5F5;
          height: 100%;
          width: 20%;
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          z-index: 0;
          @include media-breakpoint-down(xl) {
            width: 2%;
          }
        }
        .title-img-box{
          display: flex;
          align-items: flex-end;
          margin-top: -80px;
          @include media-breakpoint-down(xl) {
            flex-direction: column-reverse;
            align-items: flex-start;
            margin-top: -50px;
          }
          @include media-breakpoint-down(lg) {
          }
          @include media-breakpoint-down(md) {
          }
          .adn-content-bg-box {
            padding-bottom: 20px;
            padding-left: 60px;
            @include media-breakpoint-down(5xl) {
            }
            @include media-breakpoint-down(4xl) {
            }
            @include media-breakpoint-down(xxxl) {
              padding-left: 20px;
            }
            @include media-breakpoint-down(xxl) {
              padding-left: 45px;
            }
            @include media-breakpoint-down(xl) {
              padding-left: 20px;
            }
            .title-normal{
              h3{
                span{
                  margin-left: 30px;
                  @include media-breakpoint-down(xl) {
                    margin-left: 20px;
                  }
                }
              }
            }
            .intro-content-box {
              padding-top: 63px;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
                padding-top: 43px;
              }
              @include media-breakpoint-down(xl) {
                max-width: 379px;
                margin: 0 auto;
              }
            }
          }
          .img-box {
            margin-left: 100px;
            @include media-breakpoint-down(5xl) {
              max-width: 400px;
            }
            @include media-breakpoint-down(4xl) {
              max-width: 380px;
            }
            @include media-breakpoint-down(xxxl) {
              max-width: 340px;
            }
            @include media-breakpoint-down(xxl) {
              max-width: 300px;
            }
            @include media-breakpoint-down(xl) {
              max-width: 195px;
              margin-left: 70px;
            }
          }
        }
        .content-box{
          position: relative;
          margin-top: 75px;
          margin-left:250px;
          //margin-bottom:80px;
          @include media-breakpoint-down(4xl) {
            //margin-left: 105px;
          }
          @include media-breakpoint-down(xxxl) {
            margin-left:164px;
          }
          @include media-breakpoint-down(xxl) {
            //margin-top: 80px;
          }
          @include media-breakpoint-down(xl) {
            //margin-top: 50px;
            margin-top: 20px;
            margin-left:40px;
          }
          @include media-breakpoint-down(md) {
            //margin-bottom:50px;
          }
          &:before{
            background-image: url("/assets/img/slash.svg");
            top: -114px;
            left:-15px;
            @include media-breakpoint-down(5xl) {
              //left:-70px;
            }
            @include media-breakpoint-down(4xl) {
              //left:-80px;
            }
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xxl) {
            }
            @include media-breakpoint-down(xl) {
              left:80px;
              top: -55px;
            }
            @include media-breakpoint-down(lg) {
            }
            @include media-breakpoint-down(md) {
              left:80px;
              top: -55px;
            }
          }
          .p-title{
            max-width: 500px;
            @include media-breakpoint-down(xxxl) {
              max-width: 400px;
            }
          }
        }
        .intro-content-box{
          padding-left: 230px;
          padding-bottom: 80px;
          @include media-breakpoint-down(xxxl) {
            padding-left: 146px;
          }
          @include media-breakpoint-down(xl) {
            padding-bottom: 30px;
            padding-left: 20px;
          }
        }

      }
      .adn-open-box {
        //padding-top: 100px;
        background-color: #F5F5F5;
        @include media-breakpoint-down(5xl) {
          //padding-top: 60px;
        }
        @include media-breakpoint-down(4xl) {
          //padding-top: 40px;
        }
        @include media-breakpoint-down(xxxl) {
          //padding-top: 20px;
        }
        @include media-breakpoint-down(xxl) {
          padding-top: 0px;
        }
        .adn-open-col-content {
          padding-top: 75px;
          padding-bottom: 135px;
          position: relative;
          @include media-breakpoint-down(5xl) {
          }
          @include media-breakpoint-down(4xl) {
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xxl) {
            padding-top: 25px;
            padding-bottom: 55px;
          }
          &.bg-color-turquoise, &.bg-color-white {
            border-bottom-right-radius: 600px;
            border-top-right-radius: 600px;
            @include media-breakpoint-down(xxl) {
              margin-bottom: 30px;
              margin-top: 40px;
            }
            @include media-breakpoint-down(md) {
              padding-right: 80px;
            }
            &:before {
              content: "";
              background-color: white;
              height: 100%;
              width: 600px;
              display: block;
              position: absolute;
              top: 0;
              left: -48%;
              z-index: 0;
              @include media-breakpoint-down(xxxl) {
                width: 400px;
              }
              @include media-breakpoint-down(xxl) {
                width: 500px;
                left: -38%;
              }
              @include media-breakpoint-down(xl) {
                width: 100px;
                left: -10%;
              }
              @include media-breakpoint-down(md) {
                left: -30px;
                width: 30px;
              }
            }
          }
        }
        .adn-open-content-box {
          padding-top: 50px;
          @include media-breakpoint-down(xl) {
            max-width: 300px;
            margin: 0 auto;
          }
          h4 {
            font-size: 39px;
            line-height: 35px;
            @include font-title-reg;
            font-style: italic;
            padding-bottom: 20px;
            @include media-breakpoint-down(5xl) {
            }
            @include media-breakpoint-down(4xl) {
            }
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xxl) {
              font-size: 28px;
              line-height: 26px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 42px;
              line-height: 38px;
            }
            strong {
              @include font-black-1;
              font-size: 25px;
              line-height: 23px;
              font-style: normal;
              display: block;
              text-transform: uppercase;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
                font-size: 23px;
                line-height: 21px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 27px;
                line-height: 25px;
              }
            }
          }
        }
        .img-box {
          position: relative;
          z-index: 10;
          @include media-breakpoint-down(xl) {
            text-align: center;
          }
        }
      }
    }
    .section-raison {
      padding-top: 105px;
      padding-bottom: 100px;
      position: relative;
      @include media-breakpoint-down(5xl) {
      }
      @include media-breakpoint-down(4xl) {
      }
      @include media-breakpoint-down(xxxl) {
      }
      @include media-breakpoint-down(xxl) {
      }
      @include media-breakpoint-down(xl) {
        //background-color: mediumseagreen;
        padding-top: 65px;
        padding-bottom: 20px;
      }
      @include media-breakpoint-down(lg) {
        //background-color: hotpink;
      }
      @include media-breakpoint-down(md) {
        //background-color: salmon;
      }
      @include media-breakpoint-down(sm) {
        //background-color: greenyellow;
      }
      .raison-intro-box {
        .title-mobile-only{
          display: none;
          @include media-breakpoint-down(xl) {
            display: flex;
          }
        }
        .img-box {
          padding-top: 160px;
          margin-left: 110px;
          @include media-breakpoint-down(5xl) {
            padding-top: 140px;
          }
          @include media-breakpoint-down(4xl) {
            padding-top: 120px;
            margin-left: 50px;
            margin-right: 50px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-top: 100px;
            margin-left: 80px;
            margin-right: 80px;
          }
          @include media-breakpoint-down(xxl) {
            padding-top: 90px;
          }
          @include media-breakpoint-down(xl) {
            padding-top: 0;
            margin-top: 0;
            padding-bottom: 20px;
            max-width: 170px;
            margin-left: 0;
            margin-right:0;
          }
          @include media-breakpoint-down(lg) {

          }
          @include media-breakpoint-down(md) {
          }
          @include media-breakpoint-down(sm) {
          }
        }
        .title-normal{
          @include media-breakpoint-down(xl) {
           margin-left: 40px;
          }
          h2,h3,h4{
            margin-left: 48px;
            span{
              margin-left:-48px;
              margin-top: 10px;
            }
            .span-2{
              margin-left: 165px;
              //margin-top: 10px;
              margin-top: 0;
              @include media-breakpoint-down(xxxl) {
                margin-left: 135px;
              }
              @include media-breakpoint-down(xxl) {
                margin-left: 90px;
              }
            }
          }
          &.title-desktop-only{
            @include media-breakpoint-down(xl) {
              display: none;
            }
          }
        }
        .content-box{
          position: relative;
          margin-top: 60px;
          margin-left:0;
          margin-bottom:80px;
          @include media-breakpoint-down(4xl) {
          }
          @include media-breakpoint-down(xxl) {
            margin-top: 40px;
          }
          @include media-breakpoint-down(xl) {
            margin-left: 40px;
          }
          @include media-breakpoint-down(lg) {
          }
          @include media-breakpoint-down(md) {
            margin-bottom:50px;
          }
          &:before{
            background-image: url("/assets/img/slash.svg");
            top: -294px;
            left:-30px;
            @include media-breakpoint-down(5xl) {
              //left:-70px;
            }
            @include media-breakpoint-down(4xl) {
              top: -254px;
            }
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xxl) {
              top: -204px;
            }
            @include media-breakpoint-down(xl) {
              left:-10px;
              top: -410px;
            }
            @include media-breakpoint-down(lg) {
            }
            @include media-breakpoint-down(md) {
            }
          }
          .p-title, p{
            max-width: 450px;
          }
        }
      }
      .raison-open-box {
        padding-top: 135px;
        position: relative;
        @include media-breakpoint-down(5xl) {
          padding-top: 125px;
        }
        @include media-breakpoint-down(4xl) {
          padding-top: 115px;
        }
        @include media-breakpoint-down(xxxl) {
          padding-top: 105px;
        }
        @include media-breakpoint-down(xxl) {
          padding-top: 95px;
        }
        @include media-breakpoint-down(xl) {
          padding-top: 0px;
        }
        .dx-bg-box {
          &.bg-1 {
            width: 577px;
            height: 300px;
            top: 900px;
            left: 0;
            @include media-breakpoint-down(5xl) {
              width: 424px;
              height: 246px;
              top: 810px;
            }
            @include media-breakpoint-down(4xl) {
              width: 378px;
              height: 220px;
              top: 740px;
            }
            @include media-breakpoint-down(xxxl) {
              width: 394px;
              height: 204px;
              top: 680px;
            }
            @include media-breakpoint-down(xxl) {
              width: 350px;
              height: 156px;
              top: 600px;
            }
            @include media-breakpoint-down(xl) {
              width: 100px;
              height: 118px;
              top: 984px;
            }
          }
          &.bg-2 {
            width: 497px;
            height: 301px;
            top: 525px;
            right: 0;
            @include media-breakpoint-down(5xl) {
              width: 230px;
              height: 228px;
              top: 450px;
            }
            @include media-breakpoint-down(4xl) {
              width: 220px;
              height: 198px;
              top: 410px;
            }
            @include media-breakpoint-down(xxxl) {
              width: 200px;
              height: 188px;
              top: 360px;
            }
            @include media-breakpoint-down(xxl) {
              width: 180px;
              height: 155px;
              top: 330px;
            }
            @include media-breakpoint-down(xl) {
              width: 80px;
              height: 110px;
              top: 444px;
            }
          }
        }
        .col-img-mobile {
          .img-box {
            margin-top: -20px;
            margin-bottom: 25px;
          }
        }
        .title-box {
          h4 {
            font-size: 35px;
            line-height: 42px;
            letter-spacing: 1.74px;
            @include font-light-1;
            text-transform: uppercase;
            @include media-breakpoint-down(5xl) {
              font-size: 30px;
              line-height: 37px;
              letter-spacing: 1.6px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 25px;
              line-height: 32px;
              letter-spacing: 1.5px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 22px;
              line-height: 29px;
              letter-spacing: 1.4px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 20px;
              line-height: 27px;
              letter-spacing: 1.3px;
            }
            @include media-breakpoint-down(lg) {
              font-size: 13px;
              line-height: 16px;
            }
            strong {
              display: block;
              letter-spacing: 0;
            }
            &.part-2 {
              text-transform: lowercase;
              @include font-title-med;
              font-style: italic;
              font-size: 104px;
              line-height: 74px;
              text-align: center;
              padding-top: 40px;
              padding-bottom: 100px;
              @include media-breakpoint-down(5xl) {
                font-size: 98px;
                line-height: 68px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 92px;
                line-height: 62px;
                padding-bottom: 40px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 82px;
                line-height: 52px;
                padding-bottom: 30px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 72px;
                line-height: 42px;
                padding-top: 30px;
                padding-bottom: 20px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 65px;
                line-height: 50px;
                padding-top: 20px;
              }
              span {
                @include media-breakpoint-down(lg) {
                  display: block;
                }
              }
            }
          }
        }
        .row-content {
          padding-top: 50px;
          padding-bottom: 50px;
          @include media-breakpoint-down(5xl) {
            padding-top: 45px;
            padding-bottom: 45px;
          }
          @include media-breakpoint-down(4xl) {
            padding-top: 35px;
            padding-bottom: 35px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-top: 30px;
            padding-bottom: 30px;
          }
          @include media-breakpoint-down(xxl) {
            padding-top: 25px;
            padding-bottom: 25px;
          }
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(lg) {
            padding-top: 0;
            padding-bottom: 25px;
          }
          @include media-breakpoint-down(md) {
          }
          @include media-breakpoint-down(sm) {
          }
        }
        .content-txt-box {
          padding-top: 35px;
          @include media-breakpoint-down(lg) {
            padding-top: 27px;
          }
          p {
            font-size: 19px;
            line-height: 26px;
            @include media-breakpoint-down(5xl) {
              font-size: 18px;
              line-height: 25px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 16px;
              line-height: 24px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 15px;
              line-height: 23px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 13px;
              line-height: 17px;
            }
            @include media-breakpoint-down(xl) {
            }
            @include media-breakpoint-down(lg) {
            }
            @include media-breakpoint-down(md) {
            }
            @include media-breakpoint-down(sm) {
            }
          }
        }
      }
    }
    .section-bhag {
      margin-top: 100px;
      margin-bottom: -150px;
      @include media-breakpoint-down(xxl) {
        margin-top: 0px;
      }
      .bg-gris{
        position: relative;
        border-top-right-radius: 650px;
        border-bottom-right-radius: 650px;
        background-color: #F5F5F5;
        width: 80%;
        padding-top: 90px;
        padding-bottom: 50px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: flex-end;
        @include media-breakpoint-down(5xl) {
        }
        @include media-breakpoint-down(4xl) {
        }
        @include media-breakpoint-down(xxxl) {
        }
        @include media-breakpoint-down(xxl) {
        }
        @include media-breakpoint-down(xl) {
        }
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
        @include media-breakpoint-down(md) {
          width: 110%;
        }
        @include media-breakpoint-down(sm) {
          width: 150%;
        }
        &:before{
          content: "";
          //background-color: green;
          background-color: #F5F5F5;
          height: 100%;
          width: 20%;
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          z-index: 0;
          @include media-breakpoint-down(xl) {
            width: 2%;
          }
        }
      }

      .bagh-intro-box {
       // background-color: #F5F5F5;
        //margin-top: -400px;
        padding-right: 370px;
        @include media-breakpoint-down(5xl) {
          padding-right: 350px;
        }
        @include media-breakpoint-down(4xl) {
          padding-right: 210px;
        }
        @include media-breakpoint-down(xxxl) {
          padding-right: 200px;
        }
        @include media-breakpoint-down(xxl) {
        }
        @include media-breakpoint-down(xl) {
          margin-left: 0;
        }
        @include media-breakpoint-down(lg) {
        }
        @include media-breakpoint-down(md) {
        }

        .title-img-box{
          display: flex;
          align-items: flex-end;
          @include media-breakpoint-down(xl) {
            flex-direction: column-reverse;
            align-items: flex-start;
          }
          @include media-breakpoint-down(lg) {
          }
          @include media-breakpoint-down(md) {
          }
          .adn-content-bg-box {
            padding-bottom: 45px;
            padding-left: 60px;
            @include media-breakpoint-down(5xl) {
            }
            @include media-breakpoint-down(4xl) {
            }
            @include media-breakpoint-down(xxxl) {
              padding-left: 20px;
            }
            @include media-breakpoint-down(xxl) {
              padding-left: 45px;
            }
            @include media-breakpoint-down(xl) {
              padding-left: 20px;
              padding-bottom: 5px;
            }
            .title-normal{
              h3{
                span{
                  margin-left: 30px;
                  margin-top: 20px;
                  @include media-breakpoint-down(xl) {
                    margin-left: 20px;
                  }
                }
              }
            }
            .intro-content-box {
              padding-top: 63px;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
                padding-top: 43px;
              }
              @include media-breakpoint-down(xl) {
                max-width: 379px;
                margin: 0 auto;
              }
            }
          }
          .img-box {
            margin-left: 140px;
            position: relative;
            z-index: 2;
            @include media-breakpoint-down(5xl) {
              max-width: 320px;
            }
            @include media-breakpoint-down(4xl) {
              max-width: 290px;
            }
            @include media-breakpoint-down(xxxl) {
              max-width: 270px;
            }
            @include media-breakpoint-down(xxl) {
              max-width: 250px;
            }
            @include media-breakpoint-down(xl) {
              max-width: 195px;
              margin-left: 70px;
            }
          }
        }
        .content-box{
          position: relative;
          margin-top: 30px;
          margin-left:300px;
          max-width: 450px;
          //margin-bottom:80px;
          @include media-breakpoint-down(4xl) {
          }
          @include media-breakpoint-down(xxxl) {
            margin-left:204px;
          }
          @include media-breakpoint-down(xxl) {
            //margin-top: 80px;
          }
          @include media-breakpoint-down(xl) {
            //margin-top: 50px;
            margin-top: 20px;
            margin-left:40px;
          }
          @include media-breakpoint-down(md) {
            //margin-bottom:50px;
          }
          &:before{
            background-image: url("/assets/img/slash.svg");
            top: -114px;
            left:-15px;
            @include media-breakpoint-down(5xl) {
              //left:-70px;
            }
            @include media-breakpoint-down(4xl) {
              //left:-80px;
            }
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xxl) {
            }
            @include media-breakpoint-down(xl) {
              left:115px;
              top: -55px;
            }
            @include media-breakpoint-down(lg) {
            }
            @include media-breakpoint-down(md) {
              //left:80px;
              //top: -55px;
            }
          }
          .p-title{
            max-width: 500px;
            @include media-breakpoint-down(xxxl) {
              max-width: 400px;
            }
          }
        }
        .intro-content-box{
          padding-left: 280px;
          padding-top: 30px;
          padding-bottom: 80px;
          @include media-breakpoint-down(4xl) {
          }
          @include media-breakpoint-down(xxxl) {
            padding-left: 180px;
          }
          @include media-breakpoint-down(xl) {
            padding-bottom: 30px;
            padding-left: 20px;
          }
        }

      }
      .bagh-open-box {
        //padding-top: 100px;
        padding-right: 280px;
        padding-bottom: 100px;
        z-index: 2;
        position: relative;
        @include media-breakpoint-down(5xl) {
          //padding-top: 60px;
        }
        @include media-breakpoint-down(4xl) {
          padding-right: 200px;
        }
        @include media-breakpoint-down(xxxl) {
          //padding-top: 20px;
        }
        @include media-breakpoint-down(xxl) {
          padding-top: 0px;
        }
        .open-content-box{
          display: flex;
          .img-box{
            width: 274px;
            @include media-breakpoint-down(md) {
              width: 150px;
            }
            @include media-breakpoint-down(sm) {
              width: 100px;
            }
          }
          .text-box{
            max-width: 464px;
            margin-left: 30px;
            @include media-breakpoint-down(xxl) {
              max-width: 300px;
            }
            @include media-breakpoint-down(sm) {
              max-width: 190px;
            }
            h4{
              @include font-black-1;
              font-size: 20px;
              line-height: 29px;
              margin-bottom: 10px;
              @include media-breakpoint-down(md) {
                font-size: 15px;
                line-height: 23px;
              }
              @include media-breakpoint-down(sm) {
                font-size: 14px;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
    .section-mode-de-vie-2{
      .bg-img-color-box{
        width: 100%;
        height:100vh;
        min-height: 600px;
        background-size: cover;
        background-position: center;
        @include media-breakpoint-down(xl) {
          min-height: 400px;
        }
        @include media-breakpoint-down(md) {
        }
        .container-xxl{
          height: 100vh;
          .row{
            height: 100vh;
            .col-content{
              height: 100vh;
              display: flex;
              justify-content: center;
              flex-direction: column;
            }
          }
        }
      }
      .title-normal{
        //margin-top: 300px;
        @include media-breakpoint-down(xl) {
          //margin-top:200px;
        }
        @include media-breakpoint-down(md) {
          //margin-top: 70px;
        }
        h2,h3,h4{
          color: $color-inverse;
          line-height: 74px;
          span{
            margin-left: 0px;
            @include media-breakpoint-down(md) {
              margin-left: 0px;
              font-size: 63px;
            }
          }
        }
      }
      .content-box{
        position: relative;
        margin-top: 20px;
        margin-left: 0;
        margin-bottom: 0;
        @include media-breakpoint-down(4xl) {
        }
        @include media-breakpoint-down(xxl) {
        }
        @include media-breakpoint-down(xl) {
        }
        @include media-breakpoint-down(md) {
        }
        &:before{
          background-image: url("/assets/img/slash-white.svg");
          top:-23px;
          left:-70px;
          @include media-breakpoint-down(5xl) {
            left:-70px;
          }
          @include media-breakpoint-down(4xl) {
            left:-80px;
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            left:-60px;
          }
          @include media-breakpoint-down(lg) {
          }
          @include media-breakpoint-down(md) {
            left:-25px;
            top: -25px;
          }
        }
        .p-title{
          color: $color-inverse;
        }
      }
    }
    .section-fun{
      .boite-d{
        max-width: 830px;
        margin:-200px 0 0px 0px;
        position: relative;
        z-index: 2;
        padding: 90px 180px 70px 100px;
        border-bottom-right-radius: 380px;
        border-top-right-radius: 380px;
        background-color: #F5F5F5;
        @include media-breakpoint-down(5xl) {
          max-width: 800px;
        }
        @include media-breakpoint-down(4xl) {
          max-width: 700px;
        }
        @include media-breakpoint-down(xxxl) {
          padding: 90px 180px 70px 80px;
          max-width: 650px;
        }
        @include media-breakpoint-down(xxl) {
          max-width: 600px;
          padding: 80px 100px 60px 80px;
        }
        @include media-breakpoint-down(xl) {
          padding: 50px 60px 25px 90px;
          max-width: 550px;
        }
        @include media-breakpoint-down(lg) {
        }
        @include media-breakpoint-down(md) {
        }
        @include media-breakpoint-down(sm) {
          max-width: 550px;
          width: 430px;
          padding: 50px 100px 25px 40px;
        }
        .content-box{
          position: relative;
          z-index: 2;
          margin-left: 65px;
          @include media-breakpoint-down(xxl) {
            margin-left: 0;
          }
          p.p-title{
            max-width: 401px;
            margin-left: 125px;
            @include media-breakpoint-down(5xl) {
              margin-left: 140px;
            }
            @include media-breakpoint-down(4xl) {
              margin-left: 120px;
            }
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xl) {
              margin-left: 110px;
            }
            @include media-breakpoint-down(lg) {
              margin-left: 90px;
              padding-right: 50px;
            }
            @include media-breakpoint-down(md) {
            }
            @include media-breakpoint-down(sm) {
            }
          }
          &:before{
            display: none;
          }
        }
        .title-principal-2-parts{
          display: flex;
          align-items: center;
          @include media-breakpoint-down(xxl) {
            margin-left: 20px;
          }
          .part-1{
            margin-right: 30px;
            h2,h3,h4{
              display: block;
              //font-size: 32px;
              //line-height: 35px;
              text-transform: uppercase;
              margin-bottom: 0;
              margin-left: -30px;
              text-align: right;
              @include font-light-1;
              font-size: 21px;
              line-height: 32px;
              letter-spacing: 1.2px;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
                font-size: 20px;
                line-height: 29px;
                letter-spacing: 0.99px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 18px;
                line-height: 27px;
                letter-spacing: 0.89px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 15px;
                line-height: 22px;
                letter-spacing: 0.75px;
              }
              @include media-breakpoint-down(lg) {
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0;
              }
              @include media-breakpoint-down(md) {
              }
              @include media-breakpoint-down(sm) {
              }
              strong{
                @include font-bold-1;
                display: block;
              }
            }
            &:after{
              background-image: url("/assets/img/slash.svg");
              background-repeat: no-repeat;
              background-size: 60px;
              width: 60px;
              height: 97px;
              content: "";
              display: block;
              position: absolute;
              bottom:-55px;
              left:45px;
              @include media-breakpoint-down(5xl) {
                left:50px;
              }
              @include media-breakpoint-down(4xl) {
                background-size: 60px;
                width: 60px;
                height: 97px;
                left:50px;
              }
              @include media-breakpoint-down(xxxl) {
                left:30px;
              }
              @include media-breakpoint-down(xxl) {
              }
              @include media-breakpoint-down(xl) {
                background-size: 44px;
                width: 44px;
                height: 54px;
                left:30px;
                bottom:-25px;
              }
              @include media-breakpoint-down(lg) {
                background-size: 31px;
                width: 31px;
                height: 38px;
                //bottom: 35px;
                bottom:-5px;
              }
              @include media-breakpoint-down(sm) {
                bottom:5px;
                left:40px;
              }
            }
          }
          .part-2{
            h2,h3,h4{
              //font-size: 168px;
              //line-height: 168px;
              text-transform: lowercase;
              @include font-title-med;
              font-style: italic;
              margin-bottom: 30px;
              font-size: 104px;
              line-height: 74px;
              @include media-breakpoint-down(5xl) {
                font-size: 98px;
                line-height: 68px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 92px;
                line-height: 62px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 82px;
                line-height: 52px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 72px;
                line-height: 42px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 65px;
                line-height: 36px;
                margin-bottom: 15px;
              }
            }
          }
        }
        p.p-title{
          margin-top: 20px;
          @include media-breakpoint-down(sm) {
            strong{
              display: block;
            }
          }
        }
        p.p-txt{
          max-width: 430px;
          margin-top: 75px;
          @include media-breakpoint-down(5xl) {
            max-width: 400px;
          }
          @include media-breakpoint-down(4xl) {
            max-width: 370px;
          }
          @include media-breakpoint-down(xxxl) {
            max-width: 340px;
          }
          @include media-breakpoint-down(xxl) {
            max-width: 320px;
          }
          @include media-breakpoint-down(xl) {
            margin-top: 55px;
          }
          @include media-breakpoint-down(lg) {
            margin-top: 45px;
          }
        }
      }
    }
    .section-fiable-2{
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      margin-top: 50px;
      @include media-breakpoint-down(sm) {
       flex-direction: column-reverse;
      }
      .boite-d{
        max-width: 830px;
        position: relative;
        z-index: 2;
        margin: 75px 40px 0 25%;
        padding-bottom: 200px;
        @include media-breakpoint-down(5xl) {
          max-width: 800px;
        }
        @include media-breakpoint-down(4xl) {
          max-width: 700px;
        }
        @include media-breakpoint-down(xxxl) {
          max-width: 650px;
        }
        @include media-breakpoint-down(xxl) {
          max-width: 600px;
          padding-bottom: 140px;
        }
        @include media-breakpoint-down(xl) {
          max-width: 550px;
          padding-bottom: 50px;
        }
        @include media-breakpoint-down(lg) {
          margin: 15px 10px 0 5%;
        }
        @include media-breakpoint-down(md) {
        }
        @include media-breakpoint-down(sm) {
          max-width: 550px;
          //width: 430px;
          padding: 50px 20px 25px 20px;
        }
        .content-box{
          position: relative;
          z-index: 2;
          margin-left: 85px;
          @include media-breakpoint-down(xxl) {
            margin-left: 0;
          }
          p.p-title{
            max-width: 401px;
            margin-left: 125px;
            @include media-breakpoint-down(5xl) {
              margin-left: 140px;
            }
            @include media-breakpoint-down(4xl) {
              margin-left: 120px;
            }
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xl) {
              margin-left: 110px;
            }
            @include media-breakpoint-down(lg) {
              margin-left: 90px;
              padding-right: 0px;
            }
            @include media-breakpoint-down(md) {
            }
            @include media-breakpoint-down(sm) {
            }
          }
          &:before{
            display: none;
          }
        }
        .title-principal-2-parts{
          display: flex;
          align-items: center;
          @include media-breakpoint-down(xxl) {
            margin-left: 20px;
          }
          .part-1{
            margin-right: 30px;
            h2,h3,h4{
              display: block;
              //font-size: 32px;
              //line-height: 35px;
              text-transform: uppercase;
              margin-bottom: 0;
              margin-left: -30px;
              text-align: right;
              @include font-light-1;
              font-size: 21px;
              line-height: 32px;
              letter-spacing: 1.2px;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
                font-size: 20px;
                line-height: 29px;
                letter-spacing: 0.99px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 18px;
                line-height: 27px;
                letter-spacing: 0.89px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 15px;
                line-height: 22px;
                letter-spacing: 0.75px;
              }
              @include media-breakpoint-down(lg) {
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0;
              }
              @include media-breakpoint-down(md) {
              }
              @include media-breakpoint-down(sm) {
              }
              strong{
                @include font-bold-1;
                display: block;
              }
            }
            &:after{
              background-image: url("/assets/img/slash.svg");
              background-repeat: no-repeat;
              background-size: 60px;
              width: 60px;
              height: 97px;
              content: "";
              display: block;
              position: absolute;
              bottom:-15px;
              left:45px;
              @include media-breakpoint-down(5xl) {
                left:50px;
              }
              @include media-breakpoint-down(4xl) {
                background-size: 60px;
                width: 60px;
                height: 97px;
                left:50px;
              }
              @include media-breakpoint-down(xxxl) {
                left:30px;
              }
              @include media-breakpoint-down(xxl) {
              }
              @include media-breakpoint-down(xl) {
                background-size: 44px;
                width: 44px;
                height: 54px;
                left:30px;
                bottom:-0;
              }
              @include media-breakpoint-down(lg) {
                background-size: 31px;
                width: 31px;
                height: 38px;
                //bottom: 35px;
                bottom:-5px;
              }
              @include media-breakpoint-down(sm) {
                bottom:5px;
                left:40px;
              }
            }
          }
          .part-2{
            h2,h3,h4{
              //font-size: 168px;
              //line-height: 168px;
              text-transform: lowercase;
              @include font-title-med;
              font-style: italic;
              margin-bottom: 30px;
              font-size: 104px;
              line-height: 74px;
              @include media-breakpoint-down(5xl) {
                font-size: 98px;
                line-height: 68px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 92px;
                line-height: 62px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 82px;
                line-height: 52px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 72px;
                line-height: 42px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 65px;
                line-height: 36px;
                margin-bottom: 15px;
              }
            }
          }
        }
        p.p-title{
          margin-top: 70px;
          @include media-breakpoint-down(xl) {
            margin-top: 30px;
          }
          @include media-breakpoint-down(md) {
            strong{
              display: block;
            }
          }
        }
        p.p-txt{
          max-width: 430px;
          margin-top: 60px;
          @include media-breakpoint-down(5xl) {
            max-width: 400px;
          }
          @include media-breakpoint-down(4xl) {
            max-width: 370px;
          }
          @include media-breakpoint-down(xxxl) {
            max-width: 340px;
          }
          @include media-breakpoint-down(xxl) {
            max-width: 320px;
          }
          @include media-breakpoint-down(xl) {
            margin-top: 55px;
          }
          @include media-breakpoint-down(lg) {
            margin-top: 45px;
          }
        }
      }
      .img-box{
        width: 604px;
        position: relative;
        @include media-breakpoint-down(5xl) {
          width: 554px;
        }
        @include media-breakpoint-down(4xl) {
          width: 504px;
        }
        @include media-breakpoint-down(xxxl) {
          width: 485px;
        }
        @include media-breakpoint-down(xxl) {
          width: 435px;
        }
        @include media-breakpoint-down(xl) {
          width: 400px;
        }
        @include media-breakpoint-down(lg) {
          width: 250px;
        }
        @include media-breakpoint-down(sm) {
          width:110%;
        }
        .img-in{
          position: absolute;
          top: 0;
          right: -30px;
          width: 604px;
          @include media-breakpoint-down(5xl) {
            width: 554px;
          }
          @include media-breakpoint-down(4xl) {
            width: 504px;
          }
          @include media-breakpoint-down(xxxl) {
            width: 485px;
          }
          @include media-breakpoint-down(xxl) {
            width: 435px;
          }
          @include media-breakpoint-down(xl) {
            width: 400px;
          }
          @include media-breakpoint-down(lg) {
            width:250px;
          }
          @include media-breakpoint-down(sm) {
            width:100%;
            right: auto;
            position: relative;
          }
          img{
            border-bottom-right-radius: 400px;
            border-top-right-radius: 400px;
          }
        }
      }
    }
    .section-facile{
      background-color: #F5F5F5;
      margin-top: 100px;
      padding-top: 250px;
      margin-bottom: -460px;
      @include media-breakpoint-down(5xl) {
        margin-top: 50px;
        padding-top: 200px;
        margin-bottom: -420px;
      }
      @include media-breakpoint-down(4xl) {
        margin-top: 10px;
        padding-top: 150px;
        margin-bottom: -400px;
      }
      @include media-breakpoint-down(xxxl) {
        margin-top: 0;
        padding-top: 100px;
        margin-bottom: -380px;
      }
      @include media-breakpoint-down(xxl) {
        margin-top: 0;
        padding-top: 80px;
        margin-bottom: -360px;
      }
      @include media-breakpoint-down(xl) {
        margin-top: 30px;
        padding-top: 60px;
        margin-bottom: -290px;
      }
      @include media-breakpoint-down(md) {
        padding-top: 50px;
        margin-top: 30px;
        margin-bottom: -230px;
      }
      @include media-breakpoint-down(sm) {
        margin-top: 0px;
        padding-top: 50px;
        margin-bottom: -260px;
      }
      .boite-d{
        max-width: 830px;
        margin:0 0 0 auto;
        position: relative;
        z-index: 2;
        padding:0 180px 70px 100px;
        border-bottom-right-radius: 380px;
        border-top-right-radius: 380px;
        right: -100px;
        background-color: #F5F5F5;
        @include media-breakpoint-down(5xl) {
          max-width: 800px;
        }
        @include media-breakpoint-down(4xl) {
          max-width: 700px;
        }
        @include media-breakpoint-down(xxxl) {
          padding: 90px 180px 70px 80px;
          max-width: 650px;
        }
        @include media-breakpoint-down(xxl) {
          max-width: 600px;
          padding: 80px 100px 60px 80px;
        }
        @include media-breakpoint-down(xl) {
          padding: 50px 60px 25px 90px;
          max-width: 550px;
        }
        @include media-breakpoint-down(lg) {
          right: -20px;
        }
        @include media-breakpoint-down(md) {
        }
        @include media-breakpoint-down(sm) {
          max-width: 550px;
          width: 430px;
          padding: 0px 100px 25px 40px;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
        .content-box{
          position: relative;
          z-index: 2;
          margin-left: 65px;
          @include media-breakpoint-down(xxl) {
            margin-left: 0;
          }
          p.p-title{
            max-width: 401px;
            margin-left: 125px;
            @include media-breakpoint-down(5xl) {
              margin-left: 140px;
            }
            @include media-breakpoint-down(4xl) {
              margin-left: 120px;
            }
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xl) {
              margin-left: 110px;
            }
            @include media-breakpoint-down(lg) {
              margin-left: 90px;
              padding-right: 50px;
            }
            @include media-breakpoint-down(md) {
            }
            @include media-breakpoint-down(sm) {
            }
          }
          &:before{
            display: none;
          }
        }
        .title-principal-2-parts{
          display: flex;
          align-items: center;
          @include media-breakpoint-down(xxl) {
            margin-left: 20px;
          }
          .part-1{
            margin-right: 30px;
            h2,h3,h4{
              display: block;
              //font-size: 32px;
              //line-height: 35px;
              text-transform: uppercase;
              margin-bottom: 0;
              margin-left: -30px;
              text-align: right;
              @include font-light-1;
              font-size: 21px;
              line-height: 32px;
              letter-spacing: 1.2px;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
                font-size: 20px;
                line-height: 29px;
                letter-spacing: 0.99px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 18px;
                line-height: 27px;
                letter-spacing: 0.89px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 15px;
                line-height: 22px;
                letter-spacing: 0.75px;
              }
              @include media-breakpoint-down(lg) {
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0;
              }
              @include media-breakpoint-down(md) {
              }
              @include media-breakpoint-down(sm) {
              }
              strong{
                @include font-bold-1;
                display: block;
              }
            }
            &:after{
              background-image: url("/assets/img/slash.svg");
              background-repeat: no-repeat;
              background-size: 60px;
              width: 60px;
              height: 97px;
              content: "";
              display: block;
              position: absolute;
              bottom:-55px;
              left:45px;
              @include media-breakpoint-down(5xl) {
                left:50px;
              }
              @include media-breakpoint-down(4xl) {
                background-size: 60px;
                width: 60px;
                height: 97px;
                left:50px;
              }
              @include media-breakpoint-down(xxxl) {
                left:30px;
              }
              @include media-breakpoint-down(xxl) {
              }
              @include media-breakpoint-down(xl) {
                background-size: 44px;
                width: 44px;
                height: 54px;
                left:30px;
                bottom:-25px;
              }
              @include media-breakpoint-down(lg) {
                background-size: 31px;
                width: 31px;
                height: 38px;
                //bottom: 35px;
                bottom:-5px;
              }
              @include media-breakpoint-down(sm) {
                bottom:5px;
                left:40px;
              }
            }
          }
          .part-2{
            h2,h3,h4{
              //font-size: 168px;
              //line-height: 168px;
              text-transform: lowercase;
              @include font-title-med;
              font-style: italic;
              margin-bottom: 30px;
              font-size: 104px;
              line-height: 74px;
              @include media-breakpoint-down(5xl) {
                font-size: 98px;
                line-height: 68px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 92px;
                line-height: 62px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 82px;
                line-height: 52px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 72px;
                line-height: 42px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 65px;
                line-height: 36px;
                margin-bottom: 15px;
              }
            }
          }
        }
        p.p-title{
          margin-top: 20px;
          @include media-breakpoint-down(sm) {
            strong{
              display: block;
            }
          }
        }
        p.p-txt{
          max-width: 430px;
          margin-top: 75px;
          @include media-breakpoint-down(5xl) {
            max-width: 400px;
          }
          @include media-breakpoint-down(4xl) {
            max-width: 370px;
          }
          @include media-breakpoint-down(xxxl) {
            max-width: 340px;
          }
          @include media-breakpoint-down(xxl) {
            max-width: 320px;
          }
          @include media-breakpoint-down(xl) {
            margin-top: 55px;
          }
          @include media-breakpoint-down(lg) {
            margin-top: 45px;
          }
        }
      }
    }
    .section-hesitation{
      .bg-img{
        background-image: url("/assets/img/recrutement/fff-bg-2.jpg");
        width: 100%;
        height: 1200px;
        background-size: cover;
        @include media-breakpoint-down(5xl) {
          height: 960px;
        }
        @include media-breakpoint-down(4xl) {
          height: 840px;
        }
        @include media-breakpoint-down(xxxl) {
          height: 640px;
        }
        @include media-breakpoint-down(xxl) {
          height: 580px;
        }
        @include media-breakpoint-down(xl) {
          height: 480px;
        }
        @include media-breakpoint-down(md) {
          height: 300px;
        }
        @include media-breakpoint-down(sm) {
          height: 380px;
          background-size: 200%;
          background-position: bottom center;
        }
      }
      .bg-gris{
        background-color: $color-bg-alt-2;
        padding: 80px 0;
        @include media-breakpoint-down(xl) {
          padding: 40px 0;
        }
      }
      .title-box{
        text-align: center;
        h2{
          font-size: 200px;
          line-height: 200px;
          @include font-title-med;
          color: $color-inverse;
          font-style: italic;
          @include media-breakpoint-down(5xl) {
            font-size: 180px;
            line-height: 180px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 160px;
            line-height: 160px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 150px;
            line-height: 150px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 130px;
            line-height: 130px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 110px;
            line-height: 110px;
          }
          @include media-breakpoint-down(lg) {
            font-size: 81px;
            line-height: 81px;
          }
          @include media-breakpoint-down(md) {
            font-size: 71px;
            line-height: 71px;
          }
          @include media-breakpoint-down(sm) {
            font-size: 61px;
            line-height: 61px;
          }
        }
      }
      .content-container{
        display: flex;
        justify-content: center;
        padding-top: 40px;
        margin-left: 140px;
        @include media-breakpoint-down(xxl) {
          margin-left: 0;
        }
        @include media-breakpoint-down(lg) {
          flex-direction: column;
          padding-top: 30px;
        }
        @include media-breakpoint-down(md) {
          padding-top: 20px;
        }
      }
      .title-normal{
        margin-right: 90px;
        position: relative;
        @include media-breakpoint-down(xl) {
          margin-left: 40px;
        }
        @include media-breakpoint-down(md) {
          margin-left: 0;
          margin-right: 0px;
        }
        &:after{
          @include media-breakpoint-down(lg) {
            background-size: 31px;
            width: 31px;
            height: 38px;
            //bottom: 35px;
            background-image: url("/assets/img/slash-white.svg");
            bottom: 0;
            left:250px;
            content: "";
            display: block;
            position: absolute;
          }
          @include media-breakpoint-down(md) {
            left:200px;
          }
          @include media-breakpoint-down(sm) {
            left:140px;
          }
        }
        h2,h3,h4{
          margin-left: 108px;
          color: $color-inverse;
          @include font-reg-1;
          text-transform: uppercase;
          font-size: 40px;
          line-height: 40px;
          @include media-breakpoint-down(5xl) {
            font-size: 38px;
            line-height: 38px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 36px;
            line-height: 36px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 35px;
            line-height: 35px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 33px;
            line-height: 33px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 28px;
            line-height: 28px;
            margin-left: 80px;
          }
          @include media-breakpoint-down(md) {
            font-size: 20px;
            line-height: 20px;
            margin-left: 50px;
          }
          @include media-breakpoint-down(sm) {
            font-size: 15px;
            line-height: 15px;
          }
          span{
          text-transform: uppercase;
            margin-left:-108px;
            margin-top: 10px;
            font-size: 40px;
            line-height: 40px;
            @include font-bold-1;
            @include media-breakpoint-down(5xl) {
              font-size: 38px;
              line-height: 38px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 36px;
              line-height: 36px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 35px;
              line-height: 35px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 33px;
              line-height: 33px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 28px;
              line-height: 28px;
              margin-left:-80px;
            }
            @include media-breakpoint-down(md) {
              font-size: 20px;
              line-height: 20px;
              margin-left:-50px;
            }
            @include media-breakpoint-down(sm) {
              font-size: 15px;
              line-height: 15px;
            }
          }
          .span-2{
            margin-left: 165px;
            //margin-top: 10px;
            margin-top: 0;
            @include media-breakpoint-down(xxxl) {
              margin-left: 135px;
            }
            @include media-breakpoint-down(xxl) {
              margin-left: 110px;
            }
          }
        }
      }
      .content-box{
        position: relative;
        margin-top: 160px;
        margin-left:0;
        margin-bottom:80px;
        display: flex;
        align-self: flex-end;
        @include media-breakpoint-down(4xl) {
        }
        @include media-breakpoint-down(xxl) {
          margin-top: 110px;
        }
        @include media-breakpoint-down(xl) {
          margin-left: 40px;
        }
        @include media-breakpoint-down(lg) {
          margin-top:40px;
        }
        @include media-breakpoint-down(md) {
          margin-bottom:10px;
        }
        &:before{
          background-image: url("/assets/img/slash-white.svg");
          top: -84px;
          left:-80px;
          @include media-breakpoint-down(5xl) {
            //left:-70px;
          }
          @include media-breakpoint-down(4xl) {
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xxl) {
            top: -64px;
          }
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(lg) {
            display: none;
          }
          @include media-breakpoint-down(md) {
          }
        }
        .p-title, p{
          max-width: 450px;
        }
        .play-bt-box{
          display: flex;
          //align-items: center;
          .ico-play{
            width: 106px;
            @include media-breakpoint-down(5xl) {
              width: 120px;
            }
            @include media-breakpoint-down(4xl) {
              width:100px;
            }
            @include media-breakpoint-down(xxxl) {
              width: 90px;
            }
            @include media-breakpoint-down(xxl) {
              width:80px;
            }
            @include media-breakpoint-down(xl) {
              width: 60px;
            }
            a{
              display: block;
              &:hover{
                img{
                  opacity: 0.6;
                }
              }
              img{
                transition: opacity 0.2s linear;
              }
            }
          }
          .txt-box{
            margin-left: 30px;
            font-size: 21px;
            line-height: 34px;
            @include font-reg-1;
            color: $color-inverse;
            max-width: 350px;
            @include media-breakpoint-down(5xl) {
              font-size: 20px;
              line-height: 33px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 19px;
              line-height: 32px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 18px;
              line-height: 31px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 17px;
              line-height: 30px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 16px;
              line-height: 29px;
            }
            @include media-breakpoint-down(sm) {
              font-size: 14px;
              line-height: 22px;
              max-width: 200px;
            }
            a{
              text-decoration: underline;
            }
          }
        }
      }
    }






    &.nav-scrolled {
      .section-menu-fun{
        top:$header-nav-height-sticky;
      }
    }
    &.nav-scrolled-hide {
      .section-menu-fun{
        top:-1px;
      }
    }
    &.nav-scrolled-second-sub-show {
      .section-menu-fun{
        opacity:1;
      }
    }
  }
}//#dx-page-body
