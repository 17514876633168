#dx-page-body {
  &.page-recrutement-poste-details{
    background-color: white;
    //overflow: hidden;
    header{
      .bg-secondary{
        //background-color: transparent!important;
      }
    }
    .site-main { //main
      .main-content-wrapper {
        .content-area {
          //padding-top: 0; // hauteur de la nav - nav transparente pour carousel
          .section-main{
            > h1{
              display: none;
            }
          }
        }
      }
    }
    //Pour tous GÉNÉRAL
    .title-normal{
      h2,h3,h4{
        display: block;
        text-transform: none;
        @include font-title-med;
        font-style: italic;
        margin-left: 150px;
        font-size: 80px;
        line-height: 80px;
        @include media-breakpoint-down(5xl) {
          font-size: 76px;
          line-height: 76px;
        }
        @include media-breakpoint-down(4xl) {
          font-size: 74px;
          line-height: 74px;
        }
        @include media-breakpoint-down(xxxl) {
          //font-size: 82px;
          //line-height: 52px;
        }
        @include media-breakpoint-down(xxl) {
          font-size: 72px;
          line-height: 72px;
        }
        @include media-breakpoint-down(xl) {
          font-size: 65px;
          line-height: 65px;
          margin-left: 55px;
        }
        span{
          margin-left: 0px;
          @include media-breakpoint-down(md) {
            margin-left: 20px;
          }
        }
      }
    }
    .content-box{
      position: relative;
      margin-top: 20px;
      margin-left: 100px;
      margin-bottom: 80px;
      @include media-breakpoint-down(4xl) {
      }
      @include media-breakpoint-down(xxl) {
      }
      @include media-breakpoint-down(xl) {
        margin-left: 35px;
        margin-bottom: 25px;
      }
      @include media-breakpoint-down(md) {
      }
      &:before{
        background-image: url("/assets/img/slash.svg");
        background-repeat: no-repeat;
        background-size: 60px;
        width: 60px;
        height: 97px;
        content: "";
        display: block;
        position: absolute;
        top: -40px;
        left:-60px;
        @include media-breakpoint-down(5xl) {
          //left:-70px;
          //top: -50px;
        }
        @include media-breakpoint-down(4xl) {
          background-size: 60px;
          width: 60px;
          height: 97px;
          //left:-80px;
        }
        @include media-breakpoint-down(xxxl) {
          background-size: 71px;
          width: 71px;
          height: 87px;
        }
        @include media-breakpoint-down(xxl) {
          background-size: 63px;
          width: 63px;
          height: 77px;
        }
        @include media-breakpoint-down(xl) {
          background-size: 44px;
          width: 44px;
          height: 54px;
          top: -40px;
          left:-38px;
        }
        @include media-breakpoint-down(lg) {
          background-size: 31px;
          width: 31px;
          height: 38px;
          //bottom: 35px;
        }
        @include media-breakpoint-down(md) {
          //top: -20px;
          //left:-50px;
        }
      }
      p.p-title{
        text-transform: uppercase;
        @include font-light-1;
        font-size: 21px;
        line-height: 32px;
        letter-spacing: 1.2px;
        @include media-breakpoint-down(5xl) {
        }
        @include media-breakpoint-down(4xl) {
          font-size: 20px;
          line-height: 29px;
          letter-spacing: 0.99px;
        }
        @include media-breakpoint-down(xxxl) {
          font-size: 18px;
          line-height: 27px;
          letter-spacing: 0.89px;
        }
        @include media-breakpoint-down(xl) {
          font-size: 15px;
          line-height: 22px;
          letter-spacing: 0.75px;
        }
        @include media-breakpoint-down(lg) {
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0;
        }
        @include media-breakpoint-down(md) {
        }
        @include media-breakpoint-down(sm) {
        }
        strong{
          @include font-bold-1;
        }
      }
      &.title-mobile-only{
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }
    .avantage-box{
      text-align: center;
      //background-color: lightcyan;
      //min-width: 150px;
      //max-width: 200px;
      padding: 15px;
      margin-bottom: 20px;
      @include media-breakpoint-down(xxxl) {
      }
      @include media-breakpoint-down(sm) {
      }
      .ico-box{
        min-height: 74px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
        max-width: 73px;
        @include media-breakpoint-down(4xl) {
          max-width: 60px;
        }
        @include media-breakpoint-down(xxxl) {
          min-height: 60px;
          max-width: 50px;
        }
        @include media-breakpoint-down(xl) {
          margin-bottom: 5px;
        }
        img{
          @include media-breakpoint-down(xxxl) {
            max-height: 60px;
          }
          @include media-breakpoint-down(xl) {
            max-height: 47px;
          }
        }
      }
      .qualite-content-box{
        br{
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
        h5{
          font-style: italic!important;
          text-decoration: none;
          font-size: 34px;
          line-height: 40px;
          @include font-title-reg;
          margin-bottom: 20px;
          @include media-breakpoint-down(5xl) {
            font-size: 30px;
            line-height: 33px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 28px;
            line-height: 31px;
            margin-bottom: 15px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 26px;
            line-height: 29px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 24px;
            line-height: 27px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 22px;
            line-height: 25px;
            margin-bottom: 10px;
          }
          @include media-breakpoint-down(sm) {
          }
        }
        p{
          font-size: 16px;
          line-height: 23px;
          letter-spacing: 0.8px;
          max-width: 220px;
          margin: 0 auto;
          @include media-breakpoint-down(5xl) {
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.7px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 13px;
            line-height: 17px;
            letter-spacing: 0.65px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.6px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 11px;
            line-height: 15px;
            letter-spacing: 0.55px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 10px;
            line-height: 14px;
            letter-spacing: 0.51px;
          }
          @include media-breakpoint-down(sm) {
          }
        }
      }
    }
    .boite-bg-color-box{
      border-top-right-radius: 40vh;
      border-bottom-right-radius: 40vh;
      padding: 72px 0;
      margin-right: 25px;
      @include media-breakpoint-down(xl) {
        margin-right: 0;
        padding: 50px 0;
      }
      @include media-breakpoint-down(md) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      &.boite-bg-color-transparent{

      }
      &.boite-bg-color-red{
        background-color: $color-warning;
        .title-normal{
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(md) {
          }
          h2,h3,h4{
            color: $color-inverse;
            span{
              @include media-breakpoint-down(md) {
              }
            }
          }
        }
        .content-box{
          &:before{
            background-image: url("/assets/img/slash-white.svg");
          }
          .p-title{
            color: $color-inverse;
          }
        }
        .texte-normal-box{
          color: $color-inverse;
        }
      }
      &.boite-bg-color-green{
        background-color: $color-secondary;
        .title-normal{
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(md) {
          }
          h2,h3,h4{
            color: $color-inverse;
            span{
              @include media-breakpoint-down(md) {
              }
            }
          }
        }
        .content-box{
          &:before{
            background-image: url("/assets/img/slash-white.svg");
          }
          .p-title{
            color: $color-inverse;
          }
        }
        .texte-normal-box{
          color: $color-inverse;
        }
      }
      &.boite-bg-color-gray{
        background-color: $color-bg;
        .title-normal{
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(md) {
          }
          h2,h3,h4{
            //color: $color-inverse;
            span{
              @include media-breakpoint-down(md) {
              }
            }
          }
        }
        .content-box{
          &:before{
            //background-image: url("/assets/img/slash-white.svg");
          }
          .p-title{
            //color: $color-inverse;
          }
        }
        .texte-normal-box{
          //color: $color-inverse;
        }
      }
      &.boite-bg-color-lightblue{
        background-color: $color-primary;
        .title-normal{
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(md) {
          }
          h2,h3,h4{
            //color: $color-inverse;
            span{
              @include media-breakpoint-down(md) {
              }
            }
          }
        }
        .content-box{
          &:before{
            //background-image: url("/assets/img/slash-white.svg");
          }
          .p-title{
            //color: $color-inverse;
          }
        }
        .texte-normal-box{
          //color: $color-inverse;
        }
      }
      &.boite-bg-color-img{
        background-size: cover;
        .title-normal{
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(md) {
          }
          h2,h3,h4{
            color: $color-inverse;
            span{
              @include media-breakpoint-down(md) {
              }
            }
          }
        }
        .content-box{
          &:before{
            background-image: url("/assets/img/slash-white.svg");
          }
          .p-title{
            color: $color-inverse;
          }
        }
        .texte-normal-box{
          color: $color-inverse;
        }
      }
      .title-normal{
        h2,h3,h4{
          margin-left: 150px;
          @include media-breakpoint-down(xl) {
            margin-left: 55px;
          }
          @include media-breakpoint-down(md) {
          }
          span{
            margin-left: 0px;
            @include media-breakpoint-down(md) {
              margin-left: 20px;
            }
          }
        }
      }
      .content-box{
        position: relative;
        margin-top: 20px;
        margin-left: 100px;
        margin-bottom: 40px;
        @include media-breakpoint-down(4xl) {
        }
        @include media-breakpoint-down(xxl) {
        }
        @include media-breakpoint-down(xl) {
          margin-left: 35px;
          margin-bottom: 25px;
        }
        @include media-breakpoint-down(md) {
        }
        &:before{
          top: -40px;
          left:-60px;
          @include media-breakpoint-down(5xl) {
          }
          @include media-breakpoint-down(4xl) {
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            top: -40px;
            left:-38px;
          }
          @include media-breakpoint-down(lg) {
          }
          @include media-breakpoint-down(md) {
          }
        }
        .p-title{
        }
      }
      .texte-normal-box{

      }
    }



    .module-texte{
      padding-top: 50px;
      padding-bottom: 50px;
      @include media-breakpoint-down(xl) {
        padding-top: 25px;
        padding-bottom: 25px;
      }
    }
    .module-image-texte{
      position: relative;
      padding-top: 50px;
      padding-bottom: 50px;
      @include media-breakpoint-down(xl) {
        padding-top: 25px;
        padding-bottom: 25px;
      }
      .image-box{
        .img-box{
          position: relative;
          background-size: cover;
          background-position: top;
          min-height: 707px;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-bottom-right-radius: 380px;
          border-top-right-radius: 380px;
          margin-right: 30px;
          @include media-breakpoint-down(5xl) {
            min-height: 680px;
          }
          @include media-breakpoint-down(4xl) {
            min-height: 620px;
          }
          @include media-breakpoint-down(xxxl) {
            min-height: 540px;
          }
          @include media-breakpoint-down(xxl) {
            min-height: 420px;
          }
          @include media-breakpoint-down(xl) {
            min-height: 254px;
            border-bottom-right-radius:0;
            border-top-right-radius: 0;
          }
          @include media-breakpoint-down(lg) {
          }
          img{
            display: none;
          }
        }
      }
      .container-bulle{
        position: relative;
        margin-top: -12%;
        @include media-breakpoint-down(4xl) {
          margin-top: -14%;
        }
        @include media-breakpoint-down(xxxl) {
          margin-top: -17%;
        }
        @include media-breakpoint-down(xl) {
          margin-top: -13%;
        }
        @include media-breakpoint-down(sm) {
          margin-top: -33%;
        }
      }
      .titre-bulle-box{
        background-color: white;
        border-top-right-radius: 40vh;
        border-bottom-right-radius: 40vh;
        padding: 112px 0 250px 0;
        margin-right: 25px;
        z-index: 2;
        position: relative;
        @include media-breakpoint-down(5xl) {
          padding: 100px 0 225px 0;
        }
        @include media-breakpoint-down(4xl) {
          padding: 90px 0 200px 0;
        }
        @include media-breakpoint-down(xxxl) {
          padding: 80px 0 175px 0;
        }
        @include media-breakpoint-down(xxl) {
          padding: 70px 0 165px 0;
        }
        @include media-breakpoint-down(xl) {
          padding: 50px 0 100px 0;
        }
        @include media-breakpoint-down(md) {
          margin-right: 0;
        }
        @include media-breakpoint-down(sm) {
        }
        &:before{
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background-color: white;
          position: absolute;
          top: 0;
          left: -455px;
          @include media-breakpoint-down(xl) {
            left: -50px;
            width: 40%;
          }
        }
        .title-normal{
          h2,h3,h4{
            margin-left: 50px;
            @include media-breakpoint-down(xl) {
              margin-left: 40px;
            }
            @include media-breakpoint-down(md) {
            }
            span{
              margin-left: 0px;
              @include media-breakpoint-down(md) {
                margin-left: 20px;
              }
            }
          }
        }
        .content-box{
          position: relative;
          margin-top: 20px;
          margin-left: 0;
          margin-right: 50px;
          margin-bottom: 40px;
          @include media-breakpoint-down(4xl) {
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            margin-bottom: 25px;
            margin-left: 20px;
          }
          @include media-breakpoint-down(md) {
          }
          &:before{
            top: -40px;
            left:-60px;
            @include media-breakpoint-down(5xl) {
            }
            @include media-breakpoint-down(4xl) {
            }
            @include media-breakpoint-down(xxxl) {
              top: -60px;
              left: -35px;
            }
            @include media-breakpoint-down(xxl) {
              top: -30px;
              left:-28px;
            }
            @include media-breakpoint-down(xl) {

            }
            @include media-breakpoint-down(lg) {
            }
            @include media-breakpoint-down(md) {
            }
          }
          .p-title{
          }
        }
      }
      .contenu-avec-colonnes{
        position: relative;
        margin-top: -220px;
        z-index: 3;
        @include media-breakpoint-down(4xl) {
          margin-top: -200px;
        }
        @include media-breakpoint-down(xxxl) {
          margin-top: -160px;
        }
        @include media-breakpoint-down(xl) {
          margin-top: -100px;
        }
      }
    }
    .module-processus{
      padding-top: 50px;
      padding-bottom: 50px;
      @include media-breakpoint-down(xl) {
        padding-top: 25px;
        padding-bottom: 25px;
      }
      .carousel-box{
        @include media-breakpoint-down(xxl) {
          margin-left: 30px;
        }
        .demo-slide-box{
          .chiffre-box{
            @include media-breakpoint-down(md) {
              display: flex;
              align-items: center;
            }
            &:after{
              background-image: url("/assets/img/recrutement/fleche-processus.svg");
              width: 197px;
              height: 23px;
              display: inline-flex;
              content: "";
              margin-left: 10px;
              @include media-breakpoint-down(xxxl) {
                width: 167px;
                background-size: 167px;
                height: 20px;
              }
              @include media-breakpoint-down(md) {
                width: 97px;
                background-size: 97px;
                height:11px;
              }
            }
            .number-txt{
              font-size: 48px;
              line-height: 68px;
              @include font-title-reg;
              font-style: italic;
            }
          }
          .text-box{
            margin-right: 20px;
            p{
              font-size: 16px;
              line-height: 23px;
              letter-spacing: 0.8px;
            }
          }
        }
        .swiper-container{
          position: relative;
        }
        .swiper-button{
          transition: opacity 0.2s linear;
          //margin-top: -38.5px;
          width: 30px;
          height: 77px;
          top:40px;
          background-size: 30px;
          background-repeat: no-repeat;
          background-image: url("/assets/img/recrutement/fleche-carousel-grise.svg");
          @include media-breakpoint-down(5xl) {
          }
          @include media-breakpoint-down(4xl) {
          }
          @include media-breakpoint-down(xxxl) {
            width: 25px;
            height: 65px;
            top:40px;
            background-size: 25px;
          }
          @include media-breakpoint-down(xxl) {
            width: 20px;
            height: 50px;
            top:40px;
            background-size: 20px;
          }
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(lg) {
          }
          @include media-breakpoint-down(md) {
          }
          @include media-breakpoint-down(sm) {
            width: 14px;
            height: 40px;
            top:40px;
            background-size:14px;
          }
          &:hover{
            opacity: 0.85;
          }
          &:after{
            content: "";
          }
          &.swiper-button-prev{
            left: -80px;
            transform: rotate(180deg);
            @include media-breakpoint-down(5xl) {
              //left: 40px;
            }
            @include media-breakpoint-down(4xl) {
              left: -60px;
            }
            @include media-breakpoint-down(xxxl) {
              //left: 20px;
            }
            @include media-breakpoint-down(xxl) {
              left: -45px;
            }
            @include media-breakpoint-down(xl) {
              //left: 25px;
            }
            @include media-breakpoint-down(md) {
              //left: 15px;
            }
            @include media-breakpoint-down(sm) {
            }
          }
          &.swiper-button-next{
            //background-color: red;
            right:-60px;
            @include media-breakpoint-down(5xl) {
              //right: 40px;
            }
            @include media-breakpoint-down(4xl) {
              //right: 30px;
            }
            @include media-breakpoint-down(xxxl) {
              //right: 20px;
            }
            @include media-breakpoint-down(xxl) {
              right:-40px;
            }
            @include media-breakpoint-down(xl) {
              //right: 25px;
            }
            @include media-breakpoint-down(md) {
              // right: 15px;
            }
            @include media-breakpoint-down(sm) {
            }
          }
        }
      }
    }
    .module-icones{
      padding-top: 120px;
      padding-bottom: 80px;
      position: relative;
      @include media-breakpoint-down(xl) {
        padding-top: 25px;
        padding-bottom: 25px;
      }
      .dx-bg-box {
        width: 318px;
        height: 563px;
        top: 366px;
        right: 0;
        @include media-breakpoint-down(5xl) {
        }
        @include media-breakpoint-down(4xl) {
          width: 250px;
          height: 347px;
        }
        @include media-breakpoint-down(xxxl) {
          width: 150px;
        }
        @include media-breakpoint-down(xxl) {
          height: 339px;
        }
        @include media-breakpoint-down(xl) {
          display: none;
        }
        @include media-breakpoint-down(md) {

        }
        @include media-breakpoint-down(sm) {

        }
      }
    }
    .module-carousel{
      .slider-bout-rond {
        .demo-slide-box {
          .bg-img-box{
            min-height: 704px;
            @include media-breakpoint-down(5xl) {
              min-height: 650px;
            }
            @include media-breakpoint-down(4xl) {
              min-height: 600px;
            }
            @include media-breakpoint-down(xxxl) {
              min-height:550px;
            }
            @include media-breakpoint-down(xxl) {
              min-height:500px;
            }
            @include media-breakpoint-down(xl) {
              min-height:420px;
            }
            @include media-breakpoint-down(md) {
              min-height:320px;
            }
            @include media-breakpoint-down(sm) {
              min-height:233px;
            }
          }
        }
      }
    }
    .module-icones-details{
      padding-top: 120px;
      padding-bottom: 80px;
      position: relative;
      @include media-breakpoint-down(xxxl) {
      }
      @include media-breakpoint-down(xl) {
        padding-top: 25px;
        padding-bottom: 25px;
      }
    }
    .module-icones-details-2{
      padding-top: 50px;
      padding-bottom: 50px;
      @include media-breakpoint-down(xl) {
        padding-top: 25px;
        padding-bottom: 25px;
      }
      .boite-bg-color-box{
        padding:100px 0 72px 0;
      }
    }
    .module-galerie-images{
      position: relative;
      padding-top: 220px;
      padding-bottom: 180px;
      position: relative;
      @include media-breakpoint-down(4xl) {
        padding-top: 180px;
        padding-bottom: 140px;
      }
      @include media-breakpoint-down(xl) {
        padding-top: 55px;
        padding-bottom: 55px;
      }
      .dx-bg-box{
        &.bg-1 {
          width: 401px;
          height:791px;
          bottom: 105px;
          left: 12px;
          @include media-breakpoint-down(5xl) {
            //width: 424px;
            //height: 246px;
          }
          @include media-breakpoint-down(4xl) {
            width: 201px;
            height:550px;
            bottom: 85px;
          }
          @include media-breakpoint-down(xxxl) {
            //width: 90px;
          }
          @include media-breakpoint-down(xxl) {
            // width: 85px;
            width: 112px;
            height:355px;
          }
          @include media-breakpoint-down(xl) {
            width: 125px;
            bottom: 0px;
            height:100px;
            left: 0px;
          }
        }
        &.bg-2 {
          width: 700px;
          height: 701px;
          top: 145px;
          right: 5px;
          @include media-breakpoint-down(5xl) {
            //width: 230px;
          }
          @include media-breakpoint-down(4xl) {
            width: 520px;
            height: 503px;
          }
          @include media-breakpoint-down(xxxl) {
            width: 470px;
          }
          @include media-breakpoint-down(xxl) {
            //width: 85px;
            width: 400px;
            height: 452px;
          }
          @include media-breakpoint-down(xl) {
            display: none;
          }
        }
      }
      .galerie-image-container{
        position: relative;
        z-index: 10;
        .image-box{
          height: 560px;
          width: 100%;
          background-size: cover;
          background-position: center;
          margin-bottom: 30px;
          @include media-breakpoint-down(5xl) {
            height: 440px;
          }
          @include media-breakpoint-down(4xl) {
            height: 350px;
          }
          @include media-breakpoint-down(xxxl) {
            height: 280px;
          }
          @include media-breakpoint-down(xxl) {
            height: 200px;
          }
          @include media-breakpoint-down(xl) {
            display: none;
          }
          img{
            display: none;
          }
        }
      }
      .galerie-carousel{
        display: none;
        @include media-breakpoint-down(xl) {
          display: block;
          position: relative;
        }
        .image-box{
          width: 100%;
          background-size: cover;
          background-position: center;
          @include media-breakpoint-down(xl) {
            height: 330px;
          }
          @include media-breakpoint-down(md) {
            height: 300px;
          }
          @include media-breakpoint-down(sm) {
            height: 266px;
          }
          img{
            display: none;
          }
        }
        .swiper-button{
          transition: opacity 0.2s linear;
          width: 43px;
          height: 43px;
          //top:40px;
          margin-top: -21.5px;
          z-index: 20;
          background-size: 43px;
          background-repeat: no-repeat;
          background-image: url("/assets/img/recrutement/fleche-carousel-mobile.svg");
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(lg) {
          }
          @include media-breakpoint-down(md) {
          }
          @include media-breakpoint-down(sm) {
          }
          &:hover{
            opacity: 0.85;
          }
          &:after{
            content: "";
          }
          &.swiper-button-prev{
            left: 10px;
            transform: rotate(180deg);

            @include media-breakpoint-down(xl) {
              //left: 25px;
            }
            @include media-breakpoint-down(md) {
              //left: 15px;
            }
            @include media-breakpoint-down(sm) {
            }
          }
          &.swiper-button-next{
            //background-color: red;
            @include media-breakpoint-down(xl) {
              //right: 25px;
            }
            @include media-breakpoint-down(md) {
              // right: 15px;
            }
            @include media-breakpoint-down(sm) {
            }
          }
        }
      }
    }
    .module-carousel-equipe{
      padding-top: 110px;
      padding-bottom: 110px;
      @include media-breakpoint-down(5xl) {
        padding-top: 100px;
        padding-bottom: 100px;
      }
      @include media-breakpoint-down(4xl) {
        padding-top: 90px;
        padding-bottom: 90px;
      }
      @include media-breakpoint-down(xxxl) {
        padding-top: 80px;
        padding-bottom: 80px;
      }
      @include media-breakpoint-down(xl) {
        padding-top: 25px;
        padding-bottom: 25px;
      }
      .carousel-box{
        @include media-breakpoint-down(xxl) {
          //margin-left: 30px;
        }
        .equipe-slide-box{
          .img-box{
            img{
              object-fit: cover;
              height: 630px;
              width: 100%;
              display: block;
              @include media-breakpoint-down(5xl) {
                height: 571px;
              }
              @include media-breakpoint-down(4xl) {
                height: 436px;
              }
              @include media-breakpoint-down(xxxl) {
                height: 384px;
              }
              @include media-breakpoint-down(xxl) {
                height: 397px;
              }
              @include media-breakpoint-down(xl) {
                height: 344px;
              }
              @include media-breakpoint-down(lg) {
                height:338px;
              }
              @include media-breakpoint-down(md) {
                height: 270px;
              }
              @include media-breakpoint-down(sm) {
                height: 144px;
              }
            }
          }
          .infos-box{
            margin-top: 30px;
            @include media-breakpoint-down(sm) {
              margin-top: 15px;
            }
            h4{
              @include font-bold-1;
              font-size: 25px;
              line-height: 35px;
              text-transform: uppercase;
              margin-bottom: 15px;
              @include media-breakpoint-down(xl) {
                font-size: 20px;
                line-height: 30px;
              }
              @include media-breakpoint-down(md) {
                font-size: 18px;
                line-height: 28px;
              }
              @include media-breakpoint-down(sm) {
                font-size: 15px;
                line-height: 25px;
                margin-bottom: 5px;
              }
            }
            p{
              font-style: italic;
            }
          }
        }
        .swiper-container{
          position: relative;
        }
        .swiper-button{
          transition: opacity 0.2s linear;
          //margin-top: -38.5px;
          width: 30px;
          height: 77px;
          top:300px;
          background-size: 30px;
          background-repeat: no-repeat;
          background-image: url("/assets/img/recrutement/fleche-carousel-grise.svg");
          @include media-breakpoint-down(5xl) {
            top:280px;
          }
          @include media-breakpoint-down(4xl) {
            top:210px;
          }
          @include media-breakpoint-down(xxxl) {
            top:180px;
          }
          @include media-breakpoint-down(xxl) {
            width: 20px;
            height: 50px;
            top:190px;
            background-size: 20px;
          }
          @include media-breakpoint-down(xl) {
            display: none;
          }
          @include media-breakpoint-down(lg) {
          }
          @include media-breakpoint-down(md) {
          }
          @include media-breakpoint-down(sm) {
          }
          &:hover{
            opacity: 0.85;
          }
          &:after{
            content: "";
          }
          &.swiper-button-prev{
            left: -80px;
            transform: rotate(180deg);
            @include media-breakpoint-down(5xl) {
              //left: 40px;
            }
            @include media-breakpoint-down(4xl) {
              left: -60px;
            }
            @include media-breakpoint-down(xxxl) {
              //left: 20px;
            }
            @include media-breakpoint-down(xxl) {
              left: -45px;
            }
            @include media-breakpoint-down(xl) {
              //left: 25px;
            }
            @include media-breakpoint-down(md) {
              //left: 15px;
            }
            @include media-breakpoint-down(sm) {
            }
          }
          &.swiper-button-next{
            //background-color: red;
            right:-60px;
            @include media-breakpoint-down(5xl) {
              //right: 40px;
            }
            @include media-breakpoint-down(4xl) {
              //right: 30px;
            }
            @include media-breakpoint-down(xxxl) {
              //right: 20px;
            }
            @include media-breakpoint-down(xxl) {
              right:-40px;
            }
            @include media-breakpoint-down(xl) {
              //right: 25px;
            }
            @include media-breakpoint-down(md) {
              // right: 15px;
            }
            @include media-breakpoint-down(sm) {
            }
          }
        }
      }
    }
    .module-image-texte-bulle{
      padding-top: 110px;
      padding-bottom: 110px;
      @include media-breakpoint-down(5xl) {
        padding-top: 100px;
        padding-bottom: 100px;
      }
      @include media-breakpoint-down(4xl) {
        padding-top: 90px;
        padding-bottom: 90px;
      }
      @include media-breakpoint-down(xxxl) {
        padding-top: 80px;
        padding-bottom: 80px;
      }
      @include media-breakpoint-down(xl) {
        padding-top: 25px;
        padding-bottom: 25px;
      }
      .boite-bg-color-box{
        padding: 120px 0;
        @include media-breakpoint-down(xl) {
          padding: 60px 0;
        }
        .content-box{
          margin-bottom: 0;
        }
      }
    }



    .bande-details-sticky{
      background-color: $color-bg-alt-2;
      width: 97%;
      margin-right: 30px;
      margin-left: 30px;
      margin-bottom: 30px;
      position: sticky;
      bottom: 20px;
      left: 0;
      z-index: 10;
      padding: 0 0 0 36px;
      border-top-right-radius: 80px;
      border-bottom-right-radius: 80px;
      color: $color-inverse;
      @include media-breakpoint-down(xxxl) {
      }
      @include media-breakpoint-down(xl) {
        width: 95%;
      }
      @include media-breakpoint-down(md) {
        display: none;
      }
      .bande-content-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .infos-job{
          display: flex;
          .titre-job{
            //max-width: 400px;
            padding-right: 30px;
            @include media-breakpoint-down(xxxl) {
              //max-width: 300px;
            }
            @include media-breakpoint-down(xl) {
              //max-width:140px;
            }
            h4{
              @include font-black-1;
              font-size: 18px;
              line-height: 28px;
              margin-bottom: 0;
              @include media-breakpoint-down(4xl) {
                font-size: 18px;
                line-height: 28px;
              }
              @include media-breakpoint-down(xxxl) {
                //font-size: 19px;
                //line-height: 26px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 16px;
                line-height: 21px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 15px;
                line-height: 20px;
              }
            }
          }

        }
        .details-job{
          display: flex;
          .ico-txt{
            display: flex;
            align-items: center;
            margin-right: 45px;
            @include media-breakpoint-down(xxxl) {
              margin-right: 30px;
            }
            @include media-breakpoint-down(xl) {
              margin-right: 15px;
            }
            .ico-box{
              margin-right: 8px;
              width: 27px;
              text-align: center;
              img{
                margin: 0 auto;
              }
            }
            .txt-box{
              @include font-bold-1;
              font-size: 17px;
              line-height: 21px;
              @include media-breakpoint-down(4xl) {
                font-size: 16px;
                line-height: 20px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 14px;
                line-height: 18px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 13px;
                line-height: 17px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
        }
        .bt-appliquer-box{

        }
      }
    }



    &.nav-scrolled {
      .section-menu-fun{
        top:$header-nav-height-sticky;
      }
    }
    &.nav-scrolled-hide {
      .section-menu-fun{
        top:-1px;
      }
    }
    &.nav-scrolled-second-sub-show {
      .section-menu-fun{
        opacity:1;
      }
    }
    
    
    .objets-flottants{
      position: absolute;
      width:100%;
    }
    
  }
}//#dx-page-body
