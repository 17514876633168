@charset "utf-8";

/* ------------------------------------------------------------------------------------
// Reusables mixins
/ ------------------------------------------------------------------------------------*/
/*
Mixin center vertical
You need to add this to the parent and specify the child to centerhjg
*/
@mixin vertical-center($child,$position: relative) {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  #{$child} {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
//Déclaration temporaire pour éviter erreur de compilation dans commentaire. Vraie variable définie dans _constants
$imgFolder:'';
/*
Is retina ?

Usage:
background-image: url(#{$imgFolder}normal.png);
@include retina(){
  background-image: url(#{$imgFolder}retina.png);
}
*/
@mixin retina() {
  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (   min--moz-device-pixel-ratio: 2),
  only screen and (     -o-min-device-pixel-ratio: 2/1),
  only screen and (        min-device-pixel-ratio: 2),
  only screen and (                min-resolution: 192dpi),
  only screen and (                min-resolution: 2dppx) {
    @content ;
  }
}

@mixin icon-font-custom() {
  display: inline-block;
  font-family: "fontcustom";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}


@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

/**
 Pixels to REM mixins
 
 Examples:
 @include fontSize(48px);
 @include lineHeight(48px);
 @include margin(10px 10px 10px 10px);
 @include padding(10px 10px 10px 10px);
 @include px-to-rem("padding-top",10px);
 @include px-to-rem("left",10px);

 */

// Function for converting a px based font-size to rem.
@function calculateRem($size) {
  $remSize: $size / 16px;
  //Default font size on html element is 100%, equivalent to 16px;
  @return #{$remSize}rem;
}

//THE FOLLOWING MIXINS ARE DEPRECATED! Replaced by postcss-pxtorem!!

// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin fontSize($size) {
  font-size: $size;
  //font-size: calculateRem($size);
}

// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin lineHeight($size) {
  line-height: $size;
  //line-height: calculateRem($size);
}


// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin letterSpacing($size) {
  letter-spacing: $size;
  //letter-spacing: calculateRem($size);
}

// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin margin($size1,$size2,$size3,$size4) {
  margin: $size1 $size2 $size3 $size4;
  //@if $size1 == 0 {
  //  $size1 : 0px;
  //}
  //@if $size2 == 0 {
  //  $size2 : 0px;
  //}
  //@if $size3 == 0 {
  //  $size3 : 0px;
  //}
  //@if $size4 == 0 {
  //  $size4 : 0px;
  //}
  //margin: calculateRem($size1) calculateRem($size2) calculateRem($size3) calculateRem($size4);
}

// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin padding($size1,$size2,$size3,$size4) {
  padding: $size1 $size2 $size3 $size4;
  //@if $size1 == 0 {
  //  $size1 : 0px;
  //}
  //@if $size2 == 0 {
  //  $size2 : 0px;
  //}
  //@if $size3 == 0 {
  //  $size3 : 0px;
  //}
  //@if $size4 == 0 {
  //  $size4 : 0px;
  //}
  //padding: calculateRem($size1) calculateRem($size2) calculateRem($size3) calculateRem($size4);
}

// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin px-to-rem($property,$size) {
  #{$property}: $size;
  #{$property}: calculateRem($size);
}

/**
Conversion du letter spacing de Adobe XD
 */
@mixin letter-spacing-xd($spacing) {
    letter-spacing: ($spacing / 1000) * 1em;
}