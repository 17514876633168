#dx-page-body {
  &.page-produits{
    header{
      .bg-secondary{
        //background-color: transparent!important;
      }
    }
    .site-main { //main
      .main-content-wrapper {
        .content-area {
          //padding-top: 0; // hauteur de la nav - nav transparente pour carousel
          .section-main{
            > h1{
              display: none;
            }
          }
        }
      }
    }
    .section-products{
      //margin-top: 50px;
      .filters-mobile-nav{
        display: none;
        @include media-breakpoint-down(xl) {
          display: flex;
          position: sticky;
          transition:top 0.4s;
          top:$header-nav-height-mobile;
          z-index: $z-filters-mobile-nav;
          background-color: $color-bg;
          flex-direction: column;
          justify-content: center;
        }
        .title-box{
          padding-top: 22px;
          padding-bottom: 5px;
          text-align: center;
          h2{
            font-size: 15px;
            line-height: 19px;
            @include font-reg-1;
            margin-bottom: 0;
            text-transform: uppercase;
          }
        }
        .boite-choix-boutons{
          display: flex;
          justify-content: center;
          padding-top: 22px;
          padding-bottom: 22px;
          .filtre-triage-box{
            select{
              background-color: white;
              border: none;
              padding-top:15px;
              padding-bottom: 15px;
              font-size: 8px;
              line-height: 12px;
              @include font-bold-1;
              min-width: 170px;
              border-top-right-radius: 20px;
              border-bottom-right-radius: 20px;
              text-transform: uppercase;
              background-image: url("/assets/img/ico-trier.svg");
              background-position: center right 20px;
              background-repeat: no-repeat;
            }
          }
        }
        .products-filters-box{
          display: block;
        }
        .offcanvas{
          top: $header-height-mobile;
          right: auto;
          margin: 0;
          border: none;
          border-radius: 0;
          height: 100%;
          max-height: none;
          width: 85%;
          .offcanvas-header{
            padding: 33px 20px 13px 20px;
            .filtre-reinitialiser-box{
              //padding-top: 55px;
              margin-left: 0px;
              .btn-reinit-link{
                color: $color-secondary;
                border: 1px solid $color-secondary;
                @include font-bold-1;
                font-size: 8px;
                line-height: 12px;
                letter-spacing: 0.23px;
                padding: 16px 17px;
                border-top-right-radius: 30px;
                border-bottom-right-radius: 30px;
                transition: background-color 0.2s linear;
                &:after{
                  display: inline-flex;
                  content: "X";
                  margin-left: 10px;
                  margin-right: 6px;
                }
                &:hover, &:focus{
                  background-color: white;
                }
              }
            }
            .btn-link{
              font-size: 14px;
            }
          }
          .offcanvas-body{
            //padding: 0 20px;
            overflow: hidden;
            .items-count-box{
              padding: 0 23px;
              margin-bottom: 10px;
              .number-txt{
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.4px;
                @include font-bold-1;
              }
              .type-txt{
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.67px;
                @include font-reg-1;
                text-transform: uppercase;
              }
            }
            .products-filters-box{
              padding-right: 0;
              .accordion{
                --bs-accordion-bg: white!important;
                .accordion-item{
                  .accordion-header{//h2
                    .accordion-button{
                      font-size: 14px;
                      line-height: 24px;
                      letter-spacing: 0;
                      text-transform: none;
                      @include font-reg-1;
                      border-bottom: 1px solid transparent;
                      padding: 11px 21px;
                      &.collapsed{
                        border-bottom: 1px solid $color-border-1;
                      }
                      &:focus{
                        box-shadow: none;
                      }
                    }
                  }
                  .accordion-collapse{
                      border-bottom: 1px solid transparent;
                    &.show{
                      border-bottom: 1px solid $color-border-1;
                    }
                    .accordion-body{
                      padding: 0px 21px 20px 21px;
                      .form-check{
                        min-height: 30px;
                        .form-check-input[type=checkbox] {
                          border-radius: 50%;
                        }
                        .form-check-label{
                          font-size: 14px;
                          letter-spacing: 1.01px;
                          padding-left: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .filters-top-box{
        position: sticky;
        top:$header-nav-height-sticky;
        transition: top 0.2s;
        width: 100%;
        //padding-right: 90px;
        //padding-top: 50px;
        z-index: 10;
        //display: flex;
        //flex-direction: row;
        //justify-content: space-between;
        background-color: $color-bg;
        padding-bottom: 27px;
        @include media-breakpoint-down(xl) {
          display: none;
        }
        .filtre-reinitialiser-box{
          padding-top: 55px;
          margin-left: 20px;
          .btn-reinit-link{
            color: $color-secondary;
            border: 1px solid $color-secondary;
            @include font-bold-1;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.35px;
            padding: 24px 26px;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            transition: background-color 0.2s linear;
            @include media-breakpoint-down(5xl) {
              //background-color: red;
              padding: 20px 10px;
            }
            @include media-breakpoint-down(4xl) {
              //background-color: yellow;
              padding: 18px 5px;
              font-size: 9px;
              line-height: 17px;
              letter-spacing: 0px;
            }
            @include media-breakpoint-down(xxxl) {
              //background-color: green;
              padding: 18px 10px;
              font-size: 11px;
            }
            @include media-breakpoint-down(xxl) {
              //background-color: pink;
              font-size: 10px;
              line-height: 16px;
              padding: 18px 5px;
            }
            @include media-breakpoint-down(xl) {
              //background-color: mediumseagreen;
            }
            @include media-breakpoint-down(lg) {
              //background-color: hotpink;
            }
            @include media-breakpoint-down(md) {
              //background-color: salmon;
            }
            @include media-breakpoint-down(sm) {
              //background-color: greenyellow;
            }
            &:after{
              display: inline-flex;
              content: "X";
              margin-left: 25px;
              margin-right: 6px;
              @include media-breakpoint-down(5xl) {
                margin-left: 10px;
              }
              @include media-breakpoint-down(4xl) {
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
              }
              @include media-breakpoint-down(xl) {
              }
              @include media-breakpoint-down(lg) {
              }
              @include media-breakpoint-down(md) {
              }
              @include media-breakpoint-down(sm) {
              }
            }
            &:hover, &:focus{
              background-color: white;
            }
          }
        }
        .title-box{
          padding-top: 49px;
          h2{
            font-size: 32px;
            line-height: 40px;
            @include font-reg-1;
            margin-bottom: 0;
            text-transform: uppercase;
            @include media-breakpoint-down(5xl) {
              font-size: 30px;
              line-height: 38px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 28px;
              line-height: 36px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 26px;
              line-height: 34px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 25px;
              line-height: 33px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 23px;
              line-height: 31px;
            }
            @include media-breakpoint-down(lg) {
            }
            @include media-breakpoint-down(md) {
            }
            @include media-breakpoint-down(sm) {
            }
          }
        }
        .item-count-filtres-box{
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-top: 49px;
          .items-count-box{
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.53px;
            padding-right: 20px;
            .number-txt{
              @include font-bold-1;
            }
            .type-txt{
              @include font-reg-1;
            }

          }
          .filtre-triage-box{
            .form-floating{
              font-size: 12px;
              letter-spacing: 0.58px;
              text-transform: uppercase;
              color: $color-text-main;
            }
            select{
              min-width: 300px;
              background-color: white;
              border: none;
              border-bottom-right-radius: 30px;
              border-top-right-radius: 30px;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.58px;
              @include font-bold-1;
              text-transform: uppercase;
              color: $color-text-main;
              @include media-breakpoint-down(xxl) {
                min-width: 200px;
              }
            }
          }
        }

      }
      .col-filters{
      }
      .container-products{
        @include media-breakpoint-down(xl) {
          padding-left: 0;
          padding-right: 0;
          overflow: hidden;
        }
      }
      .products-filters-box{
        position: sticky;
        //Hauteur de la bande de filtre
        top:$header-nav-height-sticky + $products-topfilter-height;
        overflow-y: scroll;
        max-height: calc(100vh - ($header-nav-height-sticky + $products-topfilter-height));
        //padding-bottom: 200px;
        //width: 16.66666667%;
        width: 100%;
        padding-right: 10px;
        z-index: 9;
        transition:top 0.5s;
        //top: calc($header-nav-height + 130px);
        @include media-breakpoint-down(xxxl) {
         // width: 23.5%;
        }
        @include media-breakpoint-down(xl) {
          display: none; // overwrite plus haut
          width: 100%;
          position: static;
          top:auto;
          overflow-y: auto;
          max-height: calc(100vh - ($header-nav-height + $products-topfilter-height));
        }
        .accordion{
          .accordion-button{
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.45px;
            @include font-bold-1;
            text-transform: uppercase;
            @include media-breakpoint-down(5xl) {
              font-size: 17px;
              line-height: 21px;
              letter-spacing: 0.4px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 16px;
              line-height: 20px;
              letter-spacing: 0.35px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 15px;
              line-height: 19px;
              letter-spacing: 0.3px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.25px;
            }
          }
        }
        .form-check{
          min-height: 40px;
          @include media-breakpoint-down(5xl) {
            min-height: 38px;
          }
          @include media-breakpoint-down(4xl) {
            min-height: 36px;
          }
          @include media-breakpoint-down(xxxl) {
            min-height: 34px;
          }
          @include media-breakpoint-down(xxl) {
            min-height: 32px;
          }
          .form-check-input[type=checkbox] {
            border-radius: 50%;
          }
          label{
            font-size: 18px;
            letter-spacing: 1.45px;
            @include font-light-1;
            padding-left: 10px;
            @include media-breakpoint-down(5xl) {
              font-size: 17px;
              line-height: 21px;
              letter-spacing: 0.4px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 16px;
              line-height: 20px;
              letter-spacing: 0.35px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 15px;
              line-height: 19px;
              letter-spacing: 0.3px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.25px;
            }
            &:hover{
              color: $color-secondary;
            }
          }
        }
      }
      .col-products{
        margin-top: 100px;
        @include media-breakpoint-down(xl) {
          margin-top: 0px;
        }
      }
      .products-grid-box{
        position: relative;
        background-color: white;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 15px;
        @include media-breakpoint-down(xl) {
          margin-top: 0px;
          padding-top: 20px;
        }
        &.grid-2{
          margin-top: 0;
          .product-box{
            //padding-top: 0;
          }
        }
        &:before{
          @include media-breakpoint-down(md) {
            content: "";
            background-color: white;
            height: 100%;
            width: 100px;
            display: block;
            position: absolute;
            top: 0;
            left: -30px;
            z-index: -1;
          }
        }
        &:after{
          content: "";
          background-color: white;
          height: 100%;
          width: 100px;
          display: block;
          position: absolute;
          top: 0;
          right: -42px;
          z-index: -1;
          @include media-breakpoint-down(md) {
            right: -30px;
          }
        }

        .product-box{
          border: 18px solid white;
          width: 25%;
          padding-top: 30px;
          transition: opacity 0.5s linear, border-color 0.2s linear;
          position: relative;
          @include media-breakpoint-down(5xl) {
            padding-top: 25px;
          }
          @include media-breakpoint-down(4xl) {
            padding-top: 20px;
          }
          @include media-breakpoint-down(xxxl) {
            width: 33.33333333%;
            padding-top: 20px;
          }
          @include media-breakpoint-down(xxl) {
            padding-top: 20px;
          }
          @include media-breakpoint-down(xl) {
            padding: 0 20px;
            width: 50%;
            border: none;
          }
          @include media-breakpoint-down(md) {
          }
          &:hover{
            cursor: hand;
            border: 18px solid $color-bg;
            @include media-breakpoint-down(xl) {
              border: none;
            }
            .btn-box{
              opacity: 1;
            }
          }
          a{
            &:hover{
              .img-box{
                img{
                  opacity: 0.85;
                }
              }
              .desc-box{
                p{
                  color: $color-secondary;
                }
              }
            }
            .desc-box{
              p{
                color: $color-text-main;
                transition: color 0.2s linear;
              }
            }
          }
          .rabais-box{
            position: absolute;
            top: 20px;
            left: 20px;
            background-color: $color-warning;
            display: inline-flex;
            padding: 10px 33px 10px 15px;
            border-bottom-right-radius: 30px;
            border-top-right-radius: 30px;
            z-index: 9;
            @include media-breakpoint-down(5xl) {
              top: 18px;
              left: 18px;
              padding: 9px 32px 9px 14px;
            }
            @include media-breakpoint-down(4xl) {
              top: 16px;
              left: 16px;
              padding: 8px 31px 8px 13px;
            }
            @include media-breakpoint-down(xxxl) {
              top: 14px;
              left: 14px;
              padding: 7px 30px 7px 12px;
            }
            @include media-breakpoint-down(xxl) {
              top: 12px;
              left: 12px;
              padding: 6px 29px 6px 11px;
            }
            @include media-breakpoint-down(xl) {
              margin-left: 30px;
              top: 10px;
              left: 10px;
              padding: 5px 28px 5px 10px;
            }
            @include media-breakpoint-down(lg) {
              margin-left: 30px;
              top: 0px;
              left: 0px;
              padding: 2px 18px 2px 7px;
            }
            .rabais-txt{
              margin-bottom: 0;
              color: $color-inverse;
              //font-size: 20px;
              //line-height: 28px;
              font-size: 19px;
              line-height: 27px;
              @include font-black-1;
              @include media-breakpoint-down(5xl) {
                font-size: 18px;
                line-height: 26px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 17px;
                line-height: 25px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 16px;
                line-height: 24px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 16px;
                line-height: 24px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 15px;
                line-height: 23px;
              }
              @include media-breakpoint-down(sm) {
                font-size: 13px;
                line-height: 21px;
              }
            }
          }
          .img-box{
            text-align: center;
            //min-height: 335px;
            @include media-breakpoint-down(5xl) {
              //min-height: 305px;
            }
            @include media-breakpoint-down(4xl) {
              //min-height: 305px;
            }
            img{
              transition: opacity 0.2s linear;
            }

          }
          .desc-box{
            margin-left: 20px;
            margin-right: 20px;
            padding-bottom: 20px;
            p{
              font-size: 15px;
              line-height: 22px;
              letter-spacing: 0.75px;
              @include font-bold-1;
              @include media-breakpoint-down(xl) {
                font-size: 10px;
                line-height: 15px;
                letter-spacing: 0.2px;
              }
            }
          }
          .btn-box{
            margin: 0 20px 20px 20px;
            opacity: 0;
            @include media-breakpoint-down(xl) {
             display: none;
            }
          }
        }
      }
      .bg-white{
        position: relative;
        padding-left: 35px;
        @include media-breakpoint-down(md) {
          padding-left: 0;
        }
        &:before{
          @include media-breakpoint-down(md) {
            content: "";
            background-color: white;
            height: 100%;
            width: 100px;
            display: block;
            position: absolute;
            top: 0;
            left: -30px;
            z-index: -1;
          }
        }
        &:after{
          content: "";
          background-color: white;
          height: 100%;
          width: 100px;
          display: block;
          position: absolute;
          top: 0;
          right: -42px;
          z-index: -1;
          @include media-breakpoint-down(md) {
            right: -30px;
          }
        }
        &.bg-bottom{
          padding-bottom: 180px;
          @include media-breakpoint-down(md) {
            padding-bottom: 80px;
          }
        }
      }
      .demo-box{
        padding-bottom: 100px;
        @include media-breakpoint-down(xl) {
          padding-bottom: 30px;
        }
        .title-box{
          padding-top: 100px;
          padding-bottom: 20px;
          @include media-breakpoint-down(5xl) {
            padding-top: 90px;
          }
          @include media-breakpoint-down(4xl) {
            padding-top: 80px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-top: 70px;
          }
          @include media-breakpoint-down(xxl) {
            padding-top: 60px;
          }
          @include media-breakpoint-down(xl) {
            padding-top: 50px;
            padding-bottom: 15px;
            padding-left: 30px;
          }
          @include media-breakpoint-down(sm) {
            padding-top: 30px;
          }
          h3{
            font-size: 32px;
            line-height: 40px;
            @include font-reg-1;
            @include media-breakpoint-down(5xl) {
              font-size: 30px;
              line-height: 38px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 28px;
              line-height: 36px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 26px;
              line-height: 34px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 24px;
              line-height: 32px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 22px;
              line-height: 30px;
            }
            @include media-breakpoint-down(sm) {
              font-size: 18px;
              line-height: 26px;
            }
          }
        }
        .carousel-demo-box{
          .demo-slide-box{
            &:hover{
              .loupe-box{
                opacity: 1;
              }
            }
            .bg-img-box{
              background-size: cover;
              width: 100%;
              min-height: 488px;
              display: block;
              background-position: center;
              @include media-breakpoint-down(5xl) {
                min-height: 450px;
              }
              @include media-breakpoint-down(4xl) {
                min-height: 400px;
              }
              @include media-breakpoint-down(xxxl) {
                min-height: 360px;
              }
              @include media-breakpoint-down(xxl) {
                min-height: 300px;
              }
              @include media-breakpoint-down(xl) {
                min-height:280px;
              }
              @include media-breakpoint-down(lg) {
                min-height:260px;
              }
              @include media-breakpoint-down(md) {
                min-height:233px;
              }
              img{
                width: 100%;
                display: none;
              }

            }
            .loupe-box{
              position: absolute;
              top: 50%;
              left: 50%;
              margin-top: -86px;
              margin-left: -86px;
              opacity: 0;
              transition: opacity 0.2s linear;
              @include media-breakpoint-down(5xl) {
                margin-top: -80px;
                margin-left: -80px;
              }
              @include media-breakpoint-down(4xl) {
                margin-top: -70px;
                margin-left: -70px;
              }
              @include media-breakpoint-down(xxxl) {
                margin-top: -60px;
                margin-left: -60px;
              }
              @include media-breakpoint-down(xxl) {
                margin-top: -55px;
                margin-left: -55px;
              }
              @include media-breakpoint-down(xl) {
                margin-top: -43.5px;
                margin-left: -43.5px;
              }
              .loupe-bt{
                background-image: url("/assets/img/zoom.svg");
                width: 172px;
                height: 172px;
                background-repeat: no-repeat;
                display: block;
                transition: opacity 0.2s linear;
                @include media-breakpoint-down(5xl) {
                  width: 160px;
                  height: 160px;
                  background-size: 160px;
                }
                @include media-breakpoint-down(4xl) {
                  width: 140px;
                  height: 140px;
                  background-size: 140px;
                }
                @include media-breakpoint-down(xxxl) {
                  width: 120px;
                  height: 120px;
                  background-size: 120px;
                }
                @include media-breakpoint-down(xxl) {
                  width: 110px;
                  height: 110px;
                  background-size: 110px;
                }
                @include media-breakpoint-down(xl) {
                  width: 87px;
                  height: 87px;
                  background-size: 87px;
                }
                &:hover{
                  opacity: 0.8;
                }
              }
            }
          }
          .swiper-button{
            margin-top: -43px;
            transition: opacity 0.2s linear;
            width: 86px;
            height: 87px;
            background-size: 86px;
            background-repeat: no-repeat;
            background-image: url("/assets/img/carousel-fleche.svg");
            @include media-breakpoint-down(5xl) {
              width: 76px;
              height: 77px;
              background-size: 76px;
              margin-top: -38px;
            }
            @include media-breakpoint-down(4xl) {
              width: 66px;
              height: 67px;
              background-size:66px;
              margin-top: -33px;
            }
            @include media-breakpoint-down(xxxl) {
              width: 56px;
              height:57px;
              background-size:56px;
              margin-top: -28px;
            }
            @include media-breakpoint-down(xxl) {
              width: 46px;
              height:47px;
              background-size:46px;
              margin-top: -23px;
            }
            @include media-breakpoint-down(xl) {
              width: 40px;
              height:41px;
              background-size:40px;
              margin-top: -20px;
            }
            @include media-breakpoint-down(sm) {
              width: 30px;
              height:30px;
              background-size:30px;
              margin-top: -15px;
              // bottom: -20px;
            }
            &:hover{
              opacity: 0.85;
            }
            &:after{
              content: "";
            }
            &.swiper-button-prev{
              left: 100px;
              transform: rotate(180deg);
              @include media-breakpoint-down(5xl) {
                left: 90px;
              }
              @include media-breakpoint-down(4xl) {
                left: 80px;
              }
              @include media-breakpoint-down(xxxl) {
                left: 70px;
              }
              @include media-breakpoint-down(xxl) {
                left: 60px;
              }
              @include media-breakpoint-down(xl) {
                left: 50px;
              }
              @include media-breakpoint-down(sm) {
                left: 20px;
              }
            }
            &.swiper-button-next{
              //background-color: red;
              right: 100px;
              @include media-breakpoint-down(5xl) {
                right: 90px;
              }
              @include media-breakpoint-down(4xl) {
                right: 80px;
              }
              @include media-breakpoint-down(xxxl) {
                right: 70px;
              }
              @include media-breakpoint-down(xxl) {
                right: 60px;
              }
              @include media-breakpoint-down(xl) {
                right: 50px;
              }
              @include media-breakpoint-down(sm) {
                right: 20px;
              }
            }
          }
        }
      }
      .rabais-title-box {
        background-color: $color-warning;
        display: inline-flex;
        padding: 10px 33px 10px 15px;
        border-bottom-right-radius: 30px;
        border-top-right-radius: 30px;
        @include media-breakpoint-down(xl) {
          margin-left: 30px;
        }
        h3{
          margin-bottom: 0;
          color: $color-inverse;
          //font-size: 20px;
          //line-height: 28px;
          font-size: 19px;
          line-height: 27px;
          @include font-black-1;
          @include media-breakpoint-down(5xl) {
            font-size: 18px;
            line-height: 26px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 17px;
            line-height: 25px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 16px;
            line-height: 24px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 16px;
            line-height: 24px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 15px;
            line-height: 23px;
          }
          @include media-breakpoint-down(sm) {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
      .fleche-plus-rabais-box{
        text-align: center;
        padding-top: 30px;
        padding-bottom: 90px;

      }
      .encadre-saviez-vous-box{
        background-color: $color-bg;
        width: 85%;
        margin: 0 auto;
       // padding:65px 100px 50px 100px;
        padding:65px 100px 50px 100px;
        border-radius: 40px;
        @include media-breakpoint-down(5xl) {
        }
        @include media-breakpoint-down(4xl) {
        }
        @include media-breakpoint-down(xxxl) {
        }
        @include media-breakpoint-down(xxl) {
          padding:60px 90px 45px 90px;
        }
        @include media-breakpoint-down(xl) {
          width: 85%;
          margin: 0 auto;
        }
        @include media-breakpoint-down(lg) {
          padding:45px 20px 30px 20px;
        }
        .title-box{
          text-align: center;
          padding-bottom: 45px;
          @include media-breakpoint-down(5xl) {
            padding-bottom: 40px;
          }
          @include media-breakpoint-down(4xl) {
            padding-bottom: 35px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-bottom: 30px;
          }
          @include media-breakpoint-down(xxl) {
            padding-bottom: 25px;
          }
          @include media-breakpoint-down(xl) {
            padding-bottom: 20px;
          }
          @include media-breakpoint-down(sm) {
            padding-bottom: 15px;
          }
          h4{
            font-size: 32px;
            line-height: 40px;
            @include font-reg-1;
            @include media-breakpoint-down(5xl) {
              font-size: 30px;
              line-height: 38px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 28px;
              line-height: 36px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 26px;
              line-height:34px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 24px;
              line-height: 32px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 22px;
              line-height: 30px;
            }
            @include media-breakpoint-down(sm) {
              font-size: 20px;
              line-height: 28px;
            }
          }
        }
        .content-box{
          text-align: center;
          p{
            @include font-light-1;
            font-size: 21px;
            line-height: 32px;
            @include media-breakpoint-down(5xl) {
              font-size: 20px;
              line-height: 31px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 19px;
              line-height: 30px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 18px;
              line-height: 29px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 17px;
              line-height: 28px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 16px;
              line-height: 27px;
            }
            @include media-breakpoint-down(sm) {
              font-size: 14px;
              line-height: 25px;
            }
          }
          .btn-link-box{
            text-align: center;
            padding-top: 30px;
          }
        }
      }
    }
    &.nav-scrolled{
      .filters-mobile-nav{
        top:($header-nav-height-mobile-stick - 50px) !important;
      }
      .filters-mobile-nav .offcanvas{
        top:($header-nav-height-mobile-stick) !important;
      }
      .filters-top-box{
        top:($header-nav-height-mobile-stick - 5px) !important;
      }
      .products-filters-box{
        @include media-breakpoint-down(xl) {
          max-height: calc(100vh - ($header-nav-height-sticky + $products-topfilter-height)) !important;
        }
      }

    }
    &.nav-scrolled-hide{
      .filters-top-box{
        top:0 !important;
      }
      .filters-mobile-nav{
        top:-50px !important;
      }
      .products-filters-box{
        top:$products-topfilter-height !important;
      }
      .filters-mobile-nav .offcanvas{
        top:82px !important;
      }
    }

  }

}//#dx-page-body
