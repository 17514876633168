
#dx-page-body {
  .modal{
    //Import specific modals
    //@import "modal_login";
    //&#login-modal{
    //}
  }
  .modal-general{
    //background-color: red;
    .modal-dialog{
      //background-color: green;
      .modal-content{
        background-color: $color-alt-1;
        text-align: center;
        .modal-body{
          padding:2rem 2rem 1rem 2rem;
          h1,h2,h3,h4{
            padding-left: 3rem;
            padding-right: 3rem;
          }
          .close-bt-box{
            position: absolute;
            right: 1rem;
            top:1rem;
            z-index: $z-bt-close;
            @include media-breakpoint-down(lg) {
            }
          }
        }
      }
    }
  }
  .modal-produits{
    //background-color: red;
    .modal-dialog{
      //background-color: green;
      margin: 0 auto;
      height: 100%;
      @include media-breakpoint-down(xl) {
        max-width: 96%;
        height: auto;
      }
      .modal-content{
        border: 0;
        border-radius: 0;
        height: 100%;
        @include media-breakpoint-down(xl) {
          height: auto;
        }
        .modal-body{
          padding: 0;
          height: 100%;
          @include media-breakpoint-down(xl) {
            height: auto;
          }
          .btn-close{
            //position: absolute;
            position: fixed;
            right: 1rem;
            top:1rem;
            z-index: $z-bt-close;
            background-image: none;
            font-size: 30px;
            color: #898887;
            @include media-breakpoint-down(xl) {
              font-size: 20px;
            }
          }
          //pour 2 versions:
          .title-slash-box{
            margin-left: 65px;
            @include media-breakpoint-down(xl) {
              margin-left: 45px;
            }
            @include media-breakpoint-down(md) {
              padding-bottom: 0;
            }
            &:before{

            }
            .part-1{
              margin-left: 37px;
              h4{
                letter-spacing: 1px;
                @include media-breakpoint-down(xxl) {
                  font-size: 19px;
                }
                @include media-breakpoint-down(md) {
                  font-size: 15px;
                }
              }
            }
          }
          .title-p{
            padding-left: 20px;
            padding-top: 13px;
            @include media-breakpoint-down(xl) {
              padding-left: 30px;
            }
            p{
              @include font-reg-1;
              letter-spacing: 0.8px;
            }
          }
          .mobile-only-block{
            display: none;
            @include media-breakpoint-down(xl) {
              display: block;
            }
          }
          .title-mobile-box{
            padding-left: 0px;
            padding-top: 30px;
            @include media-breakpoint-down(md) {
              padding-top: 60px;
            }
          }
          .container-fluid{
            padding-left: 0;
            padding-right: 0;
            height: 100%;
            .row{
              height: 100%;
              @include media-breakpoint-down(xl) {
                height: auto;
              }
            }
          }
          .carousel-mobilier{
            height: 100%;
            @include media-breakpoint-down(xl) {
              height: auto;
            }
          }
          .col-carousel-related-p{
            padding-top: 0;
            padding-left: 40px;
           // height: 100%;
            position: relative;
            @include media-breakpoint-down(5xl) {
              //padding-top: 230px;
            }
            @include media-breakpoint-down(4xl) {
            }
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xxl) {
              padding-top: 188px;
            }
            @include media-breakpoint-down(xl) {
              padding-top: 0px;
              padding-left: 30px;
              padding-right: 30px;
            }
            .carousel-related-products{
              position: sticky;
              top: 125px;
              padding-top:0px !important;
              padding-bottom:0px !important;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(xl) {
                padding-top: 0;
                position: relative;
                top:auto;
              }
            }
          }
          .col-products-related{
            //padding-top: 0px;
            padding-top: 120px;
            padding-left: 95px;
            padding-right: 95px;
            background-color: $color-bg;
            @include media-breakpoint-down(4xl) {
              padding-left: 50px;
              padding-right: 50px;
              @include media-breakpoint-down(xxl) {
                padding-left: 20px;
                padding-right: 20px;
              }
              @include media-breakpoint-down(xl) {
                //padding-top: 25px;
              }
            }
            .title-slash-box{
              @include media-breakpoint-down(xl) {
                display: none;
              }
            }
            .title-p{
              @include media-breakpoint-down(xl) {
                display: none;
              }
            }
            .product-count{
              padding-top: 35px;
              padding-bottom: 45px;
              text-align: center;
              @include media-breakpoint-down(xxl) {
                padding-top: 5px;
                padding-bottom: 25px;
              }
              @include media-breakpoint-down(md) {
                padding-top: 0px;
                padding-bottom: 5px;
              }
              span{
                text-transform: uppercase;
                @include font-bold-1;
                font-size: 15px;
                line-height: 22px;
                letter-spacing: 0.75px;

              }
            }
          }
        }
      }
    }
  }
}//#dx-page-body
