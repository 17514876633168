/* ------------------------------------------------------------------------------------
// STYLES FOOTER
/ ------------------------------------------------------------------------------------*/
#dx-page-body {
  footer{
    .pre-footer{
      padding-top: 115px;
      padding-bottom: 100px;
      @include media-breakpoint-down(5xl) {
        padding-top: 105px;
        padding-bottom: 85px;
      }
      @include media-breakpoint-down(4xl) {
        padding-top: 100px;
        padding-bottom: 70px;
      }
      @include media-breakpoint-down(xxxl) {
        padding-top: 90px;
        padding-bottom: 60px;
      }
      @include media-breakpoint-down(xxl) {
        padding-top: 80px;
        padding-bottom: 45px;
      }
      @include media-breakpoint-down(xl) {
        padding-top: 75px;
        padding-bottom: 40px;
      }
      @include media-breakpoint-down(sm) {
      }
      h3{
        font-size: 75px;
        line-height: 85px;
        @include font-title-med;
        color: $color-inverse;
        margin-bottom: 17px;
        @include media-breakpoint-down(5xl) {
          font-size: 70px;
          line-height: 75px;
        }
        @include media-breakpoint-down(4xl) {
          font-size: 60px;
          line-height: 65px;
        }
        @include media-breakpoint-down(xxxl) {
          font-size: 50px;
          line-height: 55px;
        }
        @include media-breakpoint-down(xxl) {
          font-size: 40px;
          line-height: 45px;
        }
        @include media-breakpoint-down(xl) {
          font-size: 33px;
          line-height: 38px;
        }
        @include media-breakpoint-down(sm) {
        }
      }
      h4{
        color: $color-inverse;
        font-size: 32px;
        line-height: 40px;
        text-decoration: none;
        font-style: normal;
        @include font-reg-1;
        margin-bottom: 85px;
        @include media-breakpoint-down(5xl) {
          font-size: 30px;
          line-height: 36px;
        }
        @include media-breakpoint-down(4xl) {
          font-size: 26px;
          line-height: 30px;
        }
        @include media-breakpoint-down(xxxl) {
          font-size: 22px;
          line-height: 26px;
        }
        @include media-breakpoint-down(xxl) {
          font-size: 18px;
          line-height: 22px;
        }
        @include media-breakpoint-down(xl) {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 75px;
        }
        @include media-breakpoint-down(sm) {
        }
        strong{
          font-size: 30px;
          @include media-breakpoint-down(5xl) {
            font-size: 28px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 24px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 20px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 16px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 12px;
          }
          @include media-breakpoint-down(sm) {
          }
        }
      }
      .qualite-box{
        text-align: center;
        min-width: 150px;
        max-width: 200px;
        margin: 0 auto;
        @include media-breakpoint-down(xxxl) {
          min-width: 120px;
        }
        @include media-breakpoint-down(xl) {
          margin-bottom: 40px;
        }
        .ico-box{
          min-height: 79px;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          margin-bottom: 30px;
          @include media-breakpoint-down(xxxl) {
            min-height: 60px;
          }
          @include media-breakpoint-down(xl) {
            margin-bottom: 5px;
          }
          img{
            @include media-breakpoint-down(xxxl) {
              max-height: 60px;
            }
            @include media-breakpoint-down(xl) {
              max-height: 47px;
            }
          }
        }
        .qualite-content-box{
          h5{
            font-style: italic!important;
            text-decoration: none;
            font-size: 34px;
            line-height: 40px;
            @include font-title-reg;
            color: $color-inverse;
            margin-bottom: 20px;
            @include media-breakpoint-down(5xl) {
              font-size: 30px;
              line-height: 33px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 28px;
              line-height: 31px;
              margin-bottom: 15px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 26px;
              line-height: 29px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 24px;
              line-height: 27px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 22px;
              line-height: 25px;
              margin-bottom: 10px;
            }
            @include media-breakpoint-down(sm) {
            }
          }
          p{
            font-size: 16px;
            line-height: 23px;
            letter-spacing: 0.8px;
            color: $color-inverse;
            @include media-breakpoint-down(5xl) {
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.7px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 13px;
              line-height: 17px;
              letter-spacing: 0.65px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 0.6px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 11px;
              line-height: 15px;
              letter-spacing: 0.55px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 10px;
              line-height: 14px;
              letter-spacing: 0.51px;
            }
            @include media-breakpoint-down(sm) {
            }
          }
        }
      }
    }
    .bg-footer{
      background-color: $color-bg-alt-2;
      color: $color-inverse;
      padding: 70px 0;
      @include media-breakpoint-down(xl) {
        padding: 40px 0;
      }
    }
    .footer-container{
      //padding: 0 85px;
      .logo-dx{
        img{
          @include media-breakpoint-down(xl) {
            max-width: 173px;
          }
        }
      }
      .sociaux-box{
        padding-top: 35px;
        @include media-breakpoint-down(xxl) {
          padding-top: 15px;
        }
        @include media-breakpoint-down(xl) {
          padding-top: 26px;
          padding-left: 5px;
        }
        ul{
          list-style-type: none;
          display: flex;
          margin: 0 0 0 -10px;
          padding: 0;
          li{
            padding: 0 10px;
            @include media-breakpoint-down(xxl) {
              padding: 0 5px;
            }
            @include media-breakpoint-down(xl) {
              padding: 0 8px;
            }
            .sociaux-link{
              img{
                transition: opacity 0.2s linear;
                @include media-breakpoint-down(xl) {
                  max-width: 27px;
                }
              }
              &:hover{
                img{
                  opacity: 0.7;
                }
              }
            }
          }
        }
      }
      .order-md-2, .order-md-3{
        .footer-menu{
          @include media-breakpoint-down(xl) {
            padding-top: 0px;
          }
          @include media-breakpoint-down(md) {
            padding-top: 80px;
          }
        }

      }
      .footer-menu{
        @include media-breakpoint-down(xxl) {
          padding-bottom: 40px;
        }
        @include media-breakpoint-down(md) {
         padding-top: 80px;
          padding-bottom: 0;
        }
        .menu-title-box{
          h6{
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.45px;
            @include font-black-1;
            padding-bottom: 20px;
            text-transform: uppercase;
            @include media-breakpoint-down(5xl) {
              font-size: 17px;
              line-height: 21px;
              padding-bottom: 18px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 16px;
              line-height: 20px;
              padding-bottom: 16px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 15px;
              line-height: 19px;
              padding-bottom: 14px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 14px;
              line-height: 18px;
              padding-bottom: 12px;
            }
            @include media-breakpoint-down(md) {
              font-size: 18px;
              line-height: 22px;
              letter-spacing: 0.45px;
              padding-bottom: 18px;
            }
          }
        }
        ul{
          list-style-type: none;
          margin: 0;
          padding: 0;
          li{
            padding: 5px 0;
            .footer-menu-link{
              font-size: 18px;
              line-height: 28px;
              color: white;
              transition: opacity 0.2s linear;
              @include media-breakpoint-down(5xl) {
                font-size: 17px;
                line-height: 27px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 16px;
                line-height: 26px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 15px;
                line-height: 25px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 14px;
                line-height: 24px;
              }
              @include media-breakpoint-down(md) {
                font-size: 18px;
                line-height: 28px;
              }
              &:hover{
                opacity: 0.7;
              }
            }
          }
        }
      }
      .adresse-box{
        a{
          color: white;
        }
        .adresse-title{
          h6{
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.45px;
            @include font-black-1;
            text-transform: uppercase;
            padding-bottom: 20px;
            @include media-breakpoint-down(5xl) {
              font-size: 17px;
              line-height: 21px;
              padding-bottom: 18px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 16px;
              line-height: 20px;
              padding-bottom: 16px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 15px;
              line-height: 19px;
              padding-bottom: 14px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 14px;
              line-height: 18px;
              padding-bottom: 12px;
            }
            @include media-breakpoint-down(md) {
              font-size: 18px;
              line-height: 22px;
              padding-bottom: 10px;
              margin-top: 80px;
            }
          }
        }
        address{
          font-size: 18px;
          line-height: 38px;
          margin-bottom: 45px;
          @include media-breakpoint-down(5xl) {
            font-size: 17px;
            line-height: 37px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 16px;
            line-height: 36px;
            margin-bottom: 40px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 15px;
            line-height: 35px;
            margin-bottom: 35px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 14px;
            line-height: 34px;
            margin-bottom: 30px;
          }
          @include media-breakpoint-down(md) {
            font-size: 18px;
            line-height: 38px;
            margin-bottom: 25px;
          }
        }
        .courriel-link{
          font-size: 18px;
          line-height: 38px;
          @include media-breakpoint-down(5xl) {
            font-size: 17px;
            line-height: 37px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 16px;
            line-height: 36px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 15px;
            line-height: 35px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 14px;
            line-height: 34px;
          }
          @include media-breakpoint-down(md) {
            font-size: 18px;
            line-height: 38px;
          }
        }
      }
      .zerofail-box{
        position: relative;
        &:hover{
          .ico-box{
            img{
              opacity: 0.6;
            }
          }
          .zerofail-txt-box{
            opacity: 0.7;
          }
        }
        .ico-box{
          text-align: center;
          img{
            transition: opacity 0.3s linear;
            @include media-breakpoint-down(md) {
              width: 58px;
              height: 58px;
            }
          }
        }
        .zerofail-txt-box{
          width: 440px;
          height: 100%;
          position: absolute;
          overflow: hidden;
          transform: rotate(-90deg);
          left: calc(50% - 218px);
          top: 260px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: opacity 0.3s linear;
          a{
            color: white;
          }
        }
      }
    }
    .footer-legal{
      background-color: $color-bg-alt-2;
      color: $color-inverse;
      padding: 45px 0 115px 0;
      @include media-breakpoint-down(xxl) {
        padding: 30px 0 55px 0;
      }
      @include media-breakpoint-down(xl) {
      }
      .col-rights{
        align-items: flex-start;
      }
      .legal-txt{
        font-size: 13px;
        line-height: 19px;
        letter-spacing: 0.65px;
        @include font-bold-1;
        @include media-breakpoint-down(md) {
          display: block;
          padding-bottom: 10px;
        }
      }
      .col-link{
        text-align: center;
        @include media-breakpoint-down(md) {
          text-align: left;
        }
      }
      .legal-link-box{
        font-size: 13px;
        line-height: 19px;
        letter-spacing: 0.65px;
        padding-top: 5px;
        a{
          color: white;
          transition: opacity 0.2s linear;
          @include media-breakpoint-down(md) {
           display: block;
          }
          &:hover{
            opacity: 0.7;
          }
        }
        .tiret{
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
      }
      .col-lang{
        text-align: right;
        @include media-breakpoint-down(md) {
          text-align: left;
        }
      }
      .switch-langue-box{
        font-size: 13px;
        line-height: 19px;
        letter-spacing: 0.65px;
        margin: 0 0 0 auto;
        color: white;

        @include media-breakpoint-down(md) {
          margin: 0 0 60px 0;
        }
        a{
          color: white;
          transition: opacity 0.2s linear;
          &:hover{
            opacity: 0.7;
          }
        }
        .active{
          @include font-bold-1;
        }
      }
    }
  }
}//#dx-page-body
