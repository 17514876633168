@charset "utf-8";

/* ------------------------------------------------------------------------------------
// PATHS

/ ------------------------------------------------------------------------------------*/
$imgFolder: "assets/img/";

// Exemple:  background-image: url(#{$imgFolder}bgGen.gif);


/* ------------------------------------------------------------------------------------
// COLORS
/ ------------------------------------------------------------------------------------*/
$color-bg:#F6F6F6;
$color-bg-alt-1:#F9FAF2;
$color-bg-alt-2:#474442;

$color-text-main:#474442;
$color-text-alt:#F0EFEF;
$color-inverse:white;

$color-primary:#E3EFED; // ou pas #D8EFEB ?
$color-secondary:#3CAF9D;
$color-warning:#C92D4C;
$color-warning-2:#B93C4F;
$color-light:#F6F6F6;

$color-alt-1:#000000;
$color-alt-2:#5389CF;
$color-alt-3:#D8EFEB;
$color-alt-4:#E2F3F0;
$color-alt-5:#484442;
$color-alt-6:#474442;
$color-alt-7:#464442;

$color-border-1:#E6E6E6;
$color-border-2:#ECECEC;
$color-border-3:#394543;

$gradient-bg-dark: linear-gradient(-180deg, #112B37 0%, #0F2732 50%, #000609 100%);
$shadow-box-general: 2px 2px 5px 0 rgba(0,0,0,0.15);
$shadow-box-strong: 0px 2px 4px 0 rgba(0,0,0,0.5);


/* ------------------------------------------------------------------------------------
// Z-INDEX
/ ------------------------------------------------------------------------------------*/
$z-bt-close:1000;
$z-filters-mobile-nav:1028;

//NE PLUS METTRE LES Z-INDEX ICI, Mettre dans _bootstrap4/_variables.scss (environ ligne 615)


/* ------------------------------------------------------------------------------------
// SIZES
/ ------------------------------------------------------------------------------------*/
//$refWidth:955px;

//Nav principale
$header-nav-height: 161px;
$header-nav-height-sticky: 72px;
$header-nav-height-mobile: 145px;
$header-nav-height-mobile-stick: 64px;
$products-topfilter-height: 134px;
//Nav secondaire
$header-nav2-height: 0px;
$header-nav2-height-mobile: 0px;
//Hauteur du header (addition des 2 navs)
$header-height: $header-nav-height + $header-nav2-height;
$header-height-mobile: $header-nav-height-mobile + $header-nav2-height-mobile;

// Point ou la nav collapse en burger
// (Il faut le changer aussi dans le html.
// C'est un point plus bas que le "navbar-expand-??"
// qui est mis dans le html, parce que ça cible down)
$navbar-collapsed-size: md;

//Breakpoint au-dessus du collapse
$navbar-collapsed-size-up: lg;

/* ------------------------------------------------------------------------------------
// Nav
/ ------------------------------------------------------------------------------------*/



$mobile-size:sm;
$tablet-size:xl;

//toggler - icone quand le menu est ouvert (le X!).
$navbar-toggler-opened-icon-bg-color:rgba($color-text-main,1);
$navbar-toggler-opened-icon-bg: url('data:image/svg+xml;charset=utf8,<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><defs><style>.cls-1{fill:none;stroke:#{$navbar-toggler-opened-icon-bg-color};stroke-linecap:square;stroke-linejoin:square;stroke-width:3px;}</style></defs><line class="cls-1" x1="1" y1="1" x2="19" y2="19"/><line class="cls-1" x1="19" y1="1" x2="1" y2="19"/></svg>');
// les 3 lignes sont dans bootstrap. Allez modifier la-bas. LIGNE 667 dans VARIABLE (il y a light et dark)



