#dx-page-body {
  &.page-produit-details{
    header{
      .bg-secondary{
        //background-color: transparent!important;
      }
    }
    .site-main { //main
      .main-content-wrapper {
        .content-area {
          //padding-top: 0; // hauteur de la nav - nav transparente pour carousel
          .section-main{
            > h1{
              display: none;
            }
          }
        }
      }
    }
    .carousel-img-mobile{
      display: none;
      @include media-breakpoint-down(lg) {
        display: block;
        background-color: white;
      }
      .product-single-img-box{
        img{
          width: 100%;
        }
      }
      .swiper-button{
        transition: opacity 0.2s linear;
        background-repeat: no-repeat;
        width: 14px;
        height:28px;
        background-size:14px;
        margin-top: -7px;
        background-image: url("/assets/img/fleche-carousel-produit-mobile.svg");
        &:hover{
          opacity: 0.85;
        }
        &:after{
          content: "";
        }
        &.swiper-button-prev{
          left: 50px;
          transform: rotate(180deg);
          @include media-breakpoint-down(sm) {
            left: 20px;
          }
        }
        &.swiper-button-next{
          //background-color: red;
          right: 50px;
          @include media-breakpoint-down(sm) {
            right: 20px;
          }
        }
      }
    }
    .section-details-img{
      background-image: url("/assets/img/bg-produit-single-grand.svg");
      background-repeat: repeat-y;
      background-size: 100%;
      padding-bottom: 160px;
      @include media-breakpoint-down(5xl) {
        background-image: url("/assets/img/bg-produit-single.svg");
        padding-bottom: 130px;
      }
      @include media-breakpoint-down(4xl) {
        padding-bottom: 30px;
      }
      @include media-breakpoint-down(xxl) {
        background-position: left -100px top;
        background-color: $color-primary;
      }
      @include media-breakpoint-down(lg) {
        background-image: none;
        position: relative;
        padding-bottom: 40px;
      }
      .container-fleche{
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
      .zerofail-box{
        position: absolute;
        right: 20px;
        top: calc($header-nav-height + 40px);
        @include media-breakpoint-down(xxl) {
          top: calc($header-nav-height + 20px);
        }
        @include media-breakpoint-down(lg) {
          top:45px;
          right: 10px;
        }
        .ico-box{
          text-align: center;
          img{
            @include media-breakpoint-down(xxl) {
              width: 58px;
              height: 58px;
            }
            @include media-breakpoint-down(md) {
              width: 58px;
              height: 58px;
            }
          }
        }
        .zerofail-txt-box{
          width: 440px;
          height: 100%;
          position: absolute;
          overflow: hidden;
          transform: rotate(-90deg);
          left: calc(50% - 218px);
          top: 260px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include media-breakpoint-down(xxl) {
            top: 240px;
          }
          @include media-breakpoint-down(lg) {
            top: 180px;
            width: 432px;
          }
          a{
            color: $color-text-main;
          }
          .zerofail-txt{
            @include media-breakpoint-down(lg) {
              font-size: 11px;
              line-height: 14px;
              letter-spacing: 0.38px;
            }
          }
        }
      }
      .produit-details-img-box{
        //width: 100%;
        //height: 100px;
        display: flex;
        //flex-direction: row;
        @include media-breakpoint-down(lg) {
          display: none;
        }
        .thumbs-box{
          //display: flex;
          flex-direction: column;
          display: flex;
          justify-content: space-between;
          padding-right: 40px;
          .thumb-item{
            position: relative;
            &.thumb-active{
              &:after{
                content: "";
                background-image: url("/assets/img/fleche-small-droit.svg");
                position: absolute;
                display: block;
                width: 12px;
                height: 24px;
                top:calc(50% - 12px);
                right: -25px;
                background-repeat: no-repeat;
                @include media-breakpoint-down(5xl) {
                  width: 11px;
                  height: 23px;
                  top:calc(50% - 11.5px);
                  background-size: 11px;
                }
                @include media-breakpoint-down(4xl) {
                  width: 10px;
                  height: 22px;
                  top:calc(50% - 11px);
                  background-size: 10px;
                }
                @include media-breakpoint-down(xxxl) {
                  width: 8px;
                  height: 20px;
                  top:calc(50% - 10px);
                  background-size: 8px;
                }
                @include media-breakpoint-down(xxl) {
                  width: 6px;
                  height: 18px;
                  top:calc(50% - 9px);
                  background-size: 6px;
                }
                @include media-breakpoint-down(xl) {
                }
                @include media-breakpoint-down(lg) {
                }
                @include media-breakpoint-down(md) {
                }
                @include media-breakpoint-down(sm) {
                }
              }
              img{
                opacity: 0.7;
              }
            }
            a{
              display: block;
              height:100%;
              img{
                transition: opacity 0.2s linear;
              }
              &:after{
                transition: opacity 0.5s linear;
                opacity: 0;
                content: "";
                background-image: url("/assets/img/fleche-small-droit.svg");
                width: 12px;
                height: 24px;
                display: block;
                position: absolute;
                top:calc(50% - 12px);
                background-repeat: no-repeat;
                right: -25px;
                @include media-breakpoint-down(5xl) {
                  width: 11px;
                  height: 23px;
                  top:calc(50% - 11.5px);
                  background-size: 11px;
                }
                @include media-breakpoint-down(4xl) {
                  width: 10px;
                  height: 22px;
                  top:calc(50% - 11px);
                  background-size: 10px;
                }
                @include media-breakpoint-down(xxxl) {
                  width: 8px;
                  height: 20px;
                  top:calc(50% - 10px);
                  background-size: 8px;
                }
                @include media-breakpoint-down(xxl) {
                  width: 6px;
                  height: 18px;
                  top:calc(50% - 9px);
                  background-size: 6px;
                }
              }
              &:hover{
                img{
                  opacity: 0.7;
                }
                &:after{
                  opacity: 1;
                }
              }
            }

          }
        }
        .big-img-box{
          //height: 100%;
        }
      }
      .desc-content-box{
        .title-box{
          padding-bottom: 45px;
          @include media-breakpoint-down(5xl) {
            padding-bottom: 40px;
          }
          @include media-breakpoint-down(4xl) {
            padding-bottom: 35px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-bottom: 30px;
          }
          @include media-breakpoint-down(xxl) {
            padding-bottom: 20px;
          }
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(lg) {
            padding-right: 60px;
            padding-top: 38px;
            padding-bottom: 15px;
          }
          @include media-breakpoint-down(md) {
          }
          @include media-breakpoint-down(sm) {
          }
          h2{
            font-size: 75px;
            line-height: 85px;
            font-size: 70px;
            line-height: 80px;
            @include font-title-reg;
            font-style: italic;
            @include media-breakpoint-down(5xl) {
              //background-color: red;
              font-size: 65px;
              line-height: 75px;
            }
            @include media-breakpoint-down(4xl) {
              //background-color: yellow;
              font-size: 60px;
              line-height: 70px;
            }
            @include media-breakpoint-down(xxxl) {
              //background-color: green;
              font-size: 55px;
              line-height: 65px;
            }
            @include media-breakpoint-down(xxl) {
              //background-color: pink;
              font-size: 50px;
              line-height: 60px;
            }
            @include media-breakpoint-down(xl) {
              //background-color: mediumseagreen;
              font-size: 50px;
              line-height: 60px;
            }
            @include media-breakpoint-down(lg) {
              font-size: 35px;
              line-height: 45px;
              //background-color: hotpink;
            }
            @include media-breakpoint-down(md) {
              //background-color: salmon;
            }
            @include media-breakpoint-down(sm) {
              //background-color: greenyellow;
            }
          }
        }
        .form-field-box{
          &.couleur-box{
            padding-bottom: 50px;
            @include media-breakpoint-down(5xl) {
              padding-bottom: 45px;
            }
            @include media-breakpoint-down(4xl) {
              padding-bottom: 40px;
            }
            @include media-breakpoint-down(xxxl) {
              padding-bottom: 30px;
            }
            @include media-breakpoint-down(xxl) {
              padding-bottom: 20px;
            }
            @include media-breakpoint-down(xl) {
            }
            @include media-breakpoint-down(lg) {
              padding-bottom: 26px;
            }
            .couleur-title-box{
              padding-bottom: 10px;
              @include media-breakpoint-down(lg) {
                padding-bottom: 0px;
              }
            }
            .form-check{
              input[type='radio']:hover:before {
                box-shadow: 0px 0px 0px 12px rgba(60, 175, 157, 0.2);
                border-radius: 50%;
                opacity: 1;
              }
              input[type='radio']:before {
                content: '';
                display: block;
                width: 70%;
                height: 70%;
                margin: 13.5%; /* Keeping margin only 10% */
                padding: 10%; /* Increase the inner area for pushing the border out of the circle */
                border-radius: 50%;
                transition: 0.3s; /* Move your transition to here */
              }
            }
          }
          &.quantite-box{
            .quantite-title-box{
              //padding-top: 20px;
              padding-bottom: 1px;
            }
            .qty-container{
              display: flex;
            }
            .qty_box{
              display: flex;

              .qty_num{
                background-color: white;
                padding: 4px 0;
                border: none;
                width: 75px;
                text-align: center;
                font-size: 18px;
                line-height: 21px;
                letter-spacing: 0.9px;
                color: $color-text-main;
                margin-bottom: 0;
                border-radius: 0;
                @include media-breakpoint-down(4xl) {
                  height: 68px;
                  width: 45px;
                }
                @include media-breakpoint-down(lg) {
                  height: 43px;
                  width: 53px;
                  font-size: 12px;
                  line-height: 12px;
                  letter-spacing: 0.51px;
                }
              }
              .qty_adjust{
                border: none;
                background: #F9F9F9;
                height: 78px;
                padding: 0 20px;
                @include media-breakpoint-down(4xl) {
                  height: 68px;
                  padding: 0 10px;
                }
                @include media-breakpoint-down(lg) {
                  height: 43px;
                  padding: 0 10px;
                }
                svg{
                  width: 9px;
                  height: 17px;
                }
              }
            }
            .form-check{
              padding-left: 0;
              margin: 0!important;
              input[type="number"]{
                width: 176px;
                height: 78px;
                text-align: center;
                @include media-breakpoint-down(5xl) {
                  width: 166px;
                  height: 78px;
                }
                @include media-breakpoint-down(4xl) {
                  width: 106px;
                  height: 76px;
                }
                @include media-breakpoint-down(xxxl) {
                  width: 106px;
                  height: 74px;
                }
                @include media-breakpoint-down(xxl) {
                  width: 56px;
                  height: 70px;
                }
                @include media-breakpoint-down(xl) {
                }
                @include media-breakpoint-down(lg) {
                }
                @include media-breakpoint-down(md) {
                }
                @include media-breakpoint-down(sm) {
                }
              }
            }
          }
          label.title-txt{
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.45px;
            @include font-reg-1;
            text-transform: uppercase;
            @include media-breakpoint-down(5xl) {
              font-size: 17px;
              line-height: 21px;
              letter-spacing: 0.4px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 16px;
              line-height: 20px;
              letter-spacing: 0.35px;
              min-width: 250px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 15px;
              line-height: 19px;
              letter-spacing: 0.3px;
              min-width: 200px;
            }
            @include media-breakpoint-down(xxl) {
              min-width: 100px;
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.25px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 13px;
              line-height: 17px;
              letter-spacing: 0.25px;
            }
            @include media-breakpoint-down(lg) {
              padding-bottom: 0;
            }
            @include media-breakpoint-down(md) {
            }
            @include media-breakpoint-down(sm) {
            }
          }
          .form-check{
            &.form-check-inline{
              display: inline-flex;
              align-items: center;
              margin-right: 5px;
              @include media-breakpoint-down(lg) {
                margin-right: 1px;
              }
            }
            .form-check-input{
              width: 38px;
              height: 38px;
              @include media-breakpoint-down(5xl) {
                width: 34px;
                height: 34px;
              }
              @include media-breakpoint-down(4xl) {
                width: 30px;
                height: 30px;
              }
              @include media-breakpoint-down(xxxl) {
                width: 30px;
                height: 30px;
              }
              @include media-breakpoint-down(xxl) {
                width: 24px;
                height: 24px;
              }
              @include media-breakpoint-down(xl) {
              }
              @include media-breakpoint-down(lg) {
              }
              @include media-breakpoint-down(md) {
              }
              @include media-breakpoint-down(sm) {
              }
            }
            .form-check-input:checked[type=radio] {
              //background-color: green;
            }
            .form-check-label{
              display: none;
            }
          }
        }
        .en-bref-liste-box{
          padding-top: 65px;
          @include media-breakpoint-down(5xl) {
            padding-top: 55px;
          }
          @include media-breakpoint-down(4xl) {
            padding-top: 50px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-top: 45px;
          }
          @include media-breakpoint-down(xxl) {
            padding-top: 40px;
          }
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(lg) {
            padding-top: 30px;
          }
          @include media-breakpoint-down(md) {
          }
          @include media-breakpoint-down(sm) {
          }
          .s-title-box{
            padding-bottom: 10px;
            @include media-breakpoint-down(lg) {
              padding-bottom: 0px;
            }
            .s-title-txt{
              font-size: 18px;
              line-height: 22px;
              letter-spacing: 0.45px;
              @include font-reg-1;
              text-transform: uppercase;
              margin-bottom: 20px;
              @include media-breakpoint-down(5xl) {
                font-size: 17px;
                line-height: 21px;
                letter-spacing: 0.4px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.35px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 15px;
                line-height: 19px;
                letter-spacing: 0.3px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.25px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 13px;
                line-height: 17px;
                letter-spacing: 0.25px;
              }
              @include media-breakpoint-down(lg) {
              }
              @include media-breakpoint-down(md) {
              }
              @include media-breakpoint-down(sm) {
              }
            }
          }
          .bref-liste{
            list-style-type: none;
            margin: 0;
            padding: 0;
            .bref-item{
              font-size: 16px;
              line-height: 26px;
              display: flex;
              padding: 5px 0;
              @include media-breakpoint-down(5xl) {
                font-size: 15px;
                line-height: 25px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 14px;
                line-height: 24px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 13px;
                line-height: 23px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 12px;
                line-height: 22px;
               // padding: 2px 0;
              }
              @include media-breakpoint-down(xl) {
              }
              @include media-breakpoint-down(lg) {
              }
              @include media-breakpoint-down(md) {
              }
              @include media-breakpoint-down(sm) {
              }
              .ico-box{
                min-width: 27px;
                width: 47px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding-right: 20px;
              }

            }
          }
        }
      }
    }
    .details-box{
      padding-top: 145px;
      padding-bottom: 100px;
      @include media-breakpoint-down(5xl) {
        padding-top: 130px;
        padding-bottom: 85px;
      }
      @include media-breakpoint-down(4xl) {
        padding-top: 100px;
        padding-bottom: 55px;
      }
      @include media-breakpoint-down(xxxl) {
        padding-top: 80px;
        padding-bottom: 35px;
      }
      @include media-breakpoint-down(xxl) {
        padding-top: 80px;
        padding-bottom: 35px;
      }
      @include media-breakpoint-down(xl) {
      }
      @include media-breakpoint-down(lg) {
        padding-top: 52px;
        padding-bottom: 29px;
      }
      @include media-breakpoint-down(md) {
      }
      @include media-breakpoint-down(sm) {
      }
      h3{
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 40px;
        @include font-reg-1;
        text-transform: uppercase;
        @include media-breakpoint-down(5xl) {
          font-size: 30px;
          line-height: 38px;
          margin-bottom: 38px;
        }
        @include media-breakpoint-down(4xl) {
          font-size: 28px;
          line-height: 36px;
          margin-bottom: 36px;
        }
        @include media-breakpoint-down(xxxl) {
          font-size: 26px;
          line-height: 34px;
          margin-bottom: 34px;
        }
        @include media-breakpoint-down(xxl) {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 32px;
        }
        @include media-breakpoint-down(xl) {
        }
        @include media-breakpoint-down(lg) {
          font-size: 18px;
          line-height: 19px;
          margin-bottom: 32px;
        }
        @include media-breakpoint-down(md) {
        }
        @include media-breakpoint-down(sm) {
        }
      }
      .desc-content-box{
        padding-bottom: 115px;
        @include media-breakpoint-down(5xl) {
          padding-bottom: 100px;
        }
        @include media-breakpoint-down(4xl) {
          padding-bottom: 85px;
        }
        @include media-breakpoint-down(xxxl) {
          padding-bottom: 70px;
        }
        @include media-breakpoint-down(xxl) {
          padding-bottom: 70px;
        }
        @include media-breakpoint-down(xl) {
        }
        @include media-breakpoint-down(lg) {
          padding-bottom: 23px;
        }
        @include media-breakpoint-down(md) {
        }
        @include media-breakpoint-down(sm) {
        }
        &.table-box{
          max-width:400px;
          .table-intro-mesure-box{
            display: flex;
            justify-content: space-between;
            padding-right: 20px;
            @include media-breakpoint-down(xxl) {
              padding-right: 0;
            }
            @include media-breakpoint-down(lg) {
              align-items: baseline;
              padding-right: 102px;
            }
            @media screen and (max-width: 600px) and (min-width: 437px) {
              padding-right: 102px!important;
            }
            @media screen and (max-width: 436px) and (min-width: 415px) {
              padding-right: 85px!important;
            }
            @media screen and (max-width: 414px) and (min-width: 400px) {
              padding-right: 75px!important;
            }
            @include media-breakpoint-down(sm) {
              padding-right: 60px;
            }

            h4{
            }
            .tabs-type-mesure{
              color: $color-secondary;
              .tab-txt{
                a{
                  color: $color-secondary;
                  @include media-breakpoint-down(lg) {
                    font-size: 13px;
                    line-height: 15px;
                  }
                  &.active{
                    @include font-bold-1;
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
        h4{
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 30px;
          @include font-reg-1;
          @include media-breakpoint-down(5xl) {
            font-size: 17px;
            line-height: 21px;
            margin-bottom: 28px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 26px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 15px;
            line-height: 19px;
            margin-bottom: 24px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 20px;
          }
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(lg) {
            font-size: 13px;
            line-height: 15px;
            margin-bottom: 16px;
          }
          @include media-breakpoint-down(md) {
          }
          @include media-breakpoint-down(sm) {
          }
        }
        p{
          font-size: 16px;
          line-height: 26px;
          padding-right: 50px;
          @include font-reg-1;
          @include media-breakpoint-down(5xl) {
            font-size: 15px;
            line-height: 25px;
            padding-right: 48px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 14px;
            line-height: 24px;
            padding-right: 46px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 13px;
            line-height: 22px;
            padding-right: 44px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 12px;
            line-height: 21px;
            padding-right: 36px;
          }
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(lg) {
          }
          @include media-breakpoint-down(md) {
          }
          @include media-breakpoint-down(sm) {
          }
        }
        .table{
          font-size: 16px;
          line-height: 26px;
          @include font-reg-1;
          @include media-breakpoint-down(5xl) {
            font-size: 15px;
            line-height: 25px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 14px;
            line-height: 24px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 13px;
            line-height: 22px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 12px;
            line-height: 21px;
          }
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(lg) {
          }
          @include media-breakpoint-down(md) {
          }
          @include media-breakpoint-down(sm) {
          }
          .colonne-1{
            width:315px;
            padding-right: 20px;
            @include media-breakpoint-down(lg) {
              width:190px;
            }
          }
          .colonne-2{
            width: 85px;
            @include media-breakpoint-down(lg) {
              width:120px;
            }
          }
          .colonne-1-details{
            width: 250px;

          }
          .colonne-2-details{
            width: 150px;
          }

          &.mesure-table{
            display:none;
            &.active{
              display:table;
            }
          }
        }
        .bts-download-box{
          display: flex;
          justify-content: space-between;
          padding-top: 40px;
          max-width: 650px;
          @include media-breakpoint-down(5xl) {
            padding-top: 36px;
          }
          @include media-breakpoint-down(4xl) {
            padding-top: 32px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-top: 24px;
          }
          @include media-breakpoint-down(xxl) {
            padding-top: 18px;
          }
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(lg) {
            justify-content:flex-start;
            flex-direction: column;
            align-items: flex-start;
            padding-top: 8px;
          }
          @include media-breakpoint-down(md) {
          }
          @include media-breakpoint-down(sm) {
          }
          .btn{
            margin-bottom: 10px;
          }
        }
      }
    }
    .section-carousel-autres-produits{
      .title-slash-box{
        margin-top: 100px;
        margin-bottom: 50px;
        @include media-breakpoint-down(xl) {
          margin-top: 40px;
          margin-bottom:5px;
        }
        @include media-breakpoint-down(lg) {
          margin-top: 35px;
          margin-bottom:5px;
        }
      }
      .carousel-mobilier{// dans son propre fichier car réutiliser en accueil

      }
    }
  }
}//#dx-page-body
