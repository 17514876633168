#dx-page-body {
  .section-modules-phase-2{
    //overflow-x: hidden;
  }
  .menu-mobile-nav{
    display: none;
    @include media-breakpoint-down(xl) {
      //display: block;
    }
  }
  .container-modules-p2{
    @include media-breakpoint-down(xl) {
      padding-left: 0!important;
      padding-right: 0!important;
      overflow: hidden;
    }
    p{
      text-align: justify;
    }
    h3{
      text-transform: uppercase;
    }
    ol{
      padding-left: 20px;
      ol,ul{
        padding-top: 15px;
        padding-bottom: 15px;
      }
      ul{
        list-style-type: disc;
      }
    }
    ul{
      padding-left: 15px;
      ol,ul{
        padding-top: 15px;
        padding-bottom: 15px;
      }
      ul{
        list-style-type: disc;
      }
    }
    ol, ul{
      padding-top: 20px;
      padding-bottom: 20px;
      @include font-light-1;
      font-size:18px;
      line-height: 26px;
      letter-spacing: 0.6px;
      @include media-breakpoint-down(5xl) {
        font-size: 17px;
        line-height: 21px;
        letter-spacing: 0.4px;
      }
      @include media-breakpoint-down(4xl) {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.35px;
      }
      @include media-breakpoint-down(xxxl) {
        font-size: 15px;
        line-height: 19px;
        letter-spacing: 0.3px;
      }
      @include media-breakpoint-down(xxl) {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.25px;
      }
      @include media-breakpoint-down(lg) {
        font-size:14px;
        line-height: 22px;
        letter-spacing: 0.3px;
      }
      .liste-bold{
        &::marker{
          @include font-bold-1;
        }
      }
    }
  }
  .col-g{
    @include media-breakpoint-down(xl) {
      display: none;
    }
  }
  .col-d{
    padding: 0;
    @include media-breakpoint-down(xl) {
      padding-left: 30px;
    }
    //margin-top: 100px;
    @include media-breakpoint-down(xl) {
      //margin-top: 0px;
    }
  }
  .bande-titre{
    padding: 30px 0;
    h2{
      font-size: 21px;
      line-height: 22px;
      letter-spacing: 0.54px;
      @include font-bold-1;
      text-transform: uppercase;
      margin-bottom: 0;
      padding-top: 4px;
    }
  }
  .bande-titre-2{
    padding: 50px 0;
    @include media-breakpoint-down(xl) {
      padding: 20px 0;
    }
    h2{
      font-size: 32px;
      line-height: 40px;
      @include font-reg-1;
      margin-bottom: 0;
      text-transform: uppercase;
      @include media-breakpoint-down(5xl) {
        font-size: 30px;
        line-height: 38px;
      }
      @include media-breakpoint-down(4xl) {
        font-size: 28px;
        line-height: 36px;
      }
      @include media-breakpoint-down(xxxl) {
        font-size: 26px;
        line-height: 34px;
      }
      @include media-breakpoint-down(xxl) {
        font-size: 25px;
        line-height: 33px;
      }
      @include media-breakpoint-down(xl) {
        font-size: 23px;
        line-height: 31px;
      }
      @include media-breakpoint-down(lg) {
      }
      @include media-breakpoint-down(md) {
      }
      @include media-breakpoint-down(sm) {
      }
    }
  }
  .col-menu-nav{
    @include media-breakpoint-down(xl) {
      display: none;
    }
  }
  .nav-container{
    //background-color: red;
    height: calc(100vh - 7rem);
    //overflow-y: auto;
    position: sticky;
    top:$header-nav-height-sticky;
    transition: top 0.2s;
    width: 100%;
    z-index: 10;

    nav{
      .accordeon-nav{
        .accordion-item{
          .accordion-header{
            .accordion-button{
              @include font-bold-1;
              font-size: 18px;
              line-height: 22px;
              color: $color-text-main;
              padding-left: 0;
              letter-spacing: 0.45px;
              @include font-bold-1;
              text-transform: uppercase;
              @include media-breakpoint-down(5xl) {
                font-size: 17px;
                line-height: 21px;
                letter-spacing: 0.4px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.35px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 15px;
                line-height: 19px;
                letter-spacing: 0.3px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.25px;
              }
              &:focus{
                box-shadow: none;
              }
              .accordion-title-box{
                padding-right: 20px;
              }
            }
          }
          .accordion-collapse{
            .accordion-body{
              padding-top: 10px;
              padding-bottom: 10px;
              .nav{
                .nav-link{
                  color: $color-text-main;
                  @include font-light-1;
                  font-size: 18px;
                  line-height: 22px;
                  margin-left: 0;
                  padding-left: 0;
                  letter-spacing: 1.45px;
                  @include font-light-1;
                  @include media-breakpoint-down(5xl) {
                    font-size: 17px;
                    line-height: 21px;
                    letter-spacing: 0.4px;
                  }
                  @include media-breakpoint-down(4xl) {
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.35px;
                  }
                  @include media-breakpoint-down(xxxl) {
                    font-size: 15px;
                    line-height: 19px;
                    letter-spacing: 0.3px;
                  }
                  @include media-breakpoint-down(xxl) {
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: 0.25px;
                  }
                  &:hover{
                    color: $color-secondary;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .padding-bg{
    padding: 40px 70px 40px 100px;
    position: relative;
    height: 100%;
    @include media-breakpoint-down(xl) {
      padding: 20px 30px;
    }
    &:after{
      width: 26px;
      background-color: white;
      height: 100%;
      min-height: 100%;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      margin-right: -26px;
      z-index: -1;
    }
  }
  .navbar-scrollspy{

  }
  .item-box{

  }
  .item-box + .item-box{
    margin-top: 40px;
  }
  .texte-simple-box{
    margin-bottom:20px;
    h2{
      text-transform: uppercase;
      margin-bottom: 30px;
    }
    h2 + p{
      margin-top: -15px;
    }
  }
  .accordeon-gris{
    //margin-left: 30px;
    @include media-breakpoint-down(xl) {
      //margin-left: 5px;
    }
    .accordion-item{
      background-color: white;
      .accordion-header{
        @extend h2;
        .accordion-button{
          background-color: $color-alt-7;
          color: $color-inverse;
          border-radius: 50px;
          font-weight: 700;
          text-transform: uppercase;
          padding: 20px 40px;
          &:after{
            background-image: url("/assets/img/accordeon-fleche-bas.svg");
            width: 15px;
            height: 28px;
            background-size: 15px;
            background-position: center;
          }
          .accordion-title-box{
            padding-right: 20px;
            @include media-breakpoint-down(xl) {
              font-size:14px;
              line-height: 18px;
              letter-spacing: 0.25px;
            }
          }
        }
      }
      .accordion-body{
        padding: 0;
        @include media-breakpoint-down(xl) {
        }
      }
    }
  }
  .module-alert{
    border: 4px solid transparent;
    margin: 50px auto;
    border-radius:24vh;
    padding: 20px;
    text-align: center;
    max-width: 1000px;
    @include media-breakpoint-down(5xl) {
      margin: 50px auto;
    }
    @include media-breakpoint-down(4xl) {
      margin: 50px auto;
    }
    @include media-breakpoint-down(xxxl) {
      margin: 50px auto;
    }
    @include media-breakpoint-down(xxl) {
      margin: 30px auto;
    }
    &.alert-color-red{
      color: $color-warning-2;
      border-color:$color-warning-2;
      a{
        color: $color-warning-2;
        text-decoration: underline;
        &:hover{
          color: $color-text-main;
        }
      }
    }
    &.alert-color-turquoise{
      color: $color-secondary;
      border-color:$color-secondary;
      a{
        color: $color-secondary;
        text-decoration: underline;
        &:hover{
          color: $color-text-main;
        }
      }
    }
    h4{
      font-size: 24px;
      line-height: 28px;
      @include font-bold-1;
      padding-left: 85px;
      padding-right:85px;
      padding-top: 20px;
      @include media-breakpoint-down(xxl) {
        padding-left: 85px;
        padding-right: 85px;
      }
      @include media-breakpoint-down(sm) {
        //padding-top: 20px;
        padding-left: 65px;
        padding-right: 65px;
      }
    }
    p{
      padding-right: 50px;
      padding-left: 50px;
      text-align: center;
      @include media-breakpoint-down(xxl) {
        padding-right:25px;
        padding-left: 25px;
      }
    }
  }
  .module-alert-icon{
    border: 4px solid transparent;
    margin: 37px 0 37px 25px;
    //border-radius:24vh;
    border-radius: 0 24vh 24vh 0;
    padding:80px 120px 50px 50px;
    text-align: left;
    position: relative;
    @include media-breakpoint-down(5xl) {
    }
    @include media-breakpoint-down(4xl) {
    }
    @include media-breakpoint-down(xxxl) {
    }
    @include media-breakpoint-down(xxl) {
    }
    @include media-breakpoint-down(sm) {
      padding:80px 30px 50px 10px;
    }
    &:before{

    }
    &.alert-color-red{
      color: $color-warning-2;
      border-color:$color-warning-2;
      a{
        color: $color-warning-2;
        text-decoration: underline;
        &:hover{
          color: $color-text-main;
        }
      }
    }
    &.alert-color-turquoise{
      //color: $color-secondary;
      border-color:$color-secondary;
      a{
        color: $color-secondary;
        text-decoration: underline;
        &:hover{
          color: $color-text-main;
        }
      }
    }
    .icone-box{
      background-color: white;
      border-radius: 100%;
      width: 96px;
      height: 96px;
      display: flex;
      position: absolute;
      top: calc(50% - 48px);
      left: -48px;
      border: 4px solid $color-secondary;
      justify-content: center;
      align-items: center;
      @include media-breakpoint-down(xl) {
        width: 80px;
        height: 80px;
        top: calc(50% - 40px);
        left: -40px;
      }
      img{
        margin: 0 auto;
      }
    }
    .text-box{
      h4{
        font-size: 24px;
        line-height: 28px;
        @include font-bold-1;
      }
      p{
        padding-right: 50px;
        padding-left: 50px;
        font-size: 16px;
        line-height: 26px;
        @include font-light-1;
        @include media-breakpoint-down(5xl) {
          //background-color: red;
        }
        @include media-breakpoint-down(4xl) {
          //background-color: yellow;
          padding-right: 10px;
        }
        @include media-breakpoint-down(xxxl) {
          //background-color: green;
        }
        @include media-breakpoint-down(xxl) {
          //background-color: pink;
        }
        @include media-breakpoint-down(xl) {
          //background-color: blue;
        }
        @include media-breakpoint-down(lg) {
          //background-color: deeppink;
        }
        @include media-breakpoint-down(md) {
          //background-color: violet;
        }
        @include media-breakpoint-down(sm) {
          //background-color: grey;
          padding-right:20px;
        }
        &:first-child{
          @include media-breakpoint-down(sm) {
            //background-color: RED;
            padding-right: 80px;
          }
        }
        &:last-child{
          @include media-breakpoint-down(sm) {
           // background-color: blue;
            padding-right: 80px;
          }
        }
      }
    }
  }
  .module-alert-icon-2{
    border: 4px solid transparent;
    margin: 37px 0 37px 0;
    //border-radius:24vh;
    border-radius: 0 24vh 24vh 0;
    padding:80px 250px 50px 75px;
    text-align: left;
    position: relative;
    @include media-breakpoint-down(5xl) {
    }
    @include media-breakpoint-down(4xl) {
    }
    @include media-breakpoint-down(xxxl) {
      padding:80px 180px 50px 75px;
    }
    @include media-breakpoint-down(xxl) {
      padding:80px 180px 50px 15px;
    }
    @include media-breakpoint-down(xl) {
      padding:80px 180px 50px 0px;
    }
    @include media-breakpoint-down(sm) {
      padding:80px 30px 50px 0px;
    }
    &.alert-color-red{
      color: $color-warning-2;
      border-color:$color-warning-2;
      a{
        color: $color-warning-2;
        text-decoration: underline;
        &:hover{
          color: $color-text-main;
        }
      }
    }
    &.alert-color-turquoise{
      //color: $color-secondary;
      border-color:$color-secondary;
      a{
        color: $color-secondary;
        text-decoration: underline;
        &:hover{
          color: $color-text-main;
        }
      }
    }
    .icone-box{
      background-color: white;
      border-radius: 100%;
      width: 125px;
      height: 125px;
      display: flex;
      position: absolute;
      top: calc(50% - 62.5px);
      right: 85px;
      border: 4px solid $color-secondary;
      justify-content: center;
      align-items: center;
      @include media-breakpoint-down(xxxl) {
        right: 55px;
      }
      @include media-breakpoint-down(xl) {
        right: 50px;
        width: 80px;
        height: 80px;
        top: calc(50% - 40px);
      }
      @include media-breakpoint-down(sm) {
        right: 15px;
      }
      img{
        margin: 0 auto;
      }
    }
    .text-box{
      h4{
        font-size: 24px;
        line-height: 28px;
        @include font-bold-1;
      }
      p{
        padding-right: 50px;
        padding-left: 50px;
        font-size: 16px;
        line-height: 26px;
        @include font-light-1;
        @include media-breakpoint-down(5xl) {
        }
        @include media-breakpoint-down(4xl) {
          padding-right: 10px;
        }
        @include media-breakpoint-down(xxxl) {
        }
        @include media-breakpoint-down(xxl) {
        }
        @include media-breakpoint-down(xl) {
        }
        @include media-breakpoint-down(lg) {
        }
        @include media-breakpoint-down(md) {
          padding-left: 20px;
        }
        @include media-breakpoint-down(sm) {
          padding-right:70px;
        }
        &:first-child{
          @include media-breakpoint-down(sm) {
            padding-right: 80px;
          }
        }
        &:last-child{
          @include media-breakpoint-down(sm) {
            padding-right: 80px;
          }
        }
      }
    }
  }
  .module-encadre-pale{
    background-color: #EFF7F5;
    margin: 50px 0;
    .content-box{
      padding: 65px 55px 55px 55px;
      @include media-breakpoint-down(xl) {
        padding:45px 35px 35px 35px;
      }
      .title-box{
        margin-left: 70px;
        @include media-breakpoint-down(4xl) {
          margin-left: 63px;
        }
        @include media-breakpoint-down(xxxl) {
          margin-left: 53px;
        }
        @include media-breakpoint-down(xxl) {
          margin-left:43px;
        }
        @include media-breakpoint-down(xl) {
          margin-left: 33px;
        }
        h3{
          @include font-black-1;
          font-size: 28px;
          line-height: 32px;
          margin-bottom: 35px;
          @include media-breakpoint-down(4xl) {
            font-size: 25px;
            line-height: 29px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 24px;
            line-height: 28px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 22px;
            line-height: 26px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 20px;
          }
          .h3-suite{
            font-size: 42px;
            line-height: 42px;
            @include font-title-reg;
            font-style: italic;
            text-transform: none;
            display: block;
            margin-left: -73px;
            @include media-breakpoint-down(4xl) {
              font-size:38px;
              line-height: 38px;
              margin-left: -63px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 35px;
              line-height: 35px;
              margin-left: -53px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 32px;
              line-height: 32px;
              margin-left: -43px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 30px;
              line-height: 30px;
              margin-left: -33px;
            }
          }
        }
      }
      p{
        text-align: justify;
      }
      .h3-2{
        text-transform: uppercase;
        margin-bottom: 30px;
      }
      .col-pad-1{
        padding-right: 15px;
        @include media-breakpoint-down(md) {
          padding-right: 0;
        }
      }
      .col-pad-2{
        padding-left: 15px;
        @include media-breakpoint-down(md) {
          padding-left: 0;
        }
      }
    }
  }
  .module-texte-simple{
    .col-pad-1{
      padding-right: 15px;
      @include media-breakpoint-down(md) {
        padding-right: 0;
      }
      .content-box{
        padding: 15px 0;
      }
    }
    .col-pad-2{
      padding-left: 15px;
      @include media-breakpoint-down(md) {
        padding-left: 0;
      }
      .content-box{
        padding: 15px 0;
      }
    }
    .content-box{
      padding: 40px 0;
      ol, ul{

      }
      p{
        text-align: justify;
        &:last-of-type{
          margin-bottom: 0;
        }
      }
      &.text-center{
        p{
          text-align: center;
          max-width: 60%;
          margin-left: auto;
          margin-right: auto;
          @include media-breakpoint-down(md) {
            max-width: 80%;
          }
        }
      }
    }
    &.align-accordeon{
      padding-left: 35px;
    }
    &.avec-colonnes{
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
  .module-texte-avec-bande-dx{
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    .dx-bg-box{
      &.bg-1 {
        width: 193px;
        height: calc(100% - 80px);
        top: 45px;
        left: 12px;
        @include media-breakpoint-down(5xl) {
          //width: 424px;
          //height: 246px;
        }
        @include media-breakpoint-down(4xl) {
          width: 105px;
          //height: 220px;
        }
        @include media-breakpoint-down(xxxl) {
          width: 90px;
        }
        @include media-breakpoint-down(xxl) {
          width: 85px;
        }
        @include media-breakpoint-down(xl) {
          //width: 100px;
          //height: 118px;
        }
        @include media-breakpoint-down(md) {
          width: 45px;
        }
      }
      &.bg-2 {
        width: 193px;
        height: calc(100% - 80px);
        top: 45px;
        right: 5px;
        @include media-breakpoint-down(5xl) {
          //width: 230px;
        }
        @include media-breakpoint-down(4xl) {
          width: 105px;
        }
        @include media-breakpoint-down(xxxl) {
          width: 90px;
        }
        @include media-breakpoint-down(xxl) {
          width: 85px;
        }
        @include media-breakpoint-down(md) {
          width: 45px;
        }
      }
    }
    .content-box{
      padding-top: 33px;
      padding-bottom: 33px;
      padding-left: 300px;
      padding-right: 300px;
      @include media-breakpoint-down(4xl) {
        padding-left: 200px;
        padding-right: 200px;
      }
      @include media-breakpoint-down(xxxl) {
        padding-left: 180px;
        padding-right: 180px;
      }
      @include media-breakpoint-down(xxl) {
        // width: 180px;
      }
      @include media-breakpoint-down(xl) {
        // width: 80px;
      }
      @include media-breakpoint-down(md) {
        padding-left: 80px;
        padding-right: 80px;
      }
      .bulle-turquoise-bg{
        background-color: #DCF1ED;
        padding: 35px 20px;
        border-radius:24vh;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @include media-breakpoint-down(md) {
          flex-direction: column;
          align-items: center;
        }
        .fleche-box{
          align-self: center;
          padding: 20px;
        }
        .item-box{
          display: flex;
          justify-content: center;
          flex-direction: column;
          max-width: 36%;
          width: 36%;
          align-items: center;
          @include media-breakpoint-down(md) {
            max-width: 80%;
            width: 80%;
          }
          .img-box{
            margin-bottom: 17px;
          }
          .txt-box{
            h4{
              text-align: center;
              @include font-black-1;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: 0.7px;
              text-transform: uppercase;
              margin-bottom: 2px;
            }
            p{
              text-align: center;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: 0.7px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
    &.version-bulle{
      .content-box{
        @include media-breakpoint-down(xxxl) {
          padding-left: 130px;
          padding-right: 130px;
        }
        @include media-breakpoint-down(xxl) {

        }
        @include media-breakpoint-down(xl) {
          padding-left: 180px;
          padding-right: 180px;
        }
        @include media-breakpoint-down(md) {
          padding-left: 80px;
          padding-right: 80px;
        }
        h3{
          text-align: center;
          margin-bottom: 37px;
        }
        .bt-box{
          text-align: center;
          padding-top: 30px;
        }
      }
    }
  }
  .slider-bout-rond{
    padding-top:70px;
    padding-bottom: 70px;
    @include media-breakpoint-down(xl) {
      padding-top:40px;
      padding-bottom: 40px;
    }
    .demo-slide-box{
      &:hover{
        .loupe-box{
         // opacity: 1;
        }
      }
      .bg-img-box{
        background-size: cover;
        width: 100%;
        min-height: 488px;
        display: block;
        border-top-right-radius: 24vh;
        border-bottom-right-radius: 24vh;
        background-position: center;
        @include media-breakpoint-down(5xl) {
          min-height: 450px;
        }
        @include media-breakpoint-down(4xl) {
          min-height: 400px;
        }
        @include media-breakpoint-down(xxxl) {
          min-height: 360px;
        }
        @include media-breakpoint-down(xxl) {
          min-height: 300px;
        }
        @include media-breakpoint-down(xl) {
          min-height:280px;
        }
        @include media-breakpoint-down(lg) {
          min-height:260px;
        }
        @include media-breakpoint-down(md) {
          min-height:233px;
        }
        img{
          width: 100%;
          display: none;
        }
      }
      .loupe-box{
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -86px;
        margin-left: -86px;
        opacity: 0;
        transition: opacity 0.2s linear;
        @include media-breakpoint-down(5xl) {
          margin-top: -80px;
          margin-left: -80px;
        }
        @include media-breakpoint-down(4xl) {
          margin-top: -70px;
          margin-left: -70px;
        }
        @include media-breakpoint-down(xxxl) {
          margin-top: -60px;
          margin-left: -60px;
        }
        @include media-breakpoint-down(xxl) {
          margin-top: -55px;
          margin-left: -55px;
        }
        @include media-breakpoint-down(xl) {
          margin-top: -43.5px;
          margin-left: -43.5px;
        }
        .loupe-bt{
          background-image: url("/assets/img/zoom.svg");
          width: 172px;
          height: 172px;
          background-repeat: no-repeat;
          display: block;
          transition: opacity 0.2s linear;
          @include media-breakpoint-down(5xl) {
            width: 160px;
            height: 160px;
            background-size: 160px;
          }
          @include media-breakpoint-down(4xl) {
            width: 140px;
            height: 140px;
            background-size: 140px;
          }
          @include media-breakpoint-down(xxxl) {
            width: 120px;
            height: 120px;
            background-size: 120px;
          }
          @include media-breakpoint-down(xxl) {
            width: 110px;
            height: 110px;
            background-size: 110px;
          }
          @include media-breakpoint-down(xl) {
            width: 87px;
            height: 87px;
            background-size: 87px;
          }
          &:hover{
            opacity: 0.8;
          }
        }
      }
    }
    .swipper-bouton-bg{
      background-color: white;
      position: absolute;
      top: 50%;
      z-index: 2;
      width: 160px;
      height: 87px;
      right: 98px;
      margin-top: -43px;
      border-radius: 24vh;
      @include media-breakpoint-down(5xl) {
        width: 152px;
        height: 78px;
        right: 87px;
        margin-top: -38px;
      }
      @include media-breakpoint-down(4xl) {
        width:131px;
        height: 67px;
        right: 77px;
        margin-top: -33px;
      }
      @include media-breakpoint-down(xxxl) {
        width:112px;
        height: 58px;
        right: 67px;
        margin-top: -28px;
      }
      @include media-breakpoint-down(xxl) {
        width:89px;
        height: 49px;
        right: 59px;
        margin-top: -24px;
      }
      @include media-breakpoint-down(xl) {
        width:78px;
        height: 42px;
        right: 48px;
        margin-top: -20.5px;
      }
      @include media-breakpoint-down(sm) {
        width:62px;
        height:32px;
        right:19px;
        margin-top: -16px;
      }
    }
    .swiper-button{
      margin-top: -43px;
      transition: background-image 0.2s linear;
      width: 86px;
      height: 87px;
      background-size: 86px;
      background-repeat: no-repeat;
      background-image: url("/assets/img/carousel-fleche.svg");
      @include media-breakpoint-down(5xl) {
        width: 76px;
        height: 77px;
        background-size: 76px;
        margin-top: -38px;
      }
      @include media-breakpoint-down(4xl) {
        width: 66px;
        height: 67px;
        background-size:66px;
        margin-top: -33px;
      }
      @include media-breakpoint-down(xxxl) {
        width: 56px;
        height:57px;
        background-size:56px;
        margin-top: -28px;
      }
      @include media-breakpoint-down(xxl) {
        width: 46px;
        height:47px;
        background-size:46px;
        margin-top: -23px;
      }
      @include media-breakpoint-down(xl) {
        width: 40px;
        height:41px;
        background-size:40px;
        margin-top: -20px;
      }
      @include media-breakpoint-down(sm) {
        width: 30px;
        height:30px;
        background-size:30px;
        margin-top: -15px;
        // bottom: -20px;
      }
      &:hover{
        //opacity: 0.85;
        background-image: url("/assets/img/carousel-fleche-hover.svg");
      }
      &:after{
        content: "";
      }
      &.swiper-button-prev{
        right: 170px;
        left: auto;
        transform: rotate(180deg);
        @include media-breakpoint-down(5xl) {
          right: 160px;
        }
        @include media-breakpoint-down(4xl) {
          right: 140px;
        }
        @include media-breakpoint-down(xxxl) {
          right: 120px;
        }
        @include media-breakpoint-down(xxl) {
          right: 100px;
        }
        @include media-breakpoint-down(xl) {
          right: 85px;
        }
        @include media-breakpoint-down(sm) {
          right: 50px;
        }
      }
      &.swiper-button-next{
        //background-color: red;
        right: 100px;
        @include media-breakpoint-down(5xl) {
          right: 90px;
        }
        @include media-breakpoint-down(4xl) {
          right: 80px;
        }
        @include media-breakpoint-down(xxxl) {
          right: 70px;
        }
        @include media-breakpoint-down(xxl) {
          right: 60px;
        }
        @include media-breakpoint-down(xl) {
          right: 50px;
        }
        @include media-breakpoint-down(sm) {
          right: 20px;
        }
      }
    }
  }
  .module-grande-bulle{
    margin-top: 70px;
    margin-bottom: 70px;
    background-color: #DCEEEB;
    border-top-right-radius: 24vh;
    border-bottom-right-radius: 24vh;
    padding:150px 0;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(4xl) {
    }
    @include media-breakpoint-down(xxxl) {
      flex-direction: column;
      padding:50px 0 100px 0;
    }
    @include media-breakpoint-down(xl) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    .img-box{
      width: 49%;
      text-align: right;
      padding: 0 15px 0 45px;
      @include media-breakpoint-down(4xl) {
        width: 39%;
      }
      @include media-breakpoint-down(xxxl) {
        width: 80%;
        padding: 0 105px 40px 15px;
        text-align: center;
      }
      img{
        margin: 0 auto;
      }
    }
    .text-box{
      width: 51%;
      //background-color: red;
      padding-left: 100px;
      padding-right: 100px;
      @include media-breakpoint-down(4xl) {
        width: 61%;
      }
      @include media-breakpoint-down(xxxl) {
        width: 90%;
        padding-left: 80px;
        padding-right: 120px;
      }
      @include media-breakpoint-down(xxl) {
        padding-left: 70px;
        padding-right: 120px;
      }
      @include media-breakpoint-down(xl) {
        padding-left: 40px;
        padding-right: 120px;
      }
      p{
        font-size: 21px;
        line-height: 25px;
        @include media-breakpoint-down(4xl) {
          font-size: 19px;
          line-height: 24px;
          letter-spacing: 0.9px;
        }
        @include media-breakpoint-down(xxxl) {
          font-size: 19px;
          line-height: 24px;
          letter-spacing: 0.9px;
        }
        @include media-breakpoint-down(xxl) {
          font-size: 17px;
          line-height: 22px;
          letter-spacing: 0.7px;
        }
        @include media-breakpoint-down(xl) {
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.7px;
        }
        &:last-of-type{
          margin-bottom: 0;
        }
      }
      .title-box{
        h3{
          @include font-black-1;
          font-size: 28px;
          line-height: 32px;
          margin-bottom: 35px;
          @include media-breakpoint-down(4xl) {
            font-size: 25px;
            line-height: 29px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 24px;
            line-height: 28px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 22px;
            line-height: 26px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 20px;
          }
          .h3-suite{
            font-size: 42px;
            line-height: 42px;
            @include font-title-reg;
            font-style: italic;
            text-transform: none;
            display: block;
            margin-left: -73px;
            @include media-breakpoint-down(4xl) {
              font-size:38px;
              line-height: 38px;
              margin-left: -63px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 35px;
              line-height: 35px;
              margin-left: -53px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 32px;
              line-height: 32px;
              margin-left: -43px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 30px;
              line-height: 30px;
              margin-left: -33px;
            }
          }
        }
      }
    }
  }
}//#dx-page-body
