.objet-anim{
  position: absolute;
  &.point-plein{
    background-image: url("/assets/img/fff_az_rong_plein.svg");
    width: 99px;
    height: 99px;
    background-size: 99px;
    display: block;
    @include media-breakpoint-down(5xl) {
      width: 89px;
      height: 89px;
      background-size: 89px;
    }
    @include media-breakpoint-down(4xl) {
      width: 79px;
      height: 79px;
      background-size: 79px;
    }
    @include media-breakpoint-down(xxxl) {
      width: 69px;
      height: 69px;
      background-size: 69px;
    }
    @include media-breakpoint-down(xxl) {
      width: 59px;
      height: 59px;
      background-size: 59px;
    }
    @include media-breakpoint-down(xl) {
      width: 49px;
      height: 49px;
      background-size: 49px;
    }
  }
  &.point-vide-sm{
    background-image: url("/assets/img/fff-petit-rond-vide.svg");
    width: 42px;
    height: 42px;
    background-size: 42px;
    display: block;
    @include media-breakpoint-down(5xl) {
      width: 38px;
      height: 38px;
      background-size: 38px;
    }
    @include media-breakpoint-down(4xl) {
      width: 34px;
      height: 34px;
      background-size: 34px;
    }
    @include media-breakpoint-down(xxxl) {
      width: 30px;
      height: 30px;
      background-size: 30px;
    }
    @include media-breakpoint-down(xxl) {
      width: 26px;
      height: 26px;
      background-size: 26px;
    }
    @include media-breakpoint-down(xl) {
      width: 20px;
      height: 20px;
      background-size: 20px;
    }
  }
  &.point-plus{
    background-image: url("/assets/img/fff-plus.svg");
    width: 50px;
    height: 50px;
    background-size: 50px;
    display: block;
    @include media-breakpoint-down(5xl) {
      width: 45px;
      height: 45px;
      background-size:45px;
    }
    @include media-breakpoint-down(4xl) {
      width: 40px;
      height: 40px;
      background-size:40px;
    }
    @include media-breakpoint-down(xxxl) {
      width: 35px;
      height: 35px;
      background-size:35px;
    }
    @include media-breakpoint-down(xxl) {
      width: 30px;
      height: 30px;
      background-size:30px;
    }
    @include media-breakpoint-down(xl) {
      width: 25px;
      height: 25px;
      background-size:25px;
    }
  }
  &.point-vide-big{
    background-image: url("/assets/img/fff-grand-rond-vide.svg");
    width: 119px;
    height: 119px;
    background-size: 119px;
    display: block;
    @include media-breakpoint-down(5xl) {
      width: 109px;
      height: 109px;
      background-size: 109px;
    }
    @include media-breakpoint-down(4xl) {
      width: 99px;
      height: 99px;
      background-size: 99px;
    }
    @include media-breakpoint-down(xxxl) {
      width: 80px;
      height: 80px;
      background-size: 80px;
    }
    @include media-breakpoint-down(xxl) {
      width: 65px;
      height: 65px;
      background-size: 65px;
    }
    @include media-breakpoint-down(xl) {
      width: 50px;
      height: 50px;
      background-size: 50px;
    }
  }
}