#dx-page-body {
  &.page-recrutement-formulaire{
    background-color: white;
    //overflow: hidden;
    header{
      .bg-secondary{
        //background-color: transparent!important;
      }
    }
    .site-main { //main
      .main-content-wrapper {
        .content-area {
          //padding-top: 0; // hauteur de la nav - nav transparente pour carousel
          .section-main{
            > h1{
              display: none;
            }
          }
        }
      }
    }
    //Pour tous GÉNÉRAL
    .title-normal{
      h2,h3,h4{
        display: block;
        text-transform: none;
        @include font-title-med;
        font-style: italic;
        margin-left: 150px;
        font-size: 80px;
        line-height: 80px;
        @include media-breakpoint-down(5xl) {
          font-size: 76px;
          line-height: 76px;
        }
        @include media-breakpoint-down(4xl) {
          font-size: 74px;
          line-height: 74px;
        }
        @include media-breakpoint-down(xxxl) {
          //font-size: 82px;
          //line-height: 52px;
        }
        @include media-breakpoint-down(xxl) {
          font-size: 72px;
          line-height: 72px;
        }
        @include media-breakpoint-down(xl) {
          font-size: 65px;
          line-height: 65px;
          margin-left: 55px;
        }
        span{
          margin-left: 0px;
          @include media-breakpoint-down(md) {
            margin-left: 20px;
          }
        }
      }
    }
    .content-box{
      position: relative;
      margin-top: 20px;
      margin-left: 100px;
      margin-bottom: 80px;
      @include media-breakpoint-down(4xl) {
      }
      @include media-breakpoint-down(xxl) {
      }
      @include media-breakpoint-down(xl) {
        margin-left: 35px;
        margin-bottom: 25px;
      }
      @include media-breakpoint-down(md) {
      }
      &:before{
        background-image: url("/assets/img/slash.svg");
        background-repeat: no-repeat;
        background-size: 60px;
        width: 60px;
        height: 97px;
        content: "";
        display: block;
        position: absolute;
        top: -40px;
        left:-60px;
        @include media-breakpoint-down(5xl) {
          //left:-70px;
          //top: -50px;
        }
        @include media-breakpoint-down(4xl) {
          background-size: 60px;
          width: 60px;
          height: 97px;
          //left:-80px;
        }
        @include media-breakpoint-down(xxxl) {
          background-size: 71px;
          width: 71px;
          height: 87px;
        }
        @include media-breakpoint-down(xxl) {
          background-size: 63px;
          width: 63px;
          height: 77px;
        }
        @include media-breakpoint-down(xl) {
          background-size: 44px;
          width: 44px;
          height: 54px;
          top: -40px;
          left:-38px;
        }
        @include media-breakpoint-down(lg) {
          background-size: 31px;
          width: 31px;
          height: 38px;
          //bottom: 35px;
        }
        @include media-breakpoint-down(md) {
          //top: -20px;
          //left:-50px;
        }
      }
      p.p-title{
        text-transform: uppercase;
        @include font-light-1;
        font-size: 21px;
        line-height: 32px;
        letter-spacing: 1.2px;
        @include media-breakpoint-down(5xl) {
        }
        @include media-breakpoint-down(4xl) {
          font-size: 20px;
          line-height: 29px;
          letter-spacing: 0.99px;
        }
        @include media-breakpoint-down(xxxl) {
          font-size: 18px;
          line-height: 27px;
          letter-spacing: 0.89px;
        }
        @include media-breakpoint-down(xl) {
          font-size: 15px;
          line-height: 22px;
          letter-spacing: 0.75px;
        }
        @include media-breakpoint-down(lg) {
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0;
        }
        @include media-breakpoint-down(md) {
        }
        @include media-breakpoint-down(sm) {
        }
        strong{
          @include font-bold-1;
        }
      }
      &.title-mobile-only{
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }


    .formulaire-application{
      padding-top: 50px;
      padding-bottom: 50px;
      label{
        @include font-bold-1;
        font-size: 20px;
        line-height: 28px;
        text-transform: uppercase;
      }
      .text-area-box, .checkbox-box, .email-box, .texte-box, .files-box, .select-multiple-box{
        margin-bottom: 50px;
      }
      .text-area-box{
        textarea{
          height: 187px;
          padding: 10px 30px;
          color: $color-text-main;
        }
      }
      .select-multiple-box{
        .ts-control{
          background-color: white;
          border-radius: 40px;
          min-height: 70px;
          padding-left: 30px;
          padding-bottom: 4px;
          border: 2px solid #ACACAD;
          .item{
            padding: 8px 16px;
            border-radius: 40px;
            margin: 0 3px 3px 0;
          }
        }
      }
      .checkbox-box{
        .check-form-title{
          @include font-bold-1;
          font-size: 20px;
          line-height: 28px;
          text-transform: uppercase;
          margin-bottom: 0.5rem;
          padding-bottom: 10px;
        }
        label{
          @include font-light-1;
          font-size: 16px;
          line-height: 20px;
          text-transform: none;
        }
        .form-check-input[type=checkbox]{
          border-radius: 0;
        }
      }
      .bts-box{
        a{
          display: block;
          text-align: left;
          margin-top: 15px;
        }
      }
      .bulle-envoi-ok{
        background-color: $color-primary;
        padding:20px 40px;
        border-radius: 100px;
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        .txt-box{
          color: $color-secondary;
          padding-left: 30px;
          span{
            text-transform: uppercase;
            strong{
              display: block;
            }
          }
        }
      }
    }


    &.nav-scrolled {
      .section-menu-fun{
        top:$header-nav-height-sticky;
      }
    }
    &.nav-scrolled-hide {
      .section-menu-fun{
        top:-1px;
      }
    }
    &.nav-scrolled-second-sub-show {
      .section-menu-fun{
        opacity:1;
      }
    }
  }
}//#dx-page-body
