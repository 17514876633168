#dx-page-body {
  &.page-accueil-recrutement, &.page-recrutement-postes-listing, &.page-recrutement-poste-details, &.page-recrutement-formulaire{
    header{
      .container-nav{
        #navbar-main{
          background-color: $color-bg-alt-2!important;
          .offcanvas{
            .offcanvas-body{
              .navbar-nav{
                .nav-item{
                  .dropdown-menu{
                    background-color: $color-bg-alt-2!important;
                  }
                }
              }
            }
          }
        }
      }
    }



      //Section héro des sous-pages et formulaires
    .section-hero{
      .hero-bg{
        position: relative;
        background-image: url("/assets/img/recrutement/accueil-hero-bg.jpg");
        background-size: cover;
        background-position: top;
        min-height: 355px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include media-breakpoint-down(5xl) {
          min-height: 325px;
        }
        @include media-breakpoint-down(4xl) {
          min-height: 260px;
        }
        @include media-breakpoint-down(xxxl) {
          min-height: 200px;
        }
        @include media-breakpoint-down(xxl) {
        }
        @include media-breakpoint-down(xl) {
        }
        @include media-breakpoint-down(lg) {
        }
      }
      .hero-title-box{
        margin-top: -225px;
        @include media-breakpoint-down(5xl) {
          margin-top: -205px;
        }
        @include media-breakpoint-down(4xl) {
          margin-top: -155px;
        }
        @include media-breakpoint-down(xxxl) {
          margin-top: -105px;
        }
        @include media-breakpoint-down(xxl) {
        }
        @include media-breakpoint-down(xl) {
        }
        @include media-breakpoint-down(lg) {
        }
        .col-bt{
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
        .btn-fleche-back-link{
          position: relative;
          z-index: 2;
          margin-top: 30px;
        }
        .col-title{
          position: relative;
          &:after{
            @include media-breakpoint-down(sm) {
              background-color: white;
              height: 100%;
              width: 12%;
              content: "";
              display: block;
              position: absolute;
              right: -20px;
              top: 0;
            }
          }
        }
        .boite-d{
          margin: 0;
          position: relative;
          z-index: 2;
          padding:70px 80px;
          border-bottom-right-radius: 380px;
          border-top-right-radius: 380px;
          @include media-breakpoint-down(5xl) {
          }
          @include media-breakpoint-down(4xl) {
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(lg) {
          }
          @include media-breakpoint-down(md) {
          }
          @include media-breakpoint-down(sm) {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            padding:40px 50px;
          }
          .title-normal{
            h2,h3,h4{
              text-transform: none;
              span{
                margin-left: 30px;
              }
            }
          }
          .content-box{
            position: relative;
            margin-top: 80px;
            margin-left: 165px;
            margin-bottom: 35px;
            @include media-breakpoint-down(4xl) {
              margin-left: 105px;
            }
            @include media-breakpoint-down(xxl) {
              margin-top: 80px;
            }
            @include media-breakpoint-down(md) {
              margin-top: 50px;
              margin-bottom: 45px;
            }
            @include media-breakpoint-down(sm) {
              margin-left: 25px;
            }
            &:before{
              top: -45px;
              left:-55px;
              @include media-breakpoint-down(5xl) {
                left:-70px;
                top: -50px;
              }
              @include media-breakpoint-down(4xl) {
                height: 97px;
                left:-80px;
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
              }
              @include media-breakpoint-down(xl) {
                top: -30px;
                left:-60px;
              }
              @include media-breakpoint-down(lg) {
                bottom: 35px;
              }
              @include media-breakpoint-down(md) {
                top: -20px;
                left:-50px;
              }
            }
          }
          .btn-link-box{
            margin-top: 40px;
            .btn-link{
              &.opened{
                line-height: 0;
                font-size: 0;
              }
            }
          }
        }
      }
    }
    .section-hero-grand{
      position: relative;
      margin-bottom: 140px;
      .hero-bg{
        position: relative;
        //background-image: url("/assets/img/recrutement/accueil-hero-bg.jpg");
        background-size: cover;
        background-position: top;
        min-height: 750px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include media-breakpoint-down(5xl) {
          min-height: 640px;
        }
        @include media-breakpoint-down(4xl) {
          min-height: 540px;
        }
        @include media-breakpoint-down(xxxl) {
          min-height: 480px;
        }
        @include media-breakpoint-down(xxl) {
        }
        @include media-breakpoint-down(xl) {
        }
        @include media-breakpoint-down(md) {
          min-height: 360px;
        }
      }
      .dx-bg-box {
        width: 1018px;
        height: 265px;
        top: 752px;
        left: 0;
        @include media-breakpoint-down(5xl) {
          top: 645px;
          width: 894px;
          height: 265px;
        }
        @include media-breakpoint-down(4xl) {
          width: 642px;
          height: 237px;
          top: 545px;
        }
        @include media-breakpoint-down(xxxl) {
          width: 594px;
          height: 268px;
          top: 485px;
        }
        @include media-breakpoint-down(xxl) {
          width: 503px;
          height: 254px;
        }
        @include media-breakpoint-down(xl) {
          width: 398px;
          height: 134px;
          top: 482px;
        }
        @include media-breakpoint-down(md) {
          width: 305px;
          height: 244px;
          top: 362px;
        }
        @include media-breakpoint-down(sm) {
          width: 256px;
          height: 330px;
        }
      }
      .hero-title-box{
        margin-top: -455px;
        overflow: hidden;
        @include media-breakpoint-down(5xl) {
          margin-top: -430px;
        }
        @include media-breakpoint-down(4xl) {
          margin-top: -360px;
        }
        @include media-breakpoint-down(xxxl) {
          margin-top: -305px;
        }
        @include media-breakpoint-down(xxl) {
        }
        @include media-breakpoint-down(xl) {
        }
        @include media-breakpoint-down(md) {
          margin-top: -180px;
        }
        .col-bt{
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
        .btn-fleche-back-link{
          position: relative;
          z-index: 2;
          margin-top: 30px;
        }
        .col-title{
          position: relative;
          &:after{
            @include media-breakpoint-down(sm) {
              background-color: white;
              height: 100%;
              width: 12%;
              content: "";
              display: block;
              position: absolute;
              right: -20px;
              top: 0;
            }
          }
        }
        .boite-d{
          margin: 0;
          position: relative;
          z-index: 2;
          padding:115px 155px 45px 155px;
          width: 115%;
          border-bottom-right-radius: 380px;
          border-top-right-radius: 380px;
          @include media-breakpoint-down(5xl) {
            padding:100px 140px 52px 140px;
          }
          @include media-breakpoint-down(4xl) {
            padding:90px 130px 50px 130px;
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xxl) {
            padding:80px 120px;
          }
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(lg) {
            padding:70px 100px;
          }
          @include media-breakpoint-down(md) {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            padding:50px 90px 20px 90px;
          }
          @include media-breakpoint-down(sm) {
            padding:30px 50px 50px 77px;
          }
          .title-normal{
            max-width: 1300px;
            h2,h3,h4{
              text-transform: none;
              max-width: 1400px;
              //font-size: 104px;
              line-height: 104px;
              margin-left: 0;
              @include media-breakpoint-down(5xl) {
               // font-size: 98px;
                line-height: 98px;
              }
              @include media-breakpoint-down(4xl) {
                //font-size: 92px;
                line-height: 92px;
              }
              @include media-breakpoint-down(xxxl) {
                //font-size: 82px;
                line-height: 82px;
              }
              @include media-breakpoint-down(xxl) {
                //font-size: 72px;
                line-height: 72px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 52px;
                line-height: 52px;
              }
              @include media-breakpoint-down(lg) {
                font-size: 50px;
                line-height: 50px;
              }
              @include media-breakpoint-down(md) {
                font-size: 40px;
                line-height: 40px;
              }
              span{
                margin-left: 30px;
              }
            }
          }
          .content-box{
            position: relative;
            margin-top: 80px;
            margin-left: 650px;
            margin-bottom: 35px;
            @include media-breakpoint-down(4xl) {
              margin-left: 565px;
            }
            @include media-breakpoint-down(xxxl) {
              margin-left: 465px;
            }
            @include media-breakpoint-down(xxl) {
              margin-left: 360px;
            }
            @include media-breakpoint-down(xl) {
              margin-left: 315px;
            }
            @include media-breakpoint-down(lg) {
              margin-left: 250px;
            }
            @include media-breakpoint-down(md) {
              margin-left: 20px;
              margin-top: 35px;
            }
            @include media-breakpoint-down(sm) {
            }
            &:before{
              top: -45px;
              left:-55px;
              @include media-breakpoint-down(5xl) {
                left:-70px;
                top: -50px;
              }
              @include media-breakpoint-down(4xl) {
                height: 97px;
                left:-80px;
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
              }
              @include media-breakpoint-down(xl) {
                top: -30px;
                left:-40px;
              }
              @include media-breakpoint-down(lg) {
                bottom: 35px;
              }
              @include media-breakpoint-down(md) {
                top: -20px;
                left:-35px;
              }
            }
            .p-title{
              max-width: 400px;
            }
            .infos-job-mobile{
              display: none;
              @include media-breakpoint-down(md) {
                display: block;
              }
              .ico-txt{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                .ico-box{
                  margin-right: 8px;
                  width: 19px;
                  text-align: center;
                  img{
                    margin: 0 auto;
                  }
                }
                .txt-box{
                  @include font-bold-1;
                  font-size: 12px;
                  line-height: 14px;
                }
              }
            }
          }
          .btn-link-box{
            margin-top: 40px;
            .btn-link{
              &.opened{
                line-height: 0;
                font-size: 0;
              }
            }
          }
        }
      }
    }
    .section-hero-medium{
      position: relative;
      margin-bottom: 140px;
      @include media-breakpoint-down(sm) {
        margin-bottom:70px;
      }
      .hero-bg{
        position: relative;
        //background-image: url("/assets/img/recrutement/accueil-hero-bg.jpg");
        background-size: cover;
        background-position: center;
        min-height: 460px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include media-breakpoint-down(5xl) {
          //min-height: 640px;
        }
        @include media-breakpoint-down(4xl) {
          min-height: 400px;
        }
        @include media-breakpoint-down(xxxl) {
          min-height: 360px;
        }
        @include media-breakpoint-down(xxl) {
        }
        @include media-breakpoint-down(xl) {
          min-height: 320px;
        }
        @include media-breakpoint-down(md) {
          min-height: 360px;
        }
      }
      .dx-bg-box {
        width: 789px;
        height: 265px;
        top: 462px;
        left: 0;
        @include media-breakpoint-down(5xl) {
        }
        @include media-breakpoint-down(4xl) {
          width: 642px;
          height: 237px;
          top: 404px;
        }
        @include media-breakpoint-down(xxxl) {
          width: 594px;
          height: 268px;
          top: 365px;
        }
        @include media-breakpoint-down(xxl) {
          width: 503px;
          height: 254px;
        }
        @include media-breakpoint-down(xl) {
          width: 398px;
          height: 165px;
          top: 322px;
        }
        @include media-breakpoint-down(md) {
          width: 305px;
          height: 141px;
          top: 362px;
        }
        @include media-breakpoint-down(sm) {
          width: 256px;
          height:197px;
        }
      }
      .hero-title-box{
        margin-top: -380px;
        overflow: hidden;
        @include media-breakpoint-down(5xl) {
          //margin-top: -430px;
        }
        @include media-breakpoint-down(4xl) {
          margin-top: -300px;
        }
        @include media-breakpoint-down(xxxl) {
          margin-top: -250px;
        }
        @include media-breakpoint-down(xxl) {
        }
        @include media-breakpoint-down(xl) {
        }
        @include media-breakpoint-down(md) {
          margin-top: -180px;
        }
        .col-bt{
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
        .btn-fleche-back-link{
          position: relative;
          z-index: 2;
          margin-top: 130px;
          @include media-breakpoint-down(4xl) {
            margin-top: 110px;
          }
          @include media-breakpoint-down(xxxl) {
            margin-top: 90px;
          }
        }
        .col-title{
          position: relative;
          &:after{
            @include media-breakpoint-down(sm) {
              background-color: white;
              height: 100%;
              width: 12%;
              content: "";
              display: block;
              position: absolute;
              right: -20px;
              top: 0;
            }
          }
        }
        .boite-d{
          margin: 0;
          position: relative;
          z-index: 2;
          padding:80px 155px 45px 95px;
          width: 100%;
          border-bottom-right-radius: 380px;
          border-top-right-radius: 380px;
          @include media-breakpoint-down(5xl) {
            //padding:100px 140px 52px 140px;
          }
          @include media-breakpoint-down(4xl) {
            padding:80px 155px 45px 95px;
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xxl) {
            padding:70px 135px 35px 75px;
          }
          @include media-breakpoint-down(xl) {
            padding:60px 115px 25px 55px;
          }
          @include media-breakpoint-down(lg) {
            //padding:70px 100px;
          }
          @include media-breakpoint-down(md) {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            padding:50px 90px 20px 90px;
            width: 115%;
          }
          @include media-breakpoint-down(sm) {
            padding:30px 50px 10px 77px;
            width: 125%;
          }
          .title-normal{
            max-width: 1300px;
            h2,h3,h4{
              text-transform: none;
              max-width: 1400px;
              font-size: 80px;
              line-height: 80px;
              margin-left: 0;
              @include media-breakpoint-down(5xl) {
                // font-size: 98px;
                //line-height: 98px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 70px;
                line-height: 70px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 65px;
                line-height: 65px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 60px;
                line-height: 60px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 52px;
                line-height: 52px;
              }
              @include media-breakpoint-down(lg) {
                font-size: 50px;
                line-height: 50px;
              }
              @include media-breakpoint-down(md) {
                font-size: 40px;
                line-height: 40px;
              }
              span{
                margin-left: 30px;
              }
            }
          }
          .content-box{
            position: relative;
            margin-top: 80px;
            margin-left:170px;
            margin-bottom: 35px;
            @include media-breakpoint-down(md) {
              margin-left: 20px;
              margin-top: 35px;
            }
            @include media-breakpoint-down(sm) {
            }
            &:before{
              top: -45px;
              left:-55px;
              @include media-breakpoint-down(5xl) {
                left:-70px;
                top: -50px;
              }
              @include media-breakpoint-down(4xl) {
                height: 97px;
                left:-80px;
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
              }
              @include media-breakpoint-down(xl) {
                top: -30px;
                left:-40px;
              }
              @include media-breakpoint-down(lg) {
                bottom: 35px;
              }
              @include media-breakpoint-down(md) {
                top: -20px;
                left:-35px;
              }
            }
            .p-title{
              max-width: 400px;
            }
          }
          .btn-link-box{
            margin-top: 40px;
            .btn-link{
              &.opened{
                line-height: 0;
                font-size: 0;
              }
            }
          }
        }
      }
    }
    .section-offres{
      margin-top: 35px;
      margin-bottom: 160px;
      position: relative;
      @include media-breakpoint-down(5xl) {
        margin-bottom: 166px;
      }
      @include media-breakpoint-down(4xl) {
        margin-bottom: 140px;
      }
      @include media-breakpoint-down(xxl) {
        margin-bottom: 95px;
      }
      @include media-breakpoint-down(xl) {
        margin-bottom: 90px;
      }
      @include media-breakpoint-down(md) {
        margin-top: 85px;
      }
      .col-title{
        //padding-left: 60px;
        @include media-breakpoint-down(5xl) {
          //padding-left: 50px;
        }
      }
      .title-normal{
        h2,h3,h4{
          span{
            margin-left: 30px;
          }
        }
      }
      .content-box{
        position: relative;
        margin-top: 110px;
        margin-left: 145px;
        margin-bottom: 65px;
        @include media-breakpoint-down(4xl) {
          margin-left: 105px;
        }
        @include media-breakpoint-down(xxl) {
          margin-top: 80px;
        }
        @include media-breakpoint-down(md) {
          margin-top: 50px;
          margin-bottom: 45px;
        }
        &:before{
          top: -65px;
          left:-80px;
          @include media-breakpoint-down(5xl) {
            left:-70px;
            top: -50px;
          }
          @include media-breakpoint-down(4xl) {
            height: 97px;
            left:-80px;
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            top: -30px;
            left:-60px;
          }
          @include media-breakpoint-down(lg) {
            bottom: 35px;
          }
          @include media-breakpoint-down(md) {
            top: -20px;
            left:-50px;
          }
        }
      }
      .col-cat{
        @include media-breakpoint-down(xl) {
          display: none;
        }
      }
      .categories-menu-box {
        background-color: white;
        z-index: 100;
        .mobile-menu-accordeon {
          display: none;
          @include media-breakpoint-down(md) {
            display: block;
            .menu-active-txt {
              a {
                position: relative;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.45px;
                @include font-black-1;
                padding: 20px 40px 20px 33px;
                display: flex;
                align-items: center;
                color: $color-text-main;
                &:after {
                  background-image: url("/assets/img/histo-menu-mobile-fleche.svg");
                  width: 18px;
                  height: 10px;
                  content: "";
                  display: inline-flex;
                  background-repeat: no-repeat;
                  transition: opacity 0.3s linear;
                  margin-left: 10px;
                  transform: rotate(180deg);
                }
                &.open {
                  &:after {
                    transform: none;
                  }
                }
              }
            }
          }
        }
        .cat-menu-in {
          margin-top: 125px;
          padding: 20px 40px 20px 0;
          @include media-breakpoint-down(xxl) {
            margin-top: 75px;
          }
          @include media-breakpoint-down(md) {
            padding: 0;
            visibility: hidden;
            height:0;
            opacity: 0;
            transition: visibility 0s, opacity 0.8s ease-out;
            float:left;
          }
          &.open{
            padding: 0px 40px 20px 20px;
            opacity: 1;
            height:auto;
            visibility: visible;
            background-color: white;
            width:100%;
          }
          ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            li {
              position: relative;
              .menu-link {
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.4px;
                @include font-reg-1;
                color: $color-text-main;
                padding: 12px 0 12px 50px;
                display: block;
                text-transform: uppercase;
                @include media-breakpoint-down(xxl) {
                  font-size: 11px;
                  line-height: 13px;
                  letter-spacing: 0.27px;
                  padding: 8px 0 8px 33px;
                }
                @include media-breakpoint-down(md) {
                  font-size: 18px;
                  line-height: 22px;
                  letter-spacing: 0.45px;
                  padding: 13px 0 13px 13px;
                }
                &:before {
                  width: 20px;
                  height: 16px;
                  content: "";
                  display: block;
                  position: absolute;
                  top: 11px;
                  left: 0;
                  opacity: 0;
                  background-repeat: no-repeat;
                  transition: opacity 0.3s linear;
                  @include media-breakpoint-down(xxl) {
                    width: 13px;
                    height: 9px;
                    background-size: 13px;
                    top: 8px;
                  }
                  @include media-breakpoint-down(xl) {
                  }
                }
                &:hover {
                  @include media-breakpoint-down(md) {
                    text-decoration: underline;
                  }
                  &:before {
                    opacity: 1;
                    background-image: url("/assets/img/histo-menu-dash.svg");
                    @include media-breakpoint-down(md) {
                      background-image: none;
                    }
                  }
                }
                &.active {
                  @include font-black-1;
                  @include media-breakpoint-down(md) {
                    text-decoration: underline;
                  }
                  &:before {
                    opacity: 1;
                    background-image: url("/assets/img/histo-menu-dash.svg");
                    @include media-breakpoint-down(md) {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .location-recherche-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media-breakpoint-down(md) {
          align-items: flex-start;
          flex-direction: column-reverse;
          width: 100%;
        }
        .location-box{
          .sub-menu-box{
            @include media-breakpoint-down(xl) {
              //display: none;
              overflow-x: scroll;
              -webkit-overflow-scrolling: touch;
              &::-webkit-scrollbar {
                display: none;
              }
            }
            ul{
              list-style-type: none;
              margin: 0;
              padding: 0;
              display: flex;
              flex-wrap: nowrap;
              justify-content: center;
              //overflow-x: scroll;
              @include media-breakpoint-down(xl) {
                justify-content: start;
              }
              li{
                display: inline-flex;
                flex-wrap: nowrap;
                margin: 0 12px;
                &:first-child{
                  margin-left: 0px;
                }
                @include media-breakpoint-down(xl) {
                  margin: 0 10px;
                }
                a{
                  //padding: 21px 31px 20px 24px;
                  padding: 18px 27px 18px 20px;
                  background-color: transparent;
                  //font-size: 15px;
                  //line-height: 22px;
                  //letter-spacing: 0.75px;
                  font-size: 13px;
                  line-height: 20px;
                  letter-spacing: 0.7px;
                  text-transform: uppercase;
                  border-top-right-radius: 30px;
                  border-bottom-right-radius: 30px;
                  color: $color-text-main;
                  transition: background-color 0.2s linear;
                  @include media-breakpoint-down(5xl) {
                    font-size: 12px;
                    line-height: 19px;
                    letter-spacing: 0.66px;
                    padding: 16px 25px 16px 18px;
                  }
                  @include media-breakpoint-down(4xl) {
                    font-size: 11px;
                    line-height: 15px;
                    letter-spacing: 0.56px;
                    padding: 16px 25px 16px 18px;
                  }
                  @include media-breakpoint-down(xxxl) {
                    font-size: 11px;
                    line-height: 15px;
                    letter-spacing: 0.56px;
                    padding: 16px 25px 16px 18px;
                  }
                  @include media-breakpoint-down(xl) {
                    font-size: 10px;
                    line-height: 14px;
                    letter-spacing: 0.49px;
                  }
                  @include media-breakpoint-down(md) {
                    padding: 12px 18px 12px 15px;
                  }
                  &:hover{
                    background-color: $color-secondary;
                  }
                  &.sub-active{
                    background-color: $color-alt-4;
                    @include font-bold-1;
                  }
                }
              }
            }
          }
        }
        .recherche-box{
          @include media-breakpoint-down(md) {
            width: 100%;
            margin-bottom: 30px;
          }
          .recherche-input-box {
            width: 100%;
            @include media-breakpoint-down(5 xl) {
            }
            @include media-breakpoint-down(4 xl) {
            }
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xxl) {
            }
            @include media-breakpoint-down(lg) {
              //display: none;
            }
            form {
              //width: 100%;
              .input-group {
                min-width:500px;
                width: auto;
                @include media-breakpoint-down(5xl) {
                  min-width:450px;
                }
                @include media-breakpoint-down(4xl) {
                  min-width:400px;
                }
                @include media-breakpoint-down(xxxl) {
                  min-width:350px;
                }
                @include media-breakpoint-down(xxl) {
                  min-width:300px;
                }
                @include media-breakpoint-down(md) {
                  width: 100%;
                }
              }
              #basic-addon1 {
                border-bottom-right-radius:40px;
                border-top-right-radius: 40px;
                background-color: $color-bg;
                border-color: $color-bg;
                padding-right: 20px;
              }
              input {
                border-radius: 0;
                @include font-bold-1;
                font-size: 16px;
                letter-spacing: 0.8px;
                border-color: $color-bg;
                background-color: $color-bg;
                margin-bottom: 0;
                padding: 26px 26px;
                @include media-breakpoint-down(xxl) {
                  padding: 16px 26px;
                  font-size: 14px;
                }
                &:focus{
                  background-color: $color-primary;
                }
              }
            }
          }
        }
      }
      .offres-details-box{
        .postes-all{
          margin-top: 65px;
          padding-bottom: 15px;
          @include media-breakpoint-down(xxl) {
            margin-top: 35px;
          }
          .poste-box{
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            background-color: $color-bg;
            padding: 26px 56px;
            margin-bottom: 30px;
            color: $color-text-main;
            transition: background-color 0.2s linear;
            border-top-right-radius: 60px;
            border-bottom-right-radius: 60px;
            @include media-breakpoint-down(xxl) {
              padding: 16px 26px;
              margin-bottom: 20px;
            }
            @include media-breakpoint-down(md) {
              flex-direction: column;
              align-items: flex-start;
              padding: 16px 36px 16px 16px;
            }
            &:hover{
              background-color: $color-primary;
            }
            .title-box{
              width: 33.33333%;
              padding-right: 20px;
              @include media-breakpoint-down(xxxl) {
                width: 45%;
              }
              @include media-breakpoint-down(md) {
                width: 100%;
                padding-right: 0px;
              }
              h4{
                @include font-bold-1;
                font-size: 20px;
                line-height: 26px;
                margin-bottom: 0;
                @include media-breakpoint-down(5xl) {
                  font-size: 19px;
                  line-height: 25px;
                }
                @include media-breakpoint-down(4xl) {
                  font-size: 18px;
                  line-height: 24px;
                }
                @include media-breakpoint-down(xxxl) {
                  font-size: 17px;
                  line-height: 23px;
                }
                @include media-breakpoint-down(xxl) {
                  font-size: 16px;
                  line-height: 22px;
                }
              }
            }
            .location-box{
              width: 33.33333%;
              text-align: center;
              padding-right: 20px;
              padding-left: 20px;
              @include media-breakpoint-down(xxxl) {
                width: 35%;
              }
              @include media-breakpoint-down(md) {
                width: 100%;
                padding-right: 0px;
                padding-left: 0;
                text-align: left;
              }
              span{
                font-size: 20px;
                line-height: 26px;
                @include font-light-1;
                @include media-breakpoint-down(5xl) {
                  font-size: 19px;
                  line-height: 25px;
                }
                @include media-breakpoint-down(4xl) {
                  font-size: 18px;
                  line-height: 24px;
                }
                @include media-breakpoint-down(xxxl) {
                  font-size: 17px;
                  line-height: 23px;
                }
                @include media-breakpoint-down(xxl) {
                  font-size: 16px;
                  line-height: 22px;
                }
              }
            }
            .salaire-box{
              width: 33.33333%;
              text-align: right;
              @include media-breakpoint-down(xxxl) {
                width: 20%;
              }
              @include media-breakpoint-down(md) {
                width: 100%;
                text-align: left;
              }
              span{
                @include font-light-1;
                font-size: 20px;
                line-height: 26px;
                @include media-breakpoint-down(5xl) {
                  font-size: 19px;
                  line-height: 25px;
                }
                @include media-breakpoint-down(4xl) {
                  font-size: 18px;
                  line-height: 24px;
                }
                @include media-breakpoint-down(xxxl) {
                  font-size: 17px;
                  line-height: 23px;
                }
                @include media-breakpoint-down(xxl) {
                  font-size: 16px;
                  line-height: 22px;
                }
              }
            }
          }
        }
        .bt-tous-box{

        }
      }
      .dx-bg-box {
        width: 508px;
        height: 265px;
        bottom: -160px;
        left: 0;
        @include media-breakpoint-down(5xl) {
          width: 454px;
          height: 265px;
          bottom: -166px;
        }
        @include media-breakpoint-down(4xl) {
          width: 347px;
          height: 250px;
          bottom: -135px;
        }
        @include media-breakpoint-down(xxxl) {
          width: 347px;
          height: 250px;
          bottom: -135px;
        }
        @include media-breakpoint-down(xxl) {
          width: 198px;
          height: 184px;
          bottom: -90px;
        }
        @include media-breakpoint-down(xl) {
          display: none;
        }
      }
      .sans-offre{
        padding-bottom: 100px;
        @include media-breakpoint-down(xl) {
          padding-bottom: 0;
        }
        .img-sans-offre{

        }
        .titre-center-box{
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          margin-left: 80px;
          @include media-breakpoint-down(md) {
            margin-left: 40px;
          }
        }
        .stitle-box{ // deja diminer a 90%
          margin-left: 50px;
          padding-top: 100px;
          @include media-breakpoint-down(5xl) {
            padding-top: 90px;
          }
          @include media-breakpoint-down(4xl) {
            padding-top: 80px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-top: 70px;
          }
          @include media-breakpoint-down(xxl) {
            padding-top: 60px;
          }
          @include media-breakpoint-down(md) {
            padding-top: 0px;
          }
          h4{
            text-align: left;
            @include font-title-med;
            //font-size: 168px;
            //line-height: 160px;
            font-size: 104px;
            line-height: 74px;
            letter-spacing: 0;
            font-style: italic;
            position: relative;
            @include media-breakpoint-down(5xl) {
              font-size: 98px;
              line-height: 68px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 92px;
              line-height: 62px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 82px;
              line-height: 52px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 72px;
              line-height: 42px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 65px;
              line-height: 36px;
              text-align: right;
            }
            .h4-1{
              //margin-right: 40px;
              margin-right: 36px;
            }
            .h4-2{
              text-transform: uppercase;
              @include font-light-1;
              font-size: 21px;
              line-height: 32px;
              letter-spacing: 1.2px;
              display: block;
              text-align: left;
              padding-top: 30px;
              margin-left: -50px;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
                font-size: 20px;
                line-height: 29px;
                letter-spacing: 0.99px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 18px;
                line-height: 27px;
                letter-spacing: 0.89px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 15px;
                line-height: 22px;
                letter-spacing: 0.75px;
              }
              @include media-breakpoint-down(lg) {
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0;
                //padding-top: 15px;
              }

              strong{
                @include font-bold-1;
                display: block;
              }
            }
            &:before{
              content:"";
              display: block;
              // width: 80px;
              //height: 97px;
              //left: 65px;
              //background-size: 80px;
              width: 70px;
              height:80px;
              background-size: 70px;
              left: -130px;
              position: absolute;
              bottom:80px;
              background-repeat: no-repeat;
              background-image: url("/assets/img/slash.svg");
              @include media-breakpoint-down(5xl) {
                left: -120px;
                width: 60px;
                height:70px;
                background-size: 60px;
              }
              @include media-breakpoint-down(4xl) {
                background-size:60px;
                width: 60px;
                height:72px;
                bottom:60px;
                left: -100px;
              }
              @include media-breakpoint-down(xxxl) {
                background-size:60px;
                width: 60px;
                height:72px;
                bottom:50px;
                left: -100px;
              }
              @include media-breakpoint-down(xxl) {
                left: -100px;
              }
              @include media-breakpoint-down(xl) {
                background-size:32px;
                width: 32px;
                height:38px;
                bottom:40px;
                left: -80px;
              }
            }
          }
        }
        .btn-box{
          margin-top:50px;
          //margin-bottom: 120px;
          @include media-breakpoint-down(xxl) {
            margin-top: 70px;
            //margin-bottom: 90px;
          }
          @include media-breakpoint-down(lg) {
            margin-top: 40px;
            //margin-bottom: 90px;
          }
        }
      }
    }
  }
}//#dx-page-body
