/**
CSS Animations

Examples d'utilisation:

Avec délai
animation: fade-in-direct 1s 2s both ;

Direct:
animation: fade-in-direct ;

 */
@keyframes fade-in-direct {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes width-to-100-prc {
  from { width: 0; }
  to   { width: 100%; }
}