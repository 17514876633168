#dx-page-body {
  &.page-home{
    header{
      .navbar{
        background-color: transparent !important;
      }
    }
    .site-main { //main
      .main-content-wrapper {
        .content-area {
          padding-top: 0; // hauteur de la nav - nav transparente pour carousel
          .section-main{
            > h1{
              display: none;
            }
          }
        }
      }
    }
    .home-screen1{
      height: 100vh; // - nav
      //height: calc(100vh - $header-nav-height); // - nav
      min-height: 900px;
      @include media-breakpoint-down(4xl) {
        min-height: 850px;
      }
      @include media-breakpoint-down(xxxl) {
        min-height:700px;
      }
      @include media-breakpoint-down(xxl) {
        min-height:650px;
      }
      .swiper-container.theme-homehero{
          height: 86%;
        .swiper{
          height: 100%;
        }
        .hero-home-box{
          height: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: bottom right;
          display: flex;
          align-items: center;
          @include media-breakpoint-down(sm) {
            background-size: 140%;
          }
          .hero-content-box{
            //background-color: green;
            color: $color-inverse;
            width: 39%;
            margin-left: 12%;
            @include media-breakpoint-down(xxl) {
              width: 40%;
              margin-left: 11%;
            }
            @include media-breakpoint-down(xl) {
              width: 80%;
              margin-left: 20%;
            }
            @include media-breakpoint-down(sm) {
              margin-bottom: 100px;
            }
            .title-slash-box{
              &.slash-left{
                padding-left: 130px;
                @include media-breakpoint-down(5xl) {
                  padding-left: 120px;
                }
                @include media-breakpoint-down(4xl) {
                  padding-left: 105px;
                }
                @include media-breakpoint-down(xxxl) {
                  padding-left: 105px;
                  padding-bottom: 15px;
                }
                @include media-breakpoint-down(xxl) {
                  padding-left: 95px;
                }
                @include media-breakpoint-down(xl) {
                  padding-left: 35px;
                }
                &:before{
                  @include media-breakpoint-down(5xl) {
                    background-size: 80px;
                    width: 80px;
                    height: 100px;
                    left: 0;
                  }
                  @include media-breakpoint-down(4xl) {
                    background-size: 71px;
                    width: 71px;
                    height: 87px;
                    left: 0;
                  }
                  @include media-breakpoint-down(xxxl) {
                    background-size: 71px;
                    width: 71px;
                    height: 87px;
                  }@include media-breakpoint-down(xxxl) {
                     background-size: 63px;
                     width: 63px;
                     height: 77px;
                   }

                  @include media-breakpoint-down(xl) {
                    left: -5px;
                    background-size: 44px;
                    width: 44px;
                    height: 54px;
                  }
                }
              }
              .part-1{
                padding-left: 60px;
                @include media-breakpoint-down(5xl) {
                  padding-left: 55px;
                }
                @include media-breakpoint-down(4xl) {
                  padding-left: 40px;
                }
                @include media-breakpoint-down(xxxl) {
                  padding-left: 35px;
                }
                h2{
                  //font-size: 60px;
                  //line-height: 60px;
                  //letter-spacing: 3.12px;
                  text-transform: uppercase;
                  @include font-bold-1;
                  margin-bottom: 0;
                  font-size: 54px;
                  line-height: 50px;
                  letter-spacing: 2.6px;
                  @include media-breakpoint-down(5xl) {
                    font-size: 48px;
                    line-height: 48px;
                    letter-spacing: 2.5px;
                  }
                  @include media-breakpoint-down(4xl) {
                    font-size: 46px;
                    line-height: 46px;
                    letter-spacing: 2.1px;
                  }
                  @include media-breakpoint-down(xxxl) {
                    font-size: 40px;
                    line-height: 40px;
                    letter-spacing: 2.06px;
                  }
                  @include media-breakpoint-down(xl) {
                    font-size: 30px;
                    line-height: 30px;
                    letter-spacing: 1.5px;
                  }
                }
              }
              .part-2{
                h2{
                  //font-size: 74px;
                  //line-height: 68px;
                  //letter-spacing: -3px;
                  text-transform: none;
                  @include font-title-reg;
                  font-style: italic;
                  //margin-left: -15px;
                  margin-bottom: 0;
                  font-size: 69px;
                  line-height: 69px;
                  letter-spacing: 0px;
                  @include media-breakpoint-down(5xl) {
                    font-size: 63px;
                    line-height: 63px;
                  }
                  @include media-breakpoint-down(4xl) {
                    font-size: 56px;
                    line-height: 56px;
                  }
                  @include media-breakpoint-down(xxxl) {
                    font-size: 49px;
                    line-height: 49px;
                  }
                  @include media-breakpoint-down(xl) {
                    font-size: 34px;
                    line-height: 39px;
                  }
                }
              }
            }
          }
          p{
            padding-left: 125px;
            @include media-breakpoint-down(5xl) {
              padding-left: 110px;
            }
            @include media-breakpoint-down(4xl) {
              padding-left: 90px;
            }
            @include media-breakpoint-down(xxxl) {
              padding-left: 95px;
            }
            @include media-breakpoint-down(xl) {
              padding-left: 15px;
            }
            strong{
              display: block;
              margin-left: -40px;
              @include media-breakpoint-down(5xl) {
                margin-left: -35px;
              }
              @include media-breakpoint-down(4xl) {
                margin-left: -31px;
              }
              @include media-breakpoint-down(xxxl) {
                margin-left: -31px;
              }
              @include media-breakpoint-down(xl) {
                margin-left: -21px;
              }
            }
          }
          .btn-link-box{
            margin-top: 65px;
            padding-left: 150px;
            @include media-breakpoint-down(5xl) {
              margin-top: 55px;
              padding-left: 135px;
            }
            @include media-breakpoint-down(4xl) {
              margin-top: 45px;
              padding-left: 110px;
            }
            @include media-breakpoint-down(xxxl) {
              margin-top: 50px;
              padding-left: 100px;
            }
            @include media-breakpoint-down(xl) {
              margin-top: 20px;
              padding-left: 50px;
            }
            .btn-link{
              color: $color-inverse;
            }
          }
        }
        #hero-slide-1{
          background-image: url("/assets/img/hero-1.png");
        }
        .swiper-button-prev:after, .swiper-button-next:after{
          display: none;
        }
        .swiper-button-prev{
          bottom:0;
          //left: 27%;
          left: 25%;
          top: 79%;
          width: 22px;
          height: 39px;
          transform: rotate(180deg);
          background-repeat: no-repeat;
          background-image: url("/assets/img/carousel-fleche-d-white.svg");
          @include media-breakpoint-down(5xl) {
          left: 27%;
          }
          @include media-breakpoint-down(4xl) {
            width: 16px;
            height: 29px;
            background-size: 16px;
            left: 27%;
            top: 79%;
          }
          @include media-breakpoint-down(xxxl) {
            width: 16px;
            height: 29px;
            background-size: 16px;
            left: 27%;
            top: 79%;
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            width: 10px;
            height: 19px;
            background-size: 10px;
            left: 25%;
          }
          @include media-breakpoint-down(sm) {
            left: 21%;
          }
        }
        .swiper-button-next{
          bottom:0;
          top: 79%;
          right: auto;
          //left: 31%;
          left: 29%;
          width: 22px;
          height: 39px;
          background-repeat: no-repeat;
          background-image: url("/assets/img/carousel-fleche-d-white.svg");
          @include media-breakpoint-down(5xl) {
          left: 31%;
          }
          @include media-breakpoint-down(4xl) {
            top: 79%;
            width: 16px;
            height: 29px;
            background-size: 16px;
          }
          @include media-breakpoint-down(xxxl) {
            top: 79%;
            width: 16px;
            height: 29px;
            background-size: 16px;
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            width: 10px;
            height: 19px;
            background-size: 10px;
          }
          @include media-breakpoint-down(sm) {
            left: 29%;
          }
        }
      }

      .commanditaires-box{
        background-color: $color-bg;
        height: 14%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-down(5xl) {
          //background-color: red;
        }
        @include media-breakpoint-down(4xl) {
          //background-color: yellow;
        }
        @include media-breakpoint-down(xxxl) {
          //background-color: green;
        }
        @include media-breakpoint-down(xxl) {
          //background-color: pink;
        }
        @include media-breakpoint-down(xl) {
          //background-color: mediumseagreen;
        }
        @include media-breakpoint-down(lg) {
          //background-color: hotpink;
        }
        @include media-breakpoint-down(md) {
          //background-color: salmon;
        }
        @include media-breakpoint-down(sm) {
          //background-color: greenyellow;
        }
        .swiper-wrapper{
          align-items: center;
          .logo-box{
          }
        }
      }
    }
    .section-simple{
      position: relative;
      //padding-bottom: 100px;
      .bg-turquoise-before{
        position: relative;
        z-index: 2;
        &:before{
          background-color: $color-alt-3;
          content: "";
          display: block;
          width: 1400px;
          height: 100%;
          position: absolute;
          top:0;
          left:0;
          @include media-breakpoint-down(5xl) {
            width: 400px;
          }
          @include media-breakpoint-down(xl) {
            width: 200px;
          }
        }
        &:after{
          @include media-breakpoint-down(xl) {
            background-color: $color-alt-3;
            content: "";
            display: block;
            width: 400px;
            height: 100%;
            position: absolute;
            top:0;
            right:0;
            z-index: -1;
            @include media-breakpoint-down(xl) {
              width: 200px;
            }
          }
        }
      }
      .bg-blanc-before{
        position: relative;
        //margin-top: -150px;
        margin-top: -135px;
        z-index: 200;
        @include media-breakpoint-down(5xl) {
          margin-top: -120px;
        }
        @include media-breakpoint-down(4xl) {
          margin-top: -90px;
        }
        @include media-breakpoint-down(xxxl) {
          margin-top: -85px;
        }
        &:before{
          @include media-breakpoint-down(xl) {
            background-color: white;
            content: "";
            display: block;
            width: 300px;
            height: 100%;
            position: absolute;
            top:0;
            left:0;
            z-index: -1;
          }
          @include media-breakpoint-down(md) {
            width: 100px;
          }
        }
      }
      .bg-color-turquoise{
        &:before{
        }
      }
      .simple-txt-box{
        //padding: 135px 150px 285px 0;
        padding: 120px 135px 255px 0;
        text-align: right;
        @include media-breakpoint-down(5xl) {
          padding: 110px 130px 200px 0;
        }
        @include media-breakpoint-down(4xl) {
          padding: 90px 120px 180px 0;
        }
        @include media-breakpoint-down(xxxl) {
          padding: 70px 105px 145px 0;
        }
        @include media-breakpoint-down(xl) {
          padding: 70px 0 145px 0;
        }
        h3{
          //font-size: 60px;
          //line-height: 70px;
          //letter-spacing: 3.12px;
          font-size: 55px;
          line-height: 65px;
          letter-spacing: 2px;
          margin-bottom: 0;
          @include media-breakpoint-down(5xl) {
            font-size:48px;
            line-height: 58px;
            letter-spacing: 2.8px;
          }
          @include media-breakpoint-down(4xl) {
            font-size:44px;
            line-height: 54px;
            letter-spacing: 2.2px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 45px;
            line-height: 50px;
            letter-spacing: 2.31px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 22px;
            line-height: 30px;
            letter-spacing: 1.17px;
          }
        }
        h4{
          margin-bottom: 0;
          //font-size: 75px;
          //line-height: 80px;
          //letter-spacing: -3px;
          font-size: 68px;
          line-height: 73px;
          letter-spacing: 0;
          //margin-right: -500px;
          font-style: italic;
          @include media-breakpoint-down(5xl) {
            font-size: 60px;
            line-height: 67px;
            //margin-right: -200px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 52px;
            line-height: 65px;
            //margin-right: -200px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 56px;
            line-height: 63px;
            //margin-right: -150px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 28px;
            line-height: 32px;
            //margin-right: -150px;
          }
        }
      }
      .simple-par{
        //margin-top: -150px;
        //z-index: 200;
        position: relative;
        //padding: 155px 95px;
        padding: 140px 85px 135px 85px;
        border-bottom-right-radius: 180px;
        border-top-right-radius: 180px;
        @include media-breakpoint-down(5xl) {
          //margin-top: -100px;
          padding: 130px 75px 120px 75px;
        }
        @include media-breakpoint-down(4xl) {
          //margin-top: -100px;
          padding: 90px 65px;
        }
        @include media-breakpoint-down(xxxl) {
          //margin-top: -85px;
          padding: 80px 55px;
        }
        @include media-breakpoint-down(xl) {
          //margin-top: -85px;
          padding: 40px 20px 40px 0;
        }
        p{
          @include font-light-1;
          //font-size: 24px;
          //line-height: 36px;
          //letter-spacing: 1.2px;
          font-size: 22px;
          line-height: 34px;
          letter-spacing: 1.1px;
          margin-bottom: 0;
          @include media-breakpoint-down(5xl) {
            font-size: 20px;
            line-height: 29px;
            letter-spacing: 0.99px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 19px;
            line-height: 28px;
            letter-spacing: 0.9px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.89px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 15px;
            line-height: 22px;
            letter-spacing: 0.75px;
          }
          strong{
            @include font-bold-1;
          }
        }
      }
      .dx-bg-box{
        width: 56%;
        //height: 28%;
        height: 28.5%;
        position: absolute;
        bottom: 10%;
        //bottom: 9%;
        right: 0;
        z-index: 1;
        background-size: 600px;
        background-image: url("/assets/img/bg-dx.svg");
        @include media-breakpoint-down(5xl) {
          //height: 27%;
          height: 30%;
        }
        @include media-breakpoint-down(4xl) {
          height: 30%;
        }
        @include media-breakpoint-down(xxxl) {
          height: 31%;
        }
        @include media-breakpoint-down(xxl) {
          height: 28.5%;
        }
        @include media-breakpoint-down(xl) {
          background-size: 250px;
          bottom: -20px;
          height: 25%;
        }
        @include media-breakpoint-down(lg) {
          bottom: -15px;
          height: 25%;
        }
        @include media-breakpoint-down(md) {

        }
        @include media-breakpoint-down(sm) {
          bottom: -20px;
          height: 35%;
        }
      }
    }
    .section-mobilier{
      text-align: center;
      //padding-top: 155px;
      padding-top: 140px;
      @include media-breakpoint-down(5xl) {
        padding-top: 125px;
      }
      @include media-breakpoint-down(4xl) {
        padding-top: 110px;
      }
      .container-fluid{
        @include media-breakpoint-down(xl) {
         padding-right: 0;
          padding-left: 0;
          overflow: hidden;
        }
      }
      .sub-menu-box{
        //padding-top: 45px;
        //padding-bottom: 100px;
        padding-top: 35px;
        padding-bottom: 90px;
        @include media-breakpoint-down(5xl) {
          padding-top: 30px;
          padding-bottom: 80px;
        }
        @include media-breakpoint-down(4xl) {
          padding-top: 25px;
          padding-bottom: 70px;
        }
        @include media-breakpoint-down(xl) {
         //display: none;
          padding-top: 17px;
          padding-bottom: 30px;
          overflow-x: scroll;
          -webkit-overflow-scrolling: touch;
          &::-webkit-scrollbar {
            display: none;
          }
        }
        ul{
          list-style-type: none;
          margin: 0;
          padding: 0;
          display: flex;
          flex-wrap: nowrap;
          justify-content: center;
          //overflow-x: scroll;
          @include media-breakpoint-down(xl) {
            justify-content: start;
          }
          li{
            display: inline-flex;
            flex-wrap: nowrap;
            margin: 0 12px;
            &:first-child{
              margin-left: 30px;
            }
            @include media-breakpoint-down(xl) {
              margin: 0 10px;
            }
            a{
              //padding: 21px 31px 20px 24px;
              padding: 18px 27px 18px 20px;
              background-color: transparent;
              //font-size: 15px;
              //line-height: 22px;
              //letter-spacing: 0.75px;
              font-size: 13px;
              line-height: 20px;
              letter-spacing: 0.7px;
              text-transform: uppercase;
              border-top-right-radius: 30px;
              border-bottom-right-radius: 30px;
              color: $color-text-main;
              transition: background-color 0.2s linear;
              @include media-breakpoint-down(5xl) {
                font-size: 12px;
                line-height: 19px;
                letter-spacing: 0.66px;
                padding: 16px 25px 16px 18px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 11px;
                line-height: 15px;
                letter-spacing: 0.56px;
                padding: 16px 25px 16px 18px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 11px;
                line-height: 15px;
                letter-spacing: 0.56px;
                padding: 16px 25px 16px 18px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 10px;
                line-height: 14px;
                letter-spacing: 0.49px;
              }
              @include media-breakpoint-down(md) {
                padding: 12px 18px 12px 15px;
              }
              &:hover{
                background-color: $color-secondary;
              }
              &.sub-active{
                background-color: $color-alt-4;
                @include font-bold-1;
              }
            }
          }
        }
      }
      .carousel-mobilier{ // dans son propre fichier car re-utilisé dans product-single et modal
        //background-color: white;
        @include media-breakpoint-down(xl) {
          padding-right: 30px;
          padding-left: 30px;
        }
      }
    }
    .section-temoignages{
      //padding-top: 100px;
      //padding-bottom: 100px;
      padding-top: 90px;
      padding-bottom: 90px;
      @include media-breakpoint-down(5xl) {
        padding-top:80px;
        padding-bottom: 80px;
      }
      @include media-breakpoint-down(4xl) {
        padding-top:70px;
        padding-bottom: 70px;
      }
      @include media-breakpoint-down(xxxl) {
        padding-top:70px;
        padding-bottom: 70px;
      }
      @include media-breakpoint-down(xxl) {
      }
      @include media-breakpoint-down(xl) {
        padding-bottom: 35px;
      }
      @include media-breakpoint-down(sm) {
      }
      .container-xxl{
        @include media-breakpoint-down(xl) {
          padding-left: 0;
          padding-right: 0;
          overflow:hidden;
        }
        .row{
          @include media-breakpoint-down(xl) {
            //margin-right: 0;
            //margin-left: 0;
          }
        }
      }
      .title-slash-box{
        padding-bottom: 5px;
        @include media-breakpoint-down(xl) {
          margin-right: 20px;
        }
      }
      .swiper-container.theme-temoignages{
        @include media-breakpoint-down(xl) {
         padding-top: 35px;
        }
        .col-content{
          //padding-bottom: 50px;
          .temoignage-content-box{
            background-color: $color-alt-3;
            //padding: 85px 255px 80px 75px;
            padding: 80px 220px 70px 80px;
            border-top-right-radius: 320px;
            border-bottom-right-radius:320px;
            @include media-breakpoint-down(5xl) {
              padding: 75px 190px 60px 75px;
            }
            @include media-breakpoint-down(4xl) {
              padding: 70px 120px 50px 70px;
            }
            @include media-breakpoint-down(xxxl) {
              padding: 70px 120px 50px 70px;
            }
            @include media-breakpoint-down(xxl) {
            }
            @include media-breakpoint-down(xl) {
              padding: 35px 80px 15px 40px;
              margin-right: -30px;
            }
            @include media-breakpoint-down(sm) {
            }
            .image-txt-box{
              display: flex;
              flex-direction: row;
              align-items: center;
              //margin-bottom: 55px;
              margin-bottom: 50px;
              @include media-breakpoint-down(5xl) {
              }
              @include media-breakpoint-down(4xl) {
                margin-bottom: 45px;
              }
              @include media-breakpoint-down(xxxl) {
                margin-bottom: 45px;
              }
              @include media-breakpoint-down(xxl) {
              }
              @include media-breakpoint-down(xl) {
                margin-bottom: 17px;
              }
              @include media-breakpoint-down(sm) {
              }
              .personne-img-box{
                @include media-breakpoint-down(5xl) {
                  max-width: 90px;
                }
                @include media-breakpoint-down(4xl) {
                  max-width: 81px;
                }
                @include media-breakpoint-down(xxxl) {
                  max-width: 81px;
                }
                @include media-breakpoint-down(xxl) {
                }
                @include media-breakpoint-down(xl) {
                  max-width: 45px;
                }
                @include media-breakpoint-down(sm) {
                }
              }
              .personne-nom-box{
                //padding-left: 40px;
                padding-left: 35px;
                @include media-breakpoint-down(5xl) {
                }
                @include media-breakpoint-down(4xl) {
                  padding-left: 30px;
                }
                @include media-breakpoint-down(xxxl) {
                  padding-left: 30px;
                }
                @include media-breakpoint-down(xxl) {
                }
                @include media-breakpoint-down(xl) {
                  padding-left: 20px;
                }
                @include media-breakpoint-down(sm) {
                }
                .nom-txt{
                  @include font-bold-1;
                  //font-size: 38px;
                  //line-height: 46px;
                  font-size:34px;
                  line-height: 38px;
                  text-transform: uppercase;
                  @include media-breakpoint-down(5xl) {
                    font-size: 32px;
                    line-height: 36px;
                  }
                  @include media-breakpoint-down(4xl) {
                    font-size: 28px;
                    line-height: 34px;
                  }
                  @include media-breakpoint-down(xxxl) {
                    font-size: 28px;
                    line-height: 34px;
                  }
                  @include media-breakpoint-down(xxl) {
                  }
                  @include media-breakpoint-down(xl) {
                    font-size: 15px;
                    line-height: 15px;
                  }
                  @include media-breakpoint-down(sm) {
                  }
                }
              }
            }
            .logo-box{
              //margin-bottom: 45px;
              margin-bottom: 40px;
              @include media-breakpoint-down(5xl) {
                height: 45px;
                margin-bottom: 30px;
              }
              @include media-breakpoint-down(4xl) {
                height: 40px;
                margin-bottom: 30px;
              }
              @include media-breakpoint-down(xxxl) {
                height: 40px;
                margin-bottom: 30px;
              }
              @include media-breakpoint-down(xxl) {
              }
              @include media-breakpoint-down(xl) {
                height: 22px;
                margin-bottom: 20px;
              }
              @include media-breakpoint-down(sm) {
              }
              img{
                height: 100%;
                width: auto;
              }
            }
            .citation-box{
              p{
                //font-size: 24px;
                //line-height: 36px;
                font-size:22px;
                line-height: 32px;
                letter-spacing: 1px;
                font-style: italic;
                //margin-bottom: 20px;
                @include media-breakpoint-down(5xl) {
                  font-size:21px;
                  line-height: 29px;
                }
                @include media-breakpoint-down(4xl) {
                  font-size:18px;
                  line-height: 26px;
                }
                @include media-breakpoint-down(xxxl) {
                  font-size:18px;
                  line-height: 26px;
                }
                @include media-breakpoint-down(xxl) {
                }
                @include media-breakpoint-down(xl) {
                  font-size:10px;
                  line-height: 14px;
                  letter-spacing: 0.41px;
                }
                @include media-breakpoint-down(sm) {
                }
              }
            }
          }
        }
        .rowoverlay{
          position: absolute;
          top: 0;
          left:0;
          z-index:1;
          bottom:0;
          right:0;
          height:100%;
          display: flex;
          align-items: center;
        }
        .swiper{
          //padding-bottom: 130px;
          padding-bottom: 120px;
          @include media-breakpoint-down(5xl) {
            padding-bottom: 110px;
          }
          @include media-breakpoint-down(4xl) {
            padding-bottom: 83px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-bottom: 83px;
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            padding-bottom: 50px;
          }
          @include media-breakpoint-down(sm) {
          }
        }
        .swiper-button-prev:after, .swiper-button-next:after{
          display: none;
        }
        .swiper-button-prev{
          top: auto;
          bottom:0;
          //left: 44%;
          //width: 21px;
          //height: 39px;
          left: 45%;
          width: 19px;
          height: 37px;
          background-size: 19px;
          transform: rotate(180deg);
          background-repeat: no-repeat;
          background-image: url("/assets/img/carousel-fleche-d.svg");
          @include media-breakpoint-down(5xl) {
          }
          @include media-breakpoint-down(4xl) {
            width: 16px;
            height: 29px;
            background-size: 16px;
            left: 45%;
          }
          @include media-breakpoint-down(xxxl) {
            width: 16px;
            height: 29px;
            background-size: 16px;
            left: 45%;
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            width: 10px;
            height: 19px;
            background-size: 10px;
            left: 40%;
          }
          @include media-breakpoint-down(sm) {
          }
        }
        .swiper-button-next{
          bottom:0;
          top: auto;
          //right: 44%;
          //width: 21px;
          //height: 39px;
          width: 19px;
          height: 37px;
          right: 45%;
          background-size: 19px;
          background-repeat: no-repeat;
          background-image: url("/assets/img/carousel-fleche-d.svg");
          @include media-breakpoint-down(5xl) {
          }
          @include media-breakpoint-down(4xl) {
            width: 16px;
            height: 29px;
            background-size: 16px;
            right: 45%;
          }
          @include media-breakpoint-down(xxxl) {
            width: 16px;
            height: 29px;
            background-size: 16px;
            right: 45%;
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            width: 10px;
            height: 19px;
            background-size: 10px;
            right: 40%;
          }
          @include media-breakpoint-down(sm) {
          }
        }
        .counter{
          text-align: center;
          margin-top: -32px;
          font-size: 24px;
          letter-spacing: 7px;
          @include font-reg-1;
          @include media-breakpoint-down(5xl) {
            font-size: 22px;
            margin-top: -30px;
            letter-spacing: 6.5px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 18px;
            margin-top: -28px;
            letter-spacing: 6px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 18px;
            margin-top: -28px;
            letter-spacing: 6px;
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            font-size: 14px;
            margin-top: -21px;
            letter-spacing: 4px;
          }
          @include media-breakpoint-down(sm) {
          }
        }
      }
    }
    .section-fun-fiable-facile{
      //margin-bottom: 200px;
      overflow:hidden;
      .section-title-box{
        .title-box{
          //padding-top: 140px;
          //padding-bottom: 100px;
          padding-top: 125px;
          padding-bottom: 95px;
          @include media-breakpoint-down(5xl) {
            padding-top: 120px;
            padding-bottom: 90px;
          }
          @include media-breakpoint-down(4xl) {
            padding-top: 105px;
            padding-bottom: 80px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-top: 105px;
            padding-bottom: 80px;
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            padding-top: 156px;
            padding-bottom: 125px;
          }
          h3{
            //font-size: 48px;
            //line-height: 48px;
            //letter-spacing: 2.37px;
            font-size: 44px;
            line-height: 44px;
            letter-spacing: 1.75px;
            @include font-reg-1;
            text-transform: uppercase;
            margin-bottom: 0;
            strong{
              @include font-reg-1;
              font-weight: 700;
            }
            @include media-breakpoint-down(5xl) {
              font-size: 40px;
              line-height: 40px;
              letter-spacing: 1.8px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 35px;
              line-height: 35px;
              letter-spacing: 1.45px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 35px;
              line-height: 35px;
              letter-spacing: 1.45px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 25px;
              line-height: 31px;
              letter-spacing: 1.24px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.62px;
            }
            .h3-2{
              display: block;
              @include font-title-med;
              //font-size: 168px;
              //line-height: 200px;
              //margin-left: -625px;
              letter-spacing: 0;
              font-style: italic;
              text-transform: none;
              font-size: 150px;
              line-height: 170px;
              margin-left: -550px;
              @include media-breakpoint-down(5xl) {
                font-size: 135px;
                line-height: 160px;
                margin-left: -425px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 124px;
                line-height: 150px;
                margin-left: -325px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 110px;
                line-height: 140px;
                margin-left: -325px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 95px;
                line-height: 130px;
                margin-left: -255px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 44px;
                line-height: 62px;
                margin-left: -20px;
              }
            }
          }
        }
      }
      .stitle-box{
        //padding-top: 85px;
        padding-top: 75px;
        @include media-breakpoint-down(5xl) {
          padding-top: 65px;
        }
        @include media-breakpoint-down(4xl) {
          padding-top: 55px;
        }
        @include media-breakpoint-down(xxxl) {
          padding-top: 55px;
        }
        @include media-breakpoint-down(xxl) {
        }
        @include media-breakpoint-down(xl) {
          padding-top: 45px;
        }
        h4{
          @include font-title-med;
          //font-size: 168px;
          //line-height: 160px;
          font-size: 150px;
          line-height: 146px;
          letter-spacing: 0;
          font-style: italic;
          position: relative;
          @include media-breakpoint-down(5xl) {
            font-size: 140px;
            line-height: 136px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 124px;
            line-height: 120px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 124px;
            line-height: 120px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 112px;
            line-height: 110px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 66px;
            line-height: 60px;
            text-align: right;

          }
          .h4-1{
            //margin-right: 40px;
            margin-right: 36px;
            display: block;
          }
          .h4-2{
            //font-size: 32px;
            //line-height: 40px;
            font-size: 30px;
            line-height: 38px;
            @include font-reg-1;
            display: block;
            text-transform: uppercase;
            text-align: right;
            @include media-breakpoint-down(5xl) {
              font-size: 28px;
              line-height: 36px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 24px;
              line-height: 30px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 24px;
              line-height: 30px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 22px;
              line-height: 26px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 13px;
              line-height: 16px;
            }
            strong{
              display: block;
            }
          }
        }
      }
      .fun-content-box{
        position: relative;
        //padding-bottom: 170px;
        padding-bottom: 155px;
        @include media-breakpoint-down(5xl) {
          padding-bottom: 140px;
        }
        @include media-breakpoint-down(4xl) {
          padding-bottom: 130px;
        }
        @include media-breakpoint-down(xxxl) {
          padding-bottom: 130px;
        }
        @include media-breakpoint-down(xxl) {
          padding-bottom: 120px;
        }
        @include media-breakpoint-down(xl) {
          padding-bottom: 90px;
        }
        .box-escalier{
          //height: 155px;
          height: 140px;
          width: 43%;
          position: absolute;
          right: 0;
          top: 0;
          @include media-breakpoint-down(5xl) {
            height: 120px;
          }
          @include media-breakpoint-down(4xl) {
            height: 100px;
          }
          @include media-breakpoint-down(xxxl) {
            height: 100px;
          }
          @include media-breakpoint-down(xxl) {
            width: 22%;
            height: 100px;
          }
          @include media-breakpoint-down(xl) {
            width: 50%;
            height: 35px;
          }
        }
        .dx-bg-box{
          //width: 395px;
          //height:180px;
          //background-size: 600px;
          //top: 160px;
          left: 0;
          width: 350px;
          height:200px;
          top: 140px;
          background-size: 560px;
          @include media-breakpoint-down(5xl) {
            width: 300px;
            height:400px;
            top: 120px;
            background-size: 560px;
          }
          @include media-breakpoint-down(4xl) {
            width: 205px;
            height:200px;
            top: 100px;
            background-size: 500px;
          }
          @include media-breakpoint-down(xxxl) {
            width: 205px;
            height:200px;
            top: 100px;
            background-size: 500px;
          }
          @include media-breakpoint-down(xxl) {
            width: 138px;
            height:300px;
            top: 115px;
            background-size: 450px;
          }
          @include media-breakpoint-down(xl) {
            width: 100px;
            height:118px;
            top: 195px;
            background-size: 250px;
          }
        }
        .stitle-box{
          @include media-breakpoint-down(xl) {
            padding-bottom: 40px;
          }
          h4{
            .h4-2{
              //padding-right: 80px;
              padding-right: 75px;
              @include media-breakpoint-down(xl) {
                padding-right: 10px;
              }
            }
            &:after{
              position: absolute;
              content:"";
              display: block;
              width: 70px;
              height: 80px;
              background-size: 70px;
              bottom:-55px;
              right: 10px;
              background-repeat: no-repeat;
              background-image: url("/assets/img/slash.svg");
              @include media-breakpoint-down(5xl) {
                width: 63px;
                height: 70px;
                background-size: 63px;
                bottom:-45px;
                right: 13px;
              }
              @include media-breakpoint-down(4xl) {
                width: 60px;
                height: 72px;
                background-size: 60px;
                bottom:-40px;
                right: 15px;
              }
              @include media-breakpoint-down(xxxl) {
                width: 60px;
                height: 72px;
                background-size: 60px;
                bottom:-40px;
                right: 15px;
              }
              @include media-breakpoint-down(xxl) {
                width: 53px;
                height: 64px;
                background-size: 53px;
                bottom:-40px;
                right: 15px;
              }
              @include media-breakpoint-down(xl) {
                width: 31px;
                height: 38px;
                background-size: 31px;
                bottom:-25px;
                right: -30px;
              }
            }
          }
        }
      }
      .fiable-content-box{
        position: relative;
        //padding-bottom: 100px;
        .dx-bg-box{
          width: 140px;
          height:680px;
          top: 0;
          right: 0;
          background-size: 560px;
          @include media-breakpoint-down(5xl) {
            width: 120px;
            height:680px;
            background-size: 560px;
          }
          @include media-breakpoint-down(4xl) {
            width: 100px;
            height:680px;
            background-size: 560px;
          }
          @include media-breakpoint-down(xxxl) {
            width: 80px;
            height:680px;
            background-size: 500px;
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            width: 80px;
            height:100px;
            background-size: 250px;
            top: 300px;
          }
        }
        .col-pad{
          //padding-top: 40px;
          //padding-bottom: 40px;
          padding-top: 35px;
          padding-bottom:35px;
          position: relative;
          z-index: 2;
          @include media-breakpoint-down(5xl) {
          }
          @include media-breakpoint-down(4xl) {
          }
          @include media-breakpoint-down(xxxl) {
            padding-top: 35px;
            padding-bottom:35px;
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
          }
          &:before{
            @include media-breakpoint-down(xxl) {
              content: "";
              background-color: $color-alt-3;
              height: 100%;
              width: 100px;
              display: block;
              position: absolute;
              top:0;
              left: -72px;
              z-index: -1;
            }
          }
          .btn-link-box{
            //z-index: 100;
            //position: relative;
          }
        }
        .stitle-box{
          //padding-top:115px;
          padding-top:105px;
          @include media-breakpoint-down(xl) {
            padding-top:145px;
          }
          h4{
            text-align: right;
            &:before{
              content:"";
              display: block;
             // width: 80px;
              //height: 97px;
              //left: 65px;
              //background-size: 80px;
              width: 70px;
              height:80px;
              background-size: 70px;
              left: 110px;
              position: absolute;
              bottom:0px;
              background-repeat: no-repeat;
              background-image: url("/assets/img/slash.svg");
              @include media-breakpoint-down(5xl) {
                left: 110px;
                width: 60px;
                height:70px;
                background-size: 60px;
              }
              @include media-breakpoint-down(4xl) {
                background-size:60px;
                width: 60px;
                height:72px;
                bottom:0px;
                left: 40px;
              }
              @include media-breakpoint-down(xxxl) {
                background-size:60px;
                width: 60px;
                height:72px;
                bottom:0px;
                left: -25px;
              }
              @include media-breakpoint-down(xxl) {
                left: -60px;
              }
              @include media-breakpoint-down(xl) {
                background-size:32px;
                width: 32px;
                height:38px;
                bottom:0px;
                left: auto;
                right: 170px;
              }
            }
          }
        }
        .c-box-1{
          //padding-top: 110px;
          //padding-bottom: 25px;
          padding-top: 100px;
          padding-bottom: 20px;
          @include media-breakpoint-down(5xl) {
            padding-top: 90px;
            padding-bottom: 20px;
          }
          @include media-breakpoint-down(4xl) {
            padding-top: 80px;
            padding-bottom: 15px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-top: 80px;
            padding-bottom: 15px;
          }
          @include media-breakpoint-down(xxl) {
            padding-top: 20px;
            padding-bottom: 15px;
          }
          @include media-breakpoint-down(xl) {
            padding-top: 45px;
            padding-bottom: 0;
          }
        }
        .c-box-2{
          //padding-top: 15px;
          //padding-bottom: 120px;
          padding-top: 10px;
          padding-bottom: 100px;
          @include media-breakpoint-down(5xl) {
            padding-top: 5px;
            padding-bottom: 80px;
          }
          @include media-breakpoint-down(4xl) {
            padding-top: 0;
            padding-bottom: 60px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-top: 0;
            padding-bottom: 60px;
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
          }
        }
        .pict-box{
          &.pic-top{
            //top: 475px;
            top: 450px;
            @include media-breakpoint-down(5xl) {
              top: 425px;
            }
            @include media-breakpoint-down(4xl) {
              top: 400px;
            }
            @include media-breakpoint-down(xxxl) {
              top: 400px;
            }
            @include media-breakpoint-down(xxl) {
              top: 460px;
            }
            @include media-breakpoint-down(xl) {
              top: auto;
              bottom:-10%;
            }
          }
          .img-box{
            width: 100%;
            img{
              max-width: 50%;
              @include media-breakpoint-down(5xl) {
                max-width: 43%;
              }
              @include media-breakpoint-down(4xl) {
                max-width: 40%;
              }
              @include media-breakpoint-down(xxxl) {
                max-width: 40%;
              }
              @include media-breakpoint-down(xxl) {
              }
              @include media-breakpoint-down(xl) {
                max-width: 37%;
              }
              @include media-breakpoint-down(sm) {
                max-width: 47%;
              }
            }
          }
        }
        .btn-link-box{
          //margin-top: 65px;
          margin-top: 50px;
          @include media-breakpoint-down(5xl) {
            margin-top: 35px;
          }
          @include media-breakpoint-down(4xl) {
            margin-top: 20px;
          }
          @include media-breakpoint-down(xxxl) {
            margin-top: 20px;
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
          }
        }
      }
      .facile-content-box{
        //padding-bottom: 200px;
        padding-bottom: 180px;
        position: relative;
        @include media-breakpoint-down(5xl) {
          padding-bottom: 140px;
        }
        @include media-breakpoint-down(4xl) {
          padding-bottom: 120px;
        }
        @include media-breakpoint-down(xxxl) {
        }
        @include media-breakpoint-down(xxl) {
          padding-bottom: 85px;
        }
        @include media-breakpoint-down(xl) {
        }
        .dx-bg-box{
          &.dx-bg-1{
            left: 0;
            z-index: 0;
            //width: 395px;
            //height:680px;
            //top: -120px;
            //background-size: 600px;
            width: 350px;
            height:620px;
            top: -100px;
            @include media-breakpoint-down(5xl) {
              width: 300px;
              height:680px;
              top: -80px;
            }
            @include media-breakpoint-down(4xl) {
              width: 105px;
              height:480px;
              top: -50px;
            }
            @include media-breakpoint-down(xxxl) {
              width: 105px;
              height:480px;
              top: -50px;
            }
            @include media-breakpoint-down(xxl) {
              width: 105px;
              height:480px;
              top: -50px;
            }
            @include media-breakpoint-down(xl) {
              width: 100px;
              height:118px;
              top: auto;
              bottom: 20%;
            }
          }
          &.dx-bg-2{
            right: 0;
            z-index: 0;
            width: 200px;
            height:208px;
            bottom:0;
            @include media-breakpoint-down(5xl) {
              width: 190px;
              height:198px;
            }
            @include media-breakpoint-down(4xl) {
              width: 180px;
              height:188px;
            }
            @include media-breakpoint-down(xxxl) {
              width: 150px;
              height:114px;
            }
            @include media-breakpoint-down(xxl) {
              width: 140px;
              height:100px;
            }
            @include media-breakpoint-down(xl) {
              display: none;
            }
          }
        }
        .stitle-box{
          //padding-top: 135px;
          padding-top: 160px;
          @include media-breakpoint-down(5xl) {
            padding-top: 90px;
          }
          @include media-breakpoint-down(4xl) {
            padding-top: 60px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-top: 60px;
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            padding-top: 115px;
          }
          h4{
            @include media-breakpoint-down(xl) {
              text-align: right;
            }
            .h4-2{
              padding-right: 80px;
              @include media-breakpoint-down(xl) {
                padding-right: 0px;
              }
            }
            &:after{
              content:"";
              display: block;
              position: absolute;
              //width: 80px;
              //height: 97px;
              //background-size: 80px;
             // bottom:-55px;
              bottom:-85px;
              right: 10px;
              width: 70px;
              height:80px;
              background-size: 70px;
              background-image: url("/assets/img/slash.svg");
              @include media-breakpoint-down(5xl) {
                right: -10px;
                width: 60px;
                height:73px;
                background-size: 60px;
              }
              @include media-breakpoint-down(4xl) {
                width: 60px;
                height: 73px;
                background-size: 60px;
                bottom:-55px;
                right: 10px;
              }
              @include media-breakpoint-down(xxxl) {
                width: 60px;
                height: 73px;
                background-size: 60px;
                bottom:-55px;
                right: 10px;
              }
              @include media-breakpoint-down(xxl) {
              }
              @include media-breakpoint-down(xl) {
                background-size:32px;
                width: 32px;
                height:38px;
              }
            }
          }
        }
        .content-box{
          //padding-top: 120px;
          //padding-bottom: 200px;
          padding-top: 110px;
          padding-bottom: 100px;
          @include media-breakpoint-down(5xl) {
            padding-top: 100px;
            padding-bottom: 50px;
          }
          @include media-breakpoint-down(4xl) {
            padding-top: 90px;
            padding-bottom: 0;
          }
          @include media-breakpoint-down(xxxl) {
            padding-top: 90px;
            padding-bottom: 0;
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            padding-top: 60px;
          }
          .btn-link-box{
            //margin-top: 65px;
            margin-top: 60px;
            @include media-breakpoint-down(5xl) {
              margin-top: 50px;
            }
            @include media-breakpoint-down(4xl) {
              margin-top: 50px;
            }
            @include media-breakpoint-down(xxxl) {
              margin-top: 40px;
            }
            @include media-breakpoint-down(xxl) {
            }
            @include media-breakpoint-down(xl) {
            }
          }
        }
        .pict-box{
          &.pic-top{
            @include media-breakpoint-down(xl) {
              top: auto;
              bottom: 0px;
            }
            @include media-breakpoint-down(sm) {
              top: auto;
              bottom: 0;
            }
          }
          .img-box{
            img{
              @include media-breakpoint-down(5xl) {
                max-width: 42%;
              }
              @include media-breakpoint-down(4xl) {
                max-width: 38%;
              }
              @include media-breakpoint-down(xl) {
                max-width: 30%;
              }
              @include media-breakpoint-down(sm) {
                max-width: 40%;
              }
            }
          }
        }
      }
      .content-box{
        p{
          @include font-light-1;
          //font-size: 24px;
          //line-height: 48px;
          //letter-spacing: 0.6px;
          font-size: 22px;
          line-height: 46px;
          letter-spacing: 0.55px;
          @include media-breakpoint-down(5xl) {
            font-size: 20px;
            line-height: 40px;
            letter-spacing: 0.45px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 18px;
            line-height: 40px;
            letter-spacing: 0.45px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 18px;
            line-height: 36px;
            letter-spacing: 0.45px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 12px;
            line-height: 25px;
            letter-spacing: 0.32px;
          }
        }
        .btn-link-box{
          .btn-link{
            z-index: 20;
            position: relative;
          }
        }
      }
      .pict-box{
        width: 100%;
        position: absolute;
        //background-color: red;
        z-index: 2;

        &.pict-left{
          left: 0;
        }
        &.pict-right{
          right: -60px;
          text-align: right;
          @include media-breakpoint-down(xl) {
            right: -20px;
          }
        }
        &.pic-top{
          //top: 500px;
          top: 500px;
          @include media-breakpoint-down(5xl) {
            top: 410px;
          }
          @include media-breakpoint-down(4xl) {
            top: 340px;
          }
          @include media-breakpoint-down(xxxl) {
            top: 340px;
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
          }
        }
        &.pict-bottom{
          bottom: -325px;
          @include media-breakpoint-down(5xl) {
          }
          @include media-breakpoint-down(4xl) {
            bottom: -240px;
          }
          @include media-breakpoint-down(xxxl) {
            bottom: -240px;
          }
          @include media-breakpoint-down(xxl) {
            bottom: -100px;
          }
          @include media-breakpoint-down(xl) {
            bottom: -180px;
          }
        }
        .img-box{
          width: 100%;
          img{
            max-width: 40%;
            @include media-breakpoint-down(5xl) {
              max-width: 35%;
            }
            @include media-breakpoint-down(4xl) {
              max-width: 34%;
            }
            @include media-breakpoint-down(xxxl) {
              max-width: 40%;
            }
            @include media-breakpoint-down(xxl) {
              max-width: 34%;
            }
            @include media-breakpoint-down(xl) {
              max-width: 35%;
            }
            @include media-breakpoint-down(sm) {
              max-width: 48%;
            }
          }
        }
      }
    }
    .section-service-conseils{
      //-top: 100px;
      position: relative;
      .service-content-box{
        //padding-top: 115px;
        padding-top: 100px;
        z-index: 1;
        position: relative;
        @include media-breakpoint-down(5xl) {
          padding-top: 85px;
        }
        @include media-breakpoint-down(4xl) {
          padding-top: 75px;
        }
        @include media-breakpoint-down(xl) {
          padding-top: 65px;
        }
        &:after{
          content: "";
          display: block;
          width: 50%;
          height: 150px;
          position: absolute;
          bottom: -150px;
          right: 0;
          background-color: $color-alt-3;
          z-index: 2;
          @include media-breakpoint-down(4xl) {
            height: 135px;
            bottom: -135px;
          }
          @include media-breakpoint-down(xxxl) {
            height: 115px;
            bottom: -115px;
          }
          @include media-breakpoint-down(xl) {
            height: 62px;
            bottom: -62px;
            right: auto;
            left: 0;
            width: 70%;
          }
        }
        .dx-bg-box{
          width: 395px;
          height:300px;
          top: 0;
          left: 0;
          z-index: 0;
          background-size: 600px;
          @include media-breakpoint-down(5xl) {
          }
          @include media-breakpoint-down(4xl) {
            background-size: 500px;
            width: 105px;
            height:266px;
            top: 0;
          }
          @include media-breakpoint-down(xxxl) {
            background-size: 500px;
            width: 105px;
            height:266px;
            top: 0;
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            width: 100px;
            height:118px;
            top: 0;
            background-size: 250px;
          }
        }
        .col-title{
          text-align: center;
          @include media-breakpoint-down(md) {
            text-align: right;
          }
        }
        .title-box{
          margin: 0 auto;
          display: inline-flex;
          flex-direction: column;
          //padding-bottom: 120px;
          padding-bottom: 105px;
          @include media-breakpoint-down(5xl) {
            padding-bottom: 90px;
          }
          @include media-breakpoint-down(4xl) {
            padding-bottom: 80px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-bottom: 40px;
          }
          @include media-breakpoint-down(md) {
            align-items: flex-end;
          }
          h3{
            text-align: left;
            text-transform: uppercase;
            @include font-bold-1;
            //font-size: 90px;
            //line-height: 90px;
            //letter-spacing: 4.48px;
            //margin-bottom: 11px;
            font-size: 82px;
            line-height: 82px;
            letter-spacing: 4px;
            margin-bottom: 9px;
            //background-color: green;
            @include media-breakpoint-down(5xl) {
              font-size: 76px;
              line-height: 76px;
              letter-spacing: 3.66px;
              //background-color: pink;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 66px;
              line-height: 66px;
              letter-spacing: 3.33px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 66px;
              line-height: 66px;
              letter-spacing: 3.33px;
              //background-color: red;
            }
            @include media-breakpoint-down(xxl) {
              //background-color: blue;
            }
            @include media-breakpoint-down(xl) {
              font-size: 32px;
              line-height: 32px;
              letter-spacing: 1.6px;
              //background-color: yellow;
            }
            .h3-2{
              display: block;
              @include font-title-reg;
              font-style: italic;
              text-transform: none;
              //font-size: 112px;
              //line-height: 102px;
              //margin-left: -75px;
              //letter-spacing: -3px;
              font-size: 100px;
              line-height: 98px;
              margin-left: -70px;
              letter-spacing: 0px;
              @include media-breakpoint-down(5xl) {
                font-size: 92px;
                line-height: 90px;
                margin-left: -60px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 83px;
                line-height: 76px;
                margin-left: -55px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 83px;
                line-height: 76px;
                margin-left: -55px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 40px;
                line-height: 36px;
                margin-left: -25px;
              }
            }
          }
          h4{
            text-align: left;
            //font-size: 38px;
            //line-height: 57px;
            //letter-spacing: 1.89px;
            font-size: 34px;
            line-height: 52px;
            letter-spacing: 1.6px;
            text-transform: uppercase;
            @include font-reg-1;
            @include media-breakpoint-down(5xl) {
              font-size: 32px;
              line-height: 40px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 28px;
              line-height: 34px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 28px;
              line-height: 34px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 13px;
              line-height: 16px;
              letter-spacing: 0.68px;
            }
          }
        }
        .conseil-txt-box{
          //padding-bottom: 100px;
          padding-bottom: 90px;
          @include media-breakpoint-down(5xl) {
            padding-bottom: 80px;
          }
          @include media-breakpoint-down(4xl) {
            padding-bottom: 70px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-bottom: 20px;
          }
          &.place-col{
            //padding-top: 160px;
            padding-top: 145px;
            @include media-breakpoint-down(xl) {
              padding-top: 0;
            }
          }
          .title-slash-box{
            //padding-bottom: 60px;
            padding-bottom: 50px;
            @include media-breakpoint-down(xxxl) {
              padding-bottom: 45px;
            }
            @include media-breakpoint-down(xl) {
              padding-bottom: 20px;
            }
          }
          p{
            @include font-light-1;
            //font-size: 24px;
            //line-height: 48px;
            //letter-spacing: 0.6px;
            //margin-bottom: 30px;
            font-size: 22px;
            line-height: 44px;
            letter-spacing: 0.5px;
            margin-bottom: 25px;
            @include media-breakpoint-down(5xl) {
              font-size: 20px;
              line-height: 40px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 18px;
              line-height: 42px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 17px;
              line-height: 35px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 12px;
              line-height: 25px;
            }
          }
          .btn-link-box{
            //margin-top: 88px;
            //margin-left: -20px;
            margin-top: 78px;
            margin-left: -20px;
            @include media-breakpoint-down(5xl) {
              margin-top: 64px;
              margin-left: -20px;
            }
            @include media-breakpoint-down(4xl) {
              margin-top: 50px;
              margin-left: -20px;
            }
            @include media-breakpoint-down(xxxl) {
              margin-top: 28px;
              margin-left: -20px;
            }
            @include media-breakpoint-down(xl) {
              margin-top: 5px;
              margin-left: -10px;
            }
          }
        }
      }
      .photo-big{
        position: relative;
        z-index: 0;
      }
    }
  }
}//#dx-page-body
