form{
  //Label
  label{
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.7px;
    @include font-bold-1;
    padding-bottom: 10px;
  }
  //Input
  input{

      &::-ms-clear {
          display: none;
      }

    //Type date
    &[type=date]{
      display: block;
      margin-bottom: 30px;
      padding: 21px;
      border: 2px solid #ACACAD;
      border-radius: 40px;
      @include media-breakpoint-down(xl) {
        padding: 14px;
        border-radius: 30px;
        margin-bottom: 20px;
        border: 1px solid #ACACAD;
      }
    }
    //Type submit
    &[type=submit]{
      &:hover{

      }
    }
  }
  //Text + Text area
  input[type=text],textarea,input[type=email]{
    //Placeholder
    background-color: white;
    border-radius: 40px;
    padding: 21px;
    border: 2px solid #ACACAD;
    margin-bottom: 30px;
    @include media-breakpoint-down(xl) {
      padding: 14px;
      border-radius: 30px;
      margin-bottom: 20px;
      border: 1px solid #ACACAD;
    }
    &::placeholder{

    }
  }
  //Textarea seul
  textarea{
    background-color: white!important;
    border-radius: 40px!important;
    padding: 21px;
    border: 2px solid #ACACAD!important;
    margin-bottom: 30px;
    @include media-breakpoint-down(xl) {
      padding: 14px;
      border-radius: 30px!important;
      margin-bottom: 20px;
      border: 1px solid #ACACAD!important;
    }
  }
}
