@charset "utf-8";

/*
------------------------------------------------------------------------------------
Feuille de style
Source SCSS compilée avec gulp
------------------------------------------------------------------------------------
By Stef et Marie
www.stefetmarie.com
*/


/* ------------------------------------------------------------------------------------
// DEPENDANCES
/ ------------------------------------------------------------------------------------*/


/* ------------------------------------------------------------------------------------
// IMPORTS
/ ------------------------------------------------------------------------------------*/

//Bootstrap
@import "_bootstrap/bootstrap";


//Mixins: reusables functions
@import "mixins";

//Constants: variables and configs (colors, zindex, sizes)
@import "constants";

//Animations
@import "animations";

//Fonts!
@import "fonts";

//Preload
@import "preload";

//Base
@import "general";

//Partials
@import "_partials/header";
@import "_partials/footer";
@import "_partials/forms";
@import "_partials/_modals/modals";

//Pages
@import "_partials/_pages/404";
@import "_partials/_pages/accueil";
@import "_partials/_pages/a-propos";
@import "_partials/_pages/search";
@import "_partials/_pages/fun";
@import "_partials/_pages/products";
@import "_partials/_pages/product-single";
@import "_partials/_pages/contact";
@import "_partials/_pages/formulaire-commande";
@import "_partials/_pages/inspiration";
@import "_partials/_pages/conditions";
@import "_partials/_pages/zerofail";
@import "_partials/_pages/recrutement_general";
@import "_partials/_pages/accueil-recrutement";
@import "_partials/_pages/recrutement_postes_listing";
@import "_partials/_pages/recrutement_poste_details";
@import "_partials/_pages/recrutement_formulaire";

// Morceaux
@import "_partials/_pages/carousel-mobilier";
@import "_partials/_components/_open-more-content";
@import "_partials/_components/_map";
@import "_partials/_components/_objets-flottants";


//Swiper
@import "../../../node_modules/swiper/swiper-bundle.css";
@import "_partials/_swiper/base";
@import "_partials/_swiper/themes";
@import '../../../node_modules/aos/src/sass/aos.scss';

//Lightbox
@import '../../../node_modules/glightbox/dist/css/glightbox.min.css';
