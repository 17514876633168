@charset "utf-8";

/* ------------------------------------------------------------------------------------
// STYLES GENERAUX
/ ------------------------------------------------------------------------------------*/

html, body {
}

body {
    background-color: $color-bg;
    //width: 200%;
    //height: 200%;
    //transform-origin: top left;
    //transform: scale(50%);
    /*
    Nous utilisons toujours un div body, car parfois sur les sites d'émission télé nos sites doivent pouvoir être portés dans un gabarit du diffuseur et nous n'avons pas toujours le contrôle du body.
     */
    #dx-page-body {
        //@include font-reg-1;
        color: $color-text-main;
        font-size: 16px;
        line-height: 26px;
        *:focus {
            outline: none;
        }
        button:focus {
            outline: none;
        }
        h1 {
            position: relative;
            @include font-bold-1;
            font-size:15px;
            line-height: 22px;
            letter-spacing: 0.75px;
            @include media-breakpoint-down(lg) {
                //font-size:30px;
                //line-height: 35px;
            }
        }
        h1.h1-2 {
            position: relative;
            @include font-reg-1;
            font-size:15px;
            line-height: 22px;
            letter-spacing: 0.75px;
            @include media-breakpoint-down(lg) {
                //font-size:30px;
                //line-height: 35px;
            }
        }
        h2 {
            position: relative;
            @include font-bold-1;
            font-size:18px;
            line-height: 22px;
            letter-spacing: 0.45px;
            @include media-breakpoint-down(lg) {
                //font-size:25px;
                //line-height: 33px;
            }
        }
        h2.h2-2 {
            position: relative;
            @include font-reg-1;
            font-size:18px;
            line-height: 22px;
            letter-spacing: 0.45px;
            @include media-breakpoint-down(lg) {
                //font-size:25px;
                //line-height: 33px;
            }
        }
        h2.h2-3 {
            position: relative;
            @include font-black-1;
            font-size:18px;
            line-height: 22px;
            letter-spacing: 0.45px;
            @include media-breakpoint-down(lg) {
                //font-size:25px;
                //line-height: 33px;
            }
        }
        h2.h2-4 {
            position: relative;
            @include font-reg-1;
            font-size:18px;
            line-height: 22px;
            font-style: italic;
            @include media-breakpoint-down(lg) {
                //font-size:25px;
                //line-height: 33px;
            }
        }
        h3 {
            position: relative;
            @include font-bold-1;
            font-size:32px;
            line-height: 40px;
            @include media-breakpoint-down(lg) {
                //font-size:17px;
                //line-height: 22px;
            }
        }
        h3.h3-2 {
            position: relative;
            @include font-reg-1;
            font-size:32px;
            line-height: 40px;
            @include media-breakpoint-down(lg) {
                //font-size:25px;
                //line-height: 33px;
            }
        }
        h4 {
            position: relative;
            @include font-title-reg;
            font-size:22px;
            line-height: 26px;
            @include media-breakpoint-down(lg) {
                //font-size:16px;
                //line-height: 21px;
            }
        }
        h5 {
            position: relative;
            @include font-title-reg;
            font-size:22px;
            line-height: 26px;
            @include media-breakpoint-down(lg) {
                //font-size:16px;
                //line-height: 21px;
            }
        }
        a {
            transition: color 0.2s linear;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }
        p {
            @include font-light-1;
            font-size:18px;
            line-height: 26px;
            letter-spacing: 0.6px;
            @include media-breakpoint-down(5xl) {
                font-size: 17px;
                line-height: 21px;
                letter-spacing: 0.4px;
            }
            @include media-breakpoint-down(4xl) {
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.35px;
            }
            @include media-breakpoint-down(xxxl) {
                font-size: 15px;
                line-height: 19px;
                letter-spacing: 0.3px;
            }
            @include media-breakpoint-down(xxl) {
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.25px;
            }
            @include media-breakpoint-down(lg) {
                font-size:14px;
                line-height: 22px;
                letter-spacing: 0.3px;
            }
            &:empty {
                display: none;
            }
        }
        p.lead, .lead, .lead p {
            //font-size: 28px;
            //line-height: 36px;
            //letter-spacing: 1.35px;
            font-size: 25px;
            line-height: 33px;
            letter-spacing: 1.15px;
            @include font-reg-1;
            @include media-breakpoint-down(5xl) {
                font-size:22px;
                line-height: 27px;
                letter-spacing: 1px;
            }
            @include media-breakpoint-down(4xl) {
                font-size: 19px;
                line-height: 24px;
                letter-spacing: 0.9px;
            }
            @include media-breakpoint-down(xxxl) {
                font-size: 19px;
                line-height: 24px;
                letter-spacing: 0.9px;
            }
            @include media-breakpoint-down(xxl) {
                font-size: 17px;
                line-height: 22px;
                letter-spacing: 0.7px;
            }
            @include media-breakpoint-down(xl) {
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.7px;
            }
        }
        p.p-small, .p-small, .p-small p {
            //font-size: 12px;
            //line-height: 18px;
            @include media-breakpoint-down($tablet-size) {
                //font-size: 18px;
                //line-height: 28px;
            }
        }
        .font-light{
            @include font-light-1;
        }
        .txt-center {
            text-align: center;
        }
        .color-inverse {
            color: $color-text-alt;
        }
        .accentColor {
            color: $color-alt-1;
        }
        .hide {
            display: none;
        }
        .order-6{
            order: 6 !important;
        }
        .order-xxl-6{
            @include media-breakpoint-down(xl) {
                order:6 !important;
            }
        }
        .sr-only{
            display: none;
        }
        .offset-5xl-6{
            @include media-breakpoint-up(5xl) {
                //margin-left: 50%;
            }
        }
        .underline{
            text-decoration: underline;
        }
        .fleche-back-box{ // dans produits single et form commande
            padding-top: 50px;
            padding-bottom: 50px;
            @include media-breakpoint-down(5xl) {
                padding-top: 45px;
                padding-bottom: 45px;
            }
            @include media-breakpoint-down(4xl) {
                padding-top: 40px;
                padding-bottom: 40px;
            }
            @include media-breakpoint-down(xxxl) {
                padding-top: 35px;
                padding-bottom: 35px;
            }
            @include media-breakpoint-down(xxl) {
                padding-top: 35px;
                padding-bottom: 35px;
            }
            @include media-breakpoint-down(xl) {
            }
            @include media-breakpoint-down(lg) {
            }
            @include media-breakpoint-down(md) {
            }
            @include media-breakpoint-down(sm) {
            }
        }
        .title-slash-box{
            position: relative;
            margin: 0 auto;
            //width: 30%;
            display: inline-flex;
            flex-direction: column;
            padding-bottom: 21px;
            padding-left: 50px;
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xxl) {
            }
            @include media-breakpoint-down(xl) {
            }
            &.slash-align-right{
                margin:0 0 0 auto;
            }
            &.slash-left{
                padding-left: 80px;
                @include media-breakpoint-down(5xl) {
                    padding-left: 35px;
                }
                @include media-breakpoint-down(4xl) {
                    padding-left: 30px;
                }
                @include media-breakpoint-down(xxxl) {
                }
                @include media-breakpoint-down(xl) {
                    padding-left: 35px;
                }
                &:before{
                    background-image: url("/assets/img/slash.svg");
                    background-repeat: no-repeat;
                    //background-size: 96px;
                    //width: 96px;
                    //height: 118px;
                    background-size: 86px;
                    width: 86px;
                    height: 114px;
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    @include media-breakpoint-down(5xl) {
                        background-size: 80px;
                        width: 80px;
                        height: 100px;
                        left: -45px;
                    }
                    @include media-breakpoint-down(4xl) {
                        background-size: 71px;
                        width: 71px;
                        height: 87px;
                        left: -40px;
                    }
                    @include media-breakpoint-down(xxxl) {
                        background-size: 71px;
                        width: 71px;
                        height: 87px;
                    }
                    @include media-breakpoint-down(xxl) {
                        background-size: 63px;
                        width: 63px;
                        height: 77px;
                    }
                    @include media-breakpoint-down(xl) {
                        left: -5px;
                        background-size: 44px;
                        width: 44px;
                        height: 54px;
                    }
                }
                &.slash-white{
                    &:before{
                        background-image: url("/assets/img/slash-white.svg");
                    }
                }
            }
            &.slash-right{
                //padding-right: 68px;
                padding-right: 60px;
                @include media-breakpoint-down(4xl) {
                    padding-right: 55px;
                }
                @include media-breakpoint-down(xxl) {
                    padding-right: 50px;
                }
                @include media-breakpoint-down(xl) {
                    padding-right: 34px;
                }
                &:after{
                    background-image: url("/assets/img/slash.svg");
                    background-repeat: no-repeat;
                    //width: 96px;
                    //height: 118px;
                    //background-size: 96px;
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-size: 80px;
                    width: 80px;
                    height: 100px;
                    @include media-breakpoint-down(4xl) {
                        background-size: 71px;
                        width: 71px;
                        height: 87px;
                    }
                    @include media-breakpoint-down(xxxl) {
                        background-size: 71px;
                        width: 71px;
                        height: 87px;
                    }
                    @include media-breakpoint-down(xl) {
                        background-size: 44px;
                        width: 44px;
                        height: 54px;
                    }
                }
                .part-1{
                    text-align: right;
                }
                .part-2{
                    h4{
                        margin-left: 0;
                        margin-right: 52px;
                        text-align: right;
                        @include media-breakpoint-down(4xl) {
                            margin-right: 28px;
                        }
                        @include media-breakpoint-down(xxxl) {
                            margin-right: 28px;
                        }
                        @include media-breakpoint-down(xl) {
                            margin-right: 20px;
                        }
                    }
                }
            }
            &.slash-small{
                padding-left: 60px;
                @include media-breakpoint-down(4xl) {
                    padding-left: 35px;
                }
                @include media-breakpoint-down(xxxl) {
                }
                @include media-breakpoint-down(xl) {
                    padding-left: 30px;
                }
                &:before{
                    background-image: url("/assets/img/slash.svg");
                    background-repeat: no-repeat;
                    background-size: 68px;
                    width: 68px;
                    height: 80px;
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    @include media-breakpoint-down(4xl) {
                        background-size: 50px;
                        width: 50px;
                        height: 70px;
                    }
                    @include media-breakpoint-down(xxxl) {
                        background-size: 43px;
                        width: 43px;
                        height: 59px;
                    }
                    @include media-breakpoint-down(xl) {
                        background-size: 31px;
                        width: 31px;
                        height: 36px;
                    }
                }
                h5{
                    font-size: 32px;
                    line-height: 40px;
                    @include font-bold-1;
                    text-transform: uppercase;
                    margin-bottom: 0;
                    @include media-breakpoint-down(4xl) {
                        font-size: 26px;
                        line-height: 34px;
                    }
                    @include media-breakpoint-down(xxxl) {
                        font-size: 23px;
                        line-height: 29px;
                    }
                    @include media-breakpoint-down(xl) {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
                .part-1{
                    margin-left: 60px;
                    @include media-breakpoint-down(4xl) {
                        margin-left: 50px;
                    }
                    @include media-breakpoint-down(xxxl) {
                        margin-left: 45px;
                    }
                    @include media-breakpoint-down(xxxl) {
                        margin-left: 15px;
                    }
                }
            }

            .part-1{
                h2,h3,h4{
                    //font-size: 32px;
                    //line-height: 38px;
                    font-size: 29px;
                    line-height: 35px;
                    text-transform: uppercase;
                    @include font-reg-1;
                    margin-bottom: 0;
                    @include media-breakpoint-down(5xl) {
                        font-size: 26px;
                        line-height: 32px;
                    }
                    @include media-breakpoint-down(4xl) {
                        font-size: 24px;
                        line-height: 30px;
                    }
                    @include media-breakpoint-down(xxxl) {
                        font-size: 24px;
                        line-height: 30px;
                    }
                    @include media-breakpoint-down(xl) {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }
            .part-2{
                h2,h3,h4{
                    display: block;
                    //font-size: 75px;
                    //line-height: 75px;
                    //letter-spacing: -3px;
                    text-transform: none;
                    @include font-title-reg;
                    font-style: italic;
                    margin-bottom: 0;
                    font-size: 68px;
                    line-height: 68px;
                    letter-spacing: 0;
                    @include media-breakpoint-down(5xl) {
                        font-size: 60px;
                        line-height: 60px;
                    }
                    @include media-breakpoint-down(4xl) {
                        font-size: 55px;
                        line-height: 55px;
                    }
                    @include media-breakpoint-down(xxxl) {
                        font-size: 55px;
                        line-height: 55px;
                    }
                    @include media-breakpoint-down(xl) {
                        font-size: 34px;
                        line-height: 39px;
                    }
                }
            }
        }
        .title-slash-v2{
            position: relative;
            margin: 0 auto;
            //width: 30%;
            display: inline-flex;
            flex-direction: column;
            padding-bottom: 25px;
            padding-left: 50px;
            &.slash-left{
                padding-left: 120px;
                @include media-breakpoint-down(4xl) {
                    padding-left: 100px;
                }
                @include media-breakpoint-down(xxxl) {
                }
                @include media-breakpoint-down(xl) {
                    padding-left: 50px;
                }
                &:before{
                    background-image: url("/assets/img/slash.svg");
                    background-size: 80px;
                    background-repeat: no-repeat;
                    width: 80px;
                    height: 97px;
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 30px;
                    left: 0;
                    @include media-breakpoint-down(4xl) {
                        background-size: 80px;
                        width: 80px;
                        height: 97px;
                    }
                    @include media-breakpoint-down(xxxl) {
                        background-size: 71px;
                        width: 71px;
                        height: 87px;
                    }
                    @include media-breakpoint-down(xxl) {
                        background-size: 63px;
                        width: 63px;
                        height: 77px;
                    }
                    @include media-breakpoint-down(xl) {
                        background-size: 44px;
                        width: 44px;
                        height: 54px;
                    }
                    @include media-breakpoint-down(lg) {
                        background-size: 31px;
                        width: 31px;
                        height: 38px;
                        bottom: 35px;
                    }
                }
                &.slash-white{
                    &:before{
                        background-image: url("/assets/img/slash-white.svg");
                    }
                }
            }
            .part-1{
                h2,h3,h4{
                    //font-size: 168px;
                    //line-height: 168px;
                    text-transform: lowercase;
                    @include font-title-med;
                    font-style: italic;
                    margin-bottom: 30px;
                    font-size: 150px;
                    line-height: 150px;
                    @include media-breakpoint-down(5xl) {
                        font-size: 135px;
                        line-height: 135px;
                    }
                    @include media-breakpoint-down(4xl) {
                        font-size: 124px;
                        line-height: 124px;
                    }
                    @include media-breakpoint-down(xxxl) {
                        font-size: 110px;
                        line-height: 110px;
                    }
                    @include media-breakpoint-down(xxl) {
                        font-size: 95px;
                        line-height: 95px;
                        //margin-left: -255px;
                    }
                    @include media-breakpoint-down(xl) {
                        font-size: 66px;
                        line-height: 66px;
                        margin-bottom: 15px;
                    }
                    @include media-breakpoint-down(xl) {
                        //font-size: 44px;
                        //line-height: 62px;
                        //margin-left: -20px;
                    }
                }
            }
            .part-2{
                h2,h3,h4{
                    display: block;
                    //font-size: 32px;
                    //line-height: 35px;
                    text-transform: uppercase;
                    @include font-reg-1;
                    font-weight: normal;
                    letter-spacing: 2px;
                    margin-bottom: 0;
                    margin-left: -30px;
                    font-size: 30px;
                    line-height: 33px;
                    @include media-breakpoint-down(5xl) {
                        font-size: 28px;
                        line-height: 31px;
                    }
                    @include media-breakpoint-down(4xl) {
                        font-size: 24px;
                        line-height: 26px;
                    }
                    @include media-breakpoint-down(xxxl) {
                        font-size: 24px;
                        line-height: 26px;
                    }
                    @include media-breakpoint-down(xxl) {
                        font-size: 20px;
                        line-height: 24px;
                    }
                    @include media-breakpoint-down(xl) {
                        font-size: 13px;
                        line-height: 16px;
                        margin-left: -15px;
                    }
                    @include media-breakpoint-down(lg) {
                    }
                }
            }
        }
        .title-slash-v3{//a propos
            position: relative;
            margin: 0 auto;
            //width: 30%;
            display: inline-flex;
            flex-direction: column;
            padding-bottom: 25px;
            padding-left: 50px;
            @include media-breakpoint-down(4xl) {
                padding-bottom: 20px;
            }
            @include media-breakpoint-down(xxxl) {
                padding-bottom: 15px;
            }
            @include media-breakpoint-down(xxl) {
                padding-bottom: 10px;
            }
            @include media-breakpoint-down(xl) {
                margin: 0 0 0 auto;
            }
            &.slash-left{
                padding-left: 70px;
                @include media-breakpoint-down(5xl) {
                    padding-left: 60px;
                }
                @include media-breakpoint-down(4xl) {
                    padding-left: 50px;
                }
                @include media-breakpoint-down(xxxl) {
                    padding-left: 45px;
                }
                @include media-breakpoint-down(xxl) {
                    padding-left: 35px;
                }
                @include media-breakpoint-down(xl) {
                    //padding-left: 50px;
                }
                &:before{
                    background-image: url("/assets/img/slash.svg");
                    background-size: 138px;
                    background-repeat: no-repeat;
                    width: 138px;
                    height: 172px;
                    content: "";
                    display: block;
                    position: absolute;
                    top: 20px;
                    left: -140px;
                    @include media-breakpoint-down(5xl) {
                        background-size: 128px;
                        width: 128px;
                        height: 162px;
                        left: -130px;
                    }
                    @include media-breakpoint-down(4xl) {
                        background-size: 118px;
                        width: 118px;
                        height: 152px;
                        left: -120px;
                    }
                    @include media-breakpoint-down(xxxl) {
                        background-size: 100px;
                        width: 100px;
                        height: 117px;
                        left: -115px;
                    }
                    @include media-breakpoint-down(xxl) {
                        background-size: 94px;
                        width: 94px;
                        height: 117px;
                        left: -110px;
                    }
                    @include media-breakpoint-down(xl) {
                        background-size: 64px;
                        width: 64px;
                        height: 80px;
                        left: -80px;
                    }
                    @include media-breakpoint-down(lg) {
                        //background-size: 31px;
                        //width: 31px;
                        //height: 38px;
                        //bottom: 35px;
                    }
                }
                &.slash-white{
                    &:before{
                        background-image: url("/assets/img/slash-white.svg");
                    }
                }
            }
            .part-1{
                h2,h3,h4{
                    //font-size: 168px;
                    //line-height: 168px;
                    text-transform: lowercase;
                    @include font-title-reg;
                    font-style: italic;
                    margin-bottom: 30px;
                    font-size: 66px;
                    line-height: 61px;
                    @include media-breakpoint-down(5xl) {
                        font-size: 60px;
                        line-height: 53px;
                    }
                    @include media-breakpoint-down(4xl) {
                        font-size: 55px;
                        line-height: 48px;
                        margin-bottom: 25px;
                    }
                    @include media-breakpoint-down(xxxl) {
                        font-size: 50px;
                        line-height: 43px;
                    }
                    @include media-breakpoint-down(xxl) {
                        font-size:45px;
                        line-height: 41px;
                        margin-bottom: 20px;
                    }
                    @include media-breakpoint-down(xl) {
                        font-size:31px;
                        line-height: 28px;
                        margin-bottom: 15px;
                    }
                    @include media-breakpoint-down(xl) {
                        //font-size: 44px;
                        //line-height: 62px;
                        //margin-left: -20px;
                    }
                }
            }
            .part-2{
                h2,h3,h4{
                    display: block;
                    @include font-title-med;
                    font-style: italic;
                    margin-bottom: 0;
                    margin-left: -65px;
                    letter-spacing: -4px;
                    font-size: 151px;
                    line-height: 36px;
                    @include media-breakpoint-down(5xl) {
                        font-size: 141px;
                        line-height: 30px;
                        letter-spacing: -3.8px;
                    }
                    @include media-breakpoint-down(4xl) {
                        font-size: 131px;
                        line-height: 26px;
                    }
                    @include media-breakpoint-down(xxxl) {
                        font-size: 121px;
                        line-height: 22px;
                    }
                    @include media-breakpoint-down(xxl) {
                        font-size: 102px;
                        line-height: 20px;
                        margin-left: -50px;
                    }
                    @include media-breakpoint-down(xl) {
                        font-size: 70px;
                        line-height: 20px;
                        //margin-left: -15px;
                    }
                    @include media-breakpoint-down(lg) {
                    }
                }
            }
            .part-3{
                h2,h3,h4{
                    display: block;
                    @include font-title-med;
                    font-style: italic;
                    margin-bottom: 0;
                    margin-left: 235px;
                    font-size: 151px;
                    line-height: 170px;
                    letter-spacing: -4px;
                    @include media-breakpoint-down(5xl) {
                        font-size: 141px;
                        line-height: 160px;
                        letter-spacing: -3.8px;
                        margin-left: 220px;
                    }
                    @include media-breakpoint-down(4xl) {
                        font-size: 131px;
                        line-height: 150px;
                        letter-spacing: -3px;
                    }
                    @include media-breakpoint-down(xxxl) {
                        font-size: 121px;
                        line-height: 140px;
                        margin-left: 175px;
                        letter-spacing: -2.8px;
                    }
                    @include media-breakpoint-down(xxl) {
                        font-size: 102px;
                        line-height: 120px;
                        margin-left: 155px;
                        letter-spacing: -2px;
                    }
                    @include media-breakpoint-down(xl) {
                        font-size: 70px;
                        line-height: 90px;
                        margin-left: 55px;
                        letter-spacing: -1.8px;
                    }
                    @include media-breakpoint-down(lg) {
                        font-size: 70px;
                        line-height: 90px;
                        margin-left: 5px;
                        letter-spacing: -1.7px;
                    }
                }
            }
        }
        .fleche-plus{
            background-image: url("/assets/img/fleche-down.svg");
            width: 74px;
            height: 74px;
            display: block;
            margin: 0 auto;
            &.fleche-blanche{
                background-image: url("/assets/img/fleche-down-white.svg");
            }
            @include media-breakpoint-down(5xl) {
                width: 70px;
                height: 70px;
                background-size: 70px;
            }
            @include media-breakpoint-down(4xl) {
                width: 65px;
                height: 65px;
                background-size: 65px;
            }
            @include media-breakpoint-down(xxxl) {
                width: 60px;
                height: 60px;
                background-size: 60px;
            }
            @include media-breakpoint-down(xxl) {
                width: 55px;
                height: 55px;
                background-size: 55px;
            }
            @include media-breakpoint-down(xl) {
                width: 50px;
                height: 50px;
                background-size: 50px;
            }
            @include media-breakpoint-down(sm) {
                width: 40px;
                height: 40px;
                background-size: 40px;
            }
        }
        .fleche-plus-blanche{
            background-image: url("/assets/img/fleche-down-white.svg");
            //width: 107px;
            //height: 109px;
            width: 90px;
            height: 90px;
            background-size: 90px;
            display: block;
            margin: 0 auto;
            @include media-breakpoint-down(5xl) {
                width: 70px;
                height: 70px;
                background-size: 70px;
            }
            @include media-breakpoint-down(4xl) {
                width: 65px;
                height: 65px;
                background-size: 65px;
            }
            @include media-breakpoint-down(xxxl) {
                width: 60px;
                height: 60px;
                background-size: 60px;
            }
            @include media-breakpoint-down(xxl) {
                width: 55px;
                height: 55px;
                background-size: 55px;
            }
            @include media-breakpoint-down(xl) {
                width: 50px;
                height: 50px;
                background-size: 50px;
            }
            @include media-breakpoint-down(sm) {
                width: 40px;
                height: 40px;
                background-size: 40px;
            }
        }
        .dx-bg-box{
            position: absolute;
            z-index: 1;
            background-image: url("/assets/img/bg-dx.svg");
            background-size: 560px;
            @include media-breakpoint-down(5xl) {
                background-size: 560px;
            }
            @include media-breakpoint-down(4xl) {
                background-size: 500px;
            }
            @include media-breakpoint-down(xxxl) {
                background-size: 500px;
            }
            @include media-breakpoint-down(xxl) {
                background-size: 450px;
            }
            @include media-breakpoint-down(xl) {
                background-size: 250px;
            }
        }
        .btn {
            @include margin(0px, 0px, 0px, 0px);
            //box-shadow: $shadow-box-general;
            @include media-breakpoint-down(lg) {
                //font-size: 13px;
                //line-height: 15px;
                //margin: 0 5px 10px 5px;
            }
            &.btn-link{
                color: $color-text-main;
                //font-size: 34px;
                //line-height: 40px;
                font-size: 32px;
                line-height: 38px;
                @include font-title-reg;
                text-decoration: underline;
                font-style: italic;
                padding: 0;
                letter-spacing: 0.5px;
                transition: color 0.2s linear;
                @include media-breakpoint-down(4xl) {
                    font-size: 28px;
                    line-height: 32px;
                }
                @include media-breakpoint-down(xxxl) {
                    font-size: 25px;
                    line-height: 29px;
                }
                @include media-breakpoint-down(xl) {
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: 0;
                }
                &:before{
                    content: "> ";
                    display: inline-block;
                    text-decoration: none;
                    margin-right: 19px;
                    @include media-breakpoint-down(xxxl) {
                        margin-right: 14px;
                    }
                }
                &:hover{
                    color: $color-secondary;
                }
            }
            &.btn-link-med{
                color: $color-text-main;
                //font-size: 34px;
                //line-height: 40px;
                font-size: 24px;
                line-height: 32px;
                @include font-title-reg;
                text-decoration: underline;
                font-style: italic;
                padding: 0;
                letter-spacing: 0;
                transition: color 0.2s linear;
                @include media-breakpoint-down(4xl) {
                    font-size: 22px;
                    line-height: 30px;
                }
                @include media-breakpoint-down(xxxl) {
                    font-size: 21px;
                    line-height: 29px;
                }
                @include media-breakpoint-down(xl) {
                    font-size: 14px;
                    line-height: 16px;
                    letter-spacing: 0;
                }
                &:before{
                    content: "> ";
                    display: inline-block;
                    text-decoration: none;
                    margin-right: 19px;
                    @include media-breakpoint-down(xxxl) {
                        margin-right: 14px;
                    }
                }
                &:hover{
                    color: $color-secondary;
                }
            }
            &.btn-link-small{
                color: $color-text-main;
                //font-size: 18px;
                //line-height: 18px;
                font-size: 17px;
                line-height: 17px;
                @include font-title-reg;
                text-decoration: underline;
                font-style: italic;
                padding: 0;
                transition: color 0.2s linear;
                @include media-breakpoint-down(xxxl) {
                    font-size: 17px;
                    line-height: 17px;
                }
                @include media-breakpoint-down(xl) {
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0;
                }
                @include media-breakpoint-down(lg) {
                    font-size: 18px;
                    line-height: 18px;
                    letter-spacing: 0;
                }
                &:before{
                    content: "> ";
                    display: inline-block;
                    text-decoration: none;
                    margin-right: 10px;
                    @include media-breakpoint-down(xxxl) {
                        margin-right: 8px;
                    }
                }
                &:hover{
                    color: $color-secondary;
                }
            }
            &.btn-link-xsmall{
                color: $color-text-main;
                //font-size: 18px;
                //line-height: 18px;
                font-size: 17px;
                line-height: 17px;
                @include font-title-reg;
                text-decoration: underline;
                font-style: italic;
                padding: 0;
                transition: color 0.2s linear;
                @include media-breakpoint-down(xxxl) {
                    font-size: 17px;
                    line-height: 17px;
                }
                @include media-breakpoint-down(xl) {
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0;
                }
                @include media-breakpoint-down(lg) {
                    font-size: 11px;
                    line-height: 11px;
                    letter-spacing: 0;
                }
                &:before{
                    content: "> ";
                    display: inline-block;
                    text-decoration: none;
                    margin-right: 10px;
                    @include media-breakpoint-down(xxxl) {
                        margin-right: 8px;
                    }
                }
                &:hover{
                    color: $color-secondary;
                }
            }
            &.btn-fleche-back-link{
                background-image: url("/assets/img/fleche-retour.svg");
                width: 84px;
                height: 84px;
                background-size: 84px;
                background-position: center;
                display: block;
                transition: opacity 0.2s linear;
                @include media-breakpoint-down(5xl) {
                    width: 80px;
                    height: 80px;
                    background-size: 80px;
                }
                @include media-breakpoint-down(4xl) {
                    width: 76px;
                    height: 76px;
                    background-size: 76px;
                }
                @include media-breakpoint-down(xxxl) {
                    width: 66px;
                    height: 66px;
                    background-size: 66px;
                }
                @include media-breakpoint-down(xxl) {
                    width: 56px;
                    height: 56px;
                    background-size: 56px;
                }
                @include media-breakpoint-down(xl) {
                    width: 36px;
                    height: 36px;
                    background-size: 36px;
                }
                @include media-breakpoint-down(lg) {
                }
                @include media-breakpoint-down(md) {
                }
                @include media-breakpoint-down(sm) {
                }
                &:hover, &:focus{
                    opacity: 0.7;
                }
            }
            &.btn-add-product{
                background-color: $color-bg-alt-2;
                color: $color-inverse;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.45px;
                @include font-bold-1;
                padding: 25px;
                width: 100%;
                min-width: 300px;
                text-transform: uppercase;
                border-top-right-radius: 35px;
                border-bottom-right-radius: 35px;
                @include media-breakpoint-down(5xl) {
                    font-size: 17px;
                    line-height: 21px;
                    letter-spacing: 0.4px;
                }
                @include media-breakpoint-down(4xl) {
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.35px;
                    min-width: 250px;
                    padding:23px 25px;
                }
                @include media-breakpoint-down(xxxl) {
                    font-size: 15px;
                    line-height: 19px;
                    letter-spacing: 0.3px;
                    min-width: 200px;
                }
                @include media-breakpoint-down(xxl) {
                    min-width: 100px;
                    font-size: 11px;
                    line-height: 15px;
                    letter-spacing: 0.25px;
                }
                @include media-breakpoint-down(xl) {
                }
                @include media-breakpoint-down(lg) {
                    min-width: 100px;
                    font-size: 10px;
                    line-height: 12px;
                    letter-spacing: 0.26px;
                    padding: 14px 25px;
                }
                @include media-breakpoint-down(md) {
                }
                @include media-breakpoint-down(sm) {
                }
            }
            &.btn-sketchup{
                padding: 0;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.8px;
                text-decoration: underline;
                color: $color-text-main;
                display: inline-flex;
                align-items: center;
                transition: opacity 0.2s linear;
                @include media-breakpoint-down(5xl) {
                    font-size: 15px;
                    line-height: 27px;
                    letter-spacing: 0.75px;
                }
                @include media-breakpoint-down(4xl) {
                    font-size: 14px;
                    line-height: 26px;
                    letter-spacing: 0.7px;
                }
                @include media-breakpoint-down(xxxl) {
                    font-size: 13px;
                    line-height: 25px;
                    letter-spacing: 0.65px;
                }
                @include media-breakpoint-down(xxl) {
                    font-size: 12px;
                    line-height: 24px;
                    letter-spacing: 0.6px;
                }
                @include media-breakpoint-down(xl) {
                }
                @include media-breakpoint-down(lg) {
                }
                @include media-breakpoint-down(md) {
                }
                @include media-breakpoint-down(sm) {
                }
                &:before{
                    content: "";
                    display: inline-flex;
                    background-image: url("/assets/img/ico-sketchup.svg");
                    width: 45px;
                    height: 49px;
                    margin-right: 20px;
                    background-repeat: no-repeat;
                    @include media-breakpoint-down(5xl) {
                        width: 40px;
                        height: 45px;
                        margin-right: 18px;
                        background-size: 40px;
                    }
                    @include media-breakpoint-down(4xl) {
                        width: 36px;
                        height: 40px;
                        margin-right: 16px;
                        background-size: 36px;
                    }
                    @include media-breakpoint-down(xxxl) {
                        width: 30px;
                        height: 36px;
                        margin-right: 17px;
                        background-size: 30px;
                    }
                    @include media-breakpoint-down(xxl) {
                        width: 25px;
                        height: 30px;
                        margin-right: 16px;
                        background-size: 25px;
                    }
                }
                &:hover, &:focus{
                    opacity: 0.7;
                }
            }
            &.btn-pdf{
                padding: 0;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.8px;
                text-decoration: underline;
                color: $color-text-main;
                display: inline-flex;
                align-items: center;
                transition: opacity 0.2s linear;
                @include media-breakpoint-down(5xl) {
                    font-size: 15px;
                    line-height: 27px;
                    letter-spacing: 0.75px;
                }
                @include media-breakpoint-down(4xl) {
                    font-size: 14px;
                    line-height: 26px;
                    letter-spacing: 0.7px;
                }
                @include media-breakpoint-down(xxxl) {
                    font-size: 13px;
                    line-height: 25px;
                    letter-spacing: 0.65px;
                }
                @include media-breakpoint-down(xxl) {
                    font-size: 12px;
                    line-height: 24px;
                    letter-spacing: 0.6px;
                }
                @include media-breakpoint-down(xl) {
                }
                @include media-breakpoint-down(lg) {
                }
                @include media-breakpoint-down(md) {
                }
                @include media-breakpoint-down(sm) {
                }
                &:before{
                    content: "";
                    display: inline-flex;
                    background-image: url("/assets/img/ico-pdf.svg");
                    width: 44px;
                    height: 48px;
                    margin-right: 20px;
                    background-repeat: no-repeat;
                    @include media-breakpoint-down(5xl) {
                        width: 40px;
                        height: 45px;
                        margin-right: 18px;
                        background-size: 40px;
                    }
                    @include media-breakpoint-down(4xl) {
                        width: 36px;
                        height: 40px;
                        margin-right: 16px;
                        background-size: 36px;
                    }
                    @include media-breakpoint-down(xxxl) {
                        width: 30px;
                        height: 36px;
                        margin-right: 17px;
                        background-size: 30px;
                    }
                    @include media-breakpoint-down(xxl) {
                        width: 25px;
                        height: 30px;
                        margin-right: 16px;
                        background-size: 25px;
                    }
                }
                &:hover, &:focus{
                    opacity: 0.7;
                }
            }
            &.btn-zip{
                padding: 0;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.8px;
                text-decoration: underline;
                color: $color-text-main;
                display: inline-flex;
                align-items: center;
                transition: opacity 0.2s linear;
                @include media-breakpoint-down(5xl) {
                    font-size: 15px;
                    line-height: 27px;
                    letter-spacing: 0.75px;
                }
                @include media-breakpoint-down(4xl) {
                    font-size: 14px;
                    line-height: 26px;
                    letter-spacing: 0.7px;
                }
                @include media-breakpoint-down(xxxl) {
                    font-size: 13px;
                    line-height: 25px;
                    letter-spacing: 0.65px;
                }
                @include media-breakpoint-down(xxl) {
                    font-size: 12px;
                    line-height: 24px;
                    letter-spacing: 0.6px;
                }
                @include media-breakpoint-down(xl) {
                }
                @include media-breakpoint-down(lg) {
                }
                @include media-breakpoint-down(md) {
                }
                @include media-breakpoint-down(sm) {
                }
                &:before{
                    content: "";
                    display: inline-flex;
                    background-image: url("/assets/img/ico-zip.svg");
                    width: 43px;
                    height: 48px;
                    margin-right: 20px;
                    background-repeat: no-repeat;
                    @include media-breakpoint-down(5xl) {
                        width: 40px;
                        height: 45px;
                        margin-right: 18px;
                        background-size: 40px;
                    }
                    @include media-breakpoint-down(4xl) {
                        width: 36px;
                        height: 40px;
                        margin-right: 16px;
                        background-size: 36px;
                    }
                    @include media-breakpoint-down(xxxl) {
                        width: 30px;
                        height: 36px;
                        margin-right: 17px;
                        background-size: 30px;
                    }
                    @include media-breakpoint-down(xxl) {
                        width: 25px;
                        height: 30px;
                        margin-right: 16px;
                        background-size: 25px;
                    }
                    @include media-breakpoint-down(xl) {
                    }
                    @include media-breakpoint-down(lg) {
                    }
                    @include media-breakpoint-down(md) {
                    }
                    @include media-breakpoint-down(sm) {
                    }
                }
                &:hover, &:focus{
                    opacity: 0.7;
                }
            }
            &.btn-add-link{//deja reduit 90%
                background-color: $color-secondary;
                color: white;
                border-top-right-radius: 40px;
                border-bottom-right-radius: 40px;
                transition: background-color 0.2s linear, color 0.2s linear;
                @include font-bold-1;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.7px;
                padding: 23px 25px;
                width: 100%;
                display: block;
                text-transform: uppercase;
                @include media-breakpoint-down(5xl) {
                    font-size: 13px;
                    line-height: 20px;
                    letter-spacing: 0.65px;
                    padding: 18px 18px;
                }
                @include media-breakpoint-down(4xl) {
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 0.6px;
                    padding: 16px 16px;
                }
                @include media-breakpoint-down(xxxl) {
                }
                @include media-breakpoint-down(xxl) {
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 0.6px;
                    padding: 16px 16px;
                }
                @include media-breakpoint-down(xl) {
                }
                @include media-breakpoint-down(sm) {
                }
                &:hover{
                    background-color: $color-alt-3;
                    color: $color-secondary;
                }
            }
            &.btn-filtres{
                background-color: white;
                background-image: url("/assets/img/ico-filtres.svg");
                background-position: center right 17px;
                background-repeat: no-repeat;
                min-width: 170px;
                text-align: left;
                font-size: 8px;
                line-height: 12px;
                @include font-bold-1;
                text-transform: uppercase;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                padding: 12px 20px;
                margin-right: 10px;
            }
            &.btn-filtres-single{
                background-color: white;
                background-image: url("/assets/img/ico-filtres.svg");
                background-position: center right 17px;
                background-repeat: no-repeat;
                min-width: 170px;
                text-align: left;
                font-size: 8px;
                line-height: 12px;
                @include font-bold-1;
                text-transform: uppercase;
                border-radius: 20px;
                padding: 12px 20px;
                margin-right: 10px;
                margin-left: 10px;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
            &.btn-primary {
                color: $color-text-main;
                border-radius: 40px;
                padding: 10px 50px;
                font-size: 18px;
                @include media-breakpoint-down(xl) {
                    border-radius: 30px;
                    padding: 10px 30px;
                    font-size: 14px;
                }

            }
            &.btn-secondary {
                color: $color-inverse;
                border-radius: 40px;
                padding: 10px 50px;
                font-size: 18px;
                @include media-breakpoint-down(xl) {
                    border-radius: 30px;
                    padding: 10px 30px;
                    font-size: 14px;
                }
                &:hover {
                }
            }
            &.btn-secondary-demi-rond {
                color: $color-inverse;
                border-top-right-radius: 40px;
                border-bottom-right-radius: 40px;
                font-size: 12px;
                line-height: 19px;
                letter-spacing: 0.66px;
                padding: 16px 25px 16px 18px;
                background-color: $color-secondary;
                text-transform: uppercase;
                @include font-bold-1;
                transition: background-color 0.2s linear, color 0.2s linear;
                @include media-breakpoint-down(xxl) {
                    padding: 12px 20px 12px 13px;
                }
                @include media-breakpoint-down(xl) {
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;
                    padding: 10px 25px 10px 20px;
                    font-size: 11px;
                }
                &:hover {
                    background-color: $color-warning-2;
                    //color: $color-text-main;
                }
                &.granim-btn{
                    //position: relative;
                    
                }
            }
            &.btn-secondary-demi-rond-big{
                color: $color-inverse;
                border-top-right-radius: 40px;
                border-bottom-right-radius: 40px;
                font-size: 14px;
                line-height: 19px;
                letter-spacing: 0.66px;
                padding: 24px 50px 24px 50px;
                background-color: $color-secondary;
                text-transform: uppercase;
                @include font-bold-1;
                transition: background-color 0.2s linear, color 0.2s linear;
                @include media-breakpoint-down(xxl) {
                    //padding: 12px 20px 12px 13px;
                }
                @include media-breakpoint-down(xl) {
                    //border-top-right-radius: 30px;
                    //border-bottom-right-radius: 30px;
                    //padding: 10px 25px 10px 20px;
                    //font-size: 11px;
                }
                &:hover {
                    background-color: $color-warning-2;
                    //color: $color-text-main;
                }
            }
            &.btn-inverse-demi-rond {
                color: $color-text-main;
                border-top-right-radius: 40px;
                border-bottom-right-radius: 40px;
                font-size: 12px;
                line-height: 19px;
                letter-spacing: 0.66px;
                padding: 16px 25px 16px 18px;
                background-color: $color-inverse;
                text-transform: uppercase;
                @include font-bold-1;
                transition: background-color 0.2s linear, color 0.2s linear;
                @include media-breakpoint-down(xxl) {
                    padding: 12px 20px 12px 13px;
                }
                @include media-breakpoint-down(xl) {
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;
                    padding: 10px 25px 10px 20px;
                    font-size: 11px;
                }
                &:hover {
                    background-color: $color-warning-2;
                    color: $color-inverse;
                }
            }
            &.btn-inverse-demi-rond-txt-vert {
                color: $color-secondary;
                border-top-right-radius: 40px;
                border-bottom-right-radius: 40px;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: 0.66px;
                padding: 21px 42px 21px 33px;
                background-color: $color-inverse;
                text-transform: uppercase;
                @include font-bold-1;
                transition: background-color 0.2s linear, color 0.2s linear;
                @include media-breakpoint-down(xxl) {
                    padding: 12px 20px 12px 13px;
                }
                @include media-breakpoint-down(xl) {
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;
                    padding: 10px 25px 10px 20px;
                    font-size: 11px;
                }
                &:hover {
                    background-color: $color-primary;
                   // color: $color-inverse;
                }
            }
            &.btn-inverse {
                &:hover {
                }
            }
            &:focus {
                outline: none;
            }
        }
        .desktop-only-block{
            display: block;
            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
        .desktop-only-flex{
            display: flex;
            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
        .mobile-only-block{
            display: none;
            @include media-breakpoint-down(lg) {
                display: block;
            }
        }
        .mobile-only-flex{
            display: none;
            @include media-breakpoint-down(lg) {
                display: flex;
            }
        }
        .bg-blanc{
            background-color: white;
        }
        .bg-bleu{
            background-color: $color-alt-2;
        }
        .bg-color-turquoise{
            background-color: $color-alt-3;
        }
        .bg-color-white{
            background-color: white;
        }
        .bg-gris{
            background-color: #a1a090;
        }
        .bg-grisPale{
            background-color: $color-bg;
        }
        .bg-jaune{
            background-color: #cea94c;
        }
        .container-xxl{
            //background-color: red;
        }
        .container-fluid{
            //background-color: green;
            @include media-breakpoint-down(lg) {
                padding-left: 30px;
                padding-right: 30px;
            }
        }
        .site-main { //main
            .main-content-wrapper {
                .content-area {
                    padding-top: $header-nav-height; // hauteur de la nav
                    @include media-breakpoint-down(xxl) {
                        padding-top:149px; // hauteur de la nav
                    }
                    @include media-breakpoint-down(xl) {
                        padding-top:124px; // hauteur de la nav
                    }
                    @include media-breakpoint-down(lg) {
                        padding-top: $header-nav-height-mobile; // hauteur de la nav
                    }
                    h1 + h2 {
                    }

                    h2 + p:first-of-type {
                    }
                    section.section-main {
                        .container-fluid {
                        }
                    }
                }
            }
        }


        .offcanvas-backdrop{
            opacity: 0 !important;
        }

        //Player video 16:9 responsive
        .video-container-responsive {
            position: relative;
            //16:9
            padding-bottom: 56.25%;
            //9:16
            //padding-bottom: 177.77%;
            padding-top: 30px;
            height: 0;
            overflow: hidden;
        }

        .video-container-responsive iframe,
        .video-container-responsive object,
        .video-container-responsive embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        //Message d'alerte pour vieux browser (IE9 et moins)
        .browsehappy {
            margin: 0.2em 0;
            background: #ccc;
            color: #000;
            padding: 0.2em 0;
        }
    }
}
