#dx-page-body {
  &.page-404{
    background-color: white;
    header{
      .bg-secondary{
        //background-color: transparent!important;
      }
    }
    .site-main { //main
      .main-content-wrapper {
        .content-area {
          //padding-top: 0; // hauteur de la nav - nav transparente pour carousel
          .section-main{
            > h1{
              display: none;
            }
          }
        }
      }
    }
    .section-404{
      position: relative;
      overflow: hidden;
      .dx-bg-box{
        &.bg-1{
          width: 472px;
          height:667px;
          top: 290px;
          left: 0;
          @include media-breakpoint-down(5xl) {
            height: 632px;
            top: 290px;
            width: 472px
          }
          @include media-breakpoint-down(4xl) {
            height: 517px;
            top: 280px;
            width: 455px
          }
          @include media-breakpoint-down(xxxl) {
            height: 472px;
            width: 326px;
            top: 240px;
          }
          @include media-breakpoint-down(xxl) {
            width:184px;
            height:412px;
          }
          @include media-breakpoint-down(xl) {
            //display: none;
            width:180px;
            height:408px;
          }
          @include media-breakpoint-down(md) {
            //display: none;
            width:86px;
            height:402px;
          }
        }
      }
      .titre-center-box{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      .stitle-box{ // deja diminer a 90%
        margin-left: 50px;
        padding-top: 100px;
        @include media-breakpoint-down(5xl) {
          padding-top: 90px;
        }
        @include media-breakpoint-down(4xl) {
          padding-top: 80px;
        }
        @include media-breakpoint-down(xxxl) {
          padding-top: 70px;
        }
        @include media-breakpoint-down(xxl) {
          padding-top: 60px;
        }
        @include media-breakpoint-down(xl) {
          //padding-top:145px;
        }
        h2{
          text-align: left;
          @include font-title-med;
          //font-size: 168px;
          //line-height: 160px;
          font-size: 150px;
          line-height: 146px;
          letter-spacing: 0;
          font-style: italic;
          position: relative;
          @include media-breakpoint-down(5xl) {
            font-size: 140px;
            line-height: 136px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 124px;
            line-height: 120px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 124px;
            line-height: 120px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 112px;
            line-height: 110px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 66px;
            line-height: 60px;
            text-align: right;

          }
          .h4-1{
            //margin-right: 40px;
            margin-right: 36px;
          }
          .h4-2{
            //font-size: 32px;
            //line-height: 40px;
            font-size: 30px;
            line-height: 38px;
            @include font-reg-1;
            display: block;
            text-transform: uppercase;
            text-align: left;
            padding-top: 30px;
            margin-left: -50px;
            @include media-breakpoint-down(5xl) {
              font-size: 28px;
              line-height: 36px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 24px;
              line-height: 30px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 24px;
              line-height: 30px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 22px;
              line-height: 26px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 13px;
              line-height: 16px;
            }
            @include media-breakpoint-down(lg) {
              padding-top: 15px;
            }
            strong{
              display: block;
            }
          }
          &:before{
            content:"";
            display: block;
            // width: 80px;
            //height: 97px;
            //left: 65px;
            //background-size: 80px;
            width: 70px;
            height:80px;
            background-size: 70px;
            left: -130px;
            position: absolute;
            bottom:80px;
            background-repeat: no-repeat;
            background-image: url("/assets/img/slash.svg");
            @include media-breakpoint-down(5xl) {
              left: -120px;
              width: 60px;
              height:70px;
              background-size: 60px;
            }
            @include media-breakpoint-down(4xl) {
              background-size:60px;
              width: 60px;
              height:72px;
              bottom:60px;
              left: -100px;
            }
            @include media-breakpoint-down(xxxl) {
              background-size:60px;
              width: 60px;
              height:72px;
              bottom:50px;
              left: -100px;
            }
            @include media-breakpoint-down(xxl) {
              left: -100px;
            }
            @include media-breakpoint-down(xl) {
              background-size:32px;
              width: 32px;
              height:38px;
              bottom:40px;
              left: -80px;
            }
          }
        }
      }
      .btn-box{
        margin-top: 100px;
        margin-bottom: 120px;
        @include media-breakpoint-down(xxl) {
          margin-top: 70px;
          margin-bottom: 90px;
        }
        @include media-breakpoint-down(lg) {
          margin-top: 40px;
          margin-bottom: 90px;
        }
      }
      .bg-color-turquoise{
        position: relative;
        &:after{
          content: "";
          background-color: $color-alt-3;
          height: 100%;
          width: 42px;
          display: block;
          position: absolute;
          top: 0;
          right: -42px;
          z-index: 0;
          @include media-breakpoint-down(lg) {
            right: -30px;
            width: 30px;
          }
        }
        .img-box{
          margin-top: -270px;
          padding-bottom: 100px;
          display: flex;
          justify-content: flex-end;
          @include media-breakpoint-down(5xl) {
            margin-top: -250px;
            padding-bottom: 90px;
          }
          @include media-breakpoint-down(4xl) {
            margin-top: -230px;
            padding-bottom: 80px;
          }
          @include media-breakpoint-down(xxxl) {
            margin-top: -210px;
            padding-bottom: 70px;
          }
          @include media-breakpoint-down(xxl) {
            margin-top: -180px;
            padding-bottom: 60px;
          }
          @include media-breakpoint-down(xl) {
            margin-top: -50px;
            padding-bottom: 50px;
          }
        }
      }
    }
  }
}//#dx-page-body
