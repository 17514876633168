#dx-page-body {
  &.page-commande{
    //background-color: white;
    header{
      .bg-secondary{
        //background-color: transparent!important;
      }
    }
    .site-main { //main
      .main-content-wrapper {
        .content-area {
          //padding-top: 0; // hauteur de la nav - nav transparente pour carousel
          .section-main{
            > h1{
              display: none;
            }
          }
        }
      }
    }
    .section-form{
      position: relative;
      overflow: hidden;
      .fleche-back-box{
        padding-bottom: 30px;
        @include media-breakpoint-down(lg) {
          padding-bottom: 20px;
        }
      }
      .title-box{
        @include media-breakpoint-down(5xl) {
          //background-color: red;
        }
        @include media-breakpoint-down(4xl) {
          //background-color: yellow;
        }
        @include media-breakpoint-down(xxxl) {
          //background-color: green;
        }
        @include media-breakpoint-down(xxl) {
          //background-color: pink;
        }
        @include media-breakpoint-down(xl) {
          //background-color: mediumseagreen;
        }
        @include media-breakpoint-down(lg) {
          //background-color: hotpink;
        }
        @include media-breakpoint-down(md) {
          //background-color: salmon;
        }
        @include media-breakpoint-down(sm) {
          //background-color: greenyellow;
        }
        h2{
          padding-top: 75px;
          font-size: 32px;
          line-height: 22px;
          letter-spacing: 1.6px;
          text-transform: uppercase;
          @include font-reg-1;
          @include media-breakpoint-down(5xl) {
            padding-top: 70px;
            font-size: 30px;
            line-height: 20px;
            letter-spacing: 1.5px;
          }
          @include media-breakpoint-down(4xl) {
            padding-top: 65px;
            font-size: 28px;
            line-height: 18px;
            letter-spacing: 1.4px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-top: 60px;
            font-size: 26px;
            line-height: 16px;
            letter-spacing: 1.3px;
          }
          @include media-breakpoint-down(xxl) {
            padding-top: 55px;
            font-size: 24px;
            line-height: 14px;
            letter-spacing: 1.2px;
          }
          @include media-breakpoint-down(xl) {
            padding-top: 45px;
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 0.5px;
          }
        }
      }
      .bg-color-white{
        position: relative;
        &:before{
          content: "";
          background-color: white;
          height: 100%;
          width: 42px;
          display: block;
          position: absolute;
          top: 0;
          left: -42px;
          z-index: 0;
          @include media-breakpoint-up(xl) {
           display: none;
          }
          @include media-breakpoint-down(lg) {
            left: -30px;
            width: 30px;
          }
        }
        &:after{
          content: "";
          background-color: white;
          height: 100%;
          width: 42px;
          display: block;
          position: absolute;
          top: 0;
          right: -42px;
          z-index: 0;
          @include media-breakpoint-down(lg) {
            right: -30px;
            width: 30px;
          }
        }
        &.form-box{
          padding: 50px 280px 150px 67px;
          @include media-breakpoint-down(5xl) {
            padding: 40px 200px 130px 67px;
          }
          @include media-breakpoint-down(4xl) {
            padding: 35px 100px 120px 57px;
          }
          @include media-breakpoint-down(xxxl) {
            padding: 35px 30px 100px 57px;
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            padding:20px 0px 40px 0px;
          }
          .s-title-box{
            position: relative;
            padding-top: 55px;
            padding-bottom: 40px;
            @include media-breakpoint-down(5xl) {
              padding-top: 50px;
              padding-bottom: 35px;
            }
            @include media-breakpoint-down(4xl) {
              padding-top: 45px;
              padding-bottom: 30px;
            }
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xxl) {
              padding-top: 40px;
              padding-bottom: 25px;
            }
            @include media-breakpoint-down(lg) {
              padding-top: 20px;
              padding-bottom: 15px;
            }
            h3{
              font-size: 18px;
              line-height: 22px;
              letter-spacing: 0.9px;
              @include font-bold-1;
              text-transform: uppercase;
              margin-bottom: 0;
              @include media-breakpoint-down(5xl) {
                font-size: 17px;
                line-height: 21px;
                letter-spacing: 0.85px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.8px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 15px;
                line-height: 19px;
                letter-spacing: 0.75px;
              }
              @include media-breakpoint-down(xxl) {
              }
              @include media-breakpoint-down(xl) {
              }
              span{
                position: inherit;
                z-index: 10;
                background-color: white;
                display: inline-flex;
                padding-right: 60px;
              }
              &:after{
                width: 100%;
                height: 6px;
                background-color: #E5E5E4;
                content: "";
                display: flex;
                position: absolute;
                top:40%;
                z-index: 8;
                @include media-breakpoint-down(5xl) {
                }
                @include media-breakpoint-down(4xl) {
                  height: 5px;
                }
                @include media-breakpoint-down(xxxl) {
                }
                @include media-breakpoint-down(xxl) {
                  height: 4px;
                }
                @include media-breakpoint-down(xl) {
                }
              }
            }
          }
          .items-section{
            .items-box{
              padding-top: 12px;
              padding-bottom: 40px;
              @include media-breakpoint-down(lg) {
                padding-bottom: 20px;
              }
              .single-item-box{
                margin-bottom: 30px;
                background-color: rgba(72,68,66,0.051);
                border-radius: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-height: 79px;
                .img-box{
                  max-width: 80px;
                  img{
                    border-top-left-radius: 40px;
                    border-bottom-left-radius: 40px;
                  }
                }
                .item-number{
                  width: 92px;
                  display: flex;
                  flex-grow: 0;
                  .qty_box{
                    display: flex;

                    .qty_num{
                      background-color: transparent;
                      padding: 4px 0;
                      border: none;
                      width: 30px;
                      text-align: center;
                      font-size: 13px;
                      line-height: 19px;
                      letter-spacing: 0.65px;
                      margin-bottom: 0;
                    }
                    .qty_adjust{
                      border: none;
                      background: transparent;
                      svg{
                        width: 9px;
                        height: 17px;
                      }
                    }
                  }
                }
                .desc-box{
                  display: flex;
                  flex-grow: 1;
                  flex-direction: column;
                  padding: 2px 16px;
                  h5{
                    font-size: 13px;
                    line-height: 19px;
                    letter-spacing: 0.65px;
                    @include font-bold-1;
                    margin-bottom: 0;
                  }
                  .prix-txt{
                    font-size: 13px;
                    line-height: 19px;
                    letter-spacing: 0.65px;
                  }
                }
                .remove-item-box{
                  padding-right: 25px;
                  .btn-x{
                    border: none;
                    background: transparent;
                    font-size: 13px;
                    line-height: 19px;
                    letter-spacing: 0.65px;
                    color: $color-text-main;
                  }
                }
              }
            }
          }
          .client-infos-section{
            padding-bottom: 50px;

          }
          .event-section{
            padding-bottom: 50px;
          }
        }
      }
    }
  }
}//#dx-page-body
