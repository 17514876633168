#dx-page-body {
  .map-box{
    .map-container{
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      padding-top: 25px;
      height: 0;
      iframe, .mapping {
        filter: grayscale(100%);
        height:650px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
