#dx-page-body {
  &.page-inspiration{
    background-color: white;
    header{
      .bg-secondary{
        //background-color: transparent!important;
      }
    }
    .site-main { //main
      .main-content-wrapper {
        .content-area {
          //padding-top: 0; // hauteur de la nav - nav transparente pour carousel
          .section-main{
            > h1{
              display: none;
            }
          }
        }
      }
    }
    .section-inspiration{
      .filters-mobile-nav{
        display: none;
        @include media-breakpoint-down(xl) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          background-color: $color-bg;
        }
        .title-box{
          padding-top: 22px;
          padding-bottom: 5px;
          text-align: center;
          h2{
            font-size: 15px;
            line-height: 19px;
            @include font-reg-1;
            margin-bottom: 0;
            text-transform: uppercase;
          }
        }
        .boite-choix-boutons{
          display: flex;
          justify-content: center;
          padding-top: 22px;
          padding-bottom: 22px;
          .filtre-triage-box{
            select{
              background-color: white;
              border: none;
              padding-top:15px;
              padding-bottom: 15px;
              font-size: 8px;
              line-height: 12px;
              @include font-bold-1;
              min-width: 170px;
              border-top-right-radius: 20px;
              border-bottom-right-radius: 20px;
              text-transform: uppercase;
              background-image: url("/assets/img/ico-trier.svg");
              background-position: center right 20px;
              background-repeat: no-repeat;
            }
          }
        }
        .products-filters-box{
          display: block;
        }
        .offcanvas{
          top: $header-height-mobile;
          right: auto;
          margin: 0;
          border: none;
          border-radius: 0;
          height: 100%;
          max-height: none;
          width: 85%;
          .filtre-reinitialiser-box{
            //padding-top: 55px;
            margin-left: 0px;
            .btn-reinit-link{
              color: $color-secondary;
              border: 1px solid $color-secondary;
              @include font-bold-1;
              font-size: 8px;
              line-height: 12px;
              letter-spacing: 0.23px;
              padding: 16px 17px;
              border-top-right-radius: 30px;
              border-bottom-right-radius: 30px;
              transition: background-color 0.2s linear;
              &:after{
                display: inline-flex;
                content: "X";
                margin-left: 10px;
                margin-right: 6px;
              }
              &:hover, &:focus{
                background-color: white;
              }
            }
          }
          .offcanvas-header{
            padding: 33px 20px 13px 20px;

            .btn-link{
              font-size: 14px;
            }
          }
          .offcanvas-body{
            //padding: 0 20px;
            .items-count-box{
              padding: 0 23px;
              margin-bottom: 10px;
              .number-txt{
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.4px;
                @include font-bold-1;
              }
              .type-txt{
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.67px;
                @include font-reg-1;
                text-transform: uppercase;
              }
            }
            .products-filters-box{
              padding-right: 0;
              .accordion{
                --bs-accordion-bg: white!important;
                .accordion-item{
                  .accordion-header{//h2
                    .accordion-button{
                      font-size: 14px;
                      line-height: 24px;
                      letter-spacing: 0;
                      text-transform: none;
                      @include font-reg-1;
                      border-bottom: 1px solid transparent;
                      padding: 11px 21px;
                      &.collapsed{
                        border-bottom: 1px solid $color-border-1;
                      }
                      &:focus{
                        box-shadow: none;
                      }
                    }
                  }
                  .accordion-collapse{
                    border-bottom: 1px solid transparent;
                    &.show{
                      border-bottom: 1px solid $color-border-1;
                    }
                    .accordion-body{
                      padding: 0px 21px 20px 21px;
                      .form-check{
                        min-height: 30px;
                        .form-check-input[type=checkbox] {
                          border-radius: 50%;
                        }
                        .form-check-label{
                          font-size: 14px;
                          letter-spacing: 1.01px;
                          padding-left: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
            .filtre-title{
              font-size: 14px;
              line-height: 24px;
              letter-spacing: 0;
              text-transform: none;
              @include font-reg-1;
              padding: 11px 10px;
            }
            .recherche-filtre-mobile-box {
              padding: 10px 15px 10px 15px;
              width: 100%;
              form {
                width: 100%;
                .input-group {
                  min-width: 280px;
                  width: auto;
                  @include media-breakpoint-down(md) {
                    width: 100%;
                  }
                }
                #basic-addon1 {
                  border-bottom-left-radius: 20px;
                  border-top-left-radius: 20px;
                  background-color: $color-bg;
                  border-color: $color-bg;
                }
                input {
                  border-top-right-radius: 20px;
                  border-bottom-right-radius: 20px;
                  @include font-light-1;
                  font-size: 13px;
                  letter-spacing: 1.02px;
                  border-color: $color-bg;
                  background-color: $color-bg;
                  margin-bottom: 0;
                  padding: 0;
                }
              }
            }
            .filtre-reinitialiser-box{
              margin-left: 20px;
            }
          }
        }
      }
      .filters-top-box{
        position: fixed;
        width: 100%;
        //padding-right: 90px;
        //padding-top: 50px;
        z-index: 10;
        //display: flex;
        //flex-direction: row;
        //justify-content: space-between;
        background-color: $color-bg;
        padding-bottom: 27px;
        @include media-breakpoint-down(xl) {
          display: none;
        }
        .filtre-reinitialiser-box{
          padding-top: 55px;
          margin-left: 20px;
          &.second-init{
            padding-top: 10px;
          }
          .btn-reinit-link{
            color: $color-secondary;
            border: 1px solid $color-secondary;
            @include font-bold-1;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.35px;
            padding: 24px 26px;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            transition: background-color 0.2s linear;
            @include media-breakpoint-down(5xl) {
              //background-color: red;
              padding: 20px 10px;
            }
            @include media-breakpoint-down(4xl) {
              //background-color: yellow;
              padding: 18px 5px;
              font-size: 9px;
              line-height: 17px;
              letter-spacing: 0px;
            }
            @include media-breakpoint-down(xxxl) {
              //background-color: green;
              padding: 18px 10px;
              font-size: 11px;
            }
            @include media-breakpoint-down(xxl) {
              //background-color: pink;
              font-size: 10px;
              line-height: 16px;
              padding: 18px 5px;
            }
            @include media-breakpoint-down(xl) {
              //background-color: mediumseagreen;
            }
            @include media-breakpoint-down(lg) {
              //background-color: hotpink;
            }
            @include media-breakpoint-down(md) {
              //background-color: salmon;
            }
            @include media-breakpoint-down(sm) {
              //background-color: greenyellow;
            }
            &:after{
              display: inline-flex;
              content: "X";
              margin-left: 25px;
              margin-right: 6px;
              @include media-breakpoint-down(5xl) {
                margin-left: 10px;
              }
              @include media-breakpoint-down(4xl) {
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
              }
              @include media-breakpoint-down(xl) {
              }
              @include media-breakpoint-down(lg) {
              }
              @include media-breakpoint-down(md) {
              }
              @include media-breakpoint-down(sm) {
              }
            }
            &:hover, &:focus{
              background-color: white;
            }
          }
        }
        .title-box{
          padding-top: 49px;
          h2{
            font-size: 32px;
            line-height: 40px;
            @include font-reg-1;
            margin-bottom: 0;
            text-transform: uppercase;
            @include media-breakpoint-down(5xl) {
              font-size: 30px;
              line-height: 38px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 28px;
              line-height: 36px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 26px;
              line-height: 34px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 25px;
              line-height: 33px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 23px;
              line-height: 31px;
            }
            @include media-breakpoint-down(lg) {
            }
            @include media-breakpoint-down(md) {
            }
            @include media-breakpoint-down(sm) {
            }
          }
        }
        .item-count-filtres-box{
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-top: 49px;
          .items-count-box{
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.53px;
            padding-right: 20px;
            .number-txt{
              @include font-bold-1;
            }
            .type-txt{
              @include font-reg-1;
            }

          }
          .filtre-triage-box{
            select{
              min-width: 300px;
              background-color: white;
              border: none;
              border-bottom-right-radius: 30px;
              border-top-right-radius: 30px;
              @include media-breakpoint-down(xxl) {
                min-width: 200px;
              }
            }
          }
        }

      }
      .col-filters{
        background-color: $color-bg;
        position: relative;
        padding-right: 0;
        padding-left: 0;
        &:before{
          content: "";
          background-color: $color-bg;
          height: 100%;
          width: 100px;
          display: block;
          position: absolute;
          top: 0;
          left: -42px;
          z-index: 0;
          @include media-breakpoint-down(md) {
            left: -30px;
          }
        }
      }
      .products-filters-box{
        position: sticky;
        top:$header-nav-height-sticky;
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: calc(100vh - ($header-nav-height-sticky + 20px));


        width: 100%;
        padding-right: 0px;
        z-index: 11;
        //top: calc($header-nav-height + 110px);
        @include media-breakpoint-down(xxxl) {
          //width: 23.5%;
        }
        @include media-breakpoint-down(xl) {
          display: none; // overwrite plus haut
          width: 100%;
          position: relative;
          top:auto;
          overflow-y: auto;
        }
        .filtre-reinitialiser-box{
          padding-top: 55px;
          margin-left: 20px;
          position: relative;
          z-index: 2;
          margin-bottom:33px;
          &.second-init{
            padding-top: 20px;
          }
          .btn-reinit-link{
            color: $color-secondary;
            border: 1px solid $color-secondary;
            @include font-bold-1;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.35px;
            padding: 24px 26px;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            transition: background-color 0.2s linear;
            @include media-breakpoint-down(5xl) {
              //background-color: red;
              padding: 20px 10px;
            }
            @include media-breakpoint-down(4xl) {
              //background-color: yellow;
              padding: 18px 5px;
              font-size: 9px;
              line-height: 17px;
              letter-spacing: 0px;
            }
            @include media-breakpoint-down(xxxl) {
              //background-color: green;
              padding: 18px 10px;
              font-size: 11px;
            }
            @include media-breakpoint-down(xxl) {
              //background-color: pink;
              font-size: 10px;
              line-height: 16px;
              padding: 18px 5px;
            }
            @include media-breakpoint-down(xl) {
              //background-color: mediumseagreen;
            }
            @include media-breakpoint-down(lg) {
              //background-color: hotpink;
            }
            @include media-breakpoint-down(md) {
              //background-color: salmon;
            }
            @include media-breakpoint-down(sm) {
              //background-color: greenyellow;
            }
            &:after{
              display: inline-flex;
              content: "X";
              margin-left: 25px;
              margin-right: 6px;
              @include media-breakpoint-down(5xl) {
                margin-left: 10px;
              }
              @include media-breakpoint-down(4xl) {
              }
              @include media-breakpoint-down(xxxl) {
              }
              @include media-breakpoint-down(xxl) {
              }
              @include media-breakpoint-down(xl) {
              }
              @include media-breakpoint-down(lg) {
              }
              @include media-breakpoint-down(md) {
              }
              @include media-breakpoint-down(sm) {
              }
            }
            &:hover, &:focus{
              background-color: white;
            }
          }
        }
        .accordion{
          .accordion-button{
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.45px;
            @include font-bold-1;
            text-transform: uppercase;
            @include media-breakpoint-down(5xl) {
              font-size: 17px;
              line-height: 21px;
              letter-spacing: 0.4px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 16px;
              line-height: 20px;
              letter-spacing: 0.35px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 15px;
              line-height: 19px;
              letter-spacing: 0.3px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.25px;
            }
          }
        }
        .form-check{
          min-height: 40px;
          @include media-breakpoint-down(5xl) {
            min-height: 38px;
          }
          @include media-breakpoint-down(4xl) {
            min-height: 36px;
          }
          @include media-breakpoint-down(xxxl) {
            min-height: 34px;
          }
          @include media-breakpoint-down(xxl) {
            min-height: 32px;
          }
          .form-check-input[type=checkbox] {
            border-radius: 50%;
          }
          label{
            font-size: 18px;
            letter-spacing: 1.45px;
            @include font-light-1;
            padding-left: 10px;
            @include media-breakpoint-down(5xl) {
              font-size: 17px;
              line-height: 21px;
              letter-spacing: 0.4px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 16px;
              line-height: 20px;
              letter-spacing: 0.35px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 15px;
              line-height: 19px;
              letter-spacing: 0.3px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.25px;
            }
          }
        }
        .recherche-filtre-box {
          padding: 10px 15px 0px 15px;
          width: 100%;
          h3{
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.45px;
            @include font-bold-1;
            text-transform: uppercase;
            padding: 15px 0 5px 10px;
            @include media-breakpoint-down(5xl) {
              font-size: 17px;
              line-height: 21px;
              letter-spacing: 0.4px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 16px;
              line-height: 20px;
              letter-spacing: 0.35px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 15px;
              line-height: 19px;
              letter-spacing: 0.3px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.25px;
            }
          }
          form {
            width: 100%;
            .input-group {
              min-width: 250px;
              width: auto;
              @include media-breakpoint-down(4xl) {
                min-width: 200px;
              }
              @include media-breakpoint-down(md) {
                width: 100%;
              }
            }
            #basic-addon1 {
              border-bottom-left-radius: 20px;
              border-top-left-radius: 20px;
              background-color: white;
              border-color: white;
            }
            input {
              border-top-right-radius: 20px;
              border-bottom-right-radius: 20px;
              @include font-light-1;
              font-size: 13px;
              letter-spacing: 1.02px;
              border-color: white;
              background-color: white;
              margin-bottom: 0;
              padding: 0;
            }
          }
        }
      }
      .col-products{
        padding-left: 30px;
        padding-top: 145px;
        @include media-breakpoint-down(xl) {
          padding-top: 0px;
          padding-left: 0;
          padding-right: 0;
          overflow: hidden;
        }
        .panneau-description{
          background-color: $color-light;
          border-top-right-radius: 500px;
          border-bottom-right-radius: 500px;
          padding: 55px 120px 55px 0px;
          margin-bottom: 20px;
          display: flex;
          flex-direction: row;
          //align-items: center;
          @include media-breakpoint-down(xxxl) {
            padding: 55px 120px 55px 30px;
            flex-direction: column;
          }
            @include media-breakpoint-down(xl) {
              display: none;
            }
          .title-box{
            min-width: 30%;
            text-align: right;
            margin-right: 20px;
            @include media-breakpoint-down(4xl) {
              min-width: 38%;
            }
            @include media-breakpoint-down(xxxl) {
              width: 50%;
              padding-bottom: 30px;
            }
            @include media-breakpoint-down(xxxl) {
              width: 70%;
            }
            .title-slash-box{
              padding-bottom: 0;
              padding-left: 20px;
              .part-1{
                h2{
                  font-size: 16px;
                  line-height: 23px;
                  @include media-breakpoint-down(4xl) {
                    font-size: 15px;
                    line-height: 22px;
                  }
                  strong{
                    display: block;
                  }
                }
              }
              .part-2{
                h2{
                  font-size: 47px;
                  line-height: 55px;
                  letter-spacing: 1px;
                  margin-right: 30px;
                  text-align: right;
                  @include media-breakpoint-down(4xl) {
                    font-size: 44px;
                    line-height: 52px;
                    letter-spacing: 0.6px;
                  }
                }
              }
            }
          }
          .description-box{
            min-width: 50%;
            padding-top: 15px;
            @include media-breakpoint-down(4xl) {
              min-width: 40%;
            }
            p{
              //margin-bottom: 0;
              &:last-of-type{
                margin-bottom: 0;
              }
            }
            .plus-box{
              margin-top: 1rem;
            }
          }
          .btn-link-box{
            min-width: 20%;
            text-align: right;
            align-self: center;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            @include media-breakpoint-down(4xl) {
              min-width: 18%;
            }
            @include media-breakpoint-down(xxxl) {
              width: 100%;
              padding-top: 30px;
            }
            .btn{
              text-align: right;
              @include media-breakpoint-down(xxxl) {
                text-align: left;
              }
            }
          }
        }
        .galerie-inspiration {
          padding: 12px 0;
          .image-gallery {
            display: flex;
            flex-direction: row;
            gap: 10px;
            margin-bottom: 20px;
            @include media-breakpoint-down(lg) {
              //flex-direction: column;
            }
          }

          .image-gallery .column {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 33.333333%;
            @include media-breakpoint-down(md) {
            }
          }

          .image-item img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          /* overlay styles */

          .image-item {
            position: relative;
            cursor: pointer;
          }

          .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(57, 57, 57, 0.502);
            top: 0;
            left: 0;
            opacity: 0;
            transition: all 0.2s linear;
            color: #fff;
            /* center overlay content */
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 12px;
            line-height: 14px;
            padding: 4px;
            @include media-breakpoint-down(xxl) {
              font-size: 11px;
              line-height: 13px;
            }
            span{
              background-image: url("/assets/img/zoom.svg");
              width: 100px;
              height: 100px;
              background-size: 100px;
              @include media-breakpoint-down(xxl) {
                width: 80px;
                height: 80px;
                background-size: 80px;
              }
              @include media-breakpoint-down(lg) {
                width: 50px;
                height: 50px;
                background-size: 50px;
              }
            }
          }

          /* hover */
          .image-item:hover .overlay {
            opacity: 0.8;
          }

        }
        .stitle-box{
          padding-top: 100px;
          padding-bottom: 50px;
          @include media-breakpoint-down(5xl) {
            padding-top: 95px;
            padding-bottom: 45px;
          }
          @include media-breakpoint-down(4xl) {
            padding-top: 90px;
            padding-bottom: 40px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-top: 85px;
            padding-bottom: 35px;
          }
          @include media-breakpoint-down(xxl) {
            padding-top: 80px;
            padding-bottom: 30px;
          }
          @include media-breakpoint-down(xxl) {
            padding-top: 75px;
            padding-bottom: 25px;
          }
          @include media-breakpoint-down(lg) {
            padding-top: 30px;
            padding-bottom: 0px;
          }
          h3{
            font-size: 30px;
            line-height: 35px;
            letter-spacing: 1.51px;
            @include font-reg-1;
            text-transform: uppercase;
            @include media-breakpoint-down(5xl) {
              font-size:29px;
              line-height: 34px;
              letter-spacing: 1.41px;
            }
            @include media-breakpoint-down(4xl) {
              font-size:28px;
              line-height: 33px;
              letter-spacing: 1.31px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size:27px;
              line-height: 32px;
              letter-spacing: 1.21px;
            }
            @include media-breakpoint-down(xxl) {
              font-size:26px;
              line-height: 31px;
              letter-spacing: 1.11px;
            }
            @include media-breakpoint-down(xl) {
              font-size:25px;
              line-height: 30px;
              letter-spacing: 1.01px;
            }
            @include media-breakpoint-down(lg) {
              font-size:15px;
              line-height: 19px;
              letter-spacing: 0px;
            }
          }
        }
      }
      .products-grid-box{
        position: relative;
        background-color: white;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 15px;
        @include media-breakpoint-down(xl) {
          margin-top: 0px;
          padding-top: 20px;
        }
        &.rabais-grid{
          margin-top: 0;
          .product-box{
            padding-top: 0;
          }
        }
        &:before{
          @include media-breakpoint-down(md) {
            content: "";
            background-color: white;
            height: 100%;
            width: 100px;
            display: block;
            position: absolute;
            top: 0;
            left: -30px;
            z-index: -1;
          }
        }
        &:after{
          content: "";
          background-color: white;
          height: 100%;
          width: 100px;
          display: block;
          position: absolute;
          top: 0;
          right: -42px;
          z-index: -1;
          @include media-breakpoint-down(md) {
            right: -30px;
          }
        }

        .product-box{
          border: 18px solid white;
          width: 25%;
          padding-top: 30px;
          transition: opacity 0.5s linear, border-color 0.2s linear;
          @include media-breakpoint-down(5xl) {
            padding-top: 25px;
          }
          @include media-breakpoint-down(4xl) {
            padding-top: 20px;
          }
          @include media-breakpoint-down(xxxl) {
            width: 33.33333333%;
            padding-top: 20px;
          }
          @include media-breakpoint-down(xxl) {
            padding-top: 20px;
          }
          @include media-breakpoint-down(xl) {
            padding: 0 20px;
            width: 50%;
            border: none;
          }
          @include media-breakpoint-down(md) {
          }
          &:hover{
            cursor: hand;
            border: 18px solid $color-bg;
            @include media-breakpoint-down(xl) {
              border: none;
            }
            .btn-box{
              opacity: 1;
            }
          }
          a{
            &:hover{
              .img-box{
                img{
                  opacity: 0.85;
                }
              }
              .desc-box{
                p{
                  color: $color-secondary;
                }
              }
            }
            .desc-box{
              p{
                color: $color-text-main;
                transition: color 0.2s linear;
              }
            }
          }
          .img-box{
            text-align: center;
            //min-height: 335px;
            @include media-breakpoint-down(5xl) {
              //min-height: 305px;
            }
            @include media-breakpoint-down(4xl) {
              //min-height: 305px;
            }
            img{
              transition: opacity 0.2s linear;
            }

          }
          .desc-box{
            margin-left: 20px;
            margin-right: 20px;
            padding-bottom: 20px;
            p{
              font-size: 15px;
              line-height: 22px;
              letter-spacing: 0.75px;
              @include font-bold-1;
              @include media-breakpoint-down(xl) {
                font-size: 10px;
                line-height: 15px;
                letter-spacing: 0.2px;
              }
            }
          }
          .btn-box{
            margin: 0 20px 20px 20px;
            opacity: 0;
            @include media-breakpoint-down(xl) {
              display: none;
            }
          }
        }
      }
      .bg-white{
        position: relative;
        padding-left: 35px;
        @include media-breakpoint-down(md) {
          padding-left: 0;
        }
        &:before{
          @include media-breakpoint-down(md) {
            content: "";
            background-color: white;
            height: 100%;
            width: 100px;
            display: block;
            position: absolute;
            top: 0;
            left: -30px;
            z-index: -1;
          }
        }
        &:after{
          content: "";
          background-color: white;
          height: 100%;
          width: 100px;
          display: block;
          position: absolute;
          top: 0;
          right: -42px;
          z-index: -1;
          @include media-breakpoint-down(md) {
            right: -30px;
          }
        }
        &.bg-bottom{
          padding-bottom: 0px;
          @include media-breakpoint-down(md) {
            padding-bottom: 0px;
          }
        }
      }
      .fleche-plus-rabais-box{
        text-align: center;
        padding-top: 30px;
        padding-bottom: 30px;

      }
    }
  }
}//#dx-page-body
