#dx-page-body {
  &.page-fun{
    background-color: white;
    header{
      .bg-secondary{
        //background-color: transparent!important;
      }
    }
    .site-main { //main
      .main-content-wrapper {
        .content-area {
          //padding-top: 0; // hauteur de la nav - nav transparente pour carousel
          .section-main{
            > h1{
              display: none;
            }
          }
        }
      }
    }
    .fun-screen1{
      //height: 100vh; // - nav
      height: calc(100vh - $header-nav-height); // - nav
      min-height: 200px;
      background-color: white;
      @include media-breakpoint-down(5xl) {
      }
      @include media-breakpoint-down(4xl) {
      }
      @include media-breakpoint-down(xxxl) {
      }
      @include media-breakpoint-down(xxl) {
        height: calc(100vh - 149px); // - nav
      }
      @include media-breakpoint-down(xl) {
        height: calc(100vh - 124px); // - nav
      }
      @include media-breakpoint-down(lg) {
        height: calc(100vh - $header-nav-height-mobile) ; // - nav
      }
      @include media-breakpoint-down(md) {
      }
      @include media-breakpoint-down(sm) {
      }
      .section-title-box{
        .title-box{
          padding-top: calc(50vh - 204px);
          @include media-breakpoint-down(5xl) {
          }
          @include media-breakpoint-down(4xl) {
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(lg) {
            padding-top: calc(50vh - 180px);
          }
          h2{
            //font-size: 48px;
            //line-height: 48px;
            //letter-spacing: 2.37px;
            font-size: 44px;
            line-height: 44px;
            letter-spacing: 1.75px;
            @include font-reg-1;
            text-transform: uppercase;
            margin-bottom: 0;
            strong{
              @include font-reg-1;
              font-weight: 700;
            }
            @include media-breakpoint-down(5xl) {
              font-size: 40px;
              line-height: 40px;
              letter-spacing: 1.8px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 35px;
              line-height: 35px;
              letter-spacing: 1.45px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 35px;
              line-height: 35px;
              letter-spacing: 1.45px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 25px;
              line-height: 31px;
              letter-spacing: 1.24px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.62px;
            }
            .h2-1{
              margin-left: -600px;
              @include media-breakpoint-down(5xl) {
                margin-left: -525px;
              }
              @include media-breakpoint-down(4xl) {
                margin-left: -460px;
              }
              @include media-breakpoint-down(xxxl) {
                margin-left: -345px
              }
              @include media-breakpoint-down(xxl) {
                margin-left: -300px;
              }
              @include media-breakpoint-down(xl) {
                margin-left: -170px;
              }
            }
            .h2-2{
              display: block;
              @include font-title-med;
              //font-size: 168px;
              //line-height: 200px;
              letter-spacing: 0;
              font-style: italic;
              //margin-left: -625px;
              text-transform: none;
              font-size: 150px;
              line-height: 170px;
              @include media-breakpoint-down(5xl) {
                font-size: 135px;
                line-height: 160px;
                //margin-left: -500px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 124px;
                line-height: 150px;
                //margin-left: -425px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 110px;
                line-height: 140px;
                //margin-left: -325px;
              }
              @include media-breakpoint-down(xxl) {
                font-size: 95px;
                line-height: 130px;
                //margin-left: -255px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 44px;
                line-height: 62px;
                //margin-left: -20px;
              }
            }
          }
        }
      }
      .dx-bg-box{
        width: 180px;
        height:60vh;
        bottom: 0;
        right: 0;
        @include media-breakpoint-down(5xl) {
          //background-color: red;
          width: 180px;
        }
        @include media-breakpoint-down(4xl) {
          //background-color: yellow;
          width: 180px;
        }
        @include media-breakpoint-down(xxxl) {
          //background-color: green;
          width: 180px;
        }
        @include media-breakpoint-down(xxl) {
          //background-color: pink;
          width: 180px;
        }
        @include media-breakpoint-down(xl) {
          //background-color: mediumseagreen;
          width: 180px;
          height:100px;
        }
        @include media-breakpoint-down(lg) {
          //background-color: hotpink;
        }
        @include media-breakpoint-down(md) {
          //background-color: salmon;
        }
        @include media-breakpoint-down(sm) {
          //background-color: greenyellow;
        }
      }
    }
    .section-menu-fun{
      position: sticky;
      height: 0;
      //top:12.75%;
      top:$header-height;
      transition:top 0.6s ease-out,opacity 0.2s ease-out;
      opacity: 0;
      left: 0;
      background: white;
      z-index: 101;
      width: 100%;
      @include media-breakpoint-down(lg) {
        //top:$header-height-mobile;
        display: none;
      }
      .menu-fun-box{
        padding: 59px 60px 60px 95px;
        display: flex;
        background-color: white;
        @include media-breakpoint-down(5xl) {
          padding: 57px 58px 58px 93px;
        }
        @include media-breakpoint-down(4xl) {
          padding: 55px 56px 56px 91px;
        }
        @include media-breakpoint-down(xxxl) {
          padding: 53px 54px 54px 89px;
        }
        @include media-breakpoint-down(xxl) {
          padding: 50px 51px 51px 86px;
        }
        @include media-breakpoint-down(xl) {
          padding: 48px 49px 49px 84px;
        }
        @include media-breakpoint-down(lg) {
          padding: 46px 47px 47px 82px;
        }
        @include media-breakpoint-down(md) {
          flex-direction: column;
          max-width: 360px;
          margin: 0 auto;
        }
        .title-box{
          text-align: right;
          position: relative;
          padding-right: 75px;
          @include media-breakpoint-down(5xl) {
            padding-right: 73px;
          }
          @include media-breakpoint-down(4xl) {
            padding-right: 70px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-right: 66px;
          }
          @include media-breakpoint-down(xxl) {
            padding-right: 60px;
          }
          @include media-breakpoint-down(xl) {
            padding-right:55px;
          }
          @include media-breakpoint-down(lg) {
            padding-right: 50px;
          }
          &:after{
            width: 49px;
            height: 55px;
            background-size: 49px;
            display: inline-flex;
            content: "";
            background-image: url("/assets/img/fun-menu-title-dash.svg");
            background-repeat: no-repeat;
            position: absolute;
            right: 0;
            top:6px;
            @include media-breakpoint-down(5xl) {
              width: 47px;
              height: 53px;
              background-size: 47px;
            }
            @include media-breakpoint-down(4xl) {
              width: 45px;
              height: 51px;
              background-size: 45px;
            }
            @include media-breakpoint-down(xxxl) {
              width: 40px;
              height: 48px;
              background-size: 40px;
            }
            @include media-breakpoint-down(xxl) {
              width: 38px;
              height: 46px;
              background-size: 38px;
              top:5px;
            }
            @include media-breakpoint-down(xl) {
              width: 36px;
              height: 44px;
              background-size: 36px;
            }
            @include media-breakpoint-down(lg) {
              width: 34px;
              height: 42px;
              background-size: 34px;
            }
          }
          .menu-title-1{
            font-size: 22px;
            line-height: 28px;
            @include font-reg-1;
            display: block;
            text-transform: uppercase;
            @include media-breakpoint-down(5xl) {
              font-size: 21px;
              line-height: 27px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 20px;
              line-height: 26px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 19px;
              line-height: 25px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 18px;
              line-height: 24px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 17px;
              line-height: 23px;
            }
            @include media-breakpoint-down(lg) {
              font-size: 16px;
              line-height: 22px;
            }
          }
          .menu-title-2{
            font-size: 22px;
            line-height: 28px;
            @include font-bold-1;
            display: block;
            text-transform: uppercase;
            margin-right: 20px;
            @include media-breakpoint-down(5xl) {
              font-size: 21px;
              line-height: 27px;
              margin-right: 18px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 20px;
              line-height: 26px;
              margin-right: 16px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 19px;
              line-height: 25px;
              margin-right: 14px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 18px;
              line-height: 24px;
              margin-right: 13px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 17px;
              line-height: 23px;
              margin-right: 12px;
            }
            @include media-breakpoint-down(lg) {
              font-size: 16px;
              line-height: 22px;
              margin-right: 10px;
            }
          }
        }
        .menu-in{
          padding-top: 12px;
          padding-left: 7px;
          @include media-breakpoint-down(md) {
            padding-left: 0;
          }
          ul{
            list-style-type: none;
            display: flex;
            margin: 0;
            padding: 0;
            li{
              .menu-link{
                font-size: 34px;
                line-height: 40px;
                @include font-title-reg;
                font-style: italic;
                color: $color-text-main;
                padding: 0 30px;
                letter-spacing: -1px;
                @include media-breakpoint-down(5xl) {
                  font-size: 32px;
                  line-height: 38px;
                  padding: 0 28px;
                }
                @include media-breakpoint-down(4xl) {
                  font-size: 30px;
                  line-height: 36px;
                  padding: 0 26px;
                }
                @include media-breakpoint-down(xxxl) {
                  font-size: 29px;
                  line-height: 35px;
                  padding: 0 25px;
                }
                @include media-breakpoint-down(xxl) {
                  font-size: 28px;
                  line-height: 34px;
                  padding: 0 24px;
                }
                @include media-breakpoint-down(xl) {
                  font-size: 26px;
                  line-height: 32px;
                  padding: 0 22px;
                }
                @include media-breakpoint-down(lg) {
                  font-size: 24px;
                  line-height:30px;
                  padding: 0 20px;
                }
                &.active{
                  @include font-title-bold;
                  text-decoration: underline;
                  font-style: italic;
                }
              }
            }
          }
        }
      }
    }
    .boite-d{
      margin: 40px 0;
      position: relative;
      padding: 70px 90px 85px 155px;
      border-bottom-right-radius: 280px;
      border-top-right-radius: 280px;
      @include media-breakpoint-down(5xl) {
        margin: 30px 0;
        padding: 70px 90px 85px 155px;
      }
      @include media-breakpoint-down(4xl) {
        margin: 25px 0;
        padding: 60px 80px 75px 145px;
      }
      @include media-breakpoint-down(xxxl) {
        margin: 20px 0;
        padding: 50px 70px 65px 135px;
      }
      @include media-breakpoint-down(xxl) {
      }
      @include media-breakpoint-down(xl) {
        padding: 50px 60px 65px 90px;
      }
      @include media-breakpoint-down(lg) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        padding: 35px 0px 35px 50px;
      }
      &:after{
        @include media-breakpoint-down(lg) {
          content: "";
          background-color: white;
          width: 300px;
          height: 100%;
          display: block;
          position: absolute;
          top: 0;
          right: -30px;
          z-index: 0;
        }
      }
      &.boite-open{
        border-bottom-right-radius: 500px;
        border-top-right-radius: 500px;
        padding-right: 150px;
        @include media-breakpoint-down(lg) {
          padding-right: 10px;
        }
      }
      .content-box{
        position: relative;
        z-index: 2;
      }
      .title-slash-v2{
        margin-left: -90px;
        @include media-breakpoint-down(5xl) {
          margin-left: -90px;
        }
        @include media-breakpoint-down(4xl) {
          margin-left: -70px;
        }
        @include media-breakpoint-down(xxxl) {
        }
        @include media-breakpoint-down(xl) {
          margin-left: -30px;
        }
        @include media-breakpoint-down(lg) {
          margin-left: 0;
        }
      }
      p{
        @include font-light-1;
        font-size: 21px;
        line-height: 32px;
        letter-spacing: 1.2px;
        @include media-breakpoint-down(4xl) {
          font-size: 20px;
          line-height: 29px;
          letter-spacing: 0.99px;
        }
        @include media-breakpoint-down(xxxl) {
          font-size: 18px;
          line-height: 27px;
          letter-spacing: 0.89px;
        }
        @include media-breakpoint-down(xl) {
          font-size: 15px;
          line-height: 22px;
          letter-spacing: 0.75px;
        }
        @include media-breakpoint-down(lg) {
          font-size: 12px;
          line-height: 19px;
          letter-spacing: 0;
        }
        strong{
          @include font-bold-1;
        }
      }
      .btn-link-box{
        margin-top: 40px;
        .btn-link{
          &.opened{
            line-height: 0;
            font-size: 0;
          }
        }
      }
    }
    .section-fun{
      .fun-bg{
        position: relative;
        background-image: url("/assets/img/fun-section-fun-1-2.jpg");
        background-size: cover;
        background-position: center;
        min-height: 1070px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include media-breakpoint-down(5xl) {
        }
        @include media-breakpoint-down(4xl) {
        }
        @include media-breakpoint-down(xxxl) {
        }
        @include media-breakpoint-down(xxl) {
        }
        @include media-breakpoint-down(xl) {
          min-height: 800px;
        }
        @include media-breakpoint-down(lg) {
          min-height: auto;
        }
        &:after{
          background-color: white;
          content: "";
          display: block;
          width: 827px;
          height: 170px;
          position: absolute;
          bottom: 0;
          left: 0;
          @include media-breakpoint-down(5xl) {
            width: 650px;
            height: 160px;
          }
          @include media-breakpoint-down(4xl) {
            width:550px;
            height: 130px;
          }
          @include media-breakpoint-down(xxxl) {
            width:500px;
            height: 120px;
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
          }
          @include media-breakpoint-down(lg) {
            width:50px;
            height: 70px;
            background-color: $color-alt-3;
          }
        }
        .play-bt-box{
          .ico-play{
            a{
              display: block;
              &:hover{
                img{
                  opacity: 0.6;
                }
              }
              img{
                transition: opacity 0.2s linear;
              }
            }
          }
        }
        .open-more-content{
          margin-bottom: -40px;
        }
      }
      .play-box-mobile{
        background-color: $color-alt-3;
        position: relative;
        padding: 40px 0;
        &:before{
          content: "";
          background-color: $color-alt-3;
          width: 100px;
          height: 100%;
          display: block;
          position: absolute;
          top: 0;
          left: -30px;
          z-index: 0;
        }
        &:after{
          content: "";
          background-color: $color-alt-3;
          width: 100px;
          height: 100%;
          display: block;
          position: absolute;
          top: 0;
          right: -30px;
          z-index: 0;
        }
        .play-content-box{
          position: relative;
          z-index: 2;
          text-align: center;
          .link-box{
            a{
              color: $color-text-main;
              font-size: 15px;
              line-height: 20px;
              @include font-title-bold;
              font-style: italic;
              text-decoration: underline;
              img{
                display: inline-block;
                padding-right: 10px;
              }
            }
          }
        }
      }
      .fun-citation-box{
        @include media-breakpoint-down(lg) {
          padding-top: 60px;
        }
        .pict-citation-box{
          margin-top: -90px;
          position: relative;
          max-width: 370px;
          @include media-breakpoint-down(lg) {
            margin-top: 30px;
          }
          img{
          }
        }
        .logo-name-box{
          padding-bottom: 20px;
          @include media-breakpoint-down(xl) {
            text-align: center;
          }
          @include media-breakpoint-down(lg) {
            padding-bottom: 5px;
            padding-top: 10px;
          }
          img{
            @include media-breakpoint-down(xxxl) {
              max-width: 200px;
            }
            @include media-breakpoint-down(xxl) {
              max-width: 175px;
            }
            @include media-breakpoint-down(xl) {
              max-width: 150px;
            }
            @include media-breakpoint-down(md) {
              max-width: 120px;
            }
          }
        }
        .citation-name-txt{
          font-size: 19px;
          line-height: 30px;
          @include media-breakpoint-down(5xl) {
            font-size: 19px;
            line-height: 30px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 17px;
            line-height: 28px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 16px;
            line-height: 27px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 16px;
            line-height: 27px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 15px;
            line-height: 26px;
            display: block;
            text-align: center;
          }
          @include media-breakpoint-down(md) {
            font-size:11px;
            line-height: 15px;
          }
        }
        .citation-box{
          text-align: center;
          padding: 30px 0;
          blockquote{
            //font-size: 60px;
            //line-height: 72px;
            font-size: 55px;
            line-height: 67px;
            @include font-title-reg;
            font-style: italic;
            @include media-breakpoint-down(4xl) {
              font-size: 40px;
              line-height: 52px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 40px;
              line-height: 52px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 35px;
              line-height: 47px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 30px;
              line-height: 41px;
            }
            @include media-breakpoint-down(md) {
              font-size: 23px;
              line-height: 28px;
            }
          }
        }
      }
    }
    .section-fiable{
      position: relative;
      .dx-bg-box{
        z-index: 100;
        &.bg-1{
          width: 158px;
          height: 255px;
          bottom: 0;
          left: 0;
          @include media-breakpoint-down(5xl) {
          }
          @include media-breakpoint-down(4xl) {
            width: 138px;
            height: 225px;
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xxl) {
            //height: 28.5%;
            width: 96px;
            height: 225px;
          }
          @include media-breakpoint-down(xl) {
            width: 86px;
            height: 125px;
            background-size: 250px;
          }
          @include media-breakpoint-down(lg) {
            height: 255px;
            width: 30px;
          }
        }
        &.bg-2{
          width: 158px;
          height: 255px;
          bottom: 0;
          right: 0;
          @include media-breakpoint-down(5xl) {
          }
          @include media-breakpoint-down(4xl) {
            width: 138px;
            height: 225px;
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xxl) {
            //height: 28.5%;
            width: 96px;
            height: 225px;
          }
          @include media-breakpoint-down(xl) {
            width: 86px;
            height: 125px;
            background-size: 250px;
          }
          @include media-breakpoint-down(lg) {
            height: 255px;
            width: 30px;
          }
        }

      }
      .fiable-intro-box{
        background-color: $color-alt-3;
        //padding-top: 125px;
        //padding-bottom: 115px;
        padding-top: 125px;
        padding-bottom: 100px;
        @include media-breakpoint-down(5xl) {
          //padding-top: 125px;
          //padding-bottom: 115px;
        }
        @include media-breakpoint-down(lg) {
          padding-top: 90px;
          padding-bottom: 30px;
        }
        .boite-d{
          margin-top: 0px;
          margin-bottom: 120px;
          margin-right: 65px;
          //padding: 80px 200px 95px 165px;
          padding: 70px 190px 85px 155px;
          border-bottom-right-radius: 350px;
          border-top-right-radius:350px;
          @include media-breakpoint-down(5xl) {
            margin-bottom: 110px;
            padding: 70px 150px 85px 125px;
          }
          @include media-breakpoint-down(4xl) {
            margin-bottom: 100px;
          }
          @include media-breakpoint-down(xxxl) {
            margin-bottom: 90px;
          }
          @include media-breakpoint-down(xxl) {
            margin-bottom: 80px;
            padding: 70px 100px 85px 50px;
          }
          @include media-breakpoint-down(xl) {
            margin-bottom: 70px;
          }
          @include media-breakpoint-down(lg) {
            margin-bottom: 50px;
            margin-right: 0px;
            padding: 50px 60px 40px 0px;
            border-bottom-right-radius: 350px;
            border-top-right-radius:350px;
          }
          &:before{
            @include media-breakpoint-down(lg) {
              content: "";
              background-color: white;
              width: 100px;
              height: 100%;
              display: block;
              position: absolute;
              top: 0;
              left: -30px;
              z-index: 0;
            }
          }
          &:after{
            display: none;
          }
          .title-slash-v2{
            padding-bottom: 55px;
            @include media-breakpoint-down(xxl) {
              margin-left: 20px;
            }
            @include media-breakpoint-down(lg) {
              padding-bottom: 35px;
            }
            &.slash-left:before{
              @include media-breakpoint-down(4xl) {
                bottom: 55px;
                left: -20px;
              }
            }
          }
        }
      }
      .col-troph{
        @include media-breakpoint-down(lg) {
          display: flex;
          justify-content: center;
          padding-left: 170px;
        }
        @include media-breakpoint-down(md) {
        }
      }
      .zero-fail-box{
        .trophee-img-box{
          max-width: 600px;

        }
        .chiffres-img-box{

        }
        .fleche-img-box{
          margin-left: -140px;
          @include media-breakpoint-down(5xl) {
            margin-left: -130px;
          }
          @include media-breakpoint-down(4xl) {
            margin-left: -100px;
            max-width: 150px;
          }
          @include media-breakpoint-down(xxxl) {
            margin-left: -40px;
            max-width: 140px;
          }
          @include media-breakpoint-down(xxl) {
            margin-left: -120px;
            max-width: 130px;
          }
          @include media-breakpoint-down(lg) {
            margin-left: -50px;
            max-width: 72px;
            margin-top: -15px;
          }
          @include media-breakpoint-down(md) {
          }
        }
      }
      .col-zero{
        @include media-breakpoint-down(lg) {
          padding: 0 0 0 60px;
          display: flex;
          justify-content: center;
        }
        @include media-breakpoint-down(md) {
          padding: 0;
        }
      }
      .zero-logo-txt-box{
        display: flex;
        flex-direction: row;
        padding-top: 30px;
        padding-bottom: 120px;
        padding-left: 85px;
        align-items: center;
        @include media-breakpoint-down(xxl) {
          padding-left: 0;
          padding-bottom: 80px;
        }
        @include media-breakpoint-down(xl) {
          padding-top: 0;
          padding-bottom: 70px;
          padding-left: 100px;
        }
        @include media-breakpoint-down(lg) {
          padding-top: 0;
          padding-bottom: 70px;
          padding-left: 0px;
        }
        .logo-img-box{
          width: 268px;
          min-width: 268px;
          @include media-breakpoint-down(5xl) {
            width: 240px;
            min-width: 240px;
          }
          @include media-breakpoint-down(4xl) {
            width: 200px;
            min-width: 200px;
          }
          @include media-breakpoint-down(xxxl) {
            width: 190px;
            min-width: 190px;
          }
          @include media-breakpoint-down(xxl) {
            width: 170px;
            min-width: 170px;
          }
          @include media-breakpoint-down(xl) {
            width: 160px;
            min-width: 160px;
          }
          @include media-breakpoint-down(lg) {
            width: 112px;
            min-width: 112px;
            margin-left: 15px;
          }
          img{
            @include media-breakpoint-down(lg) {
              width: 112px;
            }
          }
        }
        .txt-col-box{
          padding-left: 50px;
          padding-top: 55px;
          @include media-breakpoint-down(lg) {
            padding-left: 20px;
            padding-top: 30px;
            max-width: 300px;
          }
          p, p.lead{
            //font-size: 32px;
            //line-height: 40px;
            font-size: 28px;
            line-height: 36px;
            text-transform: uppercase;
            @include media-breakpoint-down(5xl) {
              font-size: 26px;
              line-height: 34px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 22px;
              line-height: 30px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 20px;
              line-height: 28px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 20px;
              line-height:28px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 18px;
              line-height: 26px;
            }
            @include media-breakpoint-down(lg) {
              font-size: 14px;
              line-height: 17px;
            }
          }
          .btn-link-box{
            padding-top: 40px;
            @include media-breakpoint-down(lg) {
              padding-top: 0px;
            }
          }
        }
      }
      .txt-box{
        padding-bottom: 100px;
        @include media-breakpoint-down(5xl) {
          padding-bottom: 130px;
        }
        @include media-breakpoint-down(4xl) {
          padding-bottom: 120px;
        }
        @include media-breakpoint-down(xxxl) {
          padding-bottom: 110px;
        }
        @include media-breakpoint-down(xxl) {
          padding-bottom: 100px;
        }
        @include media-breakpoint-down(xl) {
          padding-bottom: 80px;
        }
        @include media-breakpoint-down(lg) {
          max-width: 200px;
          margin: 0 auto;
          padding-bottom: 65px;
        }
        p{
          font-size: 21px;
          line-height: 32px;
          @include font-light-1;
          @include media-breakpoint-down(5xl) {
            font-size: 19px;
            line-height: 30px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 18px;
            line-height: 29px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 17px;
            line-height: 28px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 16px;
            line-height: 27px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 15px;
            line-height: 26px;
          }
          @include media-breakpoint-down(lg) {
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0;
          }
        }
      }
      .trophee-img-box{
        margin: 0 0 50px 0;
        @include media-breakpoint-down(lg) {
          margin: 0 auto 50px auto;
        }
      }
      .ico-qualite-box{
        max-width: 160px;
        margin: 0 auto;
        @include media-breakpoint-down(5xl) {
          max-width: 140px;
        }
        @include media-breakpoint-down(4xl) {
          max-width: 120px;
        }
        @include media-breakpoint-down(xxxl) {
          max-width: 110px;
        }
        @include media-breakpoint-down(xxl) {
          max-width: 100px;
        }
        @include media-breakpoint-down(xl) {
          max-width: 100px;
        }
        @include media-breakpoint-down(lg) {
          max-width: 114px;
        }
        .img-box{
          min-height: 100px;
          @include media-breakpoint-down(lg) {
            max-width: 69px;
            min-height: 69px;
            margin: 0 auto;
          }
        }
        .title-box{
          margin-top: 20px;
          @include media-breakpoint-down(lg) {
            margin-top:10px;
          }
          h4{
            //font-size: 48px;
            //line-height: 68px;
            @include font-title-reg;
            font-style: italic;
            font-size: 40px;
            line-height:60px;
            @include media-breakpoint-down(5xl) {
              font-size: 36px;
              line-height:56px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 34px;
              line-height:54px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 32px;
              line-height:52px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 34px;
              line-height:54px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 30px;
              line-height:43px;
            }
            @include media-breakpoint-down(lg) {
              font-size: 30px;
              line-height:43px;
            }
          }
        }
        .txt-box{
          padding-bottom: 0;
          @include media-breakpoint-down(lg) {
            padding-bottom: 85px;
          }
          p{
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.8px;
            @include media-breakpoint-down(5xl) {
              font-size: 15px;
              line-height: 23px;
              letter-spacing: 0.75px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 14px;
              line-height: 22px;
              letter-spacing: 0.7px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 13px;
              line-height: 21px;
              letter-spacing: 0.65px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 12px;
              line-height: 20px;
              letter-spacing: 0.6px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 11px;
              line-height:19px;
              letter-spacing: 0.55px;
            }
            @include media-breakpoint-down(lg) {
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.51px;
            }

          }
        }
      }

    }
    .temoignage-box{
      overflow-x: hidden;
      .grande-image-box{
        padding-top: 700px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left bottom;
        @include media-breakpoint-down(5xl) {
          padding-top: 600px;
        }
        @include media-breakpoint-down(4xl) {
          padding-top: 400px;
        }
        @include media-breakpoint-down(xxxl) {
          padding-top: 350px;
          background-position: left bottom 150px;
          background-color: black;
        }
        @include media-breakpoint-down(xl) {
          padding-top: 337px;
          background-position: left bottom 150px;
        }
        .bg-temoignage{
          border-top-right-radius: 550px;
          position: relative;
          @include media-breakpoint-down(xl) {
            border-top-right-radius: 350px;
          }
          @include media-breakpoint-down(md) {
            border-top-right-radius: 250px;
          }
          &:before{
            content: "";
            background-color: white;
            height: 100%;
            width: 100px;
            display: block;
            position: absolute;
            top: 0;
            left: -42px;
            z-index: 0;
            @include media-breakpoint-down(lg) {
              left: -30px;
            }
          }
          .col-content{
            @include media-breakpoint-down(lg) {
              padding-left: 0;
              padding-right: 0;
            }
          }
          .content-txt{
            padding-top: 30px;
            padding-bottom: 90px;
            @include media-breakpoint-down(4xl) {
              padding-top: 20px;
              padding-bottom: 50px;
            }
            @include media-breakpoint-down(xl) {
              padding-top: 95px;
              padding-bottom: 50px;
            }
            h3{
              @include font-cursive-reg;
             // font-size: 187px;
              //line-height: 187px;
              font-size: 167px;
              line-height: 167px;
              @include media-breakpoint-down(5xl) {
                font-size: 147px;
                line-height: 147px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 137px;
                line-height: 137px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 127px;
                line-height: 127px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 64px;
                line-height: 52px;
                //padding-left: 15px;
              }
            }
            h4{
              @include font-reg-1;
              text-transform: uppercase;
              //font-size: 32px;
              //line-height: 40px;
              //padding-top: 25px;
              //padding-left: 120px;
              font-size: 28px;
              line-height: 36px;
              padding-top: 20px;
              padding-left: 100px;
              @include media-breakpoint-down(5xl) {
                font-size: 24px;
                line-height: 32px;
                padding-top: 20px;
                padding-left: 90px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 22px;
                line-height: 30px;
                padding-top: 15px;
                padding-left: 50px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 20px;
                line-height: 28px;
                padding-top: 10px;
                padding-left: 70px;
                padding-right: 60px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 11px;
                line-height: 14px;
                padding-top: 10px;
                padding-left: 0px;
                padding-right: 0px;
              }
            }
            blockquote{
              @include font-reg-1;
              text-transform: uppercase;
              font-style: italic;
              font-size: 18px;
              line-height: 36px;
              letter-spacing: 0.9px;
              //padding-left: 120px;
              padding-left: 100px;
              //padding-top: 25px;
              padding-top: 20px;
              padding-right: 160px;
              position: relative;
              z-index: 20;
              @include media-breakpoint-down(5xl) {
                font-size: 17px;
                line-height: 35px;
                letter-spacing: 0.85px;
                padding-left: 90px;
                padding-top: 20px;
                padding-right: 160px;
              }
              @include media-breakpoint-down(4xl) {
                font-size: 16px;
                line-height: 34px;
                letter-spacing: 0.8px;
                padding-left: 50px;
                padding-top: 20px;
                padding-right: 100px;
              }
              @include media-breakpoint-down(xxxl) {
                font-size: 15px;
                line-height: 33px;
                letter-spacing: 0.7px;
                padding-left: 70px;
                padding-top: 15px;
                padding-right: 80px;
              }
              @include media-breakpoint-down(xl) {
                font-size: 9px;
                line-height: 12px;
                letter-spacing: 0.31px;
                padding-left: 0px;
                padding-top: 10px;
                padding-right: 50px;

              }
            }
            .btn-box{
              padding-left: 100px;
              padding-top: 30px;
              position: relative;
              z-index: 20;
              @include media-breakpoint-down(5xl) {
                padding-left: 90px;
                padding-top: 25px;
              }
              @include media-breakpoint-down(4xl) {
                padding-left: 50px;
                padding-top: 20px;
              }
              @include media-breakpoint-down(xxxl) {
                padding-left: 80px;
                padding-top: 15px;
              }
              @include media-breakpoint-down(xl) {
                padding-left: 0px;
                padding-top: 10px;
              }
            }
          }
          .logo-box{
            position: absolute;
            top: 320px;
            right: 420px;
            @include media-breakpoint-down(5xl) {
              top: 330px;
              right: 300px;
            }
            @include media-breakpoint-down(4xl) {
              top: 280px;
              right: 280px;
            }
            @include media-breakpoint-down(xxxl) {
              top: 160px;
              right: 250px;
            }
            @include media-breakpoint-down(xl) {
              top: 55px;
              right: auto;
              left: 12px;
            }
            img{
              @include media-breakpoint-down(5xl) {
                max-width: 190px;
              }
              @include media-breakpoint-down(4xl) {
                max-width: 170px;
              }
              @include media-breakpoint-down(xxxl) {
                max-width: 140px;
              }
              @include media-breakpoint-down(xl) {
                max-width: 80px;
              }
            }
          }
          .img-box{
            position: absolute;
            bottom: 0;
            right: -80px;
            @include media-breakpoint-down(lg) {
              right: -50px;
            }
            img{
                max-width: 400px;
              @include media-breakpoint-down(5xl) {
                max-width: 400px;
              }
              @include media-breakpoint-down(4xl) {
                max-width: 360px;
              }
              @include media-breakpoint-down(xxxl) {
                max-width: 300px;
              }
              @include media-breakpoint-down(xl) {
                max-width: 183px;
              }

            }
          }
        }
      }
    }
    .fail-box{
      padding-top: 70px;
      padding-bottom: 160px;
      @include media-breakpoint-down(5xl) {
        padding-top: 60px;
        padding-bottom: 150px;
      }
      @include media-breakpoint-down(4xl) {
        padding-top: 60px;
        padding-bottom: 140px;
      }
      @include media-breakpoint-down(xxxl) {
        padding-top: 60px;
        padding-bottom: 130px;
      }
      @include media-breakpoint-down(xxl) {
        padding-top: 60px;
        padding-bottom: 130px;
      }
      @include media-breakpoint-down(xl) {
        padding-top: 60px;
        padding-bottom: 185px;
      }
      .simple-txt-box{ // deja diminé de 90%
        text-align: right;
        width: 100%;
        h3{
          //font-size: 60px;
          //line-height: 70px;
          //letter-spacing: 3.12px;
          font-size: 55px;
          line-height: 65px;
          letter-spacing: 2px;
          margin-bottom: 0;
          padding-right: 80px;
          @include media-breakpoint-down(5xl) {
            font-size:48px;
            line-height: 58px;
            letter-spacing: 2.8px;
          }
          @include media-breakpoint-down(4xl) {
            font-size:44px;
            line-height: 54px;
            letter-spacing: 2.2px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 45px;
            line-height: 50px;
            letter-spacing: 2.31px;
          }
          @include media-breakpoint-down(xxl) {
            padding-right: 60px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 22px;
            line-height: 30px;
            letter-spacing: 1.17px;
          }
          @include media-breakpoint-down(lg) {
            text-align: center;
          }
        }
        h4{
          margin-bottom: 0;
          //font-size: 75px;
          //line-height: 80px;
          //letter-spacing: -3px;
          font-size: 68px;
          line-height: 73px;
          letter-spacing: -3px;
          //margin-right: -500px;
          font-style: italic;
          @include media-breakpoint-down(5xl) {
            font-size: 60px;
            line-height: 67px;
            letter-spacing: -2.5px
            //margin-right: -200px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 52px;
            line-height: 65px;
            letter-spacing: -2px
            //margin-right: -200px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 56px;
            line-height: 63px;
            letter-spacing: -2px
            //margin-right: -150px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 28px;
            line-height: 32px;
            letter-spacing: -1px
            //margin-right: -150px;
          }
          @include media-breakpoint-down(md) {
            text-align: center;
          }
        }
      }
      .chiffres-txt-box{
        display: flex;
        align-items: center;
        @include media-breakpoint-down(md) {
          justify-content: center;
        }
        .chiffre-1{
          //font-size: 430px;
          //line-height: 391px;
          font-size: 400px;
          line-height: 361px;
          @include font-title-reg;
          font-style: italic;
          @include media-breakpoint-down(5xl) {
            font-size: 370px;
            line-height: 331px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 340px;
            line-height: 301px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 310px;
            line-height: 271px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 310px;
            line-height: 271px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 172px;
            line-height: 156px;
          }
          @include media-breakpoint-down(md) {
            font-size: 172px;
            line-height: 120px;
          }
        }
        .autres-chiffres{
          //font-size: 110px;
          //line-height: 100px;
          font-size: 90px;
          line-height: 80px;
          padding-bottom: 40px;
          @include font-title-reg;
          font-style: italic;
          @include media-breakpoint-down(5xl) {
            font-size: 80px;
            line-height: 70px;
            padding-bottom: 30px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 70px;
            line-height: 60px;
            padding-bottom: 20px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 60px;
            line-height: 50px;
            padding-bottom: 10px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 60px;
            line-height: 50px;
            padding-bottom: 10px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 44px;
            line-height: 40px;
            padding-bottom: 30px;
          }
        }
      }
      .col-content{
        @include media-breakpoint-down(md) {
         padding-right: 0;
        }
      }
      .content-txt-box{
        padding-left: 20px;
        padding-top: 10px;
        @include media-breakpoint-down(lg) {
          padding-top: 0;
        }
        @include media-breakpoint-down(md) {
          padding-top: 0;
          margin-top: -15px;
        }
        p{
          font-size: 21px;
          line-height: 32px;
          @include font-light-1;
          @include media-breakpoint-down(5xl) {
            font-size: 19px;
            line-height: 30px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 18px;
            line-height: 29px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 17px;
            line-height: 28px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 16px;
            line-height: 27px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 15px;
            line-height: 26px;
          }
          @include media-breakpoint-down(lg) {
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0;
          }
        }
        p.lead{
          @include font-bold-1;
          text-transform: uppercase;
          font-size: 21px;
          line-height: 32px;
          @include media-breakpoint-down(5xl) {
            font-size: 19px;
            line-height: 30px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 18px;
            line-height: 29px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 17px;
            line-height: 28px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 16px;
            line-height: 27px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 15px;
            line-height: 26px;
          }
          @include media-breakpoint-down(lg) {
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0;
          }
        }
        .btn-box{
          padding-top: 30px;
          padding-left: 120px;
          @include media-breakpoint-down(5xl) {
            padding-top: 25px;
            padding-left: 110px;
          }
          @include media-breakpoint-down(4xl) {
            padding-top: 20px;
            padding-left: 100px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-top: 15px;
            padding-left: 90px;
          }
          @include media-breakpoint-down(xxl) {
            padding-top: 10px;
            padding-left: 80px;
          }
          @include media-breakpoint-down(md) {
            padding-top: 0;
            padding-left: 0px;
          }
        }
      }
    }
    .fail-pict-box{
      margin-top: -320px;
      position: relative;
      @include media-breakpoint-down(xxl) {
        margin-top: -260px;
      }
      @include media-breakpoint-down(md) {
        margin-top: -165px;
      }
      .dx-bg-box{
        width: 100%;
        height: 28.5%;
        top: 220px;
        right: 0;
        z-index: 0;
        @include media-breakpoint-down(5xl) {
          height: 30%;
        }
        @include media-breakpoint-down(4xl) {
          height: 30.5%;
        }
        @include media-breakpoint-down(xxxl) {
          height: 31%;
        }
        @include media-breakpoint-down(xxl) {
          height: 28.5%;
          top:180px;
        }
        @include media-breakpoint-down(xl) {
        }
        @include media-breakpoint-down(md) {
          top: 80px;
          height: 48.5%;
        }
      }
      .pict-box{
        position: relative;
        z-index: 20;
        background-color: white;
        border-top-right-radius: 460px;
        border-bottom-right-radius: 460px;
        display: inline-flex;
        padding: 120px 120px 120px 50px;
        @include media-breakpoint-down(5xl) {
          padding: 110px 110px 110px 40px;
        }
        @include media-breakpoint-down(4xl) {
          padding: 100px 100px 100px 35px;
        }
        @include media-breakpoint-down(xxxl) {
          padding: 90px 90px 90px 30px;
        }
        @include media-breakpoint-down(xxl) {
          padding: 80px 80px 80px 25px;
        }
        @include media-breakpoint-down(xl) {
          padding: 70px 70px 70px 20px;
        }
        @include media-breakpoint-down(md) {
          padding: 80px 50px 80px 0;
          margin-left: -30px;
          border-top-right-radius: 600px;
          border-bottom-right-radius: 600px;
        }
        img{
          @include media-breakpoint-down(5xl) {
            max-width: 800px;
          }
          @include media-breakpoint-down(4xl) {
            max-width: 580px;
          }
          @include media-breakpoint-down(xxxl) {
            max-width: 550px;
          }
          @include media-breakpoint-down(xxl) {
            max-width: 400px;
          }
          @include media-breakpoint-down(xl) {
            max-width: 300px;
          }
          @include media-breakpoint-down(md) {
            max-width: 260px;
          }
        }
      }
    }
    .section-facile{
      margin-top: -380px;
      //background-color: red;
      position: relative;
      z-index: 21;
      @include media-breakpoint-down(5xl) {
        margin-top: -360px;
      }
      @include media-breakpoint-down(4xl) {
        margin-top: -240px;
      }
      @include media-breakpoint-down(xxl) {
        margin-top: -160px;
      }
      @include media-breakpoint-down(md) {
        //margin-top: -160px;
      }
      .stitle-box{ // deja diminer a 90%
        margin-left: 50px;
        @include media-breakpoint-down(5xl) {
          padding-top: 65px;
        }
        @include media-breakpoint-down(4xl) {
          padding-top: 55px;
        }
        @include media-breakpoint-down(xxxl) {
          padding-top: 55px;
        }
        @include media-breakpoint-down(xxl) {
        }
        @include media-breakpoint-down(xl) {
          padding-top:145px;
        }
        h4{
          text-align: left;
          @include font-title-med;
          //font-size: 168px;
          //line-height: 160px;
          font-size: 150px;
          line-height: 146px;
          letter-spacing: 0;
          font-style: italic;
          position: relative;
          @include media-breakpoint-down(5xl) {
            font-size: 140px;
            line-height: 136px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 124px;
            line-height: 120px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 124px;
            line-height: 120px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 112px;
            line-height: 110px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 66px;
            line-height: 60px;
            text-align: right;

          }
          .h4-1{
            //margin-right: 40px;
            margin-right: 36px;
          }
          .h4-2{
            //font-size: 32px;
            //line-height: 40px;
            font-size: 30px;
            line-height: 38px;
            @include font-reg-1;
            display: block;
            text-transform: uppercase;
            text-align: left;
            padding-top: 30px;
            margin-left: -50px;
            @include media-breakpoint-down(5xl) {
              font-size: 28px;
              line-height: 36px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 24px;
              line-height: 30px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 24px;
              line-height: 30px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 22px;
              line-height: 26px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 13px;
              line-height: 16px;
            }
            @include media-breakpoint-down(lg) {
              text-align: right;
              padding-top: 15px;
            }
            strong{
            }
          }
          &:before{
            content:"";
            display: block;
            // width: 80px;
            //height: 97px;
            //left: 65px;
            //background-size: 80px;
            width: 70px;
            height:80px;
            background-size: 70px;
            left: -130px;
            position: absolute;
            bottom:0px;
            background-repeat: no-repeat;
            background-image: url("/assets/img/slash.svg");
            @include media-breakpoint-down(5xl) {
              left: -120px;
              width: 60px;
              height:70px;
              background-size: 60px;
            }
            @include media-breakpoint-down(4xl) {
              background-size:60px;
              width: 60px;
              height:72px;
              bottom:0px;
              left: -100px;
            }
            @include media-breakpoint-down(xxxl) {
              background-size:60px;
              width: 60px;
              height:72px;
              bottom:0px;
              left: -100px;
            }
            @include media-breakpoint-down(xxl) {
              left: -100px;
            }
            @include media-breakpoint-down(xl) {
              background-size:32px;
              width: 32px;
              height:38px;
              bottom:0px;
              left: auto;
              right: 160px;
            }
          }
        }
      }
      .content-box{
        padding-top: 40px;
        padding-bottom: 100px;
        @include media-breakpoint-down(5xl) {
          padding-top: 35px;
          padding-bottom: 90px;
        }
        @include media-breakpoint-down(4xl) {
          padding-top: 30px;
          padding-bottom: 70px;
        }
        @include media-breakpoint-down(xxxl) {
          padding-top: 25px;
          padding-bottom: 60px;
        }
        @include media-breakpoint-down(xxl) {
          padding-top: 20px;
          padding-bottom: 50px;
        }
        p{
          font-size: 21px;
          line-height: 32px;
          @include font-light-1;
          @include media-breakpoint-down(5xl) {
            font-size: 19px;
            line-height: 30px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 18px;
            line-height: 29px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 17px;
            line-height: 28px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 16px;
            line-height: 27px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 15px;
            line-height: 26px;
          }
          @include media-breakpoint-down(lg) {
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0;
          }
        }
        .btn-box{
          padding-top: 50px;
          @include media-breakpoint-down(5xl) {
            padding-top: 40px;
          }
          @include media-breakpoint-down(4xl) {
            padding-top: 30px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-top: 30px;
          }
          @include media-breakpoint-down(xxl) {
            padding-top: 20px;
          }
        }
      }
    }
    .section-point-a-z{
      position: relative;
      padding:0 100px;
      max-width: 1560px;
      text-align: center;
      margin: 0 auto;
      @include media-breakpoint-down(5xl) {
        padding:0 90px;
      }
      @include media-breakpoint-down(4xl) {
        padding:0 80px;
      }
      @include media-breakpoint-down(xxxl) {
        padding:0 70px;
      }
      @include media-breakpoint-down(xl) {
        padding:0 30px;
      }
      .dx-bg-box{
        &.bg-1{
          top: 1800px;
          left: -170px;
          width: 507px;
          height:720px;
          @include media-breakpoint-down(5xl) {
          }
          @include media-breakpoint-down(4xl) {
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            top: 900px;
            left: 0px;
            width: 100px;
            height:153px;
          }
          @include media-breakpoint-down(lg) {
          }
          @include media-breakpoint-down(md) {
          }
          @include media-breakpoint-down(sm) {
          }
        }
        &.bg-2{
          top: 1200px;
          right: 50px;
          width: 82px;
          height:704px;
          @include media-breakpoint-down(5xl) {
            right: -90px;
          }
          @include media-breakpoint-down(4xl) {
            right: 0;
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            display: none;
          }
          @include media-breakpoint-down(lg) {
          }
          @include media-breakpoint-down(md) {
          }
          @include media-breakpoint-down(sm) {
          }
        }
      }
      .forme-bg{
        padding: 100px 0 50px 0;
        @include media-breakpoint-down(xl) {
          padding: 100px 0 50px 0;
        }
        .title-box{
          &.title-right{
            text-align: right;
            max-width: 40%;
          }
          &.title-left{
            text-align: left;
            max-width: 40%;
            margin-left: 60%;
            margin-top: -150px;
          }
          h3{
            //font-size: 75px;
            //line-height: 85px;
            font-size: 65px;
            line-height: 75px;
            @include font-title-reg;
            font-style: italic;
            @include media-breakpoint-down(5xl) {
              font-size: 60px;
              line-height: 70px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 55px;
              line-height: 65px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 50px;
              line-height: 60px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 32px;
              line-height: 36px;
            }
          }
          h4{
            //font-size: 240px;
            //line-height: 220px;
            font-size: 220px;
            line-height: 200px;
            @include font-title-reg;
            font-style: italic;
            @include media-breakpoint-down(5xl) {
              font-size: 200px;
              line-height: 180px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 180px;
              line-height: 160px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 160px;
              line-height: 140px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 150px;
              line-height: 130px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 102px;
              line-height: 94px;
            }
          }
        }
        .ligne-centre-box{
          margin: -50px auto 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          //Objets flottants sont déplacés dans componenets/objets-flottants
          .ligne-centre{
            height: 100%;
            width: 15px;
            min-height: 2665px;
            background-color: $color-border-3;
            @include media-breakpoint-down(5xl) {
              width: 14px;
            }
            @include media-breakpoint-down(4xl) {
              width: 13px;
            }
            @include media-breakpoint-down(xxxl) {
              width: 12px;
            }
            @include media-breakpoint-down(xxl) {
              width: 11px;
            }
            @include media-breakpoint-down(xl) {
              width: 2px;
              min-height: 1100px;
            }
          }
          .fleche-bottom{
            background-image: url("/assets/img/bout-de-fleche.svg");
            width: 61px;
            height: 53px;
            background-repeat: no-repeat;
            @include media-breakpoint-down(5xl) {
              width: 55px;
              height: 48px;
              background-size: 55px;
            }
            @include media-breakpoint-down(4xl) {
              width: 50px;
              height: 43px;
              background-size: 50px;
            }
            @include media-breakpoint-down(xl) {
              width: 13px;
              height: 10px;
              background-size: 13px;
            }
          }
        }
      }
      .forme-bout-bg{
        border-bottom-right-radius: 2000px;
        border-bottom-left-radius: 2000px;
        padding: 0 110px 100px 110px;
        @include media-breakpoint-down(xl) {
          padding: 0 25px 40px 25px;
        }
        .title-box{
          text-align: center;
          h5{
            //font-size: 38px;
            //line-height: 46px;
            font-size: 34px;
            line-height: 42px;
            @include font-bold-1;
            text-transform: uppercase;
            @include media-breakpoint-down(xl) {
              font-size: 20px;
              line-height: 24px;
            }
          }
        }
        .content-box{
          margin-top: 50px;
          @include media-breakpoint-down(xl) {
            margin-top: 40px;
          }
          ul{
            list-style-type:none;
            margin: 0;
            padding: 0;
            li{
              text-align: center;
              padding: 25px 0;
              font-size: 18px;
              line-height: 22px;
              text-transform: uppercase;
              letter-spacing: 0.9px;
              margin: 0 auto;
              position: relative;
              @include media-breakpoint-down(xl) {
                font-size: 11px;
                line-height: 13px;
                letter-spacing: 0.28px;
                padding: 15px 0;
              }
              &:before{
                background-image: url("/assets/img/fleche-centre-ligne.svg");
                width: 33px;
                height: 14px;
                content: "";
                display: block;
                position: absolute;
                bottom: 13px;
                left:50%;
                margin-left: -16.5px;
                background-color: $color-alt-3;
                @include media-breakpoint-down(xl) {
                  width: 16px;
                  height: 7px;
                  background-size: 16px;
                  margin-left: -8px;
                  bottom: 9px;
                }
              }
              &:after{
                display: block;
                width: 100%;
                background-color: black;
                height: 2px;
                content: "";
                margin: 43px 0 0 0;
                @include media-breakpoint-down(xl) {
                  height:1px;
                  margin: 24px 0 0 0;
                }
              }
              &:nth-child(2){
                width: 90%;
              }
              &:nth-child(3){
                width: 75%;
              }
              &:nth-child(4){
                width: 60%;
              }
            }
          }
          .btn-box{
            text-align: center;
            margin-top: 55px;
            @include media-breakpoint-down(xl) {
              margin-top: 25px;
            }
          }
        }
      }
    }
    .section-temoignage-3{
      padding: 100px 0;
      @include media-breakpoint-down(xl) {
        padding: 50px 0;
      }
      .logo-box{
        //padding-top: 100px;
        max-width: 80%;
        text-align: center;
        @include media-breakpoint-down(xl) {
          padding-top: 15px;
          max-width: none;
        }
        img{
          margin: 0 auto;
          @include media-breakpoint-down(xl) {
            max-width: 74px;
          }
        }
      }
      .blocquote-box{
        max-width: 790px;
        margin: 0 auto;
        blockquote{
          //font-size: 60px;
          //line-height: 72px;
          font-size: 56px;
          line-height: 68px;
          @include font-title-reg;
          font-style: italic;
          @include media-breakpoint-down(5xl) {
            font-size: 50px;
            line-height: 62px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 45px;
            line-height: 57px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 40px;
            line-height: 52px;
          }
          @include media-breakpoint-down(xxl) {
            font-size:35px;
            line-height: 47px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 22px;
            line-height: 26px;
          }
        }
        h6{
          //font-size: 22px;
          //line-height: 32px;
          font-size: 20px;
          line-height: 30px;
          text-transform: uppercase;
          padding-top: 20px;
          padding-bottom: 40px;
          @include media-breakpoint-down(5xl) {
            font-size: 19px;
            line-height: 29px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 18px;
            line-height: 28px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 17px;
            line-height: 27px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 16px;
            line-height: 26px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 8px;
            line-height: 11px;
            padding-top: 5px;
            padding-bottom: 20px;
          }
        }
        .btn-box{

        }
      }
      .img-box{
        text-align: center;
        img{
          border-radius: 50%;
          margin: 0 auto;
          @include media-breakpoint-down(xl) {
            max-width: 170px;
            padding-top: 20px;
          }
        }
      }
    }
    &.nav-scrolled {
      .section-menu-fun{
        top:$header-nav-height-sticky;
      }
    }
    &.nav-scrolled-hide {
      .section-menu-fun{
        top:-1px;
      }
    }
    &.nav-scrolled-second-sub-show {
      .section-menu-fun{
        opacity:1;
      }
    }
  }
}//#dx-page-body
