#dx-page-body {
  &.page-zerofail{
    background-color: white;
    overflow: hidden;
    header{
      .bg-secondary{
        //background-color: transparent!important;
      }
    }
    .site-main { //main
      .main-content-wrapper {
        .content-area {
          //padding-top: 0; // hauteur de la nav - nav transparente pour carousel
          .section-main{
            > h1{
              display: none;
            }
          }
        }
      }
    }

    .section-zerofail-hero{
      .hero-bg{
        position: relative;
        background-image: url("/assets/img/zerofail-hero-bg.jpg");
        background-size: cover;
        background-position:top right;
        height:calc(100vh - $header-height) ;
        min-height: 915px;
       // min-height: 1498px;
        width: 100%;
        display: flex;
        flex-direction: column;
       // justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        overflow: hidden;
        @include media-breakpoint-down(5xl) {
        }
        @include media-breakpoint-down(4xl) {
        }
        @include media-breakpoint-down(xxxl) {
          background-position:top right -100px;
        }
        @include media-breakpoint-down(xxl) {
          min-height: 800px;
        }
        @include media-breakpoint-down(xl) {
        }
        @include media-breakpoint-down(lg) {
          min-height: auto;
          background-image: url("/assets/img/zerofail-hero-bg-mobile.jpg");
          background-position:top -370px right;
          height: auto;
        }
        @include media-breakpoint-down(md) {
          background-position:top -270px right;
        }
        @include media-breakpoint-down(sm) {
          background-position:top -170px right;
        }

        .play-bt-box{
          display: flex;
          align-items: center;
          .ico-play{
            width: 132px;
            @include media-breakpoint-down(5xl) {
              width: 120px;
            }
            @include media-breakpoint-down(4xl) {
              width:100px;
            }
            @include media-breakpoint-down(xxxl) {
              width: 90px;
            }
            @include media-breakpoint-down(xxl) {
              width:80px;
            }
            @include media-breakpoint-down(xl) {
              width: 60px;
            }
            a{
              display: block;
              &:hover{
                img{
                  opacity: 0.6;
                }
              }
              img{
                transition: opacity 0.2s linear;
              }
            }
          }
          .txt-box{
            margin-left: 30px;
            font-size: 34px;
            @include font-title-reg;
            font-style: italic;
            color: $color-inverse;
            @include media-breakpoint-down(5xl) {
              font-size: 30px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 28px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 24px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 20px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 15px;
              margin-left: 10px;
            }
            a{
              text-decoration: underline;
            }
          }
        }
      }
      .big-title-box{
        margin-top: 175px;
        margin-bottom: 140px;
        @include media-breakpoint-down(xl) {
          margin-top: 285px;
          margin-bottom: 40px;
        }
        h2{
          color: $color-inverse;
          font-size: 60px;
          line-height: 60px;
          letter-spacing: 3.12px;
          text-transform: uppercase;
          @include font-bold-1;
          @include media-breakpoint-down(5xl) {
            font-size: 55px;
            line-height: 55px;
            letter-spacing: 3px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 50px;
            line-height: 50px;
            letter-spacing: 2.8px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 40px;
            line-height: 40px;
            letter-spacing: 2.2px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 36px;
            line-height: 46px;
            letter-spacing: 1.8px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 29px;
            line-height: 29px;
            letter-spacing: 1.48px;
          }
          .t2{
            margin-left: 140px;
            margin-top: 3px;
            @include media-breakpoint-down(5xl) {
              margin-left: 130px;
            }
            @include media-breakpoint-down(4xl) {
              margin-left: 120px;
            }
            @include media-breakpoint-down(xxxl) {
              margin-left: 100px;
            }
            @include media-breakpoint-down(xxl) {
              margin-left: 80px;
            }
            @include media-breakpoint-down(xl) {
              margin-left: 70px;
            }
          }
          .t2, .t3{
            font-size: 75px;
            line-height: 65px;
            letter-spacing: 0;
            text-transform: none;
            @include font-title-med;
            font-style: italic;
            display: block;
            @include media-breakpoint-down(5xl) {
              font-size: 65px;
              line-height: 55px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 60px;
              line-height: 55px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 50px;
              line-height: 45px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 46px;
              line-height: 40px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 36px;
              line-height: 36px;
            }
          }
        }
      }
      .boite-d{
        margin: 40px 0;
        position: relative;
        padding: 100px 170px 75px 95px;
        border-bottom-right-radius: 280px;
        border-top-right-radius: 280px;
        @include media-breakpoint-down(5xl) {
          margin: 30px 0;
          padding: 90px 150px 65px 85px;
        }
        @include media-breakpoint-down(4xl) {
          margin: 25px 0;
          padding: 80px 130px 55px 75px;
        }
        @include media-breakpoint-down(xxxl) {
          margin: 20px 0;
          padding: 70px 120px 45px 65px;
        }
        @include media-breakpoint-down(xxl) {
        }
        @include media-breakpoint-down(xl) {
          padding: 50px 60px 65px 90px;
        }
        @include media-breakpoint-down(lg) {
          margin-left: -30px;
          margin-top: 50px;
          margin-bottom: 50px;
          padding: 35px 0px 35px 50px;
          border-bottom-right-radius: 280px;
          border-top-right-radius: 280px;
        }
        &:after{
          @include media-breakpoint-down(lg) {
            content: "";
            background-color: white;
            width: 300px;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            right: -100px;
            z-index: 0;
            border-bottom-right-radius: 280px;
            border-top-right-radius: 280px;
          }
        }
        .content-box{
          position: relative;
          z-index: 2;
          p{
            font-size: 21px;
            line-height: 32px;
            @include font-light-1;
            @include media-breakpoint-down(5xl) {
              font-size: 19px;
              line-height: 30px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 18px;
              line-height: 29px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 17px;
              line-height: 28px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 16px;
              line-height: 27px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 15px;
              line-height: 26px;
            }
            @include media-breakpoint-down(lg) {
              font-size: 13px;
              line-height: 20px;
              letter-spacing: 0;
            }
          }
        }
        .title-slash-v2{
          margin-left: -90px;
          @include media-breakpoint-down(5xl) {
            margin-left: -90px;
          }
          @include media-breakpoint-down(4xl) {
            margin-left: -70px;
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xl) {
            margin-left: -30px;
          }
          @include media-breakpoint-down(lg) {
            margin-left: 0;
          }
        }
        p{
          @include font-light-1;
          font-size: 21px;
          line-height: 32px;
          letter-spacing: 1.2px;
          @include media-breakpoint-down(4xl) {
            font-size: 20px;
            line-height: 29px;
            letter-spacing: 0.99px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.89px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 15px;
            line-height: 22px;
            letter-spacing: 0.75px;
          }
          @include media-breakpoint-down(lg) {
            font-size: 12px;
            line-height: 19px;
            letter-spacing: 0;
          }
          strong{
            @include font-bold-1;
          }
        }
        .btn-link-box{
          margin-top: 40px;
        }
      }
    }
    .section-temoignage-3{
      padding: 100px 0;
      @include media-breakpoint-down(xl) {
        padding: 50px 0;
      }
      &:after{
        // background-color: red;
        //content: "";
        //display: block;
        //width: 827px;
        //height: 170px;
        //position: absolute;
        //bottom: 0;
        //left: 0;
        @include media-breakpoint-down(5xl) {
          width: 650px;
          height: 160px;
        }
        @include media-breakpoint-down(4xl) {
          width:550px;
          height: 130px;
        }
        @include media-breakpoint-down(xxxl) {
          width:500px;
          height: 120px;
        }
        @include media-breakpoint-down(xxl) {
        }
        @include media-breakpoint-down(xl) {
        }
        @include media-breakpoint-down(lg) {
          width:50px;
          height: 70px;
          background-color: $color-alt-3;
        }
      }
      .logo-box{
        //padding-top: 100px;
        max-width: 80%;
        text-align: center;
        @include media-breakpoint-down(xl) {
          padding-top: 15px;
          max-width: none;
        }
        img{
          margin: 0 auto;
          @include media-breakpoint-down(xl) {
            max-width: 74px;
          }
        }
      }
      .blocquote-box{
        max-width: 790px;
        margin: 0 auto;
        blockquote{
          //font-size: 60px;
          //line-height: 72px;
          font-size: 56px;
          line-height: 68px;
          @include font-title-reg;
          font-style: italic;
          @include media-breakpoint-down(5xl) {
            font-size: 50px;
            line-height: 62px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 45px;
            line-height: 57px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 40px;
            line-height: 52px;
          }
          @include media-breakpoint-down(xxl) {
            font-size:35px;
            line-height: 47px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 22px;
            line-height: 26px;
          }
        }
        h6{
          //font-size: 22px;
          //line-height: 32px;
          font-size: 20px;
          line-height: 30px;
          text-transform: uppercase;
          padding-top: 20px;
          padding-bottom: 40px;
          @include media-breakpoint-down(5xl) {
            font-size: 19px;
            line-height: 29px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 18px;
            line-height: 28px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 17px;
            line-height: 27px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 16px;
            line-height: 26px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 8px;
            line-height: 11px;
            padding-top: 5px;
            padding-bottom: 20px;
          }
        }
        .btn-box{

        }
      }
      .img-box{
        text-align: center;
        img{
          border-radius: 50%;
          margin: 0 auto;
          @include media-breakpoint-down(xl) {
            max-width: 170px;
            padding-top: 20px;
          }
        }
      }
    }
    .section-fiable{
      position: relative;
      overflow: hidden;
      .dx-bg-box{
        z-index: 100;
        &.bg-1{
          width: 366px;
          height:520px;
          bottom: 590px;
          left: 0;
          @include media-breakpoint-down(5xl) {
          }
          @include media-breakpoint-down(4xl) {
            width: 200px;
            height:400px;
            bottom: 560px;
          }
          @include media-breakpoint-down(xxxl) {
            width: 172px;
            height:400px;
            bottom: 560px;
          }
          @include media-breakpoint-down(xxl) {
            width: 140px;
            height:400px;
            bottom: 520px;
          }
          @include media-breakpoint-down(xl) {
            width: 100px;
            height:376px;
            bottom: 490px;
          }
          @include media-breakpoint-down(lg) {
            width: 126px;
            height:78px;
            bottom: 180px;
          }
          @include media-breakpoint-down(sm) {
            width: 126px;
            height:78px;
            bottom: 260px;
          }
        }
        &.bg-2{
          width: 158px;
          height: 255px;
          bottom: 0;
          right: 0;
          @include media-breakpoint-down(5xl) {
          }
          @include media-breakpoint-down(4xl) {
            width: 138px;
            height: 225px;
          }
          @include media-breakpoint-down(xxxl) {
          }
          @include media-breakpoint-down(xxl) {
            //height: 28.5%;
            width: 96px;
            height: 225px;
          }
          @include media-breakpoint-down(xl) {
            width: 86px;
            height: 125px;
            background-size: 250px;
          }
          @include media-breakpoint-down(lg) {
            height: 255px;
            width: 30px;
          }
        }

      }
      .bg-box{
        position: relative;
        //background-color: red;
        background-image: url("/assets/img/zerofail-fiable-bg.jpg");
        background-size: cover;
        background-position:top right;
        //min-height: 600px;
        // min-height: 1498px;
        width: 100%;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        overflow: hidden;
        &:before{
          content: "";
          background-color: white;
          width: 660px;
          height: 360px;
          display: block;
          position: absolute;
          top: 0;
          right: 0px;
          z-index: 0;
          @include media-breakpoint-down(5xl) {
            width:500px;
            height: 310px;
          }
          @include media-breakpoint-down(4xl) {
            width:360px;
            height: 270px;
          }
          @include media-breakpoint-down(xxxl) {
            width:300px;
            height: 220px;
          }
          @include media-breakpoint-down(xxl) {
            width:200px;
            height: 200px;
          }
          @include media-breakpoint-down(xl) {
            width:40px;
            height: 145px;
          }
          @include media-breakpoint-down(sm) {
            height: 245px;
          }
        }
        &:after{
          @include media-breakpoint-down(lg) {

          }
        }
      }
      .s-title-box{
        margin-top: 165px;
        margin-bottom: 110px;
        @include media-breakpoint-down(5xl) {
          margin-top: 135px;
          margin-bottom: 100px;
        }
        @include media-breakpoint-down(4xl) {
          margin-top: 125px;
          margin-bottom: 90px;
        }
        @include media-breakpoint-down(xxxl) {
          margin-top: 90px;
          margin-bottom: 80px;
        }
        @include media-breakpoint-down(lg) {
          margin-top: 50px;
          margin-bottom: 55px;
        }
        h3{
          font-size: 35px;
          line-height: 42px;
          letter-spacing: 1.74px;
          text-transform: uppercase;
          @include font-light-1;
          color: $color-inverse;
          text-align: right;
          @include media-breakpoint-down(5xl) {
            font-size: 30px;
            line-height: 36px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 28px;
            line-height: 34px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 25px;
            line-height: 30px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 20px;
            line-height: 25px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.88px;
          }
          .t2{
            @include font-bold-1;
            display: block;
            letter-spacing: 0.8px;
            @include media-breakpoint-down(4xl) {
              letter-spacing: 0.7px;
            }
            @include media-breakpoint-down(xxxl) {
              letter-spacing: 0.3px;
            }
          }
          .t3{
            font-size: 129px;
            line-height: 163px;
            letter-spacing: 0;
            text-transform: none;
            @include font-title-reg;
            font-style: italic;
            display: block;
            margin-right: -200px;
            @include media-breakpoint-down(5xl) {
              font-size: 115px;
              line-height: 135px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 100px;
              line-height: 110px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 85px;
              line-height: 90px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 75px;
              line-height: 75px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 65px;
              line-height: 65px;
              margin-right: 0;
            }
          }
        }
      }
      .content-box{
        margin-bottom: 135px;
        @include media-breakpoint-down(xl) {
          margin-bottom:150px;
        }
        @include media-breakpoint-down(sm) {
          margin-bottom:130px;
        }
        p.lead{
          color: $color-inverse;
          margin-bottom: 60px;
          @include font-light-1;
          font-size: 21px;
          line-height: 32px;
          @include font-light-1;
          @include media-breakpoint-down(5xl) {
            font-size: 19px;
            line-height: 30px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 18px;
            line-height: 29px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 17px;
            line-height: 28px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 16px;
            line-height: 27px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 15px;
            line-height: 26px;
          }
          @include media-breakpoint-down(lg) {
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0;
            margin-bottom: 25px;
          }
        }
      }
      .boite-d{
        margin-top: -510px;
        margin-right: -60px;
        position: relative;
        padding: 100px 120px 0 95px;
        border-bottom-right-radius: 380px;
        border-top-right-radius: 380px;
        @include media-breakpoint-down(5xl) {
          padding: 90px 100px 0 85px;
          margin-top: -490px;
        }
        @include media-breakpoint-down(4xl) {
          padding: 80px 90px 0 75px;
          margin-top: -530px;
        }
        @include media-breakpoint-down(xxxl) {
          padding: 70px 80px 0 65px;
          margin-top: -470px;
        }
        @include media-breakpoint-down(xxl) {
          margin-top: -510px;
        }
        @include media-breakpoint-down(xl) {
          margin-top: -470px;
          padding: 50px 60px 0 90px;
        }
        @include media-breakpoint-down(lg) {
          margin-left: -30px;
          margin-top: -80px;
          margin-bottom: 50px;
          padding: 35px 0px 0  20px;
          border-bottom-right-radius: 0px;
          border-top-right-radius: 0px;
        }
        @include media-breakpoint-down(md) {
          margin-bottom: 0px;
        }
        &:after{

        }
        .content-box{
          position: relative;
          z-index: 2;
          p{
            font-size: 21px;
            line-height: 32px;
            @include font-light-1;
            @include media-breakpoint-down(5xl) {
              font-size: 19px;
              line-height: 30px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 18px;
              line-height: 29px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 17px;
              line-height: 28px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 16px;
              line-height: 27px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 15px;
              line-height: 26px;
            }
            @include media-breakpoint-down(lg) {
              font-size: 13px;
              line-height: 20px;
              letter-spacing: 0;
            }
          }
        }
      }
      .zero-fail-box{
        .trophee-img-box{
          max-width: 690px;

        }
        .chiffres-img-box{

        }
        .fleche-img-box{
          margin-left: -50px;
          margin-top: -30px;
          @include media-breakpoint-down(5xl) {
            margin-left: -130px;
          }
          @include media-breakpoint-down(4xl) {
            margin-left: -100px;
            max-width: 150px;
          }
          @include media-breakpoint-down(xxxl) {
            margin-left: -40px;
            max-width: 140px;
          }
          @include media-breakpoint-down(xxl) {
            margin-left: -70px;
            max-width: 130px;
          }
          @include media-breakpoint-down(lg) {
            margin-left: -50px;
            max-width: 72px;
            margin-top: -15px;
          }
          @include media-breakpoint-down(md) {
          }
        }
      }
      .col-zero{
        @include media-breakpoint-down(lg) {
          padding: 0 0 0 60px;
          display: flex;
          justify-content: center;
        }
        @include media-breakpoint-down(md) {
          padding: 0;
        }
      }
      .zero-logo-txt-box{
        display: flex;
        flex-direction: row;
        padding-top: 30px;
        padding-bottom: 95px;
        padding-left: 145px;
        align-items: center;
        @include media-breakpoint-down(5xl) {
          padding-left: 55px;
        }
        @include media-breakpoint-down(4xl) {
          padding-left: 45px;
        }
        @include media-breakpoint-down(xxl) {
          padding-left: 0;
          padding-bottom: 80px;
        }
        @include media-breakpoint-down(xl) {
          padding-top: 0;
          padding-bottom: 70px;
          padding-left: 100px;
        }
        @include media-breakpoint-down(lg) {
          padding-top: 0;
          padding-bottom: 70px;
          padding-left: 0px;
        }
        .logo-img-box{
          width: 268px;
          min-width: 313px;
          @include media-breakpoint-down(5xl) {
            width: 240px;
            min-width: 240px;
          }
          @include media-breakpoint-down(4xl) {
            width: 200px;
            min-width: 200px;
          }
          @include media-breakpoint-down(xxxl) {
            width: 190px;
            min-width: 190px;
          }
          @include media-breakpoint-down(xxl) {
            width: 170px;
            min-width: 170px;
          }
          @include media-breakpoint-down(xl) {
            width: 160px;
            min-width: 160px;
          }
          @include media-breakpoint-down(lg) {
            width: 112px;
            min-width: 112px;
            margin-left: 15px;
          }
          img{
            @include media-breakpoint-down(lg) {
              width: 112px;
            }
          }
        }
        .txt-col-box{
          padding-left: 70px;
          padding-top: 55px;
          @include media-breakpoint-down(lg) {
            padding-left: 20px;
            padding-top: 30px;
            max-width: 300px;
          }
          p, p.lead{
            //font-size: 32px;
            //line-height: 40px;
            font-size: 35px;
            line-height: 42px;
            text-transform: uppercase;
            @include media-breakpoint-down(5xl) {
              font-size: 32px;
              line-height: 39px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 29px;
              line-height: 35px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 25px;
              line-height: 30px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 22px;
              line-height:28px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 20px;
              line-height: 26px;
            }
            @include media-breakpoint-down(lg) {
              font-size: 18px;
              line-height: 22px;
            }
            strong{
              display: block;
            }
          }
        }
      }
      .txt-box{
        margin-left: 128px;
        margin-bottom: 55px;
        @include media-breakpoint-down(5xl) {
        }
        @include media-breakpoint-down(4xl) {
          margin-left: 0;
        }
        @include media-breakpoint-down(xxxl) {
        }
        @include media-breakpoint-down(xxl) {
        }
        @include media-breakpoint-down(xl) {
        }
        @include media-breakpoint-down(lg) {
          //max-width: 200px;
          //margin: 0 auto;
          //padding-bottom: 65px;
        }
        p{
          font-size: 21px;
          line-height: 32px;
          @include font-light-1;
          @include media-breakpoint-down(5xl) {
            font-size: 19px;
            line-height: 30px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 18px;
            line-height: 29px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 17px;
            line-height: 28px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 16px;
            line-height: 27px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 15px;
            line-height: 26px;
          }
          @include media-breakpoint-down(lg) {
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0;
          }
        }
      }
      .trophee-img-box{
        margin: 100px 0 50px 75px;
        @include media-breakpoint-down(xxl) {
          margin: 100px 0 50px 0;
        }
        @include media-breakpoint-down(lg) {
          margin: 0 auto 50px auto;
        }
      }
      .ico-qualite-box{
        max-width: 160px;
        margin: 0 auto;
        @include media-breakpoint-down(5xl) {
          max-width: 140px;
        }
        @include media-breakpoint-down(4xl) {
          max-width: 120px;
        }
        @include media-breakpoint-down(xxxl) {
          max-width: 110px;
        }
        @include media-breakpoint-down(xxl) {
          max-width: 100px;
        }
        @include media-breakpoint-down(xl) {
          max-width: 100px;
        }
        @include media-breakpoint-down(lg) {
          max-width: 114px;
        }
        .img-box{
          min-height: 100px;
          @include media-breakpoint-down(lg) {
            max-width: 69px;
            min-height: 69px;
            margin: 0 auto;
          }
        }
        .title-box{
          margin-top: 20px;
          @include media-breakpoint-down(lg) {
            margin-top:10px;
          }
          h4{
            //font-size: 48px;
            //line-height: 68px;
            @include font-title-reg;
            font-style: italic;
            font-size: 40px;
            line-height:60px;
            @include media-breakpoint-down(5xl) {
              font-size: 36px;
              line-height:56px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 34px;
              line-height:54px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 32px;
              line-height:52px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 34px;
              line-height:54px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 30px;
              line-height:43px;
            }
            @include media-breakpoint-down(lg) {
              font-size: 30px;
              line-height:43px;
            }
          }
        }
        .txt-box{
          padding-bottom: 0;
          @include media-breakpoint-down(lg) {
            padding-bottom: 85px;
          }
          p{
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.8px;
            @include media-breakpoint-down(5xl) {
              font-size: 15px;
              line-height: 23px;
              letter-spacing: 0.75px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 14px;
              line-height: 22px;
              letter-spacing: 0.7px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 13px;
              line-height: 21px;
              letter-spacing: 0.65px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 12px;
              line-height: 20px;
              letter-spacing: 0.6px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 11px;
              line-height:19px;
              letter-spacing: 0.55px;
            }
            @include media-breakpoint-down(lg) {
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.51px;
            }

          }
        }
      }
      .fail-list-box{
        margin-left: 100px;
        margin-right: 6%;
        @include media-breakpoint-down(4xl) {
          margin-left: 0px;
          margin-right: 0;
        }
        ul{
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          list-style-type: none;
          @include media-breakpoint-down(md) {
            padding: 0;
          }
          li{
            width: 33.3333%;
            padding-left: 80px;
            padding-right: 60px;
            position: relative;
            min-height: 55px;
            margin-bottom: 20px;
            display: flex;
            font-size: 21px;
            line-height: 32px;
            @include font-light-1;
            @include media-breakpoint-down(5xl) {
              font-size: 19px;
              line-height: 30px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 18px;
              line-height: 29px;
              padding-left:70px;
              padding-right: 50px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 17px;
              line-height: 28px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 16px;
              line-height: 27px;
              padding-left:50px;
              padding-right: 30px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 15px;
              line-height: 26px;
              padding-left:50px;
              padding-right: 30px;
            }
            @include media-breakpoint-down(lg) {
              font-size: 13px;
              line-height: 20px;
              letter-spacing: 0;
              padding-left:40px;
              padding-right: 30px;
            }
            @include media-breakpoint-down(md) {
              width: 50%;
            }
            @include media-breakpoint-down(sm) {
              display: block;
              padding-left:0px;
              padding-right: 20px;
            }
            &:before{
              content: "";
              display: inline-flex;
              background-image: url("/assets/img/zerofail-list-ico.svg");
              width: 55px;
              height: 55px;
              position: absolute;
              left: 0;
              top:15px;
              @include media-breakpoint-down(4xl) {
                top:5px;
                width: 50px;
                height: 50px;
                background-size: 50px;
              }
              @include media-breakpoint-down(xxxl) {
                top:5px;
                background-size: 45px;
                width: 45px;
                height: 45px;
              }
              @include media-breakpoint-down(xxl) {
                top:5px;
                background-size: 40px;
                width: 40px;
                height: 40px;
              }
              @include media-breakpoint-down(xl) {
                top:5px;
                background-size: 35px;
                width: 35px;
                height: 35px;
              }
              @include media-breakpoint-down(lg) {
                top:0;
                background-size: 22px;
                width: 22px;
                height: 22px;
              }
              @include media-breakpoint-down(sm) {
                display: block;
                position: relative;
                margin-bottom: 15px;
              }
            }
          }
        }
      }
      .lumiere-box{
        padding-left: 30px;
        margin-top: 50px;
        @include media-breakpoint-down(4xl) {
          max-width: 115px;
        }
        @include media-breakpoint-down(xxxl) {
          max-width: 93px;
        }
        @include media-breakpoint-down(lg) {
          max-width: 50px;
          padding-left: 0px;
        }
        @include media-breakpoint-down(md) {
          margin-top: 0;
        }
      }
      .lum-txt-box{
        margin-bottom: 125px;
        margin-left: 30px;
        @include media-breakpoint-down(4xl) {
          margin-left: 20px;
          margin-bottom: 100px;
        }
        @include media-breakpoint-down(xxl) {
          margin-bottom:80px;
        }
        @include media-breakpoint-down(xl) {
          margin-left: 0px;
        }
        @include media-breakpoint-down(md) {
          margin-left: 0px;
          margin-bottom:60px;
        }
        p{
          font-size: 21px;
          line-height: 32px;
          @include font-light-1;
          font-style: italic;
          @include media-breakpoint-down(5xl) {
            font-size: 19px;
            line-height: 30px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 18px;
            line-height: 29px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 17px;
            line-height: 28px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 16px;
            line-height: 27px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 15px;
            line-height: 26px;
          }
          @include media-breakpoint-down(lg) {
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0;
          }
        }
      }
    }
    .section-garantie{
      position: relative;
      &:before{
        width: 38%;
        height: 250px;
        background-color: $color-alt-6;
        content: "";
        display: block;
        position: absolute;
        top:-250px;
        left: 0;
        @include media-breakpoint-down(5xl) {
          height: 220px;
          top:-220px;
        }
        @include media-breakpoint-down(4xl) {
          height: 180px;
          top:-180px;
        }
        @include media-breakpoint-down(xxxl) {
          height: 160px;
          top:-160px;
        }
        @include media-breakpoint-down(xxl) {
          height: 140px;
          top:-140px;
        }
        @include media-breakpoint-down(xxl) {
          height: 120px;
          top:-120px;
        }
        @include media-breakpoint-down(xl) {
          height: 80px;
          top:-80px;
        }
        @include media-breakpoint-down(md) {
          height: 45px;
          top:-45px;
          width: 10%;
        }
      }
      .dx-bg-box{
        z-index: 100;
        &.bg-1{
          width: 297px;
          height: 640px;
          top: 650px;
          right: 0;
          z-index: 0;
          @include media-breakpoint-down(5xl) {
            top: 600px;
            width: 220px;
            height: 625px;
          }
          @include media-breakpoint-down(4xl) {
            width: 260px;
            height: 690px;
            top: 550px;
          }
          @include media-breakpoint-down(xxxl) {
            width: 145px;
            height: 640px;
            top: 510px;
          }
          @include media-breakpoint-down(xxl) {
            width: 45px;
            height: 400px;
            top: 470px;
          }
          @include media-breakpoint-down(xl) {
           display: none;
          }
        }
        &.bg-2{
          width: 366px;
          height: 722px;
          top: 1620px;
          left: 0;
          z-index: 0;
          @include media-breakpoint-down(5xl) {
            top: 1570px;
            width: 300px;
          }
          @include media-breakpoint-down(4xl) {
            top: 1420px;
          }
          @include media-breakpoint-down(xxxl) {
            top: 1290px;
            width: 200px;
          }
          @include media-breakpoint-down(xxl) {
            width: 81px;
            height: 326px;
            bottom: 520px;
            top: 1090px;
          }
          @include media-breakpoint-down(xl) {
            width: 100px;
            height:416px;
          }
          @include media-breakpoint-down(lg) {
            width: 126px;
          }
          @include media-breakpoint-down(sm) {
            width: 126px;
            top: 1390px;
            height: 216px;
          }
        }

      }
      .bg-color{
        background-color: $color-alt-6;
        color: $color-inverse;
        position: relative;
        z-index: 2;
        &:before{
          position: absolute;
          background-color: $color-alt-6;
          height: 100%;
          width: 600px;
          top: 0;
          left: -400px;
          display: inline-flex;
          content: "";
          z-index: 0;
        }
        &:after{
          @include media-breakpoint-down(xl) {
            position: absolute;
            background-color: $color-alt-6;
            height: 100%;
            width: 600px;
            top: 0;
            right: -400px;
            display: inline-flex;
            content: "";
            z-index: 0;
          }
        }
      }
      .title-box{
        //background-color: red;
        position: relative;
        z-index: 3;
        h3{
          font-size: 129px;
          line-height: 129px;
          letter-spacing: 0;
          text-transform: none;
          @include font-title-reg;
          font-style: italic;
          display: block;
          margin-right: -200px;
          @include media-breakpoint-down(5xl) {
            font-size: 115px;
            line-height: 115px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 100px;
            line-height: 100px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 85px;
            line-height: 85px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 75px;
            line-height: 75px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 65px;
            line-height: 65px;
            margin-right: 0;
            margin-bottom: 80px;
          }
          @include media-breakpoint-down(md) {
            margin-top: 75px;
            font-size: 65px;
            line-height: 45px;
          }
          .t2{
            margin-left: 130px;
            display: block;
            @include media-breakpoint-down(md) {
              margin-left: 40px;
              margin-bottom: 50px;
            }
            .t2-2{
              @include media-breakpoint-down(md) {
                display: block;
                margin-left: 70px;
              }
            }
          }
        }
      }
      .s-title-box{
        position: relative;
        z-index: 3;
        margin-top: 30px;
        h4{
          font-size: 35px;
          line-height: 42px;
          text-transform: uppercase;
          @include font-light-1;
          letter-spacing: 1.15px;
          @include media-breakpoint-down(5xl) {
            font-size: 32px;
            line-height: 39px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 29px;
            line-height: 35px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 25px;
            line-height: 30px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 22px;
            line-height:28px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 20px;
            line-height: 26px;
          }
          @include media-breakpoint-down(lg) {
            font-size: 18px;
            line-height: 22px;
          }
          strong{
            display: block;
            //letter-spacing: 0;
          }
        }
      }
      .content-box{
        margin-top: 160px;
        margin-bottom: 105px;
        position: relative;
        z-index: 3;
        @include media-breakpoint-down(xl) {
          margin-top: 30px;
          margin-bottom: 150px;
        }
        p{
          font-size: 21px;
          line-height: 32px;
          @include font-light-1;
          @include media-breakpoint-down(5xl) {
            font-size: 19px;
            line-height: 30px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 18px;
            line-height: 29px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 17px;
            line-height: 28px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 16px;
            line-height: 27px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 15px;
            line-height: 26px;
          }
          @include media-breakpoint-down(lg) {
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0;
          }
        }
      }
      .fond-blanc{
        background-color: white;
        margin-top: -370px;
        position: relative;
        z-index: 4;
        min-height: 685px;
        @include media-breakpoint-down(4xl) {
          margin-top: -410px;
        }
        @include media-breakpoint-down(xxxl) {
          margin-top: -430px;
        }
        @include media-breakpoint-down(xxl) {
          margin-top: -330px;
          min-height: 465px;
        }
        @include media-breakpoint-down(xl) {
          margin-top: -80px;
          min-height: 350px;
        }
        &:before{
          position: absolute;
          background-color: white;
          height: 100%;
          width: 600px;
          top: 0;
          left: -400px;
          display: inline-flex;
          content: "";
          z-index: 0;
        }
        img{
          z-index: 10;
          position: relative;
        }
      }
      .bg-color-turquoise{
        //margin-top: -400px;
        position: relative;
        z-index: 8;
      }
      .podium-img{
        padding-left: 80px;
        @include media-breakpoint-down(xxxl) {
          padding-left: 30px;
        }
        @include media-breakpoint-down(xxl) {
          padding-left: 20px;
        }
        img{
          margin-top: -400px;
          @include media-breakpoint-down(4xl) {
            max-width: 450px;
          }
          @include media-breakpoint-down(xxxl) {
            max-width: 420px;
          }
          @include media-breakpoint-down(xxl) {
            max-width: 320px;
            margin-top: -230px;
          }
          @include media-breakpoint-down(sm) {
            max-width: 90%;

          }
        }
      }
      .podium-content-box{
        background-color: $color-alt-3;
        padding: 40px 18% 85px 18%;
        margin-bottom: -215px;
        @include media-breakpoint-down(xxl) {
          padding: 0px 18% 10px 18%;
        }
        @include media-breakpoint-down(lg) {
          margin-bottom: -115px;
        }
        @include media-breakpoint-down(sm) {
          margin-bottom: -55px;
        }
        &:before{
          background-image: url(#{$imgFolder}zerofail-slash.svg);
          width: 80px;
          height: 97px;
          display: block;
          content: "";
          margin-left: -100px;
          @include media-breakpoint-down(4xl) {
            margin-left: -80px;
          }
          @include media-breakpoint-down(xxxl) {
            width: 71px;
            height: 80px;
            background-size: 71px;
            margin-left: -70px;
          }
          @include media-breakpoint-down(xxl) {
            width: 61px;
            height: 70px;
            background-size: 61px;
          }
          @include media-breakpoint-down(xl) {
            width: 51px;
            height: 60px;
            background-size: 51px;
          }
          @include media-breakpoint-down(lg) {
            width: 41px;
            height: 49px;
            background-size: 41px;
            margin-top: 40px;
          }
          @include media-breakpoint-down(sm) {
           margin-left: -40px;
          }
        }
        p{
          font-size: 21px;
          line-height: 32px;
          @include font-light-1;
          @include media-breakpoint-down(5xl) {
            font-size: 19px;
            line-height: 30px;
          }
          @include media-breakpoint-down(4xl) {
            font-size: 18px;
            line-height: 29px;
          }
          @include media-breakpoint-down(xxxl) {
            font-size: 17px;
            line-height: 28px;
          }
          @include media-breakpoint-down(xxl) {
            font-size: 16px;
            line-height: 27px;
          }
          @include media-breakpoint-down(xl) {
            font-size: 15px;
            line-height: 26px;
          }
          @include media-breakpoint-down(lg) {
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0;
          }
        }
      }
  }


    &.nav-scrolled {
      .section-menu-fun{
        top:$header-nav-height-sticky;
      }
    }
    &.nav-scrolled-hide {
      .section-menu-fun{
        top:-1px;
      }
    }
    &.nav-scrolled-second-sub-show {
      .section-menu-fun{
        opacity:1;
      }
    }
  }
}//#dx-page-body
