#dx-page-body {

  .open-more-content{
    display:none;
    &.open-more-content-hide{

    }
  }
  .js-btn-open-more{
    transition: visibility 0s, opacity 0.8s ease-out, height 0.8s ease-out;
    visibility: visible;
    opacity: 1;
    width:auto;
    &.opened{
      width:0px;
      visibility: hidden;
      opacity: 0;
      height: 0;
    }
  }
  .js-btn-open-less{
    transition: visibility 0s, opacity 0.8s ease-out, height 0.8s ease-out;
    visibility: hidden;
    opacity: 0;
    &.opened{
      visibility: visible;
      opacity: 1;
    }
  }

}
