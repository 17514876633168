#dx-page-body {
  .carousel-mobilier{
    .col-carousel-related-p{
      @include media-breakpoint-down(xl) {
        //padding-right: 0;
        //padding-left: 0;
      }
    }
    .carousel-related-products{
      background-color: white;
      //padding: 145px 130px 155px 80px;
      padding: 131px 116px 141px 66px;
      width: 100%;
      position: relative;
      @include media-breakpoint-down(5xl) {
        //padding: 135px 120px 145px 70px;
        padding: 125px 110px 135px 60px;
      }
      @include media-breakpoint-down(4xl) {
        padding: 115px 100px 125px 50px;
      }
      @include media-breakpoint-down(xxxl) {
        padding: 115px 100px 125px 50px;
      }
      @include media-breakpoint-down(xxl) {
        padding: 105px 90px 115px 40px;
      }
      @include media-breakpoint-down(xl) {
        padding: 30px 0 30px 0;
      }
      @include media-breakpoint-down(sm) {
      }
      &:before{
        content: "";
        background-color: white;
        height: 100%;
        width: 100px;
        display: block;
        position: absolute;
        top: 0;
        left: -42px;
        z-index: -1;
        @include media-breakpoint-down(lg) {
          left: -30px;
        }
      }
      &:after{
        @include media-breakpoint-down(lg) {
          right: -30px;
          content: "";
          background-color: white;
          height: 100%;
          width: 100px;
          display: block;
          position: absolute;
          top: 0;
          z-index: -1;
        }
      }
      .swiper-container{
        .swiper-button-prev:after, .swiper-button-next:after{
          display: none;
        }
        .swiper-button-prev{
          bottom:0;
          left: 0;
          top: 50%;
          transform: rotate(180deg);
          //margin-top: -36.5px;
          //width: 40px;
          //height: 73px;
          //background-size: 40px;
          margin-top: -32.5px;
          width: 35px;
          height: 65px;
          background-size: 35px;
          background-repeat: no-repeat;
          background-image: url("/assets/img/carousel-fleche-d.svg");
          @include media-breakpoint-down(5xl) {
            margin-top: -30px;
            width: 30px;
            height: 60px;
            background-size: 30px;
          }
          @include media-breakpoint-down(4xl) {
            margin-top: -27.5px;
            width: 25px;
            height: 55px;
            background-size: 25px;
          }
          @include media-breakpoint-down(xxxl) {
            margin-top: -27.5px;
            width: 25px;
            height: 55px;
            background-size: 25px;
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            margin-top: -6px;
            width: 12px;
            height: 23px;
            background-size: 12px;
            left: -20px;
          }
          @include media-breakpoint-down(sm) {
          }
        }
        .swiper-button-next{
          bottom:0;
          top: 50%;
          right: 45px;
          //margin-top: -36.5px;
          //width: 40px;
          //height: 73px;
          //background-size: 40px;
          margin-top: -32.5px;
          width: 35px;
          height: 65px;
          background-size: 35px;
          background-repeat: no-repeat;
          background-image: url("/assets/img/carousel-fleche-d.svg");
          @include media-breakpoint-down(5xl) {
            margin-top: -30px;
            width: 30px;
            height: 60px;
            background-size: 30px;
          }
          @include media-breakpoint-down(4xl) {
            margin-top: -27.5px;
            width: 25px;
            height: 55px;
            background-size: 25px;
          }
          @include media-breakpoint-down(xxxl) {
            margin-top: -27.5px;
            width: 25px;
            height: 55px;
            background-size: 25px;
          }
          @include media-breakpoint-down(xxl) {
          }
          @include media-breakpoint-down(xl) {
            margin-top: -6px;
            width: 12px;
            height: 23px;
            background-size: 12px;
            right: -20px;
          }
          @include media-breakpoint-down(sm) {
          }
        }
      }
    }
    .demo-slide-box{
      //background-color: red;
      .bg-img-box{
        min-height: 811px;
        background-size: cover;
        background-position: center;
        @include media-breakpoint-down(5xl) {
          min-height: 594px;
        }
        @include media-breakpoint-down(4xl) {
          min-height: 447px;
        }
        @include media-breakpoint-down(xxxl) {
          min-height: 394px;
        }
        @include media-breakpoint-down(xxl) {
          min-height: 297px;
        }
        @include media-breakpoint-down(xl) {
          min-height: 670px;
        }
        @include media-breakpoint-down(lg) {
          min-height: 522px;
        }
        @include media-breakpoint-down(md) {
          min-height: 385px;
        }
        @include media-breakpoint-down(sm) {
          min-height: 245px;
        }
        img{
          width: 100%;
          display: none;
        }
      }
    }
    .col-products-related{
      @include media-breakpoint-down(xl) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .product-related-box{
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      height: 100%;
      align-items: center;
      @include media-breakpoint-down(xl) {
        margin-top: 20px;
        flex-wrap: nowrap;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .product-box{
        //border: 20px solid white;
        //padding: 0 30px 25px 30px;
        padding: 0 20px 15px 20px;
        width: 33.333333%;
        transition: opacity 0.5s linear;
        @include media-breakpoint-down(5xl) {
          padding: 0 20px 15px 20px;
        }
        @include media-breakpoint-down(4xl) {
          padding: 0 15px 10px 15px;
        }
        @include media-breakpoint-down(xxxl) {
          width: 50%;
          //width: 33.33333333%;
          //padding-top: 40px;
        }
        @include media-breakpoint-down(xxl) {
          //padding-top: 35px;
        }
        @include media-breakpoint-down(xl) {
          //padding-top: 30px;
          min-width: 150px;
        }
        @include media-breakpoint-down(md) {
          //padding-top: 25px;
        }
        &:nth-child(5), &:nth-child(6){// ICI pour les fins de démos, j'imagine que vous devrez les gérer avec les vrais produits. La c,est sur qu'on cache des produits. C'est pour ca que je laisse aussi du rouge pour savoir que c'est à arranger.
          @include media-breakpoint-down(xxxl) {
            background-color: red;
            display: none;
          }
          @include media-breakpoint-down(xl) {
            display: block;
            background-color: transparent;
          }
        }
        &:hover{
          cursor: hand;
          .btn-box{
            //opacity: 1;
          }
        }
        a{
          &:hover{
            .img-box{
              img{
                opacity: 0.85;
              }
            }
            .desc-box{
              h5{
                color: $color-secondary;
              }
            }
          }
          .desc-box{
            h5{
              color: $color-text-main;
              transition: color 0.2s linear;
            }
          }
        }
        .img-box{
          text-align: center;
          //margin-bottom: 26px;
          margin-bottom: 22px;
          background-color: white;
          @include media-breakpoint-down(4xl) {
            margin-bottom: 13px;
          }
          @include media-breakpoint-down(xl) {
            margin-bottom: 18px;
          }
          img{
            transition: opacity 0.2s linear;
          }

        }
        .desc-box{
          margin-left: 0;
          margin-right: 0;
          //padding-bottom: 20px;
          padding-bottom: 14px;
          text-align: left;
          @include media-breakpoint-down(4xl) {
            padding-bottom: 8px;
          }
          @include media-breakpoint-down(xl) {
            padding-bottom: 5px;
          }
          h5{
            //font-size: 13px;
            //line-height: 19px;
            //letter-spacing: 0.75px;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.7px;
            @include font-bold-1;
            margin-bottom: 2px;
            color: $color-text-main;
            text-align: left;
            @include media-breakpoint-down(5xl) {
              font-size: 11px;
              line-height: 17px;
              letter-spacing: 0.65px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 10px;
              line-height: 17px;
              letter-spacing: 0.55px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 10px;
              line-height: 17px;
              letter-spacing: 0.55px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 9px;
              line-height: 16px;
              letter-spacing: 0.5px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 7.55px;
              line-height: 8.7px;
              letter-spacing: 0.38px;
              margin-bottom: 0;
            }
          }
          .prix-txt {
            @include font-reg-1;
            //font-size: 13px;
            //line-height: 19px;
            //letter-spacing: 0.65px;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.6px;
            color: $color-text-main;
            @include media-breakpoint-down(5xl) {
              font-size: 11px;
              line-height: 17px;
              letter-spacing: 0.5px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 11px;
              line-height: 17px;
              letter-spacing: 0.5px;
            }
            @include media-breakpoint-down(xxxl) {
              font-size: 11px;
              line-height: 17px;
              letter-spacing: 0.5px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 11px;
              line-height: 17px;
              letter-spacing: 0.5px;
            }
            @include media-breakpoint-down(lg) {
              font-size: 7.55px;
              line-height: 8.7px;
              letter-spacing: 0.38px;
            }
          }
          .prix-strike {
            @include font-reg-1;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 0.5px;
            text-decoration: line-through;
            display: inline-block;
            margin-left: 10px;
            vertical-align: text-top;
            color: $color-text-main;
            @include media-breakpoint-down(lg) {
              font-size: 5.81px;
              line-height: 6.4px;
              letter-spacing: 0.29px;
              vertical-align: middle;
            }
          }
        }
        .btn-box{
          //margin: 10px 0 20px 0;
          margin: 8px 0 16px 0;
          text-align: left;
          @include media-breakpoint-down(xl) {
            margin: 2px 0 5px 0;
          }
          //opacity: 0;
          .btn-add-link{
            background-color: $color-secondary;
            color: white;
            border-top-right-radius: 40px;
            border-bottom-right-radius: 40px;
            transition: background-color 0.2s linear, color 0.2s linear;
            @include font-bold-1;
            font-size: 15px;
            line-height: 22px;
            letter-spacing: 0.75px;
            padding: 24px 26px;
            width: 100%;
            display: block;
            text-transform: uppercase;
            @include media-breakpoint-down(5xl) {
              font-size: 13px;
              line-height: 20px;
              letter-spacing: 0.65px;
              padding: 18px 18px;
            }
            @include media-breakpoint-down(4xl) {
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.6px;
              padding: 16px 16px;
            }
            @include media-breakpoint-down(xxxl) {
            }
            @include media-breakpoint-down(xxl) {
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.6px;
              padding: 16px 16px;
            }
            @include media-breakpoint-down(xl) {
            }
            @include media-breakpoint-down(sm) {
            }
            &:hover{
              background-color: $color-alt-3;
              color: $color-secondary;
            }
          }
        }
      }
    }
    .fleche-plus-box{
      //margin-top: 50px;
      margin-top: 45px;
      position: relative;
      @include media-breakpoint-down(5xl) {
        margin-top: 20px;
      }
      @include media-breakpoint-down(4xl) {
        margin-top: 20px;
      }
      @include media-breakpoint-down(xxxl) {
      }
      @include media-breakpoint-down(xxl) {
      }
      @include media-breakpoint-down(xl) {
        display: none;
      }
      @include media-breakpoint-down(lg) {
      }
      @include media-breakpoint-down(md) {
      }
      @include media-breakpoint-down(sm) {
      }
    }
  }
}//#dx-page-body
