#dx-page-body {
  &.page-contact{
    background-color: white;
    header{
      .bg-secondary{
        //background-color: transparent!important;
      }
    }
    .site-main { //main
      .main-content-wrapper {
        .content-area {
          //padding-top: 0; // hauteur de la nav - nav transparente pour carousel
          .section-main{
            > h1{
              display: none;
            }
          }
        }
      }
    }
    .section-contact{
      position: relative;
      overflow: hidden;
      .dx-bg-box{
        position: absolute;
        z-index: 1;
        &.bg-1{
          width: 476px;
          height:668px;
          //top: 790px; //remettre si chat box est la
          top: 700px;
          left: 0;
          @include media-breakpoint-down(5xl) {
            // top: 780px;//chat box
            top: 665px;
            width: 472px;
            height: 632px;
          }
          @include media-breakpoint-down(4xl) {
            top: 712px;
            //top: 782px;//chatbox
            width: 453px;
            height: 518px;
          }
          @include media-breakpoint-down(xxxl) {
            //top: 782px;//chatbox
            top: 672px;
            height: 457px;
            width: 315px;
          }
          @include media-breakpoint-down(xxl) {
            width:180px;
            //top: 764px; //chat box
            top: 686px;
            height:398px;
          }
          @include media-breakpoint-down(xl) {
           display: none;
          }
        }
        &.bg-2{
          width: 48%;
          height:225px;
          top: 1580px;
          right: 0;
          @include media-breakpoint-down(5xl) {
          }
          @include media-breakpoint-down(4xl) {
            height: 180px;
            top: 1400px;
          }
          @include media-breakpoint-down(xxxl) {
            height: 146px;
            top: 1315px;
          }
          @include media-breakpoint-down(xxl) {
            height:185px;
            top: 1350px;
          }
          @include media-breakpoint-down(xl) {
            width: 90px;
            height: 110px;
            top: 1138px;
          }
        }
      }
      .title-slash-box{
        margin-bottom: 30px;
        @include media-breakpoint-down(xl) {
          margin-bottom: 15px;
        }
        &.slash-left{
          padding-left: 75px;
          @include media-breakpoint-down(xl) {
            padding-left: 46px;
          }
          &:before{
            left: 0;
          }
          .part-1{
            h2{
              margin-left: 40px;
            }
          }
        }
      }
      .infos-box-container{
        .infos-box{
          padding-bottom: 60px;
          display: block;
          @include media-breakpoint-down(xl) {
            padding-bottom: 45px;
          }
          .infos-in{
            position: relative;
            padding-left: 95px;
            @include media-breakpoint-down(xl) {
              padding-left: 57px;
            }
            &:before{
              content: "";
              background-repeat: no-repeat;
              position: absolute;
              top: 5px;
              left: 0;
            }
          }
          h3{
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.4px;
            @include font-bold-1;
            text-transform: uppercase;
            @include media-breakpoint-down(4xl) {
              font-size: 15px;
              line-height: 18px;
              letter-spacing: 0.3px;
            }
            @include media-breakpoint-down(xxl) {
              font-size: 14px;
              line-height: 17px;
              letter-spacing: 0.28px;
            }
            @include media-breakpoint-down(xl) {
              font-size: 13px;
              line-height: 16px;
              letter-spacing: 0.21px;
              margin-bottom: 5px;
            }
          }
          .infos-lien{
            font-size: 20px;
            line-height: 23px;
            @include font-title-reg;
            font-style: italic;
            text-decoration: underline;
            color: $color-text-main;
            display: block;
            transition: opacity 0.3s linear;
            @include media-breakpoint-down(xl) {
              font-size: 15px;
              line-height: 12px;
            }
            &:hover{
              opacity: 0.7;
            }
          }
          .infos-lien + .infos-lien{
            padding-top: 30px;
            @include media-breakpoint-down(xl) {
              padding-top: 12px;
            }
          }

          &.chat-box{
            .infos-in{
              &:before{
                background-image: url("/assets/img/ico-chat.svg");
                width: 58px;
                height: 55px;
                background-size: 58px;
                @include media-breakpoint-down(xxl) {
                  width: 40px;
                  height: 38px;
                  background-size:40px;
                }
                @include media-breakpoint-down(xl) {
                  width: 30px;
                  height: 28px;
                  background-size:30px;
                }
              }
            }
          }
          &.mail-box{
            .infos-in{
              &:before{
                background-image: url("/assets/img/ico-mail.svg");
                width: 58px;
                height:44px;
                background-size: 58px;
                @include media-breakpoint-down(xxl) {
                  width: 38px;
                  height: 31px;
                  background-size:38px;
                }
                @include media-breakpoint-down(xl) {
                  width: 28px;
                  height: 21px;
                  background-size:28px;
                }
              }
            }
          }
          &.whatapp-box{
            .infos-in{
              &:before{
                background-image: url("/assets/img/ico-whatapp.svg");
                width: 54px;
                height:54px;
                background-size: 54px;
                @include media-breakpoint-down(xxl) {
                  width: 36px;
                  height: 36px;
                  background-size:36px;
                }
                @include media-breakpoint-down(xl) {
                  width: 26px;
                  height: 26px;
                  background-size:26px;
                }
              }
            }
          }
          &.tel-box{
            .infos-in{
              &:before{
                background-image: url("/assets/img/ico-tel.svg");
                width: 57px;
                height:57px;
                background-size: 57px;
                @include media-breakpoint-down(xxl) {
                  width: 38px;
                  height: 38px;
                  background-size:38px;
                }
                @include media-breakpoint-down(xl) {
                  width: 28px;
                  height: 28px;
                  background-size:28px;
                }
              }
            }
          }
          &.pin-box{
            .infos-in{
              &:before{
                background-image: url("/assets/img/ico-pin.svg");
                width: 44px;
                height:61px;
                background-size: 44px;
                top: -10px;
                @include media-breakpoint-down(xxl) {
                  width: 37px;
                  height: 50px;
                  background-size:37px;
                  top: -5px;
                }
                @include media-breakpoint-down(xl) {
                  width: 27px;
                  height: 39px;
                  background-size:27px;
                  top: -3px;
                }
              }
            }
          }
        }

      }
      .contact-infos-box{

        .bulle-turquoise-box{
          background-image: url("/assets/img/contact-bulle-1.png");
          background-size: 2042px;
          background-repeat: no-repeat;
          background-position: left -705px bottom;
          padding-bottom: 55px;
          @include media-breakpoint-down(5xl) {
            background-position: left -800px bottom;
          }
          @include media-breakpoint-down(4xl) {
            background-position: left -900px bottom;
          }
          @include media-breakpoint-down(xxxl) {
            background-position: left -1000px bottom;
          }
          @include media-breakpoint-down(xxl) {
            background-position: left -1205px bottom;
          }
          @include media-breakpoint-down(xl) {
            padding-bottom: 30px;
            background-position: left -1205px bottom;
          }
          .infos-box-container{
            padding-top: 85px;
            @include media-breakpoint-down(xl) {
              padding-top: 35px;
            }
          }
          .img-mobile-box{
            display: none;
            @include media-breakpoint-down(xl) {
              display: block;
              position: absolute;
              right: 50px;
              top: 180px;
              max-width: 258px;
            }
            @include media-breakpoint-down(md) {
              right: -120px;
              top: 180px;
              max-width: 258px;
            }
          }
          .img-box{
            padding-top: 180px;
            @include media-breakpoint-down(xl) {
              display: none;
            }
          }
        }
      }
      .map-infos-box{
       // margin-top: -70px; //remettre ci chatbox en haut est la
       // margin-top: -70px; //remettre ci chatbox en haut est la
        position: relative;
        z-index: 2;
        @include media-breakpoint-down(xl) {
          margin-top:60px;
        }
        .map-box{
          margin-top: 175px;
          @include media-breakpoint-down(xxl) {
            margin-top: 150px;
          }
          @include media-breakpoint-down(xl) {
            margin-top:20px;
          }
        }
        .title-slash-box{
          margin-bottom: 60px;
          @include media-breakpoint-down(xl) {
            margin-bottom: 15px;
          }
        }
      }
      .sociaux-box{
        padding-top: 35px;
        @include media-breakpoint-down(xxl) {
        }
        @include media-breakpoint-down(xl) {
        }
        .sociaux-content-box{
          padding-top: 230px;
          padding-bottom: 130px;
          @include media-breakpoint-down(4xl) {
            padding-top: 150px;
            padding-bottom: 100px;
          }
          @include media-breakpoint-down(xxxl) {
            padding-top: 100px;
            padding-bottom: 70px;
          }
          @include media-breakpoint-down(xxl) {
            padding-top: 60px;
            padding-bottom: 100px;
          }
          @include media-breakpoint-down(xl) {
            padding-top: 30px;
            padding-bottom: 60px;
          }
          h3{
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.4px;
            @include font-bold-1;
            text-transform: uppercase;
            display: inline-flex;
            margin-right: 45px;
            @include media-breakpoint-down(xl) {
              font-size: 13px;
              line-height: 16px;
              letter-spacing: 0.21px;
            }
          }
          ul{
            list-style-type: none;
            display: inline-flex;
            margin: 0 0 0 -10px;
            padding: 0;
            li{
              padding: 0 10px;
              @include media-breakpoint-down(xxl) {
                padding: 0 5px;
              }
              @include media-breakpoint-down(xl) {
                padding: 0 8px;
              }
              .sociaux-link{
                img{
                  transition: opacity 0.2s linear;
                  @include media-breakpoint-down(xl) {
                    max-width: 27px;
                  }
                }
                &:hover{
                  img{
                    opacity: 0.7;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}//#dx-page-body
